import { APP_ENV } from './central-api';

let numberOfDaysToAdd = 14;

if (APP_ENV === 'LIVE') {
  numberOfDaysToAdd = 14;
} else if (APP_ENV === 'STAGING') {
  numberOfDaysToAdd = 1;
} else {
  numberOfDaysToAdd = 1;
}

export const DAYS_TO_ADD = numberOfDaysToAdd;
