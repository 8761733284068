import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../../../styles/components/charts/chart.scss';
import DeviceConversionRateBarChart from './DeviceConversionRateBarChart';
import SingleDeviceConversionRateBarChart from './SingleDeviceConversionRateBarChart';
import {
  conversionRateOfDesktopDummyData,
  conversionRateOfMobileDummyData,
  deviceBasedConversionRateDummyData,
} from '../../../../../../lib/constants/graphDummyData/conversionRateDummyData';
import ChartWithConfig from '../../../../../../components/charts/ChartWithConfig';

const MainDeviceBasedCRChart = () => {
  const { t } = useTranslation();
  //Time filter
  const [timeFilter, setTimeFilter] = useState<string>('all');
  const timeFilterOptionsArr = [
    { OptionValue: 'all', OptionName: t('All') },
    { OptionValue: 'last_24_hours', OptionName: t('Last24Hrs') },
    { OptionValue: 'last_7_days', OptionName: t('Last7Days') },
    { OptionValue: 'last_30_days', OptionName: t('Last30Days') },
    { OptionValue: '3_months', OptionName: t('Last3Months') },
  ];

  //Selected country
  const [selectedDevice, setSelectedDevice] = useState<string>('');

  //Comments and titles
  const [title, setTitle] = useState<string>(t('OverallConversionRate'));
  const [comment, setComment] = useState<string>('');

  const [isBackButtonVisible, setIsBackButtonVisible] =
    useState<boolean>(false);

  const renderCharts = () => {
    if (selectedDevice === '') {
      return (
        <DeviceConversionRateBarChart
          apiResponse={{
            type: 'overallDevices',
            data: deviceBasedConversionRateDummyData,
          }}
          setSelectedDevice={setSelectedDevice}
        />
      );
    } else if (selectedDevice !== '') {
      return (
        <SingleDeviceConversionRateBarChart
          apiResponse={{
            type: 'singleDevice',
            data:
              selectedDevice === 'Desktop'
                ? conversionRateOfDesktopDummyData
                : conversionRateOfMobileDummyData,
          }}
        />
      );
    }
  };

  useEffect(() => {
    if (selectedDevice === '') {
      setTitle(t('DeviceBasedConversionRate'));
      setComment(t('TheConversionRateOfCartsToCheckoutsBasedOnDevice'));
    } else if (selectedDevice !== '') {
      setTitle(`${t('TheConversionRateOf')} ${selectedDevice}`);
      setComment(`${t('ConversionRateCartsToCheckoutsIn')} ${selectedDevice}`);
    }
  }, [selectedDevice]);

  const onBackButtonClick = () => {
    if (selectedDevice !== '') {
      setSelectedDevice('');
    }
  };

  useEffect(() => {
    if (selectedDevice !== '') {
      setIsBackButtonVisible(true);
    } else {
      setIsBackButtonVisible(false);
    }
  }, [selectedDevice]);

  return (
    <ChartWithConfig
      timeFilter={timeFilter}
      setTimeFilter={setTimeFilter}
      timeFilterOptionsArr={timeFilterOptionsArr}
      isBackButtonVisible={isBackButtonVisible}
      onBackButtonClick={onBackButtonClick}
      title={title}
      comment={comment}
    >
      {renderCharts()}
    </ChartWithConfig>
  );
};

export default MainDeviceBasedCRChart;
