import '../../../../styles/pages/shop/autofill/autofill.scss';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { useTranslation } from 'react-i18next';
import store from '../../../../store/store';

const Lightspeed = () => {
  let subTitle = '';
  const { t } = useTranslation();
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;

  return (
    <SubPageInnerContentHolder
      title='Lightspeed'
      subTitle={subTitle}
      backBtnLink={isWhiteLabel ? undefined : '/integrations'}
      mainBtnIcon={undefined}
      mainBtnTitle={undefined}
      mainBtnLink={undefined}
    >
      <div className='shopPage__container1'>
        <h1>{t('ComingSoon-2')}</h1>
      </div>
    </SubPageInnerContentHolder>
  );
};

export default Lightspeed;
