import '../../styles/components/progressBar/progressBar.scss';

interface Props {
  currentLevel: number;
  steps: number;
}

const ProgressBar = ({ currentLevel, steps }: Props) => {
  const widthForOneStep = 100 / steps;
  const width = widthForOneStep * currentLevel;
  return (
    <div className='progressBar'>
      <span
        style={{ width: `${width}%` }}
        data-level={currentLevel || 1}
        className='progressBar-level'
      />
    </div>
  );
};

export default ProgressBar;
