import React from 'react';
import store from '../../store/store';
import CustomDataGrid from './CustomDataGrid';
import CustomDataGridTheme2 from './CustomDataGridTheme2';

type ColumnType = {
  field: string;
  headerName: string;
  imageField?: boolean;
  width?: number;
  highlightingValue?: { value: string; color: string }[];
};

interface Props {
  allRows: any[];
  columns: ColumnType[];
  actions?: boolean;
  onDeleteHandler?: (params: any) => void;
  onEditHandler?: (params: any) => void;
  onShareHandler?: (params: any) => void;
  onViewHandler?: (params: any) => void;
  titleField: string;
  imageField?: string;
  dateField?: string;
  excludingField?: string[];
  borderType?: 'none' | 'round';
  isManual?: string;
}

//This component is a platform to switch between different customDataGrid components
const CustomDataGridMain = ({
  isManual,
  columns,
  allRows,
  titleField,
  imageField,
  dateField,
  excludingField,
  borderType,
  onDeleteHandler,
  onEditHandler,
  onShareHandler,
  onViewHandler,
}: Props) => {
  const themeType = store.getState().UI.theme.type;

  const renderUI = () => {
    if (themeType === '2') {
      return (
        <CustomDataGridTheme2
          columns={columns}
          allRows={allRows}
          onDeleteHandler={onDeleteHandler}
          onEditHandler={onEditHandler}
          onShareHandler={onShareHandler}
          onViewHandler={onViewHandler}
        />
      );
    } else {
      return (
        <CustomDataGrid
          isManual={isManual}
          allRows={allRows}
          titleField={titleField}
          imageField={imageField}
          dateField={dateField}
          excludingField={excludingField}
          borderType={borderType}
          onDeleteHandler={onDeleteHandler}
          onEditHandler={onEditHandler}
          onShareHandler={onShareHandler}
          onViewHandler={onViewHandler}
        />
      );
    }
  };

  return renderUI();
};

export default CustomDataGridMain;
