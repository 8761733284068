import Bugsnag from '@bugsnag/js';
import { APP_ENV } from '../constants/central-api';

export function submitLogToBugsnag(
  logType: 'warning' | 'error' | 'info',
  data: any
) {
  if (APP_ENV !== 'DEV') {
    Bugsnag.notify(new Error(`${APP_ENV} : ${logType} : ${data}`));
  } else {
    console.log('Prevented Bugsnag Error Notification since APP_ENV is DEV');
    console.log(`${APP_ENV} : ${logType} : ${data}`);
  }
}
