export default function addDaysToDate(
  inputDate: string | Date,
  daysToAdd: number
): Date {
  // Parse the input date (if it's a string)
  const originalDate =
    typeof inputDate === 'string' ? new Date(inputDate) : inputDate;

  // Check if the input date is valid
  if (isNaN(originalDate.getTime())) {
    // Invalid input, default to the current date
    const currentDate = new Date();
    originalDate.setTime(currentDate.getTime());
  }

  // Add the specified number of days to the original date
  const newDate = new Date(
    originalDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000
  );

  return newDate;
}

/* 
  // Example usage:
  const inputTimestamp = "2024-04-02T06:24:59.000000Z";
  const daysToAdd = 14; // You can adjust this value as needed
  const result = addDaysToDate(inputTimestamp, daysToAdd);
  console.log("Result:", result.toISOString());
  */
