const getPaymentMethods = (availablePSP: any) => {
  let psp_payment_methods: any = {};
  for (let i = 0; i < availablePSP?.length; i++) {
    const current_psp = availablePSP[i];
    const current_psp_slug = current_psp?.slug;
    const currentPSP_PaymentMethods: any = current_psp?.payment_methods;
    psp_payment_methods[current_psp_slug] = currentPSP_PaymentMethods;
  }
  return psp_payment_methods;
};

export default getPaymentMethods;
