import '../../styles/pages/whiteLabel.scss';
import store from '../../store/store';
import { setMessage } from '../../store/reducers/appSlice';
import { setAccessToken } from '../../store/reducers/authSlice';
import { useNavigate } from 'react-router-dom';
import { fetchAppInit } from '../../lib/api/app-init';
import { submitLogToBugsnag } from '../../lib/api/log';
import isNotEmptyString from '../../lib/helper-functions/isNotEmptyString';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { HOME_ROUTE } from '../../lib/constants/route';

const PortalLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get('token');
  const token_type = 'Bearer';

  useEffect(() => {
    try {
      if (token && isNotEmptyString(token)) {
        store.dispatch(
          setAccessToken({
            sellerToken: token,
            type: token_type,
            expiresOn: moment().add(1, 'days'),
          })
        );
        navigate(HOME_ROUTE);
        fetchAppInit();
      } else {
        store.dispatch(
          setMessage({
            message: 'Authorization failed',
            messageType: 'error',
          })
        );
        console.log('PortalLogin : Token is empty or invalid.');
        submitLogToBugsnag('error', `PortalLogin : Token is empty or invalid.`);
        navigate('/login');
      }
    } catch (error) {
      store.dispatch(
        setMessage({
          message: 'Authorization failed',
          messageType: 'error',
        })
      );
      console.log('error', `PortalLogin : ${error}`);
      submitLogToBugsnag('error', `PortalLogin : ${error}`);
      navigate('/login');
    }
  }, []);

  return (
    <div className='container'>
      <div className='loadingSpinner__roller'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default PortalLogin;
