import { useNavigate } from 'react-router-dom';
import '../../styles/components/dropdowns/subPageListDropdown.scss';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';

interface Props {
  dropdownItems: {
    id: string;
    icon: any;
    title: string;
    subTitle: string;
    url: string;
  }[];
  id: number;
  onListItemClick: () => void;
}

const SubPageListDropdown = ({ dropdownItems, id, onListItemClick }: Props) => {
  const navigate = useNavigate();
  return (
    <ul className='subPageListDropdown'>
      {dropdownItems.map((item, i) => {
        return (
          <li
            onClick={() => {
              onListItemClick();
              navigate(`${item.url}/${id}`);
            }}
            key={i}
            className='subPageListDropdown__item'
          >
            <div className='subPageListDropdown__item--left'>
              <CustomSVGs svg={item.icon} />
            </div>
            <div className='subPageListDropdown__item--right'>
              <h3>{item.title}</h3>
              <p>{item.subTitle}</p>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default SubPageListDropdown;
