import '../../styles/components/charts/chart.scss';

type Props = {
  color: string;
  label: string;
  value?: number;
  symbol?: string;
  hideColorBox?: boolean;
  onClick?: () => void;
};

const ChartLegend = ({
  color,
  label,
  value,
  symbol,
  onClick,
  hideColorBox,
}: Props) => {
  return (
    <div onClick={onClick} className='chart__bottom--content-chart-legend-item'>
      {!hideColorBox && (
        <span
          className='chart__bottom--content-chart-legend-item-color'
          style={{ backgroundColor: color }}
        ></span>
      )}
      <span className='chart__bottom--content-chart-legend-item-text'>
        {`${label} ${
          value !== undefined && value !== null
            ? `(${value}${symbol ? symbol : ''})`
            : ''
        }`}
      </span>
    </div>
  );
};

export default ChartLegend;
