/**
 *
 * @param {Object} obj
 * @return { Object }
 */
export function applicationJSONBodyBuilder(obj: object) {
  return JSON.stringify(obj);
}

/**
 *
 * @param {Object} obj
 * @return { Object }
 */
export function applicationWWWXRLEncodedBodyBuilder(obj: {
  [key: string]: any;
}) {
  const formBody = [];
  // eslint-disable-next-line guard-for-in
  for (const property in obj) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(obj[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }

  const result = formBody.join('&');

  return result;
}

/**
 *
 * @param {Array} array
 * @return { Object }
 */
export function applicationWWWXRLEncodedNestedBodyBuilder(
  array: any[],
  nestedProperty: string
) {
  const formBody = [] as any;
  // eslint-disable-next-line guard-for-in
  array.forEach((product, index) => {
    for (const key in product) {
      if (product.hasOwnProperty(key)) {
        formBody[`${nestedProperty}[${index}][${key}]`] = product[key];
      }
    }
  });

  return applicationWWWXRLEncodedBodyBuilder(formBody);
}
