import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../store/store';
import { useEffect, useState } from 'react';
import get_graph_data_api_call from '../../../../lib/api/stats/get_graph_data_api_call';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';
import { useSelector } from 'react-redux';

interface DataPoint {
  week: string;
  total_carts: number;
  total_checkouts: number;
  conversion_rate: number;
}

const WeeklyConversionRateLineChart = () => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);

  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  const [response, setResponse] = useState<any>([]);

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    setLoading('pending');
    try {
      // Call to get conversion rate stats by location
      const res: DataPoint[] | undefined = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        '?graph_type=conversion_rate&group_by=weekly'
      );

      if (res) {
        if (Object.keys(res)?.length === 0) {
          setLoading('null');
          return;
        } else {
          const sortedRes = res?.sort(
            (a, b) => new Date(a.week).getTime() - new Date(b.week).getTime()
          );
          setResponse(sortedRes);
          setLoading('success');
        }
      } else {
        setLoading('null');
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
      setLoading('null');
    }
  };

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      getGraphData();
    }
  }, [stats]);

  /* ========================== */

  const valueFormatter = (value: number | null) => `${value}%`;
  return (
    <div className='pieChartComponent'>
      <h2 className='pieChartComponent__title'>{'Weekly Conversion Rate'}</h2>
      <div className='pieChartComponent__chart'>
        {loading === 'pending' && <LoadingSpinner />}

        {loading === 'null' && (
          <NoData
            title={t('No data available')}
            subTitle={t('NeedMoreInteractionsToGenerateStats')}
          />
        )}
        {loading === 'success' && (
          <LineChart
            dataset={response}
            xAxis={[{ scaleType: 'band', dataKey: 'week', label: 'Date' }]}
            yAxis={[
              {
                label: 'Conversion Rate (%)',
                min: 0,
                max: 100,
              },
            ]}
            series={[{ dataKey: 'conversion_rate', valueFormatter }]}
            height={300}
            colors={['#8884d8']}
            margin={{ left: 50, right: 30, top: 30, bottom: 40 }}
            grid={{ vertical: true, horizontal: true }}
            sx={(theme) => ({
              [`.${axisClasses.root}`]: {
                [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                  stroke: '#8b909a',
                  strokeWidth: 3,
                },
                [`.${axisClasses.tickLabel}`]: {
                  fill: '#8b909a',
                },
              },
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                fill: '#8b909a',
                // transform: 'translate(-10px, -10px)',
              },
              [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                fill: '#8b909a', // Change color of x-axis label
              },
              [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
                fill: '#8b909a', // Change color of x-axis tick labels
              },
            })}
          />
        )}
      </div>
    </div>
  );
};

export default WeeklyConversionRateLineChart;
