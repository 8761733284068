import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Connections from './connections/Connections';
import Instructions from './instructions/Instructions';
import { useTranslation } from 'react-i18next';
import { allRoutes } from '../../../lib/constants/route';

const Autofill = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    navigate('/integrations/autofill/connections');
  }, []);

  return (
    <Routes>
      {allRoutes['/integrations/autofill/connections'] && (
        <Route path='connections/*' element={<Connections />} />
      )}
      {allRoutes['/integrations/autofill/instructions'] && (
        <Route path='instructions/*' element={<Instructions />} />
      )}
    </Routes>
  );
};

export default Autofill;
