export const overallConversionRateDummyData = {
  total_carts: 400,
  total_checkouts: 100,
  conversion_rate: 25,
};

export const locationBasedConversionRateDummyData: any = {
  France: {
    total_carts: 100,
    location_carts: 100,
    total_checkouts: 10,
    conversion_rate: 10,
  },
  Germany: {
    total_carts: 100,
    location_carts: 100,
    total_checkouts: 15,
    conversion_rate: 15,
  },
  Italy: {
    total_carts: 100,
    location_carts: 100,
    total_checkouts: 35,
    conversion_rate: 35,
  },
  'The Netherlands': {
    total_carts: 100,
    location_carts: 100,
    total_checkouts: 40,
    conversion_rate: 40,
  },
  company_overall_carts: 400,
  company_overall_checkouts: 100,
};

/* ----------------------------------------------------------------------------------------- */

export const weeklyConversionRateDummyData = [
  {
    week: '2024-10-28',
    total_carts: 389,
    total_checkouts: 197,
    conversion_rate: 50.64,
  },
  {
    week: '2024-10-21',
    total_carts: 564,
    total_checkouts: 278,
    conversion_rate: 49.29,
  },
  {
    week: '2024-10-14',
    total_carts: 300,
    total_checkouts: 150,
    conversion_rate: 50.0,
  },
  {
    week: '2024-10-07',
    total_carts: 250,
    total_checkouts: 100,
    conversion_rate: 40.0,
  },
  {
    week: '2024-09-30',
    total_carts: 200,
    total_checkouts: 80,
    conversion_rate: 40.0,
  },
  {
    week: '2024-09-23',
    total_carts: 150,
    total_checkouts: 70,
    conversion_rate: 46.67,
  },
  {
    week: '2024-09-16',
    total_carts: 100,
    total_checkouts: 50,
    conversion_rate: 50.0,
  },
  {
    week: '2024-09-09',
    total_carts: 80,
    total_checkouts: 30,
    conversion_rate: 37.5,
  },
  {
    week: '2024-09-02',
    total_carts: 60,
    total_checkouts: 25,
    conversion_rate: 41.67,
  },
  {
    week: '2024-08-26',
    total_carts: 40,
    total_checkouts: 15,
    conversion_rate: 37.5,
  },
  {
    week: '2024-08-19',
    total_carts: 20,
    total_checkouts: 5,
    conversion_rate: 25.0,
  },
  {
    week: '2024-08-12',
    total_carts: 10,
    total_checkouts: 3,
    conversion_rate: 30.0,
  },
  {
    week: '2024-08-05',
    total_carts: 5,
    total_checkouts: 1,
    conversion_rate: 20.0,
  },
];

/* ----------------------------------------------------------------------------------------- */

export const deviceBasedConversionRateDummyData = {
  Desktop: {
    total_carts: 424,
    total_checkouts: 259,
    conversion_rate: 61.08,
  },
  Mobile: {
    total_carts: 505,
    total_checkouts: 206,
    conversion_rate: 40.79,
  },
  company_overall_carts: 953,
};

export const conversionRateOfDesktopDummyData = {
  Chrome: {
    os_carts: 1,
    os_checkouts: 0,
    conversion_rate: 0,
  },
  Linux: {
    os_carts: 2,
    os_checkouts: 0,
    conversion_rate: 0,
  },
  Mac: {
    os_carts: 143,
    os_checkouts: 77,
    conversion_rate: 53.85,
  },
  'Windows 10': {
    os_carts: 282,
    os_checkouts: 184,
    conversion_rate: 65.25,
  },
  'Windows 7': {
    os_carts: 0,
    os_checkouts: 0,
    conversion_rate: 0,
  },
  'Windows 8.1': {
    os_carts: 0,
    os_checkouts: 0,
    conversion_rate: 0,
  },
  total_device_carts: 428,
};

export const conversionRateOfMobileDummyData = {
  Android: {
    os_carts: 187,
    os_checkouts: 84,
    conversion_rate: 44.92,
  },
  iOS: {
    os_carts: 319,
    os_checkouts: 122,
    conversion_rate: 38.24,
  },
  total_device_carts: 506,
};

/* ----------------------------------------------------------------------------------------- */

export const conversionRateByTimeOfDayDummyData = [
  {
    time_slot: '00:00 - 05:59',
    total_carts: 86,
    total_checkouts: 26,
    conversion_rate: 30.23,
  },
  {
    time_slot: '06:00 - 11:59',
    total_carts: 257,
    total_checkouts: 127,
    conversion_rate: 49.42,
  },
  {
    time_slot: '12:00 - 17:59',
    total_carts: 362,
    total_checkouts: 217,
    conversion_rate: 59.94,
  },
  {
    time_slot: '18:00 - 23:59',
    total_carts: 248,
    total_checkouts: 105,
    conversion_rate: 42.34,
  },
];

export const conversionRateByDayOfWeekDummyData = [
  {
    day_of_week: 'Sunday',
    total_carts: 67,
    total_checkouts: 40,
    conversion_rate: 59.7,
  },
  {
    day_of_week: 'Monday',
    total_carts: 195,
    total_checkouts: 93,
    conversion_rate: 47.69,
  },
  {
    day_of_week: 'Tuesday',
    total_carts: 165,
    total_checkouts: 88,
    conversion_rate: 53.33,
  },
  {
    day_of_week: 'Wednesday',
    total_carts: 174,
    total_checkouts: 90,
    conversion_rate: 51.72,
  },
  {
    day_of_week: 'Thursday',
    total_carts: 172,
    total_checkouts: 92,
    conversion_rate: 53.49,
  },
  {
    day_of_week: 'Friday',
    total_carts: 108,
    total_checkouts: 48,
    conversion_rate: 44.44,
  },
  {
    day_of_week: 'Saturday',
    total_carts: 74,
    total_checkouts: 24,
    conversion_rate: 32.43,
  },
];
