import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { CodeSection } from 'react-code-section-lib';
import SelectableButton from '../../../../ui/buttons/SelectableButton';
import { shopifySVG, webShopSVG } from '../../../../lib/allCustomSVGs';
import { useState } from 'react';
import {
  regularScriptCode,
  shopifyPixelCode,
} from '../../../../lib/constants/integration_script_code';

interface Props {
  apiKey: any;
}

function Integration({ apiKey }: Props) {
  const { t } = useTranslation();
  const isAppThemeDark = useSelector((state: RootState) => state.UI.theme.dark);
  const [isShopifyIntegration, setIsShopifyIntegration] =
    useState<boolean>(false);

  return (
    <div className='normalForm'>
      <div className='normalForm__top'>
        {/* //* Integration method Selection -- Start */}
        <div className='shopPage__container1-alignLeft'>
          <SelectableButton
            icon={webShopSVG}
            title={''}
            subTitle={t('RegularIntegration')}
            subTitle2={t('RegularIntegration-Sub')}
            link=''
            onSelect={() => {
              setIsShopifyIntegration(false);
            }}
            isSelected={!isShopifyIntegration}
          />
          <SelectableButton
            icon={shopifySVG}
            title={''}
            subTitle={t('ShopifyIntegration')}
            subTitle2={t('ShopifyIntegration-Sub')}
            link=''
            onSelect={() => {
              setIsShopifyIntegration(true);
            }}
            isSelected={isShopifyIntegration}
          />
        </div>
        {/* //* Integration method Selection -- End */}

        {/* //* ----------- RegularIntegration -- Start ----------- */}
        {!isShopifyIntegration && (
          <>
            <h3>{t('CheckoutTrackingIntegrationInstructions1')}</h3>
            <ul style={{ marginLeft: '2rem', marginBottom: '-1.5rem' }}>
              <li style={{ listStyleType: 'disc' }}>
                {t('CheckoutTrackingIntegrationInstructions2')}{' '}
                <strong>{`<head>`}</strong>
              </li>
            </ul>

            <CodeSection
              showLineNumbers
              theme={isAppThemeDark ? 'dark' : 'light'}
              lang={'html'}
            >
              {regularScriptCode(apiKey)}
            </CodeSection>
          </>
        )}
        {/* //* ----------- RegularIntegration -- End ----------- */}

        {/* //* ----------- ShopifyIntegration -- Start ----------- */}
        {isShopifyIntegration && (
          <>
            <h3>{t('CheckoutTrackingIntegrationInstructions1-Shopify')}</h3>
            <ul style={{ marginLeft: '2rem', marginBottom: '-1.5rem' }}>
              <li style={{ listStyleType: 'disc' }}>
                {t('CheckoutTrackingIntegrationInstructions2-Shopify')}
              </li>
              <li style={{ listStyleType: 'disc' }}>
                {t('CheckoutTrackingIntegrationInstructions3-Shopify')}
              </li>
              <li style={{ listStyleType: 'disc' }}>
                {t('CheckoutTrackingIntegrationInstructions4-Shopify')}
              </li>
              <li style={{ listStyleType: 'disc' }}>
                {t('CheckoutTrackingIntegrationInstructions5-Shopify')}
              </li>
              <li style={{ listStyleType: 'disc' }}>
                {t('CheckoutTrackingIntegrationInstructions6-Shopify')}
              </li>
            </ul>

            <CodeSection
              showLineNumbers
              theme={isAppThemeDark ? 'dark' : 'light'}
              lang={'js'}
            >
              {shopifyPixelCode(apiKey)}
            </CodeSection>
          </>
        )}
        {/* //* ----------- ShopifyIntegration -- End ----------- */}
      </div>
    </div>
  );
}

export default Integration;
