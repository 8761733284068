import { useTranslation } from 'react-i18next';
import '../../../../../../styles/components/charts/chart.scss';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { useEffect, useState } from 'react';
import colorsForCharts from '../../../../../../lib/constants/colors';
import {
  addedCartSVG,
  completedCartSVG,
} from '../../../../../../lib/allCustomSVGs';
import ChartLegend from '../../../../../../components/charts/ChartLegend';
import ChartAdditionalStats from '../../../../../../components/charts/ChartAdditionalStats';

type Props = {
  setSelectedCountry: (country: string) => void;
  apiResponse: any;
};

const LocationBasedConversionRatePieChart = ({
  setSelectedCountry,
  apiResponse,
}: Props) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any[]>([]);
  const [additionalStats, setAdditionalStats] = useState<any>({
    companyOverallCarts: 0,
    companyOverallCheckouts: 0,
  });

  const getConversionRateData = async (res: any) => {
    if (res?.type === 'location' && res?.data) {
      let newChartData = [];
      let count = 0;
      let extraStats = { ...additionalStats };

      if (Object.keys(res?.data)?.length === 0) {
        return;
      }
      for (const key in res?.data) {
        if (key === 'company_overall_carts') {
          extraStats.companyOverallCarts = res?.data[key] || 0;
        } else if (key === 'company_overall_checkouts') {
          extraStats.companyOverallCheckouts = res?.data[key] || 0;
        } else {
          newChartData.push({
            label: key,
            value: res?.data[key]?.conversion_rate || 0,
            color: colorsForCharts[count],
          });
        }

        count += 1;
      }

      // Set chart data once
      setAdditionalStats(extraStats);
      setChartData(newChartData);
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  const valueFormatter = (item: { value: number }) => `${item.value}%`;

  const data = {
    data: chartData,
    valueFormatter,
  };

  const onItemClickHandler = (e: any, d: any) => {
    if (d?.dataIndex !== undefined) {
      setSelectedCountry(data?.data[d.dataIndex]?.label);
    }
  };

  const AdditionalStatsData = [
    {
      label: t('CompanyOverallCarts'),
      value: additionalStats?.companyOverallCarts,
      fillColor: '#8884d8',
      svg: addedCartSVG,
    },
    {
      label: t('CompanyOverallCheckouts'),
      value: additionalStats?.companyOverallCheckouts,
      fillColor: '#82ca9d',
      svg: completedCartSVG,
    },
  ];

  return (
    <>
      <div className='chart__bottom--content-chart'>
        <div className='chart__bottom--content-chart-pie'>
          <PieChart
            margin={{ top: 5, bottom: 5, left: 5, right: 5 }}
            series={[
              {
                arcLabel: (item) => `${item.value}%`,
                innerRadius: 50,
                arcLabelMinAngle: 35,
                arcLabelRadius: '60%',
                highlightScope: { fade: 'global', highlight: 'item' },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: 'gray',
                },
                ...data,
              },
            ]}
            onItemClick={onItemClickHandler}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fontWeight: 'bold',
              },
            }}
            slotProps={{
              legend: {
                hidden: true, // Set to true to hide the legend
              },
            }}
          />
        </div>
        <div className='chart__bottom--content-chart-legend'>
          <div className='chart__bottom--content-chart-legend-content'>
            {chartData?.map((item, index) => (
              <ChartLegend
                key={index}
                color={item?.color}
                label={item?.label}
                value={item?.value}
                onClick={() => setSelectedCountry(item?.label)}
                symbol='%'
              />
            ))}
          </div>
        </div>
      </div>
      <ChartAdditionalStats stats={AdditionalStatsData} />
    </>
  );
};
export default LocationBasedConversionRatePieChart;
