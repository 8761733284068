export const averageCartValueDummyData = [
  {
    month: '2024-August',
    total_cart_value: '3022',
    cart_count: 54,
    average_cart_value: 55.96,
  },
  {
    month: '2024-September',
    total_cart_value: '4539',
    cart_count: 48,
    average_cart_value: 94.56,
  },
  {
    month: '2024-October',
    total_cart_value: '127445.79',
    cart_count: 2233,
    average_cart_value: 57.08,
  },
  {
    month: '2024-November',
    total_cart_value: '6085.66',
    cart_count: 76,
    average_cart_value: 80.07,
  },
];
