import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import ToggleSwitch from '../../../ui/buttons/ToggleSwitch';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import store from '../../../store/store';
import { asyncHandler } from '../../../lib/api/async-handler';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../lib/utils/body-builder';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import { useTranslation } from 'react-i18next';
import { setUserCompanySettings } from '../../../store/reducers/userSlice';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { SHOP_API } from '../../../lib/constants/central-api';
import { submitLogToBugsnag } from '../../../lib/api/log';
import NormalInput from '../../../ui/inputs/NormalInput';
import { HOME_ROUTE } from '../../../lib/constants/route';

const SettingsNotifications = () => {
  const location = useLocation();
  const activeLink = location.pathname;

  const { t } = useTranslation();

  // redux state
  const newsLetterText = useSelector(
    (state: RootState) => state.user.merchant.config.monthly_newsletter_text
  );
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const slug = store.getState().user?.company?.shop_slug;

  // local state
  const [sendNewsLetter, setSendNewsLetter] = useState<boolean>(false);
  const [isShowCustomMessage, setIsShowCustomMessage] =
    useState<boolean>(false);
  const [customMessage, setCustomMessage] = useState<string>('');
  const [loading, setLoading] = useState('pending');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  useEffect(() => {
    if (slug && slug !== '') {
      const response = getNotificationsSettings();
      response
        .then((result) => {
          setSendNewsLetter(
            result?.send_monthly_newsletter === 'true' ? true : false
          );
          setIsShowCustomMessage(
            result?.is_show_custom_message === 'true' ? true : false
          );
          setCustomMessage(
            result?.custom_message ? result?.custom_message : ''
          );
          setLoading('success');
        })
        .catch((err) => {
          console.error(err);
          setLoading('error');
          submitLogToBugsnag('error', `${err}`);
        });
    }
  }, [slug]);

  /* ----------------------------API CALL START---------------------------- */
  async function getNotificationsSettings() {
    try {
      const requestInfo: RequestInfo = {
        url: SHOP_API + `/${slug}/settings?filter[group]=notifications`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_NOTIFICATIONS_SETTINGS';
      const customMsg = 'Notifications Settings fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        let data: any = {};
        response?.data?.forEach((item: any) => {
          data[item.key] = item.value === null ? '' : item.value;
        });
        return data;
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const sendingData = {
        group: 'notifications',
        send_monthly_newsletter: sendNewsLetter,
        is_show_custom_message: isShowCustomMessage,
        custom_message: customMessage,
      };

      const requestInfo: RequestInfo = {
        url: SHOP_API + `/${slug}/settings`,
        method: 'PUT',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'UPDATE_NOTIFICATIONS_SETTINGS';
      const customMsg = t('SettingsUpdatedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      setIsSubmitting(false);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        store.dispatch(setUserCompanySettings(response?.result));
        return response.result;
      }
    } catch (exception) {
      console.log('Error during authentication request');
      setIsSubmitting(false);
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  return (
    <SubPageInnerContentHolder
      title={t('NotificationSettings')}
      subTitle={t('ManageNotificationSettings')}
      backBtnLink={isWhiteLabel ? undefined : HOME_ROUTE}
    >
      <>
        {loading === 'pending' && <LoadingSpinner />}
        {loading === 'error' && (
          <NoData
            title={t('ErrorFetchingData')}
            subTitle={t('SomethingWentWrong-msg')}
          />
        )}
        {loading === 'success' && (
          <form
            id='notification-settings-form'
            onSubmit={onSubmitHandler}
            className='normalForm'
          >
            {/* ------------------------------------------------------- */}
            {/* <div className='normalForm__top'>
              <h2>{t('NewsLetter')}</h2>
              <hr />
              <div className='normalForm__top--container1'>
                <div className='normalForm__container1--left'>
                  <ToggleSwitch
                    isOn={sendNewsLetter}
                    title={
                      newsLetterText
                        ? newsLetterText
                        : t('SendMonthlyAdUpNewsletter')
                    }
                    onClick={setSendNewsLetter}
                    classes='margin-top-2rem'
                  />
                </div>
              </div>
            </div> */}

            {/* ------------------------------------------------------- */}
            {/* Set the className as className='normalForm__center' when NewsLetter section is added in the future since this is the second section*/}
            <div className='normalForm__top'>
              <h2>{t('CustomMessage')}</h2>
              <hr />
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <ToggleSwitch
                    isOn={isShowCustomMessage}
                    title={t('ShowCustomMessage')}
                    onClick={setIsShowCustomMessage}
                    classes='margin-top-2rem'
                    id='notification-settings-is-custom-message'
                  />
                </div>
                <div className='normalForm__container1--right'>
                  <NormalInput
                    id='notification-settings-custom-message'
                    value={customMessage}
                    changeListeners={[(e) => setCustomMessage(e.target.value)]}
                    focusListeners={[]}
                    placeholder={t('CustomMessage')}
                    classes='lg:w-[13rem]'
                    required={isShowCustomMessage}
                    pattern='^.{1,100}$'
                    minlength={1}
                    maxlength={100}
                    title={t('CustomMessage-validation')}
                  />
                </div>
              </div>
              {/* ------------------------------------------------------- */}
            </div>

            <div className='normalForm__bottom flex-start'>
              <MainSquareButton
                value={
                  isSubmitting ? `${t('Updating')}...` : t('UpdateSettings')
                }
                /* icon={<CustomSVGs svg={plusSignSVG} />} */
                disabled={isSubmitting}
                type='submit'
                form='notification-settings-form'
              />
            </div>
          </form>
        )}
      </>
    </SubPageInnerContentHolder>
  );
};

export default SettingsNotifications;
