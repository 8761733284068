import React, { useEffect, useState } from 'react';
import ChartLegend from '../../../../../components/charts/ChartLegend';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
} from 'recharts';

type Props = {
  apiResponse: any;
};

const IndividualCTRBarChart = ({ apiResponse }: Props) => {
  const [chartData, setChartData] = useState<any[]>([]);

  const getConversionRateData = async (res: any) => {
    if (res?.type === 'individual' && res?.data) {
      if (res.data?.length === 0) {
        return;
      }

      const newChartData = res.data?.map((item: any) => {
        return {
          label: item.button_color,
          value: item.ctr,
          color: item.button_color,
        };
      });

      // Set chart data once
      setChartData(newChartData);
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  return (
    <>
      <div className='chart__bottom--content-chart'>
        <div
          style={{ width: '100%', marginRight: '0' }}
          className='chart__bottom--content-chart-pie'
        >
          <ResponsiveContainer width='100%' height={300}>
            <BarChart
              data={chartData}
              margin={{
                top: 20,
                right: 50,
                left: -10,
                bottom: 10,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='label'
                label={{
                  value: 'Element Color',
                  position: 'insideBottom',
                  offset: -5,
                }}
              />
              <YAxis
                domain={[0, 100]}
                label={{
                  value: 'CTR (%)',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 20,
                }}
              />
              <Bar dataKey='value' fill='#8884d8'>
                <LabelList
                  dataKey='value'
                  position='top'
                  formatter={(value: number) => `${value.toFixed(0)}%`}
                  style={{ fill: '#6d6d6d' }}
                />
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div
        data-is-chart-legend-row={'true'}
        className='chart__bottom--content-chart-legend'
      >
        <div className='chart__bottom--content-chart-legend-content'>
          {chartData?.map((item, index) => (
            <ChartLegend
              key={index}
              color={item?.color}
              label={item?.label}
              value={item?.value}
              symbol='%'
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default IndividualCTRBarChart;
