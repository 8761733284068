const colorsForCharts = [
  '#D6A5B2',
  '#A7C7E7',
  '#B7D9B7',
  '#E5D8A1',
  '#F7C5A5',
  '#A8D0A5',
  '#A6B7B8',
  '#D3D2D7',
  '#C5D8A4',
  '#B9C8D6',
  '#D1D8E0',
  '#B6D4B2',
  '#A2A9B6',
  '#F2E1A1',
  '#D6E4F2',
  '#D8E4D6',
  '#A9D1B8',
  '#E4E8D3',
  '#C1B9D6',
  '#A3C5B9',
  '#B2A0C6',
  '#E0D1B5',
  '#C8A8B8',
  '#F2D6B5',
  '#A9D1D5',
  '#B2C9A6',
  '#C5A9C6',
  '#F1E2D0',
  '#E1D4E0',
  '#D0D3C4',
  '#B6C6D7',
  '#C9B5B5',
  '#C9D6A0',
  '#B9D5D4',
  '#E2A5B2',
  '#A4B6B0',
  '#D5A9B1',
  '#C4B6D4',
  '#F5D0A9',
  '#D0D8E6',
  '#D0D4A6',
  '#F7A4A6',
  '#B8A8D3',
  '#A9C8A3',
  '#D9D8B2',
  '#F0D9B4',
  '#C2B8D1',
  '#A7C4D5',
  '#B7D5C5',
  '#F0D8A9',
  '#A7D8C6',
  '#C6D0A4',
  '#B6C7D2',
  '#D2D8A8',
  '#D1C5D8',
  '#C7D4B2',
  '#A6C1D7',
  '#D9C4B4',
  '#E4D0A8',
  '#A9D6C6',
  '#A8C9D7',
  '#A1D7B9',
  '#C5D7E2',
  '#E4B7D8',
  '#C8A2D2',
  '#D3E2A7',
  '#C9D0B6',
  '#F1D1C0',
  '#D8B9C6',
  '#D3C6A4',
  '#D4C0B0',
  '#A6C5B7',
  '#A9D2C4',
  '#E1C7B2',
  '#A5C1B3',
  '#C8D6B5',
  '#F1C8A4',
  '#A8C7D3',
  '#E4B7D6',
  '#B8D7C4',
  '#D2E0B1',
  '#A1C0B5',
  '#C0B4D1',
  '#B8D2A8',
  '#D1B4C8',
  '#D2E4B9',
  '#E0A8B9',
  '#C6C7D3',
  '#A7D8B7',
  '#C1A8D4',
  '#A9D0B9',
  '#F1B8A7',
  '#D0C1A4',
  '#B8D2B6',
  '#F7A6D0',
  '#C8C5A8',
  '#D9A7C4',
  '#F1C9D1',
  '#B8D4A5',
  '#C8B8D6',
  '#E0C6B3',
  '#D0C4D3',
  '#D8C6A9',
  '#F1A8B6',
  '#B8D6B8',
  '#E0B1C0',
  '#B6C8A9',
  '#C4D0D7',
  '#D8C9A1',
  '#C6A9B9',
  '#A1D7B6',
  '#D4C8B1',
  '#C8D9A4',
  '#B9A8D7',
  '#A5D1B7',
  '#D7A9C7',
  '#C1A7D6',
  '#A9B7A4',
  '#D0C7A5',
  '#B2C6D9',
  '#B8D6A9',
  '#C7A7B6',
  '#C0D1D4',
  '#C8A9D2',
  '#B9C8A8',
  '#F0C9B6',
  '#A6D3B5',
  '#E3B8D9',
  '#C1B9D6',
  '#A9C0A4',
  '#D4B1C6',
  '#B8D0D4',
  '#B7D2C0',
  '#D1A9B6',
  '#A1D6C9',
  '#D0C9A6',
  '#D6A7C8',
  '#C0D8C2',
  '#A6D7C6',
  '#D8C5B7',
  '#A9D1C9',
  '#A9C6D3',
  '#D0D8A7',
  '#C9D1B9',
  '#F0A8C4',
  '#A9B1D6',
  '#D1C8B5',
  '#B9D0D1',
  '#B7C9A6',
  '#D8A5B8',
  '#B9A9D6',
  '#A8D7C1',
  '#D6A7C0',
  '#C7A9D8',
  '#C8D9A6',
  '#D3C7A1',
  '#A9C8D6',
  '#F1C8A7',
  '#A5B8C4',
  '#B8D7B9',
  '#D0B6C4',
  '#A6D0B5',
  '#A9B1D4',
  '#F0D8A9',
  '#C5D6B7',
  '#B7D1C8',
  '#B8C9D1',
  '#A6C1B5',
  '#A9D6A8',
  '#C7D8A9',
  '#B8C5D3',
  '#D4D1A9',
  '#D0A8B7',
  '#A6D7C9',
  '#C1A9D6',
  '#B8C7D1',
  '#D1C7A5',
  '#C5B8D0',
  '#D0A7B8',
  '#B7D1C9',
  '#B1D8A7',
  '#C8D6B1',
  '#D9C6A9',
  '#B7C9B8',
  '#A9D0C5',
  '#C5D8A7',
  '#F1A7D8',
  '#B7C6D4',
  '#C8D1A6',
  '#D1B9C5',
];
export default colorsForCharts;
