import React from 'react';
import store from '../../store/store';
import ReactPaginate from 'react-paginate';
import PaginationTheme2 from './PaginationTheme2';

type Props = {
  totalRecords: number;
  itemsPerPage: number;
  onRecordsPerPageChange: (recordsPerPage: number) => void;
  breakLabel: any;
  nextLabel: any;
  onPageChange: any;
  pageRangeDisplayed: number;
  initialPage: number;
  pageCount: number;
  previousLabel: any;
  renderOnZeroPageCount?: (para: any) => void | null | undefined;
  marginPagesDisplayed: number;
  containerClassName: string;
  pageLinkClassName: string;
  previousLinkClassName: string;
  nextLinkClassName: string;
  disabledLinkClassName: string;
  activeLinkClassName: string;
};

//This component is a platform to switch between different pagination components
const PaginationMain = ({
  totalRecords,
  itemsPerPage,
  onRecordsPerPageChange,
  breakLabel,
  nextLabel,
  onPageChange,
  pageRangeDisplayed,
  initialPage,
  pageCount,
  previousLabel,
  renderOnZeroPageCount,
  marginPagesDisplayed,
  containerClassName,
  pageLinkClassName,
  previousLinkClassName,
  nextLinkClassName,
  disabledLinkClassName,
  activeLinkClassName,
}: Props) => {
  const themeType = store.getState().UI.theme.type;

  const renderUI = () => {
    if (themeType === '2') {
      return (
        <PaginationTheme2
          totalRecords={totalRecords}
          itemsPerPage={itemsPerPage}
          onRecordsPerPageChange={onRecordsPerPageChange}
          breakLabel={breakLabel}
          onPageChange={onPageChange}
          pageRangeDisplayed={pageRangeDisplayed}
          initialPage={initialPage}
          pageCount={pageCount}
          renderOnZeroPageCount={renderOnZeroPageCount}
          marginPagesDisplayed={marginPagesDisplayed}
          containerClassName={containerClassName}
          pageLinkClassName={pageLinkClassName}
          previousLinkClassName={previousLinkClassName}
          nextLinkClassName={nextLinkClassName}
          disabledLinkClassName={disabledLinkClassName}
          activeLinkClassName={activeLinkClassName}
        />
      );
    } else {
      return (
        <ReactPaginate
          breakLabel={breakLabel}
          nextLabel={nextLabel}
          onPageChange={onPageChange}
          pageRangeDisplayed={pageRangeDisplayed}
          initialPage={initialPage}
          pageCount={pageCount}
          previousLabel={previousLabel}
          renderOnZeroPageCount={renderOnZeroPageCount}
          marginPagesDisplayed={marginPagesDisplayed}
          containerClassName={containerClassName}
          pageLinkClassName={pageLinkClassName}
          previousLinkClassName={previousLinkClassName}
          nextLinkClassName={nextLinkClassName}
          disabledLinkClassName={disabledLinkClassName}
          activeLinkClassName={activeLinkClassName}
        />
      );
    }
  };
  return renderUI();
};

export default PaginationMain;
