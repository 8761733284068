import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { RECOMMENDATIONS_API } from '../../constants/central-api';
import { applicationWWWXRLEncodedBodyBuilder } from '../../utils/body-builder';

export default async function get_recommendations_api_call() {
  const shopId = store.getState().user?.company?.shop_id;
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url: RECOMMENDATIONS_API + '/get',
    method: 'POST',
    body: applicationWWWXRLEncodedBodyBuilder({
      shop_id: shopId,
    }),
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_RECOMMENDATIONS';
  const customMsg = 'Recommendations loaded successfully';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;
  if (!response?.success) {
    return false;
  }
  if (res?.actionType === actionType && response?.success === true) {
    if (response?.data === null) {
      return null;
    } else {
      return response?.data;
    }
  }
}
