export function decodeConfigurationsResponse(value: any) {
  if (typeof value === 'object' && Array.isArray(value)) {
    return value;
  }
  try {
    const parsedValue = JSON.parse(value);
    return typeof parsedValue === 'object' && Array.isArray(parsedValue)
      ? parsedValue
      : [value];
  } catch (error) {
    return [value];
  }
}
