import { t } from 'i18next';
import { setIsInitSuccess, setMessage } from '../../store/reducers/appSlice';
import { clearAuthSlice, setAccessToken } from '../../store/reducers/authSlice';
import { clearUserSlice } from '../../store/reducers/userSlice';
import store from '../../store/store';
import { tokenRevokeAtLogOutUser } from './logout-user';
import { initResponseStateUpdate } from './state-updates';
import { submitLogToBugsnag } from './log';
import { APP_ENV, INIT_API } from '../constants/central-api';
import isNotEmptyString from '../helper-functions/isNotEmptyString';
import { runCacheBuster } from '../cache-buster/cache-bust-handler';
import moment from 'moment';

export async function fetchAppInit() {
  if (!(await runCacheBuster())) {
    const isWhiteLabel = store.getState().UI?.isWhiteLabel;
    try {
      //* Extracting cacheBustToken from URL if there is any
      const queryParameters = new URLSearchParams(window.location.search);
      const cacheBustToken = queryParameters.get(
        'cacheBustToken'
      ) as unknown as string;

      const accessToken = store.getState().auth.authorization.accessToken;
      const requestHeaders: { [idx: string]: any } = {};

      let token: string;
      if (isWhiteLabel) {
        token = accessToken?.currentWhiteLabelSellerToken;
      } else {
        token = accessToken?.sellerToken;
      }

      store.dispatch(setIsInitSuccess(false));

      if (isNotEmptyString(token)) {
        requestHeaders['Authorization'] = accessToken.type + ' ' + token;
      } else if (isNotEmptyString(cacheBustToken)) {
        requestHeaders['Authorization'] =
          accessToken.type + ' ' + cacheBustToken;
      }
      const response = await fetch(INIT_API, {
        method: 'GET',
        headers: requestHeaders,
      });

      const res = await response.json();
      const { success, data, message } = res;

      if (
        !isWhiteLabel &&
        (isNotEmptyString(token) || isNotEmptyString(cacheBustToken))
      ) {
        store.dispatch(
          setAccessToken({
            ...accessToken,
            sellerToken: token ?? cacheBustToken,
            expiresOn: moment().add(21, 'days'),
          })
        );
      }

      //* Removing cacheBustToken from browser URL
      queryParameters?.delete('cacheBustToken');
      window.history.replaceState(
        {},
        '',
        `${window?.location?.pathname}${
          isNotEmptyString(queryParameters.toString())
            ? `?${queryParameters}`
            : ''
        }`
      );

      if (success) {
        store.dispatch(setIsInitSuccess(true));
        initResponseStateUpdate(data);
        return data;
      } else {
        store.dispatch(setIsInitSuccess(false));
        if (!isWhiteLabel) {
          logoutHandler();
          store.dispatch(
            setMessage({
              message: message ?? t('SessionExpired'),
              messageType: 'error',
            })
          );
        }
        return null;
      }
    } catch (exception) {
      store.dispatch(setIsInitSuccess(false));
      submitLogToBugsnag('error', `${exception}`);
      if (!isWhiteLabel) {
        logoutHandler();
        store.dispatch(
          setMessage({
            message: t('SessionExpired'),
            messageType: 'error',
          })
        );
      }
      console.log('Error fetching init response', exception);
      submitLogToBugsnag(
        'error',
        `Error fetching init response : ${exception}`
      );
    }
  }
}

const logoutHandler = () => {
  tokenRevokeAtLogOutUser();
  if (APP_ENV === 'LIVE') {
    // @ts-ignore:next-line
    window.Intercom && window.Intercom('shutdown'); //shutdown intercom
  }
  store.dispatch(clearUserSlice());
  store.dispatch(clearAuthSlice());
  window.localStorage.clear();
};
