import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import get_graph_data_api_call from '../../../../lib/api/stats/get_graph_data_api_call';
import ChartWithConfig from '../../ChartWithConfig';
import TimeOfDayCRLineChart from './TimeOfDayCRLineChart';
import DayOfWeekCRLineChart from './DayOfWeekCRLineChart';

const MainTimeAndDayCRChart = () => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    '?graph_type=conversion_rate&group_by=time_of_day&time_range=all'
  );
  const [response, setResponse] = useState<any>('initial');

  //Group by options
  const [groupBy, setGroupBy] = useState<string>('time_of_day');
  const groupByOptionsArr = [
    { OptionValue: 'time_of_day', OptionName: t('TimeOfDay') },
    { OptionValue: 'day_of_week', OptionName: t('DayOfWeek') },
  ];

  const [title, setTitle] = useState<string>(t('ConversionRateByTimeOfDay'));

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        if (groupBy === 'time_of_day') {
          setResponse({ type: 'TimeOfDay', data: res });
        } else {
          setResponse({ type: 'DayOfWeek', data: res });
        }
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    setResponse('initial');
    setParams(`?graph_type=conversion_rate&group_by=${groupBy}&time_range=all`);
    if (groupBy === 'time_of_day') {
      setTitle(t('ConversionRateByTimeOfDay'));
    } else if (groupBy === 'day_of_week') {
      setTitle(t('ConversionRateByDayOfWeek'));
    }
  }, [groupBy]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  function renderChart() {
    if (groupBy === 'time_of_day') {
      return <TimeOfDayCRLineChart apiResponse={response} />;
    } else {
      return <DayOfWeekCRLineChart apiResponse={response} />;
    }
  }

  return (
    <ChartWithConfig
      groupBy={groupBy}
      setGroupBy={setGroupBy}
      groupByOptionsArr={groupByOptionsArr}
      title={title}
    >
      {renderChart()}
    </ChartWithConfig>
  );
};

export default MainTimeAndDayCRChart;
