import { useState } from 'react';
import { copySVG, filledCheckboxSVG } from '../../lib/allCustomSVGs';
import CustomSVGs from '../customSVGs/CustomSVGs';
import '../../styles/inputs/copyToClipboard.scss';

interface Props {
  inputTitle: string;
  inputValue: string;
}

const CopyToClipboard = ({ inputTitle, inputValue }: Props) => {
  const [isSVGClicked, setIsSVGClicked] = useState<boolean>(false);

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

  const svgClickHandler = () => {
    setIsSVGClicked(true);

    const timer = setTimeout(() => {
      setIsSVGClicked(false);
      clearTimeout(timer);
    }, 800);
  };
  return (
    <div data-copied={isSVGClicked.toString()} className='copyToClipboard'>
      <div className='copyToClipboard__left'>
        <span>{inputTitle}</span>
        <span>{inputValue}</span>
      </div>

      <div
        onClick={() => {
          svgClickHandler();
          copyToClipboard(inputValue);
        }}
        title='Copy to Clipboard'
        className='copyToClipboard__right'
      >
        {!isSVGClicked && <CustomSVGs svg={copySVG} />}
        {isSVGClicked && <CustomSVGs svg={filledCheckboxSVG} />}
      </div>
    </div>
  );
};

export default CopyToClipboard;
