// convert (2022-10-19T05:38:59.000000Z) to => 1 day 1 month, 1 year
export default function timeSince(input: any) {
  const date: any = new Date(input);
  const today: any = new Date();
  let seconds: any = Math.floor((today - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + ' year'
      : Math.floor(interval) + ' years';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + ' month'
      : Math.floor(interval) + ' months';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + ' day'
      : Math.floor(interval) + ' days';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + ' hour'
      : Math.floor(interval) + ' hours';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? Math.floor(interval) + ' minute'
      : Math.floor(interval) + ' minutes';
  }
  return Math.floor(seconds) === 1
    ? Math.floor(seconds) + ' second'
    : Math.floor(seconds) + ' seconds';
}
