//api call imports
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../lib/api/async-handler';
import store from '../../../store/store';

//other imports
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTitle from '../../../customHooks/useTitle';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import EditProduct from './EditProduct';
import { PRODUCT_API } from '../../../lib/constants/central-api';

const LoadEditProduct = () => {
  useTitle('Edit Product');
  const { productSku } = useParams();
  const navigate = useNavigate();

  //state
  const [productResponse, setProductResponse] = useState<any>();
  const [variantResponse, setVariantResponse] = useState<any>();
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [productImages, setProductImages] = useState<any>();
  const [loading, setLoading] = useState('pending');

  /* ----------------------------API CALL GET PRODUCT START---------------------------- */
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const shop_slug = store.getState().user.company.shop_slug;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url: `${PRODUCT_API(shop_slug)}/${productSku}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_SINGLE_PRODUCT';
  const customMsg = 'Product Loaded Successfully.';

  useEffect(() => {
    const getProducts = async () => {
      setLoading('pending');
      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;

      if (res.actionType === actionType && response.success === true) {
        const productObj = response?.data;
        if (productObj === 'products_not_found') {
          navigate('/products');
          return;
        }
        if (productObj) {
          /* const productVariantsArr: any = []; */

          //* Redirects back to Products Table if the product is in synced state *//
          if (productObj?.is_manual === 0 || productObj?.is_manual === false) {
            console.log(
              'Edit Product permissions denied since product is in synced state'
            );
            navigate('/products');
            return;
          }

          const mainProductVariantArr: any = productObj.variants;

          const productInit = {
            productName: productObj?.name,
            productDescription: productObj?.description,
            productId: productObj?.id,
            productSKU: productObj?.sku,
            productStock: productObj?.stock || 0,
            productPrice: '',
            productImages: [],
            productType: productObj?.type,
          };
          if (productObj?.images) {
            const values: any = Object?.values(productObj?.images);
            if (values?.length > 0) {
              const images = values?.map((value: any) => value?.small);
              productInit.productImages = images;
            }
          }
          if (productObj?.price) {
            productInit.productPrice =
              productObj?.price?.values?.[
                productObj?.price?.default
              ]?.price?.major;
          }
          setProductResponse(productInit);

          if (
            mainProductVariantArr?.length > 0 &&
            productInit.productType === 'variable'
          ) {
            const sendingVariants = mainProductVariantArr.map(
              (el: any, index: number) => {
                let sendingAttributes;
                const attributes = convertObjectToArray(el?.attributes);
                if (attributes?.length > 0) {
                  sendingAttributes = attributes?.map(
                    (item: any, i: number) => {
                      const attKey = Object.keys(item);
                      const attValue = Object.values(item);
                      return {
                        id: i,
                        name: attKey[0],
                        value: attValue[0],
                      };
                    }
                  );
                }

                let previewImages = [];
                if (el?.images) {
                  const values: any = Object?.values(el?.images);
                  if (values?.length > 0) {
                    const images = values?.map((value: any) => value?.small);
                    previewImages = images;
                  }
                }

                return {
                  productVariantId: el?.id,
                  productVariants: {
                    productVariantName: el?.name,
                    productVariantDescription: el?.description,
                    productVariantSKU: el?.sku,
                    productVariantDisplayPrice:
                      el?.price?.values?.[el?.price?.default]?.price?.formatted,
                    productVariantPrice:
                      el?.price?.values?.[el?.price?.default]?.price?.major,
                    productVariantStock: el?.stock || 0,
                  },
                  productVariantStatus:
                    el?.is_published === 1 ? 'active' : 'inactive',
                  productVariantImages: {
                    selectedProductVariantImages: [],
                    previewProductVariantImages:
                      previewImages?.length > 0 ? previewImages : [],
                  },
                  variantAttributes: sendingAttributes,
                };
              }
            );

            setVariantResponse(sendingVariants);
          } else {
            setVariantResponse([]);
          }

          setLoading('success');
          /* setProductVariants(mainProductVariantObj);
          setCurrentProduct(mainProductObj[0]); */
          //
        } else {
          setLoading('null');
        }
      } else {
        setLoading('error');
      }
    };

    getProducts();
  }, [refreshCount]);
  /* ----------------------------API CALL GET PRODUCT  END---------------------------- */

  return (
    <>
      {loading === 'error' && (
        <NoData
          title='Error Fetching Data'
          subTitle='Something went wrong :('
        />
      )}
      {loading === 'pending' && <LoadingSpinner />}
      {loading === 'success' && (
        <EditProduct
          productSku={productSku}
          productRes={productResponse}
          variantRes={variantResponse}
          triggerRefreshData={() => {
            setRefreshCount(refreshCount + 1);
          }}
        />
      )}
    </>
  );
};

export default LoadEditProduct;

function convertObjectToArray(obj: any) {
  return Object.keys(obj).map((key) => {
    return { [key]: obj[key] };
  });
}
