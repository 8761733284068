import { plusSignSVG } from '../../lib/allCustomSVGs';
import '../../styles/pages/products/productsPage.scss';
import { useEffect, useState } from 'react';
import { RootState } from '../../store/store';

//api call imports
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import store from '../../store/store';

// other imports
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../ui/noData/NoData';
import { useNavigate } from 'react-router-dom';
import SubPageInnerContentHolder from '../../components/subPageContentHolder/SubPageInnerContentHolder';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import useTitle from '../../customHooks/useTitle';
import Popup from '../../ui/popup/Popup';
import ShareProduct from './ShareProduct';
import { useSelector } from 'react-redux';
import DeleteProductModal from './DeleteProductModal';
import { useTranslation } from 'react-i18next';
import returnArrayOrNull from '../../lib/helper-functions/returnArrayOrNull';
import { PRODUCT_API, SHOP_API } from '../../lib/constants/central-api';
import CustomDataGridMain from '../../ui/customDataGrid.tsx/CustomDataGridMain';
import PaginationMain from '../../components/pagination/PaginationMain';
import FilterTheme2 from '../../components/filter/FilterTheme2';

interface Props {
  currentPageNumber: number;
  setCurrentPageNumber: (input: any) => void;
}

const AllProducts = ({ currentPageNumber, setCurrentPageNumber }: Props) => {
  const { t } = useTranslation();
  useTitle('Products');
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const slug = store.getState().user?.company?.shop_slug;
  const [loading, setLoading] = useState('pending');
  const navigate = useNavigate();

  /*------------------------- PAGINATION STATE START ------------------------- */
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [productData, setProductData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  /*------------------------- PAGINATION STATE END ------------------------- */

  /* ----------- pagination stuf start ------------ */
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(totalRecords / itemsPerPage));
  }, [itemOffset, itemsPerPage, productData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    setCurrentPageNumber(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % totalRecords;
    setItemOffset(newOffset);
  };

  const handleRecordsPerPageChange = (value: number) => {
    setItemsPerPage(value);
    setCurrentPageNumber(1); // Reset to the first page
    setItemOffset(0); // Reset the item offset
  };
  /* ----------- pagination stuf end   ------------ */

  /* share product modal state */
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  /* delete product modal state */
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  /* get company details from store */
  const userCompany = useSelector((state: RootState) => state.user.company);

  const [selectedProductDetails, setSelectedProductDetails] = useState<any>();
  const [deleteState, setDeleteState] = useState<number>(1);

  const togglePopupModal = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const toggleDeletePopupModal = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  /* ----------------------------API CALL START---------------------------- */
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url:
      SHOP_API +
      `/${slug}/products?per_page=${itemsPerPage}&page=${currentPageNumber}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_ALL_PRODUCTS';
  const customMsg = 'Products Loaded Successfully.';

  useEffect(() => {
    const getProducts = async () => {
      setLoading('pending');
      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;

      if (!response?.success) {
        setLoading('error');
      }
      if (response?.data?.data?.length === 0) {
        setLoading('null');
      } else {
        if (res.actionType === actionType && response.success === true) {
          const productsResult = response?.data;
          setTotalRecords(productsResult?.meta?.total);

          const productsArray = returnArrayOrNull(productsResult);

          if (productsArray?.length > 0) {
            const data: any = [];
            for (let i = 0; i < productsArray?.length; i++) {
              const product: any = productsArray[i];

              const defaultImage = `${staticURL}/small/default.jpg`;

              let firstImage = defaultImage;

              if (product?.images) {
                const values: any = Object?.values(product?.images);
                if (values?.length > 0) {
                  firstImage = values[0]?.small
                    ? values[0]?.small
                    : defaultImage;
                }
              }

              const defaultCurrency = product?.price?.default;
              const productPrice = product?.price?.values[defaultCurrency]
                ?.price?.formatted
                ? product?.price?.values[defaultCurrency]?.price?.formatted
                : '';

              data.push({
                type: product?.type,
                id: product?.id,
                sku: product?.sku,
                name: product?.name,
                price: productPrice,
                firstImage,
                isManual: Boolean(product?.is_manual),
              });
            }
            setLoading('success');
            setProductData(data);
          }
        }
      }
    };

    if (slug && slug !== '') {
      getProducts();
    }
  }, [currentPageNumber, deleteState, slug, itemsPerPage]);

  /* ----------------------------API CALL END---------------------------- */

  /* ---------------------------- DELETE PRODUCT API CALL START ---------------------------- */
  const deleteHandler = async (productSku: string | number) => {
    setLoading('pending');
    const deleteRequestInfo: RequestInfo = {
      url: `${PRODUCT_API(slug)}/${productSku}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: accessToken.type + ' ' + sellerToken,
      },
    };

    const actionType = 'DELETE_PRODUCTS';
    const customMsg = t('ProductDeletedSuccessfully-msg');

    const res: any = await asyncHandler(
      deleteRequestInfo,
      actionType,
      customMsg
    );

    const response = res?.data;

    if (res.actionType === actionType && response === null) {
      setDeleteState(deleteState + 1);
    } else {
      setLoading('success');
    }
  };
  // highlightingValue
  const dataGridColumns = [
    // { field: 'id', headerName: 'id', width: 5 },
    {
      field: 'firstImage',
      headerName: 'image',
      imageField: true,
      width: 5,
    },
    { field: 'name', headerName: 'name', truncate: 23 },
    { field: 'sku', headerName: 'sku' },
    { field: 'price', headerName: 'price' },
    {
      field: 'type',
      headerName: 'type',
      width: 5,
      highlightingValue: [
        { value: 'simple', color: 'green' },
        { value: 'variable', color: 'blue' },
      ],
    },
  ];

  /* ---------------------------- DELETE PRODUCT API CALL END ------------------------------ */

  /* ----------------------------FILTER SECTION START------------------------------ */

  /* ----------------------------FILTER SECTION END------------------------------ */

  return (
    <SubPageContentHolder>
      <SubPageInnerContentHolder
        title={t('AllProducts')}
        subTitle={t('ManageProducts')}
        mainBtnIcon={plusSignSVG}
        mainBtnTitle={t('AddProduct')}
        mainBtnLink='/products/add'
      >
        <div className='productsPage'>
          <div className='productsPage__top'>
            <div className='productsPage__top--left'></div>
            <div className='productsPage__top--right'></div>
          </div>
          <div className='productsPage__bottom'>
            {/* dt */}
            <div className='productsPage__bottom--dataGridContainer'>
              {loading === 'success' && (
                <>
                  <CustomDataGridMain
                    allRows={productData}
                    columns={dataGridColumns}
                    onEditHandler={(params) => {
                      navigate(`/products/edit/${params?.sku}`);
                    }}
                    onShareHandler={(params) => {
                      setSelectedProductDetails(params);
                      togglePopupModal();
                    }}
                    onViewHandler={(params) => {
                      navigate(`/products/${params?.sku}`);
                    }}
                    onDeleteHandler={(params) => {
                      setSelectedProductDetails(params);
                      toggleDeletePopupModal();
                    }}
                    titleField='name'
                    imageField='firstImage'
                    excludingField={['id', 'price']}
                    borderType={'none'}
                    isManual='isManual'
                  />

                  <PaginationMain
                    totalRecords={totalRecords}
                    itemsPerPage={itemsPerPage}
                    onRecordsPerPageChange={handleRecordsPerPageChange}
                    breakLabel='...'
                    nextLabel={t(`${'Next'} >`)}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    initialPage={currentPageNumber - 1}
                    pageCount={pageCount}
                    previousLabel={t(`< ${'Previous'}`)}
                    renderOnZeroPageCount={undefined}
                    marginPagesDisplayed={2}
                    containerClassName='pagination'
                    pageLinkClassName='pagination__page-num'
                    previousLinkClassName='pagination__page-num'
                    nextLinkClassName='pagination__page-num'
                    disabledLinkClassName='pagination__page-num-disabled'
                    activeLinkClassName='pagination__active'
                  />
                </>
              )}
            </div>
            {/* dt */}

            {loading === 'null' && (
              <NoData
                title={t('NoProductsFound-msg')}
                /* subTitle='Add Products to view them here.' */
              />
            )}
            {loading === 'error' && (
              <NoData
                title={t('FetchingDataError-msg')}
                subTitle='Something went wrong :('
              />
            )}
            {loading === 'pending' && <LoadingSpinner />}
          </div>
        </div>

        {/* ------share popup start-------- */}
        <Popup
          styles={{
            backgroundColor: '#fff',
          }}
          setTrigger={togglePopupModal}
          trigger={isPopupOpen}
        >
          <ShareProduct
            shopSlug={userCompany.shop_slug}
            shopId={userCompany.shop_id}
            sku={selectedProductDetails?.sku}
            productType={selectedProductDetails?.type}
            isMainProduct={true}
          />
        </Popup>
        {/* ------share popup end-------- */}

        {/* ------delete popup start-------- */}
        <Popup
          styles={{
            backgroundColor: '#fff',
          }}
          setTrigger={toggleDeletePopupModal}
          trigger={isDeletePopupOpen}
        >
          <DeleteProductModal
            handler={() => {
              deleteHandler(selectedProductDetails?.sku);
            }}
            closeModal={toggleDeletePopupModal}
            mainTitle={`${t('DeleteProductSubTitle1')} (${
              selectedProductDetails?.name
            })`}
            subTitle1={t('DeleteProductSubTitle1')}
            subTitle2={t('DeleteProductSubTitle2')}
          />
        </Popup>
        {/* ------delete popup end-------- */}
      </SubPageInnerContentHolder>
    </SubPageContentHolder>
  );
};

export default AllProducts;
