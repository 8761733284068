/* ----------------------------------------------------------------------------------------------------- */
interface InputItem {
  name: string;
  type: string;
  label: string;
  default: any;
  is_active: boolean;
  is_required: boolean;
}

/**
 * Converts an array of objects into an object with custom keys.
 * Each key is initialized with the corresponding 'default' value from the input.
 * @param array The input array of objects.
 * @returns An object with custom keys based on the 'name' values and corresponding 'default' values.
 */
export default function array_of_objects_to_default_value_object(
  array: InputItem[]
): Record<string, any> {
  // Initialize an empty object to store the result
  const result: Record<string, any> = {};

  // Iterate over the array and add each element's name as a key with the default value
  for (const item of array) {
    result[item.name] = item.default;
  }

  // Return the final object
  return result;
}

/* 
  Example usage:
  const inputArray = [
    {
      name: "magento_mollie_enabled",
      type: "toggle",
      label: "Mageto Mollie Enabled",
      default: false,
      is_active: true,
      is_required: true
    },
    {
      name: "magento_stripe",
      type: "text",
      label: "Mageto Stripe",
      default: "",
      is_active: true,
      is_required: true
    }
  ];
  
  const outputObject = arrayToObject(inputArray);
  
  // Log the output object
  console.log(outputObject); // => { "magento_mollie_enabled": false, "magento_stripe": "" }
  */

/* ----------------------------------------------------------------------------------------------------- */
