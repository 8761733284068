import '../../styles/pages/ai-onboarding/aiOnboardingCompany.scss';
import React, { useState } from 'react';
import AINormalInput from '../../ui/inputs/AINormalInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';
import AIPhoneInput from '../../ui/inputs/AIPhoneInput';
import AIURLInput from '../../ui/inputs/AIURLInput';
import AISelectInput from '../../ui/inputs/AISelectInput';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import {
  setAiOnboardingNextStep,
  setAiOnboardingStepCompleted,
  setUserCompanyDetails,
  updateProfile,
} from '../../store/reducers/userSlice';
import { asyncHandler } from '../../lib/api/async-handler';
import { RequestInfo } from '../../interfaces/RequestInfo';
import {
  CREATE_SHOP_API,
  REGISTRATION_API,
  SHOP_API,
  STATISTICS_BY_SHOP_API,
} from '../../lib/constants/central-api';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import { submitLogToBugsnag } from '../../lib/api/log';
import loadingGif from '../../assets/gif/loading.gif';
import { setMessage } from '../../store/reducers/appSlice';

const AIOnboardingCompany = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // global state
  const accessToken = useSelector(
    (state: RootState) => state.auth.authorization.accessToken
  );

  const seller_id = useSelector(
    (state: RootState) => state.user.profile.seller_id
  );
  const merchantSlug = useSelector(
    (state: RootState) => state.user.merchant.merchant_slug
  );

  const isWhiteLabel = useSelector((state: RootState) => state.UI.isWhiteLabel);

  const userProfile = useSelector((state: RootState) => state.user.profile);
  const companyDetails = useSelector((state: RootState) => state.user.company);

  // helper functions
  const getName = () => {
    if (userProfile.first_name && userProfile.last_name) {
      return `${userProfile.first_name} ${userProfile.last_name}`;
    } else if (userProfile.first_name) {
      return userProfile.first_name;
    } else if (userProfile.last_name) {
      return userProfile.last_name;
    } else {
      return '';
    }
  };

  const getURL = () => {
    if (companyDetails?.webshop_url) {
      return companyDetails?.webshop_url.replace('https://', '');
    } else {
      return '';
    }
  };

  // local state
  const [loading, setLoading] = useState<'pending' | 'initial'>('initial');
  const [companyName, setCompanyName] = useState(companyDetails.name);
  const [userName, setUserName] = useState(getName());
  const [url, setURL] = useState(getURL());
  const [numberOfTransactions, setNumberOfTransactions] = useState(
    companyDetails?.transaction_per_month !== ''
      ? companyDetails?.transaction_per_month
      : 'non'
  );
  const IPCountry = useSelector(
    (state: RootState) => state.app.countries.countryOfIP
  );
  const [phoneNumber, setPhoneNumber] = useState({
    country: userProfile.country_code ? userProfile.country_code : IPCountry,
    number: userProfile.phone,
  });
  const url_prefix = 'https://';
  const transactionsArr = [
    {
      OptionValue: 'non',
      OptionName: t('SelectMonthlyTransactions'),
      OptionDisabled: true,
    },
    { OptionValue: '0-2500', OptionName: '0-2500' },
    { OptionValue: '2500-5000', OptionName: '2500-5000' },
    { OptionValue: '5000-10000', OptionName: '5000-10000' },
    { OptionValue: '10000+', OptionName: '10000+' },
  ];

  async function submitProfile() {
    const firstName = userName.split(' ')[0];
    const lastName = userName.split(' ')[1];

    const sendingData = {
      first_name: firstName ? firstName : '',
      last_name: lastName ? lastName : '-',
      contact: phoneNumber.number,
      // country_code: countriesData[phoneNumber.country]?.dialCode?.substring(1),
      country_code: phoneNumber.country,
      language: 'en',
    };
    try {
      const requestInfo: RequestInfo = {
        url: `${REGISTRATION_API(merchantSlug)}/${seller_id}`,
        method: 'PUT',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: `${accessToken.type} ${
            isWhiteLabel
              ? accessToken.currentWhiteLabelSellerToken
              : accessToken.sellerToken
          }`,
        },
      };

      const actionType = 'ONBOARDING_PROFILE_UPDATE';
      const customMsg = 'Profile Update Successfull.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;
      if (res.actionType === actionType && response.success === true) {
        const { user } = response?.data;

        if (user?.role[0]?.name === 'seller') {
          if (user?.seller_id) {
            dispatch(
              updateProfile({
                first_name: firstName,
                last_name: lastName,
                country_code: phoneNumber.country,
                phone: phoneNumber.number,
                language: 'en',
              })
            );
            return true;
          }
        }
      } else {
        setLoading('initial');
        return false;
      }
    } catch (exception) {
      setLoading('initial');
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  async function submitCompany() {
    try {
      const requestInfo: RequestInfo = {
        url: CREATE_SHOP_API(seller_id),
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({
          name: companyName,
          currency: 'EUR',
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: `${accessToken.type} ${
            isWhiteLabel
              ? accessToken.currentWhiteLabelSellerToken
              : accessToken.sellerToken
          }`,
        },
      };

      const actionType = 'ONBOARDING_ADD_COMPANY';
      const customMsg = 'Company Added Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        const companyResponse = {
          name: companyName,
          currency: 'EUR',
          shop_id: response?.data?.id?.toString(),
          shop_slug: response?.data?.slug,
          location: response?.data?.location ? response?.data?.location : '',
          is_active: response?.data?.is_active ? true : false,
          initial: response?.data?.initial ? response?.data?.initial : '',
          webshop_url: '',
          transaction_per_month: '',
        };

        if (companyResponse?.shop_slug) {
          dispatch(setUserCompanyDetails(companyResponse));
          return companyResponse;
        } else {
          setLoading('initial');
        }
      } else {
        setLoading('initial');
        return false;
      }
    } catch (exception) {
      setLoading('initial');
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  const submitTransactionData = async (companyResponse: any) => {
    const sendingData = JSON.stringify({
      additional_stats: {
        monthly_transactions: numberOfTransactions,
      },
    });

    const requestInfo: RequestInfo = {
      url: STATISTICS_BY_SHOP_API(companyResponse?.shop_slug),
      method: 'PUT',
      body: sendingData,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `${accessToken.type} ${
          isWhiteLabel
            ? accessToken.currentWhiteLabelSellerToken
            : accessToken.sellerToken
        }`,
      },
    };

    const actionType = 'ONBOARDING_WEB_SHOP_TRANSACTIONS';
    const customMsg = 'Webshop Transactions Update Successfull.';

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res.data;

    if (res.actionType === actionType && response.success === true) {
      dispatch(
        setUserCompanyDetails({
          transaction_per_month: numberOfTransactions,
        })
      );
      return true;
    } else {
      return false;
    }
  };

  const submitWebshopData = async (companyResponse: any) => {
    const sendingData = {
      webshop_url: url_prefix + url,
    };

    const requestInfo: RequestInfo = {
      url: SHOP_API + `/${companyResponse?.shop_slug}/setting/shop-general`,
      method: 'PUT',
      body: applicationWWWXRLEncodedBodyBuilder(sendingData),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: `${accessToken.type} ${
          isWhiteLabel
            ? accessToken.currentWhiteLabelSellerToken
            : accessToken.sellerToken
        }`,
      },
    };

    const actionType = 'ONBOARDING_WEB_SHOP_URL';
    const customMsg = t('Update Successfully.');

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res.data;

    if (res.actionType === actionType && response.success === true) {
      dispatch(
        setUserCompanyDetails({
          webshop_url: url_prefix + url,
        })
      );
      return true;
    } else {
      return false;
    }
  };

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !userName ||
      !companyName ||
      !phoneNumber.number ||
      !url ||
      numberOfTransactions === 'non'
    ) {
      return;
    }

    setLoading('pending');

    try {
      const profileUpdated = await submitProfile();
      if (!profileUpdated) throw new Error('Profile update failed');

      const companyCreated = await submitCompany();
      if (!companyCreated) throw new Error('Company creation failed');

      if (companyCreated?.shop_slug && companyCreated?.shop_slug !== '') {
        const transactionDataSubmitted = await submitTransactionData(
          companyCreated
        );
        if (!transactionDataSubmitted)
          throw new Error('Transaction data submission failed');

        const webshopDataSubmitted = await submitWebshopData(companyCreated);
        if (!webshopDataSubmitted)
          throw new Error('Webshop data submission failed');

        setLoading('initial');
        dispatch(
          setAiOnboardingStepCompleted({ stepNumber: 1, isCompleted: true })
        );

        if (
          numberOfTransactions === '5000-10000' ||
          numberOfTransactions === '10000+'
        ) {
          dispatch(setAiOnboardingNextStep(3));
        } else {
          dispatch(setAiOnboardingNextStep(2));
        }
      }
    } catch (error) {
      const message = t('SomethingWentWrong-msg');
      const messageType = 'error';
      dispatch(setMessage({ message, messageType }));
      console.error('Error during submission:', error);
      setLoading('initial'); // Reset loading state on error
    }
  };

  const isDisabled =
    !userName ||
    !companyName ||
    !phoneNumber.number ||
    !url ||
    numberOfTransactions === 'non';

  return (
    <form
      onSubmit={(e) => {
        submitForm(e);
      }}
      className='aiOnboardingCompany'
    >
      <div className='aiOnboardingCompany__title'>
        <span>{t('CompanyDetails')}</span>
      </div>

      <div className='aiOnboardingCompany__input'>
        <AINormalInput
          changeListeners={[(e) => setUserName(e.target.value)]}
          id='aiOnboardingCompany__user-name-input'
          value={userName}
          placeholder={t('Name')}
          pattern='^.{1,100}$'
          required={true}
          title={'Name must be between 1 and 100 characters'}
          innerPlaceholder={t('EnterYourName')}
          minlength={1}
          maxlength={100}
        />
      </div>
      <div className='aiOnboardingCompany__input'>
        <AINormalInput
          changeListeners={[(e) => setCompanyName(e.target.value)]}
          id='aiOnboardingCompany__company-name-input'
          value={companyName}
          placeholder={t('CompanyName')}
          pattern='^.{1,100}$'
          required={true}
          title={'Company name must be between 1 and 100 characters'}
          innerPlaceholder={t('EnterCompanyName')}
          minlength={1}
          maxlength={100}
        />
      </div>
      <div className='aiOnboardingCompany__input'>
        <AIPhoneInput
          id='aiOnboardingCompany__user-phone-input'
          setPhoneNumber={setPhoneNumber}
          phoneNumber={phoneNumber}
          label={t('PhoneNumber')}
          placeholder={t('EnterPhoneNumber')}
          title={t('Phone-validation')}
          required={true}
          pattern='^[0-9]{4,}$'
        />
      </div>
      <div className='aiOnboardingCompany__input'>
        <AIURLInput
          prefix={url_prefix}
          changeListeners={[(e) => setURL(e.target.value)]}
          id='aiOnboardingCompany__shop-url-input'
          value={url}
          label={t('WebshopURL')}
          placeholder={t('www.example.com')}
          title={t('URL-validation')}
          required={true}
        />
      </div>
      <div className='aiOnboardingCompany__input'>
        <AISelectInput
          defaultValue={numberOfTransactions}
          setSelect={setNumberOfTransactions}
          selectOptionArr={transactionsArr}
          placeholder={t('AmountOfMonthlyTransactions')}
          id={'aiOnboardingCompany__transaction-number'}
        />
      </div>
      {loading === 'pending' ? (
        <div className='aiOnboardingCompany__loader'>
          <img src={loadingGif} alt='loading' />
        </div>
      ) : (
        <div className='aiOnboardingCompany__button'>
          <MainSquareButton
            type={'submit'}
            value={t('Next')}
            normal={true}
            disabled={isDisabled}
          />
        </div>
      )}
    </form>
  );
};

export default AIOnboardingCompany;
