import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/components/pageWrappers/pageWrapper.scss';
import Footer from '../footer/Footer';
import SidebarTheme1 from '../sidebar/SidebarTheme1';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { sellerAllSidebarLinks } from '../../lib/constants/sidebarLinks';

const PageWrapper: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const isSidebarDrawerOpen = useSelector(
    (state: RootState) => state.UI.isSidebarDrawerOpen
  );

  // console.log(sellerAllSidebarLinks);

  return (
    <div className='pageWrapper--outer'>
      <div className='pageWrapper'>
        <div className='pageWrapper__content'>
          <div
            data-is-sidebar-open={isSidebarDrawerOpen?.toString()}
            className='pageWrapper__content--sidebar'
          >
            <SidebarTheme1
              mainSubTitle={t('Autofill')}
              items={sellerAllSidebarLinks}
            />
          </div>
          <div className='pageWrapper__content--page'>{children}</div>
        </div>
      </div>
      <div className='pageWrapper--footer'>
        <Footer />
      </div>
    </div>
  );
};

export default PageWrapper;
