import { downloadSVG } from '../../../../lib/allCustomSVGs';
import '../../../../styles/pages/shop/autofill/autofill.scss';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';
import CategoryCard from '../../../../ui/cards/CategoryCard';
import { useTranslation } from 'react-i18next';
import store from '../../../../store/store';
import getCacheBustedUrl from '../../../../lib/helper-functions/cacheBuster';

const Prestashop = () => {
  let subTitle = '';
  const { t } = useTranslation();
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;

  const usingURLForDownloadPlugin = getCacheBustedUrl(
    'https://downloads.adup.io/ai-fastcheckout-prestashop/latest.zip'
  );

  return (
    <SubPageInnerContentHolder
      title='PrestaShop'
      subTitle={subTitle}
      backBtnLink={isWhiteLabel ? undefined : '/integrations'}
      mainBtnIcon={undefined}
      mainBtnTitle={undefined}
      mainBtnLink={undefined}
    >
      <div className='shopPage__container1'>
        <CategoryCard
          icon={downloadSVG}
          title={''}
          subTitle={t('DownloadPlugin')}
          link=''
          onSelect={() => {
            window.open(`${usingURLForDownloadPlugin}`, '_self');
          }}
        />
      </div>
    </SubPageInnerContentHolder>
  );
};

export default Prestashop;
