import { Route, Routes, useNavigate } from 'react-router-dom';
import WooCommerce from './WooCommerce/WooCommerce';
import Magento from './Magento/Magento';
// import Lightspeed from './Lightspeed/Lightspeed';
import CustomFastcheckout from './custom/CustomFastcheckout';
import { useEffect } from 'react';
import Opencart from './Opencart/Opencart';
import Prestashop from './Prestashop/Prestashop';
import Lightspeed from './Lightspeed/Lightspeed';
import { allRoutes } from '../../../lib/constants/route';

const Fastcheckout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/integrations/fastcheckout/woocommerce');
  }, []);

  return (
    <Routes>
      {allRoutes['/integrations/fastcheckout/woocommerce'] && (
        <Route path='woocommerce/*' element={<WooCommerce />} />
      )}
      {allRoutes['/integrations/fastcheckout/magento'] && (
        <Route path='magento/*' element={<Magento />} />
      )}
      {allRoutes['/integrations/fastcheckout/opencart'] && (
        <Route path='opencart/*' element={<Opencart />} />
      )}
      {allRoutes['/integrations/fastcheckout/prestashop'] && (
        <Route path='prestashop/*' element={<Prestashop />} />
      )}
      {allRoutes['/integrations/fastcheckout/lightspeed'] && (
        <Route path='lightspeed/*' element={<Lightspeed />} />
      )}
      {allRoutes['/integrations/fastcheckout/custom'] && (
        <Route path='custom/*' element={<CustomFastcheckout />} />
      )}
    </Routes>
  );
};

export default Fastcheckout;
