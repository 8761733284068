import { useTranslation } from 'react-i18next';
import '../../../../../../styles/components/charts/chart.scss';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { useEffect, useState } from 'react';
import ChartLegend from '../../../../../../components/charts/ChartLegend';

type Props = {
  apiResponse: any;
};

const OverallConversionRatePieChart = ({ apiResponse }: Props) => {
  const { t } = useTranslation();

  const [conversionRate, setConversionRate] = useState(0);
  const [chartData, setChartData] = useState<any[]>([]);

  const getConversionRateData = async (res: any) => {
    if (res?.type === 'overall' && res?.data) {
      const totalCarts = res?.data?.total_carts ? res?.data.total_carts : 0;
      // const totalPurchases = res?.data?.total_purchases ? res?.data.total_purchases : 0;
      const conversionRate = res?.data?.conversion_rate
        ? parseFloat(res?.data?.conversion_rate?.toFixed(2))
        : 0;
      const remainingRate = parseFloat((100 - conversionRate)?.toFixed(2));

      setChartData([
        {
          label: t('Purchases'),
          value: conversionRate,
          color: '#82ca9d',
        },
        {
          label: t('RemainingCarts'),
          value: remainingRate,
          color: '#8884d8',
        },
      ]);

      if (conversionRate === 0) {
        return;
      }

      setConversionRate(conversionRate);
      return;
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  const valueFormatter = (item: { value: number }) => `${item.value}%`;

  const data = {
    data: chartData,
    valueFormatter,
  };

  return (
    <>
      <div className='chart__bottom--content-chart'>
        <div className='chart__bottom--content-chart-pie'>
          <PieChart
            margin={{ top: 5, bottom: 5, left: 5, right: 5 }}
            series={[
              {
                arcLabel: (item) => `${item.value}%`,
                arcLabelMinAngle: 35,
                arcLabelRadius: '60%',
                ...data,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fontWeight: 'bold',
              },
            }}
            slotProps={{
              legend: {
                hidden: true, // Set to true to hide the legend
              },
            }}
          />
        </div>
        <div className='chart__bottom--content-chart-legend'>
          {chartData?.map((item, index) => (
            <ChartLegend key={index} color={item?.color} label={item.label} />
          ))}
        </div>
      </div>
    </>
  );
};

export default OverallConversionRatePieChart;
