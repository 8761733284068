import { downloadSVG } from '../../../../lib/allCustomSVGs';
import '../../../../styles/pages/shop/autofill/autofill.scss';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';
import CategoryCard from '../../../../ui/cards/CategoryCard';
import { useTranslation } from 'react-i18next';
import store from '../../../../store/store';
import getCacheBustedUrl from '../../../../lib/helper-functions/cacheBuster';

const currentOrigin = window.location.origin;
function openLinkInNewTab(url: string) {
  let link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
}
function downloadLink(url: string) {
  let link = document.createElement('a');
  link.setAttribute('download', 'fastcheckout.js');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
}
const CustomFastcheckout = () => {
  const { t } = useTranslation();
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;

  const usingURLForDownloadPlugin = getCacheBustedUrl(
    `https://downloads.adup.io/fastcheckout-js/latest.zip`
  );

  return (
    <SubPageInnerContentHolder
      title={t('CustomIntegration')}
      backBtnLink={isWhiteLabel ? undefined : '/integrations'}
    >
      <div className='shopPage__container1'>
        <CategoryCard
          icon={downloadSVG}
          title={''}
          subTitle={t('DownloadPlugin')}
          link=''
          onSelect={() => {
            // downloadLink(`${currentOrigin}/scripts/fastcheckout.js`);
            downloadLink(`${usingURLForDownloadPlugin}`);
          }}
        />

        {/* <CategoryCard
          icon={userManualSVG}
          title={''}
          subTitle={t('Manual')}
          link=''
          onSelect={() => {
            openLinkInNewTab(
              'https://intercom.help/ad-up/en/articles/6376149-how-to-install-the-embedded-checkout'
            );
          }}
        /> */}
      </div>
    </SubPageInnerContentHolder>
  );
};

export default CustomFastcheckout;
