import React from 'react';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import PageWrapperTheme2 from './PageWrapperTheme2';
import PageWrapper from './PageWrapper';

const PageWrapperMain: React.FC = ({ children }) => {
  const themeType = useSelector((state: RootState) => state.UI.theme.type);

  const renderPageWrapper = () => {
    if (themeType === '2') {
      return <PageWrapperTheme2>{children}</PageWrapperTheme2>;
    }
    return <PageWrapper>{children}</PageWrapper>;
  };

  return renderPageWrapper();
};

export default PageWrapperMain;
