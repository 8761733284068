import React, { useEffect, useState } from 'react';
import WorldMapChart from '../../../../../components/charts/UserDemographics/WorldMapChart';

type Props = {
  apiResponse: any;
  groupBy: string;
};

const UserDemographicsMapChart = ({ apiResponse }: Props) => {
  const [chartData, setChartData] = useState<any[]>([]);

  const getData = async (res: any) => {
    if (res !== 'initial' && res?.length === 0) {
      return;
    } else if (res !== 'initial' && res?.length > 0) {
      if (res[0]?.id?.length !== 3) {
        return;
      } else {
        setChartData(res);
      }
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getData(apiResponse);
    }
  }, [apiResponse]);

  return <WorldMapChart data={chartData} />;
};

export default UserDemographicsMapChart;
