import { useLayoutEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

export const HistoryRouter = ({ history, children, ...props }: any) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <BrowserRouter
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {' '}
      {children}{' '}
    </BrowserRouter>
  );
};
