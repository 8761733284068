/**
 * Generates a box-shadow CSS property value based on the provided main theme color and opacity values.
 * @param mainThemeColor - The main theme color in hex format.
 * @param opacityValues - An array of opacity values for each box-shadow part. Default: [0.12, 0.1, 0.1]
 * @returns The box-shadow CSS property value.
 */
export function generateBoxShadow(
  mainThemeColor: string,
  opacityValues: number[] = [0.12, 0.1, 0.1]
): string {
  // Create an array of box-shadow parts
  const boxShadowParts = opacityValues.map((opacity, index) => {
    // Determine the color part based on the index
    const colorPart = index === 1 ? '0 4px 23px 0' : '0 8px 10px -5px';

    // Convert the opacity to its corresponding hex value
    const opacityHex = Math.round(opacity * 255)
      .toString(16)
      .padStart(2, '0');

    // Combine the color part, mainThemeColor, and opacity value to form a single box-shadow part
    return `${colorPart} ${mainThemeColor}${opacityHex}`;
  });

  // Join the box-shadow parts with commas and newlines to form the complete box-shadow string
  const boxShadow = boxShadowParts.join(',\n');

  // Return the final box-shadow value
  return boxShadow;
}

/*   
// Usage example
const themeColor = '#111111';
const opacityValues = [0.42, 0.12, 0.2];

const boxShadow = generateBoxShadow(themeColor, opacityValues);
console.log(boxShadow); // Output: "0 14px 26px -12px #1111116b, 0 4px 23px 0 #1111111f, 0 8px 10px -5px #11111133"
  */
