import { useNavigate } from 'react-router-dom';
import '../../styles/buttons/selectableButton.scss';
import CustomSVGs from '../customSVGs/CustomSVGs';

interface Props {
  icon: any;
  title: string;
  subTitle: string;
  subTitle2?: string;
  link: string;
  isSelected?: boolean;
  onSelect?: (event: React.MouseEvent) => void;
}

const SelectableButton = ({
  icon,
  title,
  subTitle,
  subTitle2,
  link,
  isSelected,
  onSelect,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={
        onSelect
          ? onSelect
          : () => {
              navigate(link);
            }
      }
      className={
        isSelected
          ? 'selectableButton selectableButton__isSelected'
          : 'selectableButton'
      }
    >
      {icon && icon !== '' && (
        <div
          className={
            isSelected
              ? 'selectableButton__icon__isSelected'
              : 'selectableButton__icon'
          }
        >
          <CustomSVGs svg={icon} />
        </div>
      )}
      <div className='selectableButton__titles'>
        <h2>{title}</h2>
        <h2>{subTitle}</h2>
        {subTitle2 && (
          <>
            <span /* style={{ fontSize: 8 }} */>{subTitle2}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default SelectableButton;
