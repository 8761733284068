import { errorSVG, successSVG } from '../../lib/allCustomSVGs';
import MessageBanner from '../../components/messageBanner/MessageBanner';
import store from '../../store/store';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../store/reducers/appSlice';
import { useTranslation } from 'react-i18next';

const AdUpPayOnboardingSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [adUpPayStatus, setAdUpPayStatus] = useState<string>('');

  useEffect(() => {
    let urlStatus = window.location?.search.split('?status=')[1];
    console.log('AdUp Pay onboarding status : ', urlStatus);
    setAdUpPayStatus(urlStatus);
    if (!urlStatus) {
      navigate('/');
    }
  }, []);

  if (adUpPayStatus && adUpPayStatus === 'success') {
    return (
      <>
        <MessageBanner
          title={t('AdUpPaySuccessMainText')}
          subTitle={t('AdUpPaySuccessSubText')}
          icon={successSVG}
          btnTitle='Ok'
          onSelect={() => {
            window.opener.location.reload();
            window.close();
          }}
          success={true}
        />
      </>
    );
  } else if (adUpPayStatus && adUpPayStatus === 'failed') {
    return (
      <>
        <MessageBanner
          title={t('AdUpPayFailMainText')}
          subTitle={t('AdUpPayFailSubText')}
          icon={errorSVG}
          btnTitle={t('GoBack')}
          onSelect={() => {
            window.opener.location.reload();
            window.close();
          }}
          success={false}
        />
      </>
    );
  } else if (adUpPayStatus && adUpPayStatus !== '') {
    navigate('/');
    store.dispatch(
      setMessage({
        message: `${t('AdUpPayFailMainText')}`,
        messageType: 'error',
      })
    );
    return null;
  } else {
    return null;
  }
};

export default AdUpPayOnboardingSuccess;
