export const clickThroughRateDummyData = [
  {
    button_type: 'addToCartButtonElement',
    button_label: 'Add To Cart Button',
    impressions: 14834,
    clicks: 754,
    ctr: 5.08,
  },
  {
    button_type: 'goToCheckoutButtonElement',
    button_label: 'Go To Checkout Button',
    impressions: 10042,
    clicks: 2077,
    ctr: 20.68,
  },
  {
    button_type: 'payButtonElement',
    button_label: 'Pay Button',
    impressions: 2909,
    clicks: 343,
    ctr: 11.79,
  },
];
export const addToCartButtonElement_CTR_DummyData = [
  {
    button_color: '#c07509',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#f49710',
    impressions: 14396,
    clicks: 585,
    ctr: 4.06,
  },
  {
    button_color: '#f49813',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#f49a17',
    impressions: 3,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#f49a18',
    impressions: 1,
    clicks: 1,
    ctr: 100,
  },
  {
    button_color: '#f6a736',
    impressions: 2,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#f6a837',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#f6aa3c',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#f7b759',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#f9c57a',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#facf91',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#fad093',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#fcdfb6',
    impressions: 1,
    clicks: 1,
    ctr: 100,
  },
  {
    button_color: '#fce2bd',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#fdeace',
    impressions: 2,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#fdedd7',
    impressions: 2,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#fef7ec',
    impressions: 1,
    clicks: 1,
    ctr: 100,
  },
  {
    button_color: '#fffcf7',
    impressions: 6,
    clicks: 1,
    ctr: 16.67,
  },
  {
    button_color: '#fffcf9',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#fffefd',
    impressions: 1,
    clicks: 1,
    ctr: 100,
  },
  {
    button_color: '#ffffff',
    impressions: 32,
    clicks: 10,
    ctr: 31.25,
  },
  {
    button_color: 'Undetected',
    impressions: 423,
    clicks: 156,
    ctr: 36.88,
  },
];
export const goToCheckoutButtonElement_CTR_DummyData = [
  {
    button_color: '#000000',
    impressions: 278,
    clicks: 187,
    ctr: 67.27,
  },
  {
    button_color: '#c07509',
    impressions: 2,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#f49710',
    impressions: 6133,
    clicks: 157,
    ctr: 2.56,
  },
  {
    button_color: '#f49a18',
    impressions: 1,
    clicks: 1,
    ctr: 100,
  },
  {
    button_color: '#f6a939',
    impressions: 1,
    clicks: 1,
    ctr: 100,
  },
  {
    button_color: '#fdedd5',
    impressions: 1,
    clicks: 1,
    ctr: 100,
  },
  {
    button_color: '#fffbf6',
    impressions: 1,
    clicks: 1,
    ctr: 100,
  },
  {
    button_color: '#fffefd',
    impressions: 1,
    clicks: 1,
    ctr: 100,
  },
  {
    button_color: '#ffffff',
    impressions: 3684,
    clicks: 1731,
    ctr: 46.99,
  },
  {
    button_color: 'Undetected',
    impressions: 62,
    clicks: 62,
    ctr: 100,
  },
];
export const payButtonElement_CTR_DummyData = [
  {
    button_color: '#00950a',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#009607',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#009608',
    impressions: 2,
    clicks: 1,
    ctr: 50,
  },
  {
    button_color: '#009803',
    impressions: 9,
    clicks: 5,
    ctr: 55.56,
  },
  {
    button_color: '#009900',
    impressions: 58,
    clicks: 8,
    ctr: 13.79,
  },
  {
    button_color: '#009901',
    impressions: 5,
    clicks: 3,
    ctr: 60,
  },
  {
    button_color: '#018d1f',
    impressions: 4,
    clicks: 2,
    ctr: 50,
  },
  {
    button_color: '#019213',
    impressions: 2,
    clicks: 1,
    ctr: 50,
  },
  {
    button_color: '#019311',
    impressions: 10,
    clicks: 6,
    ctr: 60,
  },
  {
    button_color: '#028534',
    impressions: 2,
    clicks: 1,
    ctr: 50,
  },
  {
    button_color: '#02892a',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#037c4c',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#037d4a',
    impressions: 9,
    clicks: 5,
    ctr: 55.56,
  },
  {
    button_color: '#037d4b',
    impressions: 2,
    clicks: 1,
    ctr: 50,
  },
  {
    button_color: '#03823d',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#038536',
    impressions: 2,
    clicks: 1,
    ctr: 50,
  },
  {
    button_color: '#047856',
    impressions: 2,
    clicks: 1,
    ctr: 50,
  },
  {
    button_color: '#047857',
    impressions: 2854,
    clicks: 301,
    ctr: 10.55,
  },
  {
    button_color: '#047955',
    impressions: 11,
    clicks: 5,
    ctr: 45.45,
  },
  {
    button_color: '#047a51',
    impressions: 1,
    clicks: 0,
    ctr: 0,
  },
  {
    button_color: '#047a53',
    impressions: 2,
    clicks: 1,
    ctr: 50,
  },
  {
    button_color: 'Undetected',
    impressions: 21,
    clicks: 11,
    ctr: 52.38,
  },
];
