import { infoSVG } from '../../lib/allCustomSVGs';
import '../../styles/components/showInfoBlock/showInfoBlock.scss';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useComponentVisibility } from '../../customHooks/useComponentVisibility';

interface Props {
  infoText: string;
  hoverTitle?: string;
}

const ShowInfoBlock = ({ infoText, hoverTitle }: Props) => {
  const { t } = useTranslation();

  /* ========================InfoBlock Toggle Logic Start============================ */
  const {
    ref: infoBlockRef,
    isComponentVisible: isInfoBlockVisible,
    setIsComponentVisible: setIsInfoBlockVisible,
  } = useComponentVisibility<HTMLLIElement>(false);
  const handleInfoBlockClick = () => {
    setIsInfoBlockVisible((prev) => !prev);
  };

  const handleInfoBlockItemClick = () => {
    setIsInfoBlockVisible(false);
    // Handle specific actions when a dropdown item is clicked
  };
  /* ========================InfoBlock Toggle Logic End============================= */

  return (
    <li
      ref={infoBlockRef}
      onClick={handleInfoBlockClick}
      title={hoverTitle ? hoverTitle : t('ClickToViewInstructions-tag')}
      data-info_block={isInfoBlockVisible ? 'true' : 'false'}
      className='showInfoBlock'
    >
      <CustomSVGs svg={infoSVG} />
      <AnimatePresence>
        {isInfoBlockVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            className='showInfoBlock__infoHolder'
            onClick={handleInfoBlockItemClick}
          >
            <p>{infoText}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </li>
  );
};

export default ShowInfoBlock;
