import '../../styles/buttons/squareGrayButton.scss';

type props = {
  onClick: (event: React.MouseEvent) => void;
  icon?: any;
  value?: string;
  classes?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  normal?: boolean;
  title?: string;
};

const SquareGrayButton: React.FC<props> = ({
  onClick,
  icon,
  value,
  type,
  disabled,
  classes = '',
  normal,
  title,
}) => {
  let isNormalButton;
  if (normal) isNormalButton = 'true';
  else isNormalButton = 'false';
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={'squareGrayButton' + (' ' + classes)}
      data-square-gray-button-normal={isNormalButton}
      title={title}
    >
      {icon}
      <p>{value}</p>
    </button>
  );
};

export default SquareGrayButton;
