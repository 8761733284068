import '../../styles/sliders/slideFromBottom.scss';
import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

interface Props {
  hideSegment: () => void;
  children: React.ReactNode;
}

const backdropOpacityAnimation = {
  animate: {
    opacity: 1,
  },
  initial: {
    opacity: 0,
  },
  transition: {
    duration: 0.2,
    delay: 0.5,
  },
  exit: {
    opacity: 0,
  },
};

const slideFromBottomAnimation = {
  animate: {
    transform: 'translateY(0px)',
  },
  initial: {
    transform: 'translateY(1000px)',
  },
  exit: {
    transform: 'translateY(1000px)',
  },
  transition: {
    delay: 0,
    duration: 0.5,
  },
};

const SlideFromBottom = ({ hideSegment, children }: Props) => {
  const selfRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    selfRef.current.focus();
  }, []);

  return (
    <motion.div ref={selfRef} className='slideFromBottom' tabIndex={0}>
      <motion.div
        key='backdrop'
        className='slideFromBottom-backdrop'
        {...backdropOpacityAnimation}
        onClick={hideSegment}
      ></motion.div>
      <motion.div
        key='slideFromBottomPrompt'
        {...slideFromBottomAnimation}
        className='slideFromBottom-prompt'
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <div className='slideFromBottom-prompt-container'>{children}</div>
      </motion.div>
    </motion.div>
  );
};

export default SlideFromBottom;
