import SubPageContentHolder from '../../../components/subPageContentHolder/SubPageContentHolder';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PSPConnection from './pspConnection/PSPConnection';
import PSPOptions from './pspOptions/PSPOptions';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { allRoutes } from '../../../lib/constants/route';

const PSP = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/settings/payments/connection');
  }, []);

  return (
    <div className='subPage'>
      <SubPageContentHolder>
        <Routes>
          {allRoutes['/settings/payments/connection'] && (
            <Route path='connection/*' element={<PSPConnection />} />
          )}
          {allRoutes['/settings/payments/options'] && (
            <Route path='options/*' element={<PSPOptions />} />
          )}
        </Routes>
      </SubPageContentHolder>
    </div>
  );
};

export default PSP;
