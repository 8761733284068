import { useEffect, useState } from 'react';
import '../../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../../ui/inputs/NormalInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useNavigate, useParams } from 'react-router-dom';
import ToggleSwitch from '../../../../ui/buttons/ToggleSwitch';
import { PaymentMethodsInterface } from './AddPSP';
import { updateIntercom } from '../../../../store/reducers/userSlice';
import NormalSelectInput from '../../../../ui/inputs/NormalSelectInput';
import { useTranslation } from 'react-i18next';
import makeFirstLetterCapital from '../../../../lib/helper-functions/makeFirstLetterCapital';
import update_psp_api_call from '../../../../lib/api/psp/update-psp';

const EditPSP = () => {
  const { pspId } = useParams();
  const [editingPSP, setEditingPSP] = useState<any>([]);
  const [editingPSPAllData, setEditingPSPAllData] = useState<any>([]);
  const [currentPSPPaymentMethodsList, setCurrentPSPPaymentMethodsList] =
    useState<any>([]);
  const [pspInputs, setPSPInputs] = useState([]);
  const [pspInputValues, setPSPInputValues] = useState<any>();
  const { t } = useTranslation();
  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alreadyAddedPSP = useSelector(
    (state: RootState) => state.user.company.alreadyAddedPSP
  ) as any;

  const [selectedPSP, setSelectedPSP] = useState({ value: '', label: '' });

  const [selectedPaymentMethods, setSelectedPaymentMethods] =
    useState<PaymentMethodsInterface[]>();
  const [visiblePaymentMethods, setVisiblePaymentMethods] =
    useState<JSX.Element>();
  const [paymentMethodClickChanges, setPaymentMethodClickChanges] = useState(0);
  const tempItems: JSX.Element[] = [];

  useEffect(() => {
    //getting the current PSP data to edit
    const addedPSPArr = Object.values(alreadyAddedPSP[0]?.payment_secret);
    const currentPSP: any = addedPSPArr?.filter(
      (el: any) => el?.payment_service_id?.toString() === pspId?.toString()
    );

    let current_PSP_API_values;
    if (pspId && currentPSP?.length > 0) {
      setEditingPSP(currentPSP[0]);

      //getting current PSP inputs keys and values object
      current_PSP_API_values = currentPSP[0]?.access_keys;
    }

    //getting full data about the current psp from the available PSPs array using the pspId
    const currentPSPAllData: any = onboarding_object?.available_psp?.filter(
      (el: any) => el?.id?.toString() === pspId?.toString()
    );

    let temp_inputs: any = [];
    if (currentPSPAllData?.length > 0) {
      setEditingPSPAllData(currentPSPAllData[0]);

      //getting current PSP inputs structure
      if (currentPSPAllData[0]?.connection_fields?.length > 0) {
        temp_inputs = currentPSPAllData[0]?.connection_fields;
      }
    }

    //getting all the payment methods that are already added for the current PSP
    const allAddedPSPMethods = Object.values(
      alreadyAddedPSP[0]?.payment_methods
    );

    //getting the current payment methods that are already added for the current PSP
    const currentPSPPaymentMethods: any = [];
    allAddedPSPMethods?.forEach((el: any) => {
      if (el?.payment_service_id?.toString() === pspId?.toString()) {
        currentPSPPaymentMethods.push(el?.common_alias);
      }
    });
    setCurrentPSPPaymentMethodsList(currentPSPPaymentMethods);

    setPSPInputs(temp_inputs);
    setPSPInputValues(current_PSP_API_values);
  }, [pspId]);

  useEffect(() => {
    setSelectedPSP({
      value: editingPSPAllData?.slug,
      label: editingPSPAllData?.label,
    });
  }, [editingPSP]);

  useEffect(() => {
    let finalArray: PaymentMethodsInterface[] = [];
    if (selectedPSP?.value) {
      let data = editingPSPAllData?.payment_methods;
      for (const key in data) {
        let method = {
          value: key,
          label: data?.[key]?.label,
          isSelected: undefined,
        };
        finalArray.push(method);
      }
    }
    setSelectedPaymentMethods(finalArray);
  }, [selectedPSP]);

  const isToggleSwitchDisabled = (method: string) => {
    //getting all the payment methods that are already added for the current PSP
    const allUsedPaymentMethodsInShop =
      alreadyAddedPSP[0]?.payment_methods[method];

    //checking if the current payment method is already added for the current PSP
    if (
      allUsedPaymentMethodsInShop &&
      !currentPSPPaymentMethodsList?.includes(
        allUsedPaymentMethodsInShop?.common_alias
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  //setting the payment methods toggle switches
  if (selectedPaymentMethods) {
    for (let method of selectedPaymentMethods) {
      let updatedValues = selectedPaymentMethods;
      let isSelected =
        selectedPaymentMethods[selectedPaymentMethods.indexOf(method)]
          .isSelected ??
        currentPSPPaymentMethodsList?.includes(`${method.value}`);
      if (
        selectedPaymentMethods[selectedPaymentMethods.indexOf(method)]
          .isSelected === undefined
      ) {
        updatedValues[updatedValues.indexOf(method)] = {
          ...method,
          isSelected: currentPSPPaymentMethodsList?.includes(`${method.value}`),
        };
        setSelectedPaymentMethods(updatedValues);
      }
      const onClickHandler = (e: any) => {
        updatedValues[updatedValues.indexOf(method)] = {
          ...method,
          isSelected: e,
        };
        setSelectedPaymentMethods(updatedValues);
        isSelected = e;
        setPaymentMethodClickChanges(paymentMethodClickChanges + 1);
      };
      tempItems.push(
        <div className='normalForm__switch'>
          <ToggleSwitch
            disabled={isToggleSwitchDisabled(method.value)}
            message={t('PaymentMethodAlreadyAdded-msg')}
            key={method?.label}
            id={selectedPaymentMethods.indexOf(method)}
            isOn={isSelected}
            defaultChecked={editingPSP?.methods?.includes(`${method.value}`)}
            title={method?.label}
            onClick={onClickHandler}
            classes='margin-top-2rem'
          />
        </div>
      );
    }
  }

  useEffect(() => {
    if (tempItems) {
      setVisiblePaymentMethods(tempItems as any);
    }
  }, [paymentMethodClickChanges, selectedPaymentMethods]);

  const getAllSelectedPaymentMethods = () => {
    return selectedPaymentMethods
      ?.filter((el) => {
        return el.isSelected === true;
      })
      .map(({ value }) => value);
  };

  let isDisabled;
  let disabledButtonMessage = t('FillAllFields-msg');

  if (pspInputValues) {
    isDisabled = Object.values(pspInputValues)?.some((el: any) => el === '');
  }
  /* if (getAllSelectedPaymentMethods()?.length === 0) {
    isDisabled = true;
  } */

  const onInputChange = (e: any, value: string) => {
    setPSPInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  console.log(getAllSelectedPaymentMethods());

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    const sendingPSPMethods = getAllSelectedPaymentMethods();

    const res: boolean = await update_psp_api_call(
      selectedPSP?.value,
      pspInputValues,
      sendingPSPMethods
    );

    if (res) {
      dispatch(
        updateIntercom({
          psp: selectedPSP?.value,
          pspdatafilled: 'true',
        })
      );
      navigate('/settings/payments/connection');
    }
  }

  return (
    <>
      {selectedPSP.label && selectedPSP.value && (
        <form
          id='PSPEdit-form'
          onSubmit={onSubmitHandler}
          className='normalForm'
        >
          <div className='normalForm__top'>
            <div className='normalForm__container3'>
              {selectedPSP && (
                <div className='normalForm__container3--item'>
                  <div className='normalForm__container3--item-content'>
                    <NormalInput
                      disabled
                      id={'selected-psp-edit'}
                      value={selectedPSP?.label}
                      defaultValue={selectedPSP?.label}
                      placeholder={t('PSP-Type')}
                    />
                  </div>
                </div>
              )}

              {pspInputs.map((item: any, index: number) => {
                const el = item?.name;
                const isDisabled = !item?.is_active;
                return (
                  <div className='normalForm__container3--item' key={index}>
                    {selectedPSP?.value === 'aduppay' &&
                    el === 'profile_id' &&
                    !isDisabled ? (
                      <div className='normalForm__container3--item-content'>
                        <NormalSelectInput
                          key={index}
                          defaultValue={pspInputValues[el]}
                          setSelect={(value) => {
                            setPSPInputValues((prev: any) => ({
                              ...prev,
                              [el]: value,
                            }));
                          }}
                          disabled={isDisabled}
                          selectOptionArr={[
                            {
                              OptionValue: pspInputValues[el],
                              OptionName: pspInputValues[el],
                            },
                          ]}
                          placeholder={makeFirstLetterCapital(
                            el.replaceAll('_', ' ')
                          )}
                          id={`psp-integration-${el}-edit`}
                        />
                      </div>
                    ) : !isDisabled ? (
                      <div className='normalForm__container3--item-content'>
                        <NormalInput
                          key={index}
                          changeListeners={[(e) => onInputChange(e, el)]}
                          id={`psp-integration-${el}-edit`}
                          value={pspInputValues ? pspInputValues[el] : ''}
                          placeholder={makeFirstLetterCapital(
                            el.replaceAll('_', ' ')
                          )}
                          disabled={isDisabled}
                          required={true}
                          autoFocus={index === 0 ? true : false}
                          classes={`edit-psp-${selectedPSP?.value}-${el}-input`}
                          labelClasses={`edit-psp-${selectedPSP?.value}-${el}-label`}
                        />
                      </div>
                    ) : null}
                  </div>
                );
              })}

              {/* Choose Payment Methods : */}
              <div className='normalForm__switches'>
                {visiblePaymentMethods}
              </div>
            </div>
          </div>

          <div className='normalForm__bottom'>
            <MainSquareButton
              value={t('Edit')}
              /* icon={<CustomSVGs svg={plusSignSVG} />} */
              disabled={isDisabled}
              type='submit'
              form='PSPEdit-form'
              message={disabledButtonMessage}
            />
          </div>
        </form>
      )}
    </>
  );
};

export default EditPSP;
