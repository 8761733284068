import React from 'react';

interface Props {
  svg: any;
  fillColor?: string;
}

const CustomSVGs = ({ svg, fillColor }: Props) => {
  return React.cloneElement(svg, fillColor ? { fill: fillColor } : {});
};

export default CustomSVGs;
