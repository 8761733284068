import React from 'react';
import '../../styles/cards/ribbonCard.scss';
import CustomSVGs from '../customSVGs/CustomSVGs';
import { motion } from 'framer-motion';
/* --center-text-font-size: 1.5rem; */
type Props = {
  centerText: string;
  ribbonText: string;
  ribbonPosition: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  baseSize?: number;
  cardHeight?: number;
  ribbonColor?: string;
  centerTextFontSize?: number;
  icon?: any;
  onClick?: () => void;
};

const RibbonCard = ({
  centerText,
  ribbonPosition,
  ribbonText,
  baseSize = 10,
  cardHeight = 40,
  ribbonColor = '#3498db',
  centerTextFontSize = 1.5,
  icon,
  onClick,
}: Props) => {
  const stylesObj = {
    '--base-size': `${baseSize}rem`,
    '--card-height': `${cardHeight}rem`,
    '--ribbon-color': ribbonColor,
    '--center-text-font-size': `${centerTextFontSize}rem`,
  } as React.CSSProperties;

  return (
    <motion.div
      initial={{ opacity: 0, scale: 1 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1 }}
      transition={{ duration: 0.3 }}
      onClick={onClick}
      className='ribbonCard'
      style={stylesObj}
    >
      <div className='text'>
        {icon && <CustomSVGs svg={icon} />}
        <span>{centerText}</span>
      </div>
      {ribbonPosition === 'top-left' && (
        <div className='ribbon ribbon-top-left'>
          <span>{ribbonText}</span>
        </div>
      )}
      {ribbonPosition === 'top-right' && (
        <div className='ribbon ribbon-top-right'>
          <span>{ribbonText}</span>
        </div>
      )}
      {ribbonPosition === 'bottom-left' && (
        <div className='ribbon ribbon-bottom-left'>
          <span>{ribbonText}</span>
        </div>
      )}
      {ribbonPosition === 'bottom-right' && (
        <div className='ribbon ribbon-bottom-right'>
          <span>{ribbonText}</span>
        </div>
      )}
    </motion.div>
  );
};

export default RibbonCard;
