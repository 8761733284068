import '../../styles/pages/thankYou/stripeSubscriptionFailed.scss';
import { errorSVG } from '../../lib/allCustomSVGs';
import MessageBanner from '../../components/messageBanner/MessageBanner';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const StripeSubscriptionFailed = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <MessageBanner
        title={t('StripeSubscriptionFailMainText')}
        subTitle={t('StripeSubscriptionFailSubText')}
        icon={errorSVG}
        btnTitle={t('GoBack')}
        onSelect={() => {
          if (window?.opener) {
            window.opener?.location?.reload();
            window.close();
          } else {
            navigate('/');
          }
        }}
        success={false}
      />
    </>
  );
};

export default StripeSubscriptionFailed;
