// iPhone model checks.
export function getiPhoneModel() {
  // Create a canvas element which can be used to retrieve information about the GPU.
  var canvas: any = document.createElement("canvas");
  if (canvas) {
    var context =
      canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    if (context) {
      var info = context.getExtension("WEBGL_debug_renderer_info");
      if (info) {
        var renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
      }
    }
  }

  if (
    (window.screen.height / window.screen.width == 812 / 375 ||
      window.screen.height / window.screen.width == 896 / 414 ||
      window.screen.height / window.screen.width == 844 / 390 ||
      window.screen.height / window.screen.width == 926 / 428) &&
    (window.devicePixelRatio == 3 || window.devicePixelRatio == 2)
  ) {
    return "iPhone X or Above with FaceId";
  } else if (
    (window.screen.height / window.screen.width == 1194 / 834 ||
      window.screen.height / window.screen.width == 1366 / 1024 ||
      window.screen.height / window.screen.width == 1024 / 768 ||
      window.screen.height / window.screen.width == 1133 / 744 ||
      window.screen.height / window.screen.width == 1180 / 820 ||
      window.screen.height / window.screen.width == 1080 / 810 ||
      window.screen.height / window.screen.width == 1112 / 834) &&
    (window.devicePixelRatio == 2 || window.devicePixelRatio == 1)
  ) {
    switch (renderer) {
      default:
        return "iPad with TouchId";
      case "Apple A15 GPU":
        return "iPad Pro 3rd Gen or Above with FaceId";
      case "Apple A14 GPU":
        return "iPad Pro 3rd Gen or Above with FaceId";
      case "Apple A13 GPU":
        return "iPad Pro 3rd Gen or Above with FaceId";
      case "Apple A12 GPU":
        return "iPad Pro 3rd Gen or Above with FaceId";
      case "Apple A11 GPU":
        return "iPad Pro 3rd Gen or Above with FaceId";
      case "Apple A8 GPU":
        return "iPad without TouchId";
      case "Apple A7 GPU":
        return "iPad without TouchId";
    }
  } else if (
    (window.screen.height / window.screen.width == 736 / 414 ||
      window.screen.height / window.screen.width == 667 / 375 ||
      window.screen.height / window.screen.width == 847 / 476) &&
    (window.devicePixelRatio == 3 || window.devicePixelRatio == 2)
  ) {
    return "iPhone 8+ or Below with TouchId";
  } else if (
    window.screen.height / window.screen.width == 1.775 &&
    window.devicePixelRatio == 2
  ) {
    switch (renderer) {
      default:
        return "iPhone 8+ or Below with TouchId";
      case "PowerVR SGX 543":
        return "iPhone 5 or Below without TouchId";
    }
  } else if (
    window.screen.height / window.screen.width == 1.5 &&
    (window.devicePixelRatio == 2 || window.devicePixelRatio == 1)
  ) {
    return "iPhone 5 or Below without TouchId";
  } else {
    return "Not an iPhone";
  }
}
