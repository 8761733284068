import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import store from '../../../store/store';
import SettingsConvenienceFeeOptions from './SettingsConvenienceFeeOptions';
import { HOME_ROUTE } from '../../../lib/constants/route';

const SettingsConvenienceFee = () => {
  const location = useLocation();
  const activeLink = location.pathname;
  const { t } = useTranslation();
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;

  return (
    <SubPageInnerContentHolder
      title={t('CompanyConvenienceFee')}
      subTitle={t('ManageCompanyConvenienceFeeDetails')}
      backBtnLink={isWhiteLabel ? undefined : HOME_ROUTE}
    >
      <Routes>
        <Route path='/' element={<SettingsConvenienceFeeOptions />} />
      </Routes>
    </SubPageInnerContentHolder>
  );
};

export default SettingsConvenienceFee;
