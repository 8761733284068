//Example: convert this string 'color:red-size:small' to this array of objects => [{"id": 0,"name": "color","value": "red"},{"id": 1,"name": "size","value": "small"}]
const convertStringAttritubtesToObject = (strAttributes: string) => {
  const singleAttribute = strAttributes?.split('~~');
  if (singleAttribute.length > 0) {
    const attributesObject = singleAttribute.map(
      (el: string, index: number) => {
        const keyValueArr = el.split(':');
        const obj = {
          id: index,
          name: keyValueArr[0],
          value: keyValueArr[1],
        };
        return obj;
      }
    );
    return attributesObject;
  }
};

export default convertStringAttritubtesToObject;
