import React, { useEffect, useState } from 'react';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAiOnboardingNextStep,
  setAiOnboardingSelectedIntegrate,
  setAiOnboardingStepCompleted,
  setIsAiOnboardingIntegrationConnected,
} from '../../store/reducers/userSlice';
import { useTranslation } from 'react-i18next';
import '../../styles/pages/ai-onboarding/aiOnboardingIntegrations.scss';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { cartSVG, shopifySVG } from '../../lib/allCustomSVGs';
import post_integration_api_call from '../../lib/api/integration/post_integration_api_call';
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../ui/noData/NoData';
import { CodeSection } from 'react-code-section-lib';
import {
  regularScriptCode,
  shopifyPixelCode,
} from '../../lib/constants/integration_script_code';
import { RootState } from '../../store/store';
import { asyncHandler } from '../../lib/api/async-handler';
import { RequestInfo } from '../../interfaces/RequestInfo';
import loadingGif from '../../assets/gif/loading.gif';
import { CHECK_CONNECTION_API } from '../../lib/constants/central-api';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';

type Props = {
  apiKey: string;
  setAPIKey: (apiKey: string) => void;
};

const AIOnboardingIntegrations = ({ apiKey, setAPIKey }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selected_integrate = useSelector(
    (state: RootState) =>
      state.user?.ai_onboarding?.selected_integrate || 'standard'
  );
  const is_integration_connected = useSelector(
    (state: RootState) =>
      state.user?.ai_onboarding?.is_integration_connected || false
  );

  const webshop_url = useSelector(
    (state: RootState) => state.user.company?.webshop_url || ''
  );

  const isAppThemeDark = true;
  const [loadingConnection, setLoadingConnection] = useState<
    'pending' | 'initial' | 'null'
  >('initial');

  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  useEffect(() => {
    if (apiKey) {
      setLoading('success');
      return;
    }
    getAvailableTrackingData();
  }, []);

  const getAvailableTrackingData = async () => {
    setLoading('pending');
    try {
      const sendingData = {
        name: 'checkout-tracking-class-names',
        type: 'tracker',
        configuration: { key: 'value' },
      };
      const res = await post_integration_api_call(
        sendingData,
        undefined,
        {
          is_autofill_enabled: 'false',
        },
        true
      );
      if (res && res.api_key) {
        setAPIKey(res.api_key);
        setLoading('success');
      } else {
        setLoading('null');
      }
    } catch (error) {
      console.error('Error fetching tracking data:', error);
      setLoading('error');
    }
  };

  const checkConnection = async () => {
    if (!apiKey || !webshop_url || webshop_url === '') return;

    setLoadingConnection('pending');
    try {
      const sendingData = {
        tracking_id: apiKey,
        site_url: webshop_url,
      };

      const requestInfo: RequestInfo = {
        url: CHECK_CONNECTION_API,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };

      const actionType = 'CHECK_CONNECTION_API';
      const customMsg = 'Connection successful!';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        true
      );

      const response = res.data;

      if (
        res.actionType === actionType &&
        response.success === true
        // response.data?.script_detected
      ) {
        setLoadingConnection('initial');
        dispatch(setIsAiOnboardingIntegrationConnected(true));
        setTimeout(() => {
          dispatch(
            setAiOnboardingStepCompleted({ stepNumber: 2, isCompleted: true })
          );
          dispatch(setAiOnboardingNextStep(3));
        }, 1000);
      } else {
        setLoadingConnection('null');
        dispatch(setIsAiOnboardingIntegrationConnected(false));
      }
    } catch (error) {
      console.error('Error fetching tracking data:', error);
      setLoadingConnection('null');
    }
  };

  const renderCode = () => {
    if (loading === 'pending') return <LoadingSpinner />;
    if (loading === 'error')
      return (
        <NoData
          title={t('ErrorFetchingData')}
          subTitle={t('SomethingWentWrong-msg')}
        />
      );
    if (loading === 'null') return null;
    if (loading === 'success') {
      if (selected_integrate === 'standard' && apiKey) {
        return (
          <CodeSection
            showLineNumbers
            theme={isAppThemeDark ? 'dark' : 'light'}
            lang={'html'}
          >
            {regularScriptCode(apiKey)}
          </CodeSection>
        );
      }
      if (selected_integrate === 'shopify' && apiKey) {
        return (
          <CodeSection
            showLineNumbers
            theme={isAppThemeDark ? 'dark' : 'light'}
            lang={'js'}
          >
            {shopifyPixelCode(apiKey)}
          </CodeSection>
        );
      }
    }
  };

  const renderCodeInstructions = () => {
    if (selected_integrate === 'standard') {
      return (
        <>
          <p>{t('CheckoutTrackingIntegrationInstructions1')}</p>
          <ul>
            <li>
              {t('CheckoutTrackingIntegrationInstructions2')}{' '}
              <strong>{`<head>`}</strong>
            </li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          <p>{t('CheckoutTrackingIntegrationInstructions1-Shopify')}</p>
          <ul>
            <li>{t('CheckoutTrackingIntegrationInstructions2-Shopify')}</li>
            <li>{t('CheckoutTrackingIntegrationInstructions3-Shopify')}</li>
            <li>{t('CheckoutTrackingIntegrationInstructions4-Shopify')}</li>
            <li>{t('CheckoutTrackingIntegrationInstructions5-Shopify')}</li>
            <li>{t('CheckoutTrackingIntegrationInstructions6-Shopify')}</li>
          </ul>
        </>
      );
    }
  };

  return (
    <div className='aiOnboardingIntegrations'>
      {/* title section */}
      <div className='aiOnboardingIntegrations__title'>
        <div className='aiOnboardingIntegrations__title--left'>
          <span>{t('Integrate')}</span>
        </div>
        <div className='aiOnboardingIntegrations__title--right'>
          <div
            data-is-connected={is_integration_connected.toString()}
            className='aiOnboardingIntegrations__title--right-connection'
          >
            <p>
              {is_integration_connected ? t('Connected') : t('Disconnected')}
            </p>
            <span></span>
          </div>
        </div>
      </div>

      {/* cards section */}
      <div className='aiOnboardingIntegrations__cards'>
        <div
          onClick={() => dispatch(setAiOnboardingSelectedIntegrate('standard'))}
          data-is-card-selected={
            selected_integrate === 'standard' ? 'true' : 'false'
          }
          className='aiOnboardingIntegrations__cards--card'
        >
          <div className='aiOnboardingIntegrations__cards--card-icon'>
            <CustomSVGs svg={cartSVG} />
          </div>
          <div className='aiOnboardingIntegrations__cards--card-text'>
            <p>{t('StandardScriptIntegration')}</p>
            <span>{t('StandardScriptIntegration-info')}</span>
          </div>
        </div>
        <div
          onClick={() => dispatch(setAiOnboardingSelectedIntegrate('shopify'))}
          data-is-card-selected={
            selected_integrate === 'shopify' ? 'true' : 'false'
          }
          className='aiOnboardingIntegrations__cards--card'
        >
          <div className='aiOnboardingIntegrations__cards--card-icon'>
            <CustomSVGs svg={shopifySVG} />
          </div>
          <div className='aiOnboardingIntegrations__cards--card-text'>
            <p>{t('ShopifyWebPixelIntegration')}</p>
            <span>{t('ShopifyWebPixelIntegration-info')}</span>
          </div>
        </div>
      </div>

      <div className='aiOnboardingIntegrations__code'>
        <div className='aiOnboardingIntegrations__code--titles'>
          {renderCodeInstructions()}
        </div>
        <div className='aiOnboardingIntegrations__code--code'>
          {renderCode()}
        </div>
      </div>

      {loadingConnection === 'pending' ? (
        <div className='aiOnboardingIntegrations__loader'>
          <img src={loadingGif} alt='loading' />
        </div>
      ) : (
        <div className='aiOnboardingIntegrations__button'>
          <MainSquareButton
            onClick={(e) => {
              e.preventDefault();
              checkConnection();
            }}
            type={'button'}
            value={
              loadingConnection === 'initial'
                ? t('CheckConnection')
                : t('TryConnectingAgain')
            }
            normal={true}
          />
        </div>
      )}

      <div
        onClick={() => {
          dispatch(
            setAiOnboardingStepCompleted({ stepNumber: 2, isCompleted: true })
          );
          dispatch(setAiOnboardingNextStep(3));
        }}
        className='aiOnboardingIntegrations__link'
      >
        {t('ContinueWithoutConnecting')}
      </div>
    </div>
  );
};

export default AIOnboardingIntegrations;
