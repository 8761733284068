import { useNavigate } from 'react-router-dom';
import '../../styles/components/dropdowns/commonDropdown.scss';
import { useTranslation } from 'react-i18next';

const CommonDropdown = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className='commonDropdown'>
      <span onClick={() => navigate('products/add')}>{t('AddProduct')}</span>
      {/* <span onClick={() => navigate('/orders')}>{t('AllOrders')}</span> */}
    </div>
  );
};

export default CommonDropdown;
