import { useNavigate } from 'react-router-dom';
import { warningWithCircleSVG } from '../../../../lib/allCustomSVGs';
import '../../../../styles/components/subPageList/subPageListDelete.scss';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { INTEGRATION_ENDPOINT } from '../../../../lib/constants/urls';
import { useDispatch } from 'react-redux';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../../lib/api/async-handler';
import store from '../../../../store/store';
import { setAutofillInregrations } from '../../../../store/reducers/userSlice';
import { useTranslation } from 'react-i18next';

const ConnectionsDelete = () => {
  const navigate = useNavigate();
  const { integrationId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const autofillInregrations: any = useSelector(
    (state: RootState) => state.user.autofillInregrations
  );

  const selectedItem = autofillInregrations.find((item: any) => {
    return item.id.toString() === integrationId;
  });

  const updatedList = autofillInregrations.filter((item: any) => {
    return item.id.toString() !== integrationId;
  });

  const deleteHandler = async () => {
    /*  */
    const accessToken = store.getState().auth.authorization.accessToken;
    const isWhiteLabel = store.getState().UI?.isWhiteLabel;
    let sellerToken: string;
    if (isWhiteLabel) {
      sellerToken = accessToken?.currentWhiteLabelSellerToken;
    } else {
      sellerToken = accessToken?.sellerToken;
    }

    const requestInfo: RequestInfo = {
      url: `${INTEGRATION_ENDPOINT}?integration_id=${integrationId}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: accessToken.type + ' ' + sellerToken,
      },
    };

    const actionType = 'AUTOFILL_INTEGRATION_DELETE';
    const customMsg = `${selectedItem.website_name} ${t(
      'IntegrationDeleteSuccess-msg'
    )}`;

    const res: any = await asyncHandler(requestInfo, actionType, customMsg);

    const response = res.data;

    if (res.actionType === actionType && response.success === true) {
      dispatch(setAutofillInregrations(updatedList));
    }
  };

  return (
    <div className='subPageListDelete'>
      <CustomSVGs svg={warningWithCircleSVG} />
      <h1>{t('AskToDelete-msg')}</h1>
      <h1>{`${selectedItem.website_name} ${t('Integration')}?`}</h1>
      <div className='subPageListDelete__buttons'>
        <MainSquareButton
          onClick={(e) => {
            e.preventDefault();
            deleteHandler();
            navigate('/integrations/autofill/connections');
          }}
          type={'button'}
          value={t('Yes')}
          normal={true}
          buttonType={'danger'}
        />
        <MainSquareButton
          onClick={(e) => {
            e.preventDefault();
            navigate('/integrations/autofill/connections');
          }}
          type={'button'}
          value={t('No')}
          normal={true}
          buttonType={'light'}
        />
      </div>
    </div>
  );
};

export default ConnectionsDelete;
