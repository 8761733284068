//converting Attributes Array To Key Values pair eg: {color:"blue", size:"small"}
const convertAttributesArrayToKeyValues = (variantAttributes: any) => {
  if (variantAttributes?.length > 0) {
    let sendingAttributesObj: any = {};
    variantAttributes.forEach((el: any, i: number) => {
      /* console.log(el); */
      sendingAttributesObj[el.name] = el.value;
    });
    return JSON.stringify(sendingAttributesObj);
  }
};

export default convertAttributesArrayToKeyValues;
