import makeFirstLetterCapital from './makeFirstLetterCapital';

const generateErrorMessage = (responseObj: any) => {
  let errorMessage;
  if (responseObj.message && typeof responseObj.message === 'string') {
    return (errorMessage = makeFirstLetterCapital(responseObj.message));
  }
  if (typeof responseObj.errors === 'object') {
    if (typeof responseObj.errors[0] === 'string') {
      return (errorMessage = makeFirstLetterCapital(
        responseObj.errors.toString().replaceAll('"', '')
      ));
    }
    if (typeof Object.values(responseObj.errors) === 'object') {
      return (errorMessage = makeFirstLetterCapital(
        Object.values(responseObj.errors).toString().replaceAll('"', '')
      ));
    }
  } else {
    return (errorMessage = 'Something went wrong');
  }

  return errorMessage;
};

export default generateErrorMessage;
