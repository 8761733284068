import '../../styles/pages/orders/ordersPage.scss';
import { useEffect, useState } from 'react';

//api call imports
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import store from '../../store/store';
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../ui/noData/NoData';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import SubPageInnerContentHolder from '../../components/subPageContentHolder/SubPageInnerContentHolder';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SHOP_API } from '../../lib/constants/central-api';
import CustomDataGridMain from '../../ui/customDataGrid.tsx/CustomDataGridMain';
import PaginationMain from '../../components/pagination/PaginationMain';
import FilterTheme2 from '../../components/filter/FilterTheme2';

interface Props {
  currentPageNumber: number;
  setCurrentPageNumber: (input: any) => void;
}

const AllOrders = ({ currentPageNumber, setCurrentPageNumber }: Props) => {
  // const [orders, setOrders] = useState<any>();
  /* const shopId = useSelector((state: RootState) => state.user.company.shop_id); */
  const [loading, setLoading] = useState('pending');
  const slug = store.getState().user?.company?.shop_slug;
  const navigate = useNavigate();
  const [t] = useTranslation();

  /*------------------------- PAGINATION STUFF START ------------------------- */
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  // const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalOrderRecords, setTotalOrderRecords] = useState(0);
  const [orderData, setOrderData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(totalOrderRecords / itemsPerPage));
  }, [itemOffset, itemsPerPage, orderData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    setCurrentPageNumber(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % totalOrderRecords;
    setItemOffset(newOffset);
  };
  const handleRecordsPerPageChange = (value: number) => {
    setItemsPerPage(value);
    setCurrentPageNumber(1); // Reset to the first page
    setItemOffset(0); // Reset the item offset
  };
  /*------------------------- PAGINATION STUFF END ------------------------- */

  /* ----------------------------FILTER SECTION START------------------------------ */
  const categoryFilter = [
    { name: 'all', value: 'none' },
    { name: 'completed', value: 'none' },
    { name: 'paid', value: 'none' },
    { name: 'shipped', value: 'none' },
    { name: 'processing', value: 'none' },
    { name: 'pending', value: 'none' },
    { name: 'cancelled', value: 'none' },
    { name: 'failed', value: 'none' },
    { name: 'expired', value: 'none' },
  ];

  const sortingValues = [
    { name: t('DefaultSort'), value: 'none' },
    { name: t('SortByNameASC'), value: 'name' },
    { name: t('SortByNameDESC'), value: '-name' },
    { name: t('SortByPriceASC'), value: 'price' },
    { name: t('SortByPriceDESC'), value: '-price' },
    { name: t('SortByDateASC'), value: 'date' },
    { name: t('SortByDateDESC'), value: '-date' },
  ];

  const [selectedFilterCategory, setSelectedFilterCategory] = useState('all');

  const [selectedSortingValue, setSelectedSortingValue] = useState('none');

  const [searchFilter, setSearchFilter] = useState('');
  const [debouncedSearchFilter, setDebouncedSearchFilter] = useState('');

  const onCategoryFilterChange = (filterName: string) => {
    setSelectedFilterCategory(filterName);
  };
  const onSortingValueChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSortingValue(e.target.value);
  };

  const onSearchFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(e.target.value);
  };
  /* ----------------------------FILTER SECTION END------------------------------ */

  // Debounce function
  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setDebouncedSearchFilter(searchFilter);
    }, 1000); // Adjust debounce delay as needed (e.g., 500ms)

    return () => clearTimeout(delayTimer);
  }, [searchFilter]);

  /* ----------------------------API CALL START---------------------------- */
  useEffect(() => {
    const accessToken = store.getState().auth.authorization.accessToken;
    const isWhiteLabel = store.getState().UI?.isWhiteLabel;
    let sellerToken: string;
    if (isWhiteLabel) {
      sellerToken = accessToken?.currentWhiteLabelSellerToken;
    } else {
      sellerToken = accessToken?.sellerToken;
    }

    //Example for filter and sorting in URL
    //https://centralapi-dev.kodeia.com/api/v1/shops/sunil/orders?per_page=8&sort=-name&page=1&filter[status]=pending&filter[id]=123

    const getURL = () => {
      let mainUrl =
        SHOP_API +
        `/${slug}/orders?per_page=${itemsPerPage}&page=${currentPageNumber}`;

      let filterString = '';

      if (selectedFilterCategory !== 'all') {
        filterString =
          filterString + `&filter[status]=${selectedFilterCategory}`;
      }

      if (debouncedSearchFilter !== '') {
        filterString = filterString + `&filter[id]=${debouncedSearchFilter}`;
      }

      if (selectedSortingValue !== 'none') {
        mainUrl = mainUrl + `&sort=${selectedSortingValue}`;
      }

      return mainUrl + filterString;
    };

    // Make the API call using the debounced search filter
    const requestInfo: RequestInfo = {
      url: getURL(),
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: accessToken.type + ' ' + sellerToken,
      },
    };

    const actionType = 'GET_ALL_ORDERS';
    const customMsg = 'Orders Loaded Successfully.';

    const getOrders = async () => {
      setLoading('pending');
      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;

      if (!response.success) {
        setLoading('error');
      }
      if (response?.data?.data.length === 0) {
        setLoading('null');
      } else {
        if (res.actionType === actionType && response.success === true) {
          const ordersResult = response?.data;
          setTotalOrderRecords(ordersResult?.meta?.total);

          const ordersArray = ordersResult?.data;

          if (ordersArray?.length > 0) {
            const ordersArr: any = [];
            for (let i = 0; i < ordersArray?.length; i++) {
              ordersArr.push({
                status: ordersArray[i]?.status,
                id: ordersArray[i]?.id,
                shopper_id: ordersArray[i]?.shopper?.id,
                created_at: ordersArray[i]?.created_at,
                cart_total: ordersArray[i]?.grand_total?.formatted,
                method: ordersArray[i]?.payment_method,
                shopper_email: ordersArray[i]?.email,
                shopper_name: `${ordersArray[i]?.first_name} ${ordersArray[i]?.last_name}`,
                gateway: '', //TODO:  missing in API
              });
            }
            setLoading('success');
            setOrderData(ordersArr);
          }
        }
      }
    };
    if (slug && slug !== '') {
      getOrders();
    }
  }, [
    currentPageNumber,
    slug,
    itemsPerPage,
    selectedFilterCategory,
    selectedSortingValue,
    debouncedSearchFilter,
  ]);
  /* ----------------------------API CALL END---------------------------- */

  const dataGridColumns = [
    { field: 'id', headerName: t('OrderID'), width: 13 },
    { field: 'shopper_id', headerName: t('ShopperID'), width: 13 },
    { field: 'shopper_name', headerName: t('ShopperName') },
    { field: 'shopper_email', headerName: t('ShopperEmail'), width: 14 },
    { field: 'method', headerName: t('PaymentMethod'), width: 12 },
    {
      field: 'status',
      headerName: t('Status'),
      width: 6,
      highlightStyle: 'background',
      highlightingValue: [
        { value: 'pending', color: '#ffc107' },
        { value: 'paid', color: '#00abff' },
        { value: 'processing', color: '#ff6700' },
        { value: 'shipped', color: '#5680e9' },
        { value: 'completed', color: '#28a745' },
        { value: 'cancelled', color: '#ca6e77' },
        { value: 'failed', color: '#dc3545' },
        { value: 'expired', color: '#343a40' },
      ],
    },
    {
      field: 'created_at',
      headerName: t('OrderPlaced'),
      dateField: true,
      dataFormat: 'timeSince',
      width: 10,
    },
  ];

  return (
    <SubPageContentHolder>
      <SubPageInnerContentHolder
        title={t('AllOrders')}
        subTitle={t('ManageOrders')}
        /* mainBtnIcon={plusSignSVG}
        mainBtnTitle='Create Order' */
        mainBtnLink='/orders'
      >
        <div className='ordersPage'>
          <div className='ordersPage__top'>
            <div className='ordersPage__top--left'></div>
            <div className='ordersPage__top--right'></div>
          </div>
          <div className='ordersPage__bottom'>
            {/* dt */}
            <div className='ordersPage__bottom--dataGridContainer'>
              <FilterTheme2
                categoryFilter={categoryFilter}
                onCategoryFilterChange={onCategoryFilterChange}
                selectedFilterCategory={selectedFilterCategory}
                onSearchFilterChange={onSearchFilterChange}
                sortingValues={sortingValues}
                selectedSortingValue={selectedSortingValue}
                onSortingValueChange={onSortingValueChange}
                searchFilterInputPlaceholder='Search by Order ID'
              />

              {loading === 'success' && (
                <>
                  <CustomDataGridMain
                    allRows={orderData}
                    columns={dataGridColumns}
                    onViewHandler={(params) => {
                      navigate(`/orders/${params?.id}`);
                    }}
                    titleField='id'
                    dateField='created_at'
                    excludingField={[
                      'method',
                      'gateway',
                      'shopper_id',
                      'shopper_name',
                      'shopper_email',
                    ]}
                    borderType={'none'}
                  />

                  <PaginationMain
                    totalRecords={totalOrderRecords}
                    itemsPerPage={itemsPerPage}
                    onRecordsPerPageChange={handleRecordsPerPageChange}
                    breakLabel='...'
                    nextLabel={t(`${'Next'} >`)}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    initialPage={currentPageNumber - 1}
                    pageCount={pageCount}
                    previousLabel={t(`< ${'Previous'}`)}
                    renderOnZeroPageCount={undefined}
                    marginPagesDisplayed={2}
                    containerClassName='pagination'
                    pageLinkClassName='pagination__page-num'
                    previousLinkClassName='pagination__page-num'
                    nextLinkClassName='pagination__page-num'
                    disabledLinkClassName='pagination__page-num-disabled'
                    activeLinkClassName='pagination__active'
                  />
                </>
              )}
            </div>
            {/* dt */}

            {loading === 'null' && (
              <NoData
                title={t('NoOrdersFound-msg')}
                // subTitle='Something went wrong.'
              />
            )}
            {loading === 'error' && (
              <NoData
                title={t('FetchingDataError-msg')}
                subTitle={t('SomethingWentWrong-msg')}
              />
            )}
            {loading === 'pending' && <LoadingSpinner />}
          </div>
        </div>
      </SubPageInnerContentHolder>
    </SubPageContentHolder>
  );
};

export default AllOrders;
