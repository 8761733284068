import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import { warningWithCircleSVG } from '../../../../lib/allCustomSVGs';
import '../../../../styles/generic/commonModal.scss';

interface Props {
  handler: () => void;
  closeModal: () => void;
  mainTitle: string;
  subTitle1: string;
}

const CssClassesWarningMsg = ({
  handler,
  closeModal,
  mainTitle,
  subTitle1,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className='commonModal'>
      <div className='commonModal__title'>
        <h1>{mainTitle}</h1>
      </div>
      <div className='commonModal__content'>
        <CustomSVGs svg={warningWithCircleSVG} />
        <h1>{subTitle1}</h1>
        <div className='commonModal__content--buttons'>
          <MainSquareButton
            onClick={(e) => {
              e.preventDefault();
              handler();
              closeModal();
            }}
            type={'button'}
            value={t('Yes')}
            normal={true}
            buttonType={'warning'}
          />
          <MainSquareButton
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            type={'button'}
            value={t('No')}
            normal={true}
            buttonType={'light'}
          />
        </div>
      </div>
    </div>
  );
};

export default CssClassesWarningMsg;
