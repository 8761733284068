import { warningWithCircleSVG } from '../../lib/allCustomSVGs';
import { useLocation, useNavigate } from 'react-router-dom';
import MessageBanner from '../../components/messageBanner/MessageBanner';
import { useTranslation } from 'react-i18next';
import { UNSUBSCRIBE_ENDPOINT } from '../../lib/constants/urls';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import { useState } from 'react';
import { HOME_ROUTE } from '../../lib/constants/route';

const Unsubscribe = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shopId = queryParams.get('shopid');
  const email = queryParams.get('email');

  const [isLoading, setIsLoading] = useState(false);
  /* ----------------------------API CALL START---------------------------- */

  const requestInfo: RequestInfo = {
    //   url: `https://v3-shopper-api-dev.adup.io/api/updatenewsletter?shopid=${shopId}&email=${email}`,
    url: `${UNSUBSCRIBE_ENDPOINT}?shopid=${shopId}&email=${email} `,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };

  const actionType = 'GET_UNSUBSCRIBE';
  const customMsg = 'Unsubscribed Successfully.';

  const onClick = async () => {
    setIsLoading(true);
    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res?.data;

    if (res?.actionType === actionType && response?.success === true) {
      //do something with the response
      console.log(response);
      navigate(HOME_ROUTE);
    } else {
      setIsLoading(false);
      console.log('getting an error, please try again!');
    }
  };

  /* ----------------------------API CALL END---------------------------- */

  return (
    <>
      <MessageBanner
        title={'Are you sure you want to unsubscribe?'}
        // subTitle={t('EmailVerifySuccessSubText')}
        icon={warningWithCircleSVG}
        btnTitle={t('Yes')}
        otherBtnTitle={t('No')}
        onSelectOtherBtn={() => {
          navigate(HOME_ROUTE);
        }}
        onSelect={onClick}
        success={false}
        isLoading={isLoading}
      />
    </>
  );
};

export default Unsubscribe;
