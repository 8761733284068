export interface VariantAttributesInterface {
  id: number;
  name: string;
  value: string[];
}

export const productInitialState = {
  productName: '',
  productDescription: '',
  productSKU: '',
  productPrice: '',
  productStock: '',
};

export interface productVariantsInitialStateInterface {
  productVariantName: string;
  productVariantDescription: string;
  productVariantSKU: string;
  productVariantPrice: string;
  productVariantStock: string;
}
export const productVariantsInitialState = {
  productVariantName: '',
  productVariantDescription: '',
  productVariantSKU: '',
  productVariantPrice: '',
  productVariantStock: '',
};
