import ToggleSwitch from '../../../../ui/buttons/ToggleSwitch';
import { useTranslation } from 'react-i18next';
import {
  initialInputData,
  transformInputData,
} from '../../../../lib/constants/trackingClassesData';
import post_integration_api_call from '../../../../lib/api/integration/post_integration_api_call';

interface Props {
  setIsAutofillEnabled: (value: boolean) => void;
  isAutofillEnabled: boolean;
  data: any;
  setScriptKey: (key: string) => void;
}

// Helper function to get initial values without mutating the second input object
function getInitialValues(
  configObjFromAPI: { [key: string]: any }, // The first input object with initial values
  allEmptyConfigObject: { [key: string]: any } // The second input object with empty values
): { [key: string]: any } {
  // The return type is an object with string keys and any values
  // Create a new object by spreading allEmptyConfigObject
  const newConfigObject = { ...allEmptyConfigObject };

  // Iterate over each key in the first object (configObjFromAPI)
  for (const key in configObjFromAPI) {
    // Check if the value in the first object is not null
    // and if the second object (newConfigObject) has this key
    if (configObjFromAPI[key] !== null && newConfigObject.hasOwnProperty(key)) {
      // If both conditions are met, set the value of the new object to the value from the first object
      newConfigObject[key] = configObjFromAPI[key];
    }
  }
  // Return the new object
  return newConfigObject;
}

const Autofill = ({
  setIsAutofillEnabled,
  isAutofillEnabled,
  data,
  setScriptKey,
}: Props) => {
  const { t } = useTranslation();

  const trackingInputValues =
    data?.length > 0
      ? getInitialValues(
          data[data?.length - 1]?.configuration,
          transformInputData(initialInputData)
        )
      : transformInputData(initialInputData);

  async function onConfirmHandler(isEnabled: boolean) {
    const isEverythingNull = !Object.values(trackingInputValues).some(
      (value) => value !== ''
    );

    const autofillEnabledObj: { is_autofill_enabled: 'true' | 'false' } = {
      is_autofill_enabled: isEnabled ? 'true' : 'false',
    };

    try {
      const sendingData: any = {
        name: 'checkout-tracking-class-names',
        type: 'tracker',
        configuration: isEverythingNull
          ? { key: 'value' }
          : trackingInputValues,
      };

      if (data?.length > 0) {
        sendingData['_method'] = 'put';
      }

      const res = await post_integration_api_call(
        sendingData,
        data?.length > 0 ? data[data?.length - 1]?.slug : undefined,
        autofillEnabledObj
      );
      if (res?.api_key) {
        setScriptKey(res?.api_key);
      }
    } catch (exception) {
      console.log('Error during authentication request');
      // submitLogToBugsnag('error', `${exception}`);
    }
  }

  return (
    <div className='normalForm'>
      <div className='normalForm__top'>
        <h2>{t('AutofillSectionTitle')}</h2>
        <hr />
        <div className='shopPage__container1-alignLeft'>
          <ToggleSwitch
            isOn={isAutofillEnabled}
            title={t('ParticipateInAdUpNetwork')}
            onClick={() => {
              setIsAutofillEnabled(!isAutofillEnabled);
              onConfirmHandler(!isAutofillEnabled);
            }}
            classes='margin-top-2rem'
            id='notification-settings-is-custom-message'
          />
        </div>
        <h3>{t('AutofillEnableDescription')}</h3>
      </div>
    </div>
  );
};

export default Autofill;
