import { motion } from 'framer-motion';
import '../../styles/pages/ai-onboarding/aiOnboarding.scss';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import adUpLogoDark from '../../assets/adup_merchant_logo_dark.png';
import adUpLogoLight from '../../assets/adup_merchant_logo_light.png';
import AIOnboardingMain from './AIOnboardingMain';

const AIOnboarding = () => {
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);

  return (
    <motion.div {...pageSlideOutForward} className='aiOnboarding'>
      <div className='aiOnboarding__left'>
        <div className='aiOnboarding__left--content'>
          <div className='aiOnboarding__left--image'>
            {merchantTheme?.merchant_logo_light &&
            merchantTheme?.merchant_logo_light !== '' ? (
              <img
                src={`${staticURL}/medium/${
                  isDarkMode
                    ? merchantTheme?.merchant_logo_light
                    : merchantTheme?.merchant_logo_dark
                }`}
                alt={'logo'}
              />
            ) : (
              <img
                src={isDarkMode ? adUpLogoLight : adUpLogoDark}
                alt={'logo'}
              />
            )}
          </div>
        </div>
      </div>

      <div className='aiOnboarding__right'>
        <div className='aiOnboarding__content'>
          <AIOnboardingMain />
        </div>
      </div>
    </motion.div>
  );
};

export default AIOnboarding;
