import { useEffect, useState } from 'react';
import '../../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../../ui/inputs/NormalInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import NormalSelectInput from '../../../../ui/inputs/NormalSelectInput';
import { useNavigate } from 'react-router-dom';
import ToggleSwitch from '../../../../ui/buttons/ToggleSwitch';
import { updateIntercom } from '../../../../store/reducers/userSlice';
import { useTranslation } from 'react-i18next';
import createSelectOptionValueObject from '../../../../lib/helper-functions/createSelectOptionValueObject';
import makeFirstLetterCapital from '../../../../lib/helper-functions/makeFirstLetterCapital';
import '../../../../styles/generic/normalForm.scss';
import { getCustomInputFields } from '../../../../lib/helper-functions/getCustomInputFields';
import add_psp_api_call from '../../../../lib/api/psp/add-psp';

export interface PaymentMethodsInterface {
  value: string;
  label: string;
  isSelected: boolean | undefined;
}

const AddPSP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const paymentMethods = useSelector(
    (state: RootState) => state.user.onboarding.paymentMethods
  );
  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const availablePSPs: any = useSelector(
    (state: RootState) => state.user.onboarding.availablePSP
  );
  const alreadyAddedPSP: any = useSelector(
    (state: RootState) => state.user.company.alreadyAddedPSP
  );

  const pspOptions: any = createSelectOptionValueObject(availablePSPs);

  const [pspInputs, setPSPInputs] = useState<string[]>([]);
  const [pspInputValues, setPSPInputValues] = useState<any>();
  const [selectedPSP, setSelectedPSP] = useState(availablePSPs[0]);
  const [selectedPaymentMethods, setSelectedPaymentMethods] =
    useState<PaymentMethodsInterface[]>();
  const [visiblePaymentMethods, setVisiblePaymentMethods] =
    useState<JSX.Element>();
  const [paymentMethodClickChanges, setPaymentMethodClickChanges] = useState(0);
  const tempItems: JSX.Element[] = [];

  useEffect(() => {
    let finalArray: PaymentMethodsInterface[] = [];
    if (selectedPSP) {
      let data: any = paymentMethods?.[selectedPSP];
      console.log(data);
      for (const key in data) {
        let method = {
          value: key,
          label: data?.[key]?.label,
          isSelected: false,
        };
        finalArray.push(method);
      }
    }
    setSelectedPaymentMethods(finalArray);
  }, [selectedPSP]);

  const isToggleSwitchDisabled = (method: string) => {
    if (alreadyAddedPSP.length === 0) return false;
    //getting all the payment methods that are already added for the current PSP
    const allAddedPSPMethods = Object.values(
      alreadyAddedPSP[0]?.payment_methods
    );

    //getting the current payment methods that are already added for the current PSP
    const currentPSPPaymentMethods: any = [];
    allAddedPSPMethods?.forEach((el: any) => {
      if (el?.payment_service === selectedPSP?.toString()) {
        currentPSPPaymentMethods.push(el?.common_alias);
      }
    });

    //getting the current payment method that is already added for the current PSP
    const allUsedPaymentMethodsInShop =
      alreadyAddedPSP[0]?.payment_methods[method];

    //checking if the current payment method is already added for the current PSP
    if (
      allUsedPaymentMethodsInShop &&
      !currentPSPPaymentMethods?.includes(
        allUsedPaymentMethodsInShop?.common_alias
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (selectedPaymentMethods) {
    for (let method of selectedPaymentMethods) {
      let updatedValues = selectedPaymentMethods;
      let isSelected =
        selectedPaymentMethods[selectedPaymentMethods.indexOf(method)]
          .isSelected ?? false;
      const onClickHandler = (e: any) => {
        updatedValues[updatedValues.indexOf(method)] = {
          ...method,
          isSelected: e,
        };
        setSelectedPaymentMethods(updatedValues);
        isSelected = e;
        setPaymentMethodClickChanges(paymentMethodClickChanges + 1);
      };
      tempItems.push(
        <div className='normalForm__switch'>
          <ToggleSwitch
            message={t('PaymentMethodAlreadyAdded-msg')}
            disabled={isToggleSwitchDisabled(method.value)}
            key={selectedPaymentMethods.indexOf(method)}
            id={selectedPaymentMethods.indexOf(method)}
            isOn={isSelected}
            title={method?.label}
            onClick={onClickHandler}
            classes='margin-top-2rem'
          />
        </div>
      );
    }
  }

  useEffect(() => {
    if (tempItems) {
      setVisiblePaymentMethods(tempItems as any);
    }
  }, [paymentMethodClickChanges, selectedPaymentMethods]);

  /* ================================================================================= */
  useEffect(() => {
    const [currentPSP_inputs_array, currentPSP_inputs_object] =
      getCustomInputFields(selectedPSP, onboarding_object?.available_psp);

    setPSPInputs(currentPSP_inputs_array);
    setPSPInputValues(currentPSP_inputs_object);
  }, [selectedPSP]);

  const checkSavingPSPAlreadyAdded = () => {
    if (alreadyAddedPSP?.length > 0) {
      return alreadyAddedPSP[0]?.payment_secret[selectedPSP] ? true : false;
    }
  };

  const getAllSelectedPaymentMethods = () => {
    return selectedPaymentMethods
      ?.filter((el) => {
        return el.isSelected === true;
      })
      .map(({ value }) => value);
  };

  let isDisabled;
  let disabledButtonMessage = t('FillAllFields-msg');

  if (pspInputValues) {
    isDisabled = Object.values(pspInputValues)?.some((el: any) => el === '');
    disabledButtonMessage = t('FillAllFields-msg');
  }

  if (checkSavingPSPAlreadyAdded()) {
    isDisabled = true;
    disabledButtonMessage = t('PSPAlreadyAdded-msg');
  }

  /* if (getAllSelectedPaymentMethods()?.length === 0) {
    isDisabled = true;
    disabledButtonMessage = t('PSPAlreadyAdded-msg');
  } */

  /* ================================================================================= */
  async function onSubmitHandler(e: any) {
    e.preventDefault();

    const sendingPSPMethods: any = getAllSelectedPaymentMethods();
    const res: boolean = await add_psp_api_call(
      selectedPSP,
      pspInputValues,
      sendingPSPMethods
    );

    if (res) {
      dispatch(
        updateIntercom({
          psp: selectedPSP,
          pspdatafilled: 'true',
        })
      );
      navigate('/settings/payments/connection');
    }
  }

  const onInputChange = (e: any, value: string) => {
    setPSPInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  return (
    <form id='PSPAdd-form' onSubmit={onSubmitHandler} className='normalForm'>
      <div className='normalForm__top'>
        <div className='normalForm__container3'>
          <div className='normalForm__container3--item'>
            <div className='normalForm__container3--item-content'>
              <NormalSelectInput
                defaultValue={selectedPSP}
                setSelect={setSelectedPSP}
                selectOptionArr={pspOptions}
                placeholder={t('PSP-Type')}
                id={'selected-psp-add'}
              />
            </div>
          </div>
          {pspInputs.map((el: string, index: number) => {
            return (
              <div className='normalForm__container3--item' key={index}>
                <div className='normalForm__container3--item-content'>
                  <NormalInput
                    changeListeners={[(e) => onInputChange(e, el)]}
                    id={`psp-integration-${el}-add`}
                    value={pspInputValues ? pspInputValues[el] : ''}
                    placeholder={makeFirstLetterCapital(
                      el.replaceAll('_', ' ')
                    )}
                    required={true}
                    autoFocus={index === 0 ? true : false}
                    classes={`add-psp-${selectedPSP}-${el}-input`}
                    labelClasses={`add-psp-${selectedPSP}-${el}-label`}
                  />
                </div>
              </div>
            );
          })}
          <div className='normalForm__switches'>{visiblePaymentMethods}</div>
        </div>
      </div>

      <div className='normalForm__bottom'>
        <MainSquareButton
          value={t('Add')}
          message={disabledButtonMessage}
          /* icon={<CustomSVGs svg={plusSignSVG} />} */
          disabled={isDisabled}
          type='submit'
          form='PSPAdd-form'
        />
      </div>
    </form>
  );
};

export default AddPSP;
