import { useLocation } from 'react-router-dom';
import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { useEffect, useState } from 'react';
import '../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../ui/inputs/NormalInput';

/* ---------API Connectionn Imports------------- */
import { applicationWWWXRLEncodedBodyBuilder } from '../../../lib/utils/body-builder';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../lib/api/async-handler';
import store from '../../../store/store';
import ToggleSwitch from '../../../ui/buttons/ToggleSwitch';
import NoData from '../../../ui/noData/NoData';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { setUserCompanySettings } from '../../../store/reducers/userSlice';
import { SHOP_API } from '../../../lib/constants/central-api';
import { submitLogToBugsnag } from '../../../lib/api/log';
import { HOME_ROUTE } from '../../../lib/constants/route';

const SettingsGeneral = () => {
  const location = useLocation();
  const activeLink = location.pathname;
  const { t } = useTranslation();

  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const slug = store.getState().user?.company?.shop_slug;

  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const currencyArr = [
    { OptionValue: 'EUR', OptionName: 'Euro' },
    { OptionValue: 'USD', OptionName: 'US Dollar' },
  ];
  const [currency, setCurrency] = useState<string>('EUR');
  const [shopName, setShopName] = useState<string>('');
  const [shopSlug, setShopSlug] = useState<string>('');
  const [shopURL, setShopURL] = useState<string>('');
  const [imageSyncType, setImageSyncType] = useState<string>('');
  const [twoFaEnabled, setRwoFaEnabled] = useState<string>('');
  const [shopInitials, setShopInitials] = useState<string>('');
  const [shopStatus, setShopStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState('pending');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (slug && slug !== '') {
      const response = getGeneralSettings();
      response
        .then((result) => {
          setShopName(result?.shop_name === null ? '' : result?.shop_name);
          setShopSlug(result?.shop_slug === null ? '' : result?.shop_slug);
          setShopInitials(
            result?.shop_initials === null ? '' : result?.shop_initials
          );
          setCurrency(result?.currency);
          setShopURL(result?.webshop_url === null ? '' : result?.webshop_url);
          if (result?.status === false || result?.status === 'false') {
            setShopStatus(false);
          } else {
            setShopStatus(true);
          }
          setImageSyncType(result?.image_sync_type);
          setRwoFaEnabled(result?.two_fa_enabled);
          setLoading('success');
        })
        .catch((err) => {
          console.error(err);
          submitLogToBugsnag('error', `${err}`);
          setLoading('error');
        });
    }
  }, [slug]);

  /* ----------------------------API CALL START---------------------------- */
  async function getGeneralSettings() {
    try {
      const requestInfo: RequestInfo = {
        url: SHOP_API + `/${slug}/settings?filter[group]=general`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_GENERAL_SETTINGS';
      const customMsg = 'General Settings fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        let data: any = {};
        response?.data?.forEach((item: any) => {
          data[item.key] = item.value === null ? '' : item.value;
        });
        return data;
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const sendingData = {
        group: 'general',
        shop_name: shopName,
        shop_slug: shopSlug,
        shop_initials: shopInitials,
        currency: 'EUR', //! Currency is hardcoded for now, until the application supports multiple currencies
        status: shopStatus,
        image_sync_type: imageSyncType,
        two_fa_enabled: twoFaEnabled,
        webshop_url: shopURL,
      };

      const requestInfo: RequestInfo = {
        url: SHOP_API + `/${slug}/settings`,
        method: 'PUT',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'UPDATE_GENERAL_SETTINGS';
      const customMsg = t('SettingsUpdatedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      setIsSubmitting(false);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        store.dispatch(setUserCompanySettings(response?.result));
        return response.result;
      }
    } catch (exception) {
      console.log('Error during authentication request');
      setIsSubmitting(false);
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  const isDisabled = shopName === '' || shopInitials === '' ? true : false;

  return (
    <SubPageInnerContentHolder
      title={t('GeneralSettings')}
      subTitle={t('ManageGeneralSettings')}
      backBtnLink={isWhiteLabel ? undefined : HOME_ROUTE}
    >
      <>
        {loading === 'pending' && <LoadingSpinner />}
        {loading === 'error' && (
          <NoData
            title={t('ErrorFetchingData')}
            subTitle={t('SomethingWentWrong-msg')}
          />
        )}
        {loading === 'success' && (
          <form
            id='general-settings-form'
            onSubmit={onSubmitHandler}
            className='normalForm'
          >
            <div className='normalForm__top'>
              {/* <h1>Add the Following Information.</h1> */}

              {/* ------------------------------------------------------- */}
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <NormalInput
                    id='company-details-shopname'
                    value={shopName}
                    changeListeners={[(e) => setShopName(e.target.value)]}
                    focusListeners={[]}
                    placeholder={t('ShopName')}
                    classes='lg:w-[13rem]'
                    required={true}
                    /* pattern='^[A-Za-z0-9 ]{1,100}$' */
                    title={t('ShopName-validation')}
                  />
                </div>
                <div className='normalForm__container1--right'>
                  <NormalInput
                    id='company-details-shopname'
                    value={shopInitials}
                    changeListeners={[(e) => setShopInitials(e.target.value)]}
                    focusListeners={[]}
                    placeholder={t('ShopInitials')}
                    classes='lg:w-[13rem]'
                    required={true}
                    /* pattern='^[A-Za-z0-9 ]{1,100}$' */
                    title={t('ShopInitials-validation')}
                  />
                </div>
              </div>

              {/* ------------------------------------------------------- */}
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <NormalInput
                    id='company-details-shopURL'
                    value={shopURL}
                    changeListeners={[(e) => setShopURL(e.target.value)]}
                    focusListeners={[]}
                    placeholder={t('ShopURL')}
                    classes='lg:w-[13rem]'
                    // required={true}
                    innerPlaceholder={'https://example.com'}
                    /* pattern='^[A-Za-z0-9 ]{1,100}$' */
                  />
                </div>
                <div className='normalForm__container1--right'>
                  <NormalInput
                    id='company-details-shopname'
                    value={shopSlug}
                    changeListeners={[(e) => setShopSlug(e.target.value)]}
                    focusListeners={[]}
                    placeholder={t('ShopSlug')}
                    classes='lg:w-[13rem]'
                    required={true}
                    disabled={true}
                    infoBanner={t('ShopSlug-Banner')}
                  />
                </div>
              </div>

              {/* ------------------------------------------------------- */}

              {/* ------------------------------------------------------- */}
              {/* 
              //! Currency is hardcoded for now, until the application supports multiple currencies
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <NormalSelectInput
                    defaultValue={currency}
                    setSelect={setCurrency}
                    selectOptionArr={currencyArr}
                    placeholder={t('Currency')}
                    id={'company-details-currency'}
                  />
                </div>
                <div className='normalForm__container1--right'>
                </div>
              </div> */}

              {/* ------------------------------------------------------- */}
            </div>

            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <ToggleSwitch
                  isOn={shopStatus}
                  title={t('ShopStatus')}
                  onClick={setShopStatus}
                  classes='margin-top-2rem'
                />
              </div>
            </div>

            <div className='normalForm__bottom flex-start'>
              <MainSquareButton
                value={
                  isSubmitting ? `${t('Updating')}...` : t('UpdateSettings')
                }
                /* icon={<CustomSVGs svg={plusSignSVG} />} */
                disabled={isDisabled || isSubmitting}
                type='submit'
                form='general-settings-form'
              />
            </div>
          </form>
        )}
      </>
    </SubPageInnerContentHolder>
  );
};

export default SettingsGeneral;
