import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { addedCartSVG } from '../../../../../../lib/allCustomSVGs';
import ChartAdditionalStats from '../../../../../../components/charts/ChartAdditionalStats';

type Props = {
  apiResponse: any;
  setSelectedDevice: (selectedDevice: string) => void;
};

const DeviceConversionRateBarChart = ({
  apiResponse,
  setSelectedDevice,
}: Props) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any[]>([]);
  const [additionalStats, setAdditionalStats] = useState<any>({
    companyOverallCarts: 0,
    companyOverallCheckouts: 0,
  });

  const getConversionRateData = (res: any) => {
    if (res?.type === 'overallDevices' && res?.data) {
      let newChartData = [];
      let count = 0;
      let extraStats = { ...additionalStats };

      if (Object.keys(res?.data)?.length === 0) {
        return;
      }
      for (const key in res?.data) {
        if (key === 'company_overall_carts') {
          extraStats.companyOverallCarts = res?.data[key] || 0;
        } else if (key === 'company_overall_checkouts') {
          extraStats.companyOverallCheckouts = res?.data[key] || 0;
        } else {
          newChartData.push({
            key: key, // Change 'label' to 'key'
            value: res?.data[key]?.conversion_rate || 0,
          });
        }

        count += 1;
      }

      // Set chart data once
      setAdditionalStats(extraStats);
      setChartData(newChartData);
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  const chartSetting = {
    yAxis: [
      {
        label: 'Conversion Rate (%)',
      },
    ],
  };

  const valueFormatter = (value: number | null) => `${value}%`;

  const data = {
    data: chartData,
    valueFormatter,
  };

  const onItemClickHandler = (e: any, d: any) => {
    if (d?.dataIndex !== undefined) {
      setSelectedDevice(data?.data[d.dataIndex]?.key);
    }
  };

  const AdditionalStatsData = [
    {
      label: t('CompanyOverallCarts'),
      value: additionalStats?.companyOverallCarts,
      fillColor: '#84b6d8',
      svg: addedCartSVG,
    },
  ];

  return (
    <>
      <div className='chart__bottom--content-chart'>
        <div
          style={{ width: '100%' }}
          className='chart__bottom--content-chart-pie'
        >
          <BarChart
            dataset={chartData}
            xAxis={[{ scaleType: 'band', dataKey: 'key' }]}
            series={[{ dataKey: 'value', valueFormatter }]}
            colors={['#8884d8']}
            onItemClick={onItemClickHandler}
            {...chartSetting}
            sx={(theme) => ({
              [`.${axisClasses.root}`]: {
                [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                  stroke: '#8b909a',
                  strokeWidth: 3,
                },
                [`.${axisClasses.tickLabel}`]: {
                  fill: '#8b909a',
                },
              },
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                fill: '#8b909a',
                transform: 'translate(-10px, 0)',
              },
              border: '1px solid #8b909a25',
              backgroundImage:
                'linear-gradient(#8b909a25 1px, transparent 1px), linear-gradient(90deg, #8b909a25 1px, transparent 1px)',
              backgroundSize: '50px 50px',
              backgroundPosition: '0px 0px, 0px 0px',
              ...theme.applyStyles('dark', {
                borderColor: '#8b909a25',
                backgroundImage:
                  'linear-gradient(#8b909a25 1px, transparent 1px), linear-gradient(90deg, #8b909a25 1px, transparent 1px)',
              }),
            })}
          />
        </div>
      </div>
      <ChartAdditionalStats stats={AdditionalStatsData} />
    </>
  );
};

export default DeviceConversionRateBarChart;
