import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import get_graph_data_api_call from '../../../lib/api/stats/get_graph_data_api_call';
import OverallCTRBarChart from './OverallCTRBarChart';
import '../../../styles/components/charts/pieChartComponent.scss';
import ChartWithConfig from '../ChartWithConfig';
import IndividualCTRBarChart from './IndividualCTRBarChart';

const MainCTRChart = () => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    '?graph_type=ctr_graph&group_by=overall'
  );
  const [selectedElement, setSelectedElement] = useState<string>('');
  const [response, setResponse] = useState<any>('initial');
  const [isBackButtonVisible, setIsBackButtonVisible] =
    useState<boolean>(false);

  const [comment, setComment] = useState<string>(t('OverallClickThroughRate'));

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        if (selectedElement !== '') {
          setResponse({ type: 'individual', data: res });
        } else {
          setResponse({ type: 'overall', data: res });
        }
      } else {
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    setResponse('initial');
    if (selectedElement === '') {
      setParams(`?graph_type=ctr_graph&group_by=overall`);
      setComment(t('OverallClickThroughRate'));
    } else if (selectedElement !== '') {
      setParams(
        `?graph_type=ctr_graph&group_by=individual&group_by_value=${selectedElement}`
      );
      setComment(`${t('ClickThroughRateOf')} ${selectedElement}`);
    }
  }, [selectedElement]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  const onBackButtonClick = () => {
    if (selectedElement !== '') {
      setSelectedElement('');
    }
  };

  useEffect(() => {
    if (selectedElement !== '') {
      setIsBackButtonVisible(true);
    } else {
      setIsBackButtonVisible(false);
    }
  }, [selectedElement]);

  function renderChart() {
    if (selectedElement === '') {
      return (
        <OverallCTRBarChart
          apiResponse={response}
          setSelectedElement={setSelectedElement}
        />
      );
    } else {
      return <IndividualCTRBarChart apiResponse={response} />;
    }
  }

  return (
    <ChartWithConfig
      title={t('ClickThroughRate')}
      comment={comment}
      onBackButtonClick={onBackButtonClick}
      isBackButtonVisible={isBackButtonVisible}
      isConfigHidden={true}
    >
      {renderChart()}
    </ChartWithConfig>
  );
};

export default MainCTRChart;
