import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import './splash-screen.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import lottieBanner from '../../assets/json/lottie_animation/click_1.json';
import Lottie from 'lottie-react';

const SplashScreen = () => {
  const { t } = useTranslation();
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);

  return (
    <motion.div className='splash-screen'>
      <div className='splash-screen-container'>
        {merchantTheme?.merchant_logo_light &&
        merchantTheme?.merchant_logo_light !== '' ? (
          <img
            src={`${staticURL}/medium/${
              isDarkMode
                ? merchantTheme?.merchant_logo_light
                : merchantTheme?.merchant_logo_dark
            }`}
            alt={'logo'}
          />
        ) : (
          <div className='splash-screen-lottie'>
            <Lottie animationData={lottieBanner} loop={true} />
          </div>
        )}

        <h2>{t('SplashScreenSlogan')}</h2>
      </div>
    </motion.div>
  );
};

export default SplashScreen;
