import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import { useTranslation } from 'react-i18next';
import WorldMapChart from './WorldMapChart';

type Props = {
  apiResponse: any;
  groupBy: string;
};

const UserDemographicsMapChart = ({ apiResponse, groupBy }: Props) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any[]>([]);

  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  const getData = async (res: any) => {
    setLoading('pending');

    if (res !== 'initial' && res?.length === 0) {
      setLoading('null');
      return;
    } else if (res !== 'initial' && res?.length > 0) {
      if (res[0]?.id?.length !== 3) {
        setLoading('null');
        return;
      } else {
        setLoading('success');
        setChartData(res);
      }
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getData(apiResponse);
    }
  }, [apiResponse]);

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}

      {loading === 'null' && (
        <NoData
          title={t('No data available')}
          subTitle={t('NeedMoreInteractionsToGenerateStats')}
        />
      )}
      {loading === 'success' && <WorldMapChart data={chartData} />}
    </>
  );
};

export default UserDemographicsMapChart;
