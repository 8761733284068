import { motion } from 'framer-motion';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import { useState } from 'react';
import '../../styles/pages/verifyOTP.scss';
import { useNavigate } from 'react-router-dom';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import { useTranslation } from 'react-i18next';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import RenderLoginPageLogo from '../login/RenderLoginPageLogo';
import { submitLogToBugsnag } from '../../lib/api/log';
import { OTPCodeInput } from '../../ui/inputs/OTPCodeInput';
import {
  FORGOT_PASSWORD_ENDPOINT,
  VERIFY_PASSWORD_OTP_ENDPOINT,
} from '../../lib/constants/central-api';
import adUpLogoDark from '../../assets/adup_merchant_logo_dark.png';
import adUpLogoLight from '../../assets/adup_merchant_logo_light.png';

const VerifyOTP = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [otpCode, setOTPCode] = useState('');
  const [otpKey, setOtpKey] = useState(0);
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);
  const userEmail = useSelector((state: RootState) => state.user.profile.email);
  const isDisabled =
    !otpCode || otpCode?.length !== 6 || !userEmail || userEmail === ''
      ? true
      : false;

  async function onSubmitHandler(e: any) {
    e.preventDefault();

    try {
      const requestInfo: RequestInfo = {
        url: VERIFY_PASSWORD_OTP_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({
          email: userEmail,
          otp: otpCode,
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };

      const actionType = 'VERIFY_PASSWORD_OTP';
      const customMsg = t('VerifyOTPSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        const token = response?.data?.token;
        if (token) {
          navigate('/reset-password', { state: { token } });
        }
      }
    } catch (exception) {
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  async function onResendOTP() {
    // Reset the OTP code to its initial state
    setOTPCode('');

    // Change the key to force re-render
    setOtpKey((prevKey) => prevKey + 1);
    try {
      const requestInfo: RequestInfo = {
        url: FORGOT_PASSWORD_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({
          email: userEmail,
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };

      const actionType = 'FORGOT_PASSWORD';
      const customMsg = t('ForgotPasswordSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        // console.log('OTP sent successfully');
      }
    } catch (exception) {
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  return (
    <motion.div {...pageSlideOutForward} className='verifyOTP'>
      <form onSubmit={onSubmitHandler} className='verifyOTP__left'>
        <div className='verifyOTP__logo'>
          <RenderLoginPageLogo
            staticURL={staticURL}
            merchantTheme={merchantTheme}
            isDarkMode={isDarkMode}
          />
        </div>
        <div className='verifyOTP__content'>
          <div className='verifyOTP__left--top'>
            <h1>{t('VerifyYourIdentity')}</h1>
          </div>
          <div className='verifyOTP__left--center'>
            <OTPCodeInput
              key={otpKey}
              numberOfFields={6}
              onComplete={(code: string) => {
                setOTPCode(code);
              }}
            />
          </div>
          <div className='verifyOTP__left--bottom'>
            <div className='verifyOTP__left--bottom-button'>
              <MainSquareButton
                value={t('VerifyOTP')}
                type='submit'
                disabled={isDisabled}
                classes='mainSquareButton-extra-1'
              />
            </div>

            <div className='verifyOTP__left--bottom-links'>
              <h3>{t('EnterThe6DigitCodeSentToYourEmail')}</h3>
              <h2>
                {t('DidNotReceiveCode')}{' '}
                <span onClick={onResendOTP}>{t('ResendOTP')} </span>
              </h2>
            </div>
          </div>
        </div>
      </form>

      <div className='verifyOTP__right'>
        <div className='verifyOTP__right--image'>
          {merchantTheme?.merchant_logo_light &&
          merchantTheme?.merchant_logo_light !== '' ? (
            <img
              src={`${staticURL}/medium/${
                isDarkMode
                  ? merchantTheme?.merchant_logo_light
                  : merchantTheme?.merchant_logo_dark
              }`}
              alt={'logo'}
            />
          ) : (
            <img src={isDarkMode ? adUpLogoLight : adUpLogoDark} alt={'logo'} />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default VerifyOTP;
