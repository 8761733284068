import '../../styles/inputs/multipleImagesInput.scss';
import { useState, useEffect, useRef } from 'react';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { crossSVG, uploadSVG } from '../../lib/allCustomSVGs';
import { useTranslation } from 'react-i18next';

interface Props {
  setSendingFiles: (input: any) => void;
  setSendingFilesPreviews: (input: any) => void;
  receivingFiles?: any;
  uneditableImageArray?: any;

  labelName: string;
  acceptedFileTypes?: string;
  id: string;
  manyImages: boolean;
}

const MultipleImagesInput = ({
  setSendingFiles,
  setSendingFilesPreviews,
  receivingFiles,
  labelName,
  acceptedFileTypes,
  id,
  manyImages,
  uneditableImageArray,
}: Props) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState(
    receivingFiles?.length > 0 ? receivingFiles : []
  );

  useEffect(() => {
    if (!manyImages && selectedFiles.length > 0) {
      setSelectedFiles([selectedFiles[0]]);
    }
  }, []);

  const disableInput = () => {
    if (!manyImages && selectedFiles.length > 0) {
      return true;
    } else return false;
  };

  const [selectedFilesPreviews, setSelectedFilesPreviews] = useState([]);

  const handleImageChange = (e: any) => {
    if (e.target.files) {
      setSelectedFiles((prevFiles: any) =>
        prevFiles?.concat(...e.target.files)
      );
    }
  };

  useEffect(() => {
    if (selectedFiles) {
      const filesArray: any = Array?.from(selectedFiles)?.map(
        (file: any) => URL?.createObjectURL(file) //creating blob
      );

      setSelectedFilesPreviews(filesArray);

      setSendingFiles(selectedFiles);
      setSendingFilesPreviews(filesArray);

      Array?.from(selectedFiles)?.map(
        (file: any) => URL?.revokeObjectURL(file) // avoid memory leak
      );
    }
    // console.log(selectedFiles);
  }, [selectedFiles]);

  const removeImageHandler = (index: number) => {
    if (selectedFiles?.length > 0) {
      const updatedFileArr: any = [];
      for (let i = 0; i < selectedFiles?.length; i++) {
        if (i !== index) {
          updatedFileArr?.push(selectedFiles[i]);
        }
      }
      setSelectedFiles(updatedFileArr);
    }
  };

  const renderPhotos = (source: any, file: any) => {
    return source?.map((photo: any, i: number) => {
      let isVideo = file[i]?.type?.includes('video');
      return (
        <div className='multipleImagesInput__images--image' key={photo}>
          <div
            onClick={() => {
              removeImageHandler(i);
            }}
            className='multipleImagesInput__images--image-remove'
          >
            <CustomSVGs svg={crossSVG} />
          </div>
          {!isVideo && <img src={photo} alt='media' />}
          {isVideo && <video src={photo} autoPlay loop muted />}
        </div>
      );
    });
  };

  //drag and drop code
  const [isDragActive, setIsDragActive] = useState(false);
  const mainContainerRef = useRef<HTMLDivElement>(null);

  const onFileDropHandler = (e: any) => {
    e.preventDefault();
    setIsDragActive(false);
    if (mainContainerRef.current) {
      mainContainerRef.current.classList.remove('multipleImagesInput-dragOver');
    }
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      if (manyImages) {
        setSelectedFiles((prevFiles: any) => prevFiles?.concat(...files));
      } else {
        if (selectedFiles?.length < 1) {
          setSelectedFiles((prevFiles: any) => prevFiles?.concat(...files));
        }
      }
    }
  };

  const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    /* e.stopPropagation(); */
    setIsDragActive(true);
    if (mainContainerRef.current) {
      mainContainerRef.current.classList.add('multipleImagesInput-dragOver');
    }
  };

  const onDragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActive(false);
    if (mainContainerRef.current) {
      mainContainerRef.current.classList.remove('multipleImagesInput-dragOver');
    }
  };

  const renderImage = (imageURL: string) => {
    if (imageURL?.includes('.mp4')) {
      const newURL = imageURL.replace('small', 'medium');
      return <video src={newURL} />;
    } else if (imageURL?.includes('.gif')) {
      const newURL = imageURL.replace('small', 'medium');
      return <img src={newURL} alt='pic' />;
    } else {
      return <img src={imageURL} alt='pic' />;
    }
  };

  return (
    <div ref={mainContainerRef} className='multipleImagesInput'>
      <div className='multipleImagesInput-label'>{labelName}</div>
      {/* ------------------------------------- */}
      <div className='multipleImagesInput__button'>
        <div
          title={
            manyImages ? 'Click to upload images.' : 'Click to upload an image.'
          }
          onDrop={onFileDropHandler}
          onDragOver={onDragOverHandler}
          onDragLeave={onDragLeaveHandler}
          className='multipleImagesInput__button--content'
        >
          <div>
            <label htmlFor={id}>
              <div>
                <CustomSVGs svg={uploadSVG} />
              </div>
              {acceptedFileTypes && (
                <span>{`${t(
                  'AcceptedFileTypes'
                )} (${acceptedFileTypes})`}</span>
              )}
              {isDragActive && (
                <span className='multipleImagesInput__button--content-dragText-1'>
                  {t('DropSelectedFiles')}
                </span>
              )}
              {!isDragActive && (
                <span className='multipleImagesInput__button--content-dragText-2'>
                  {t('DragDropFiles')}
                </span>
              )}
            </label>
            <input
              id={id}
              type='file'
              style={{ fontFamily: 'var(--main-font-secondary)' }}
              multiple={manyImages}
              accept={acceptedFileTypes}
              onChange={handleImageChange}
              disabled={disableInput()}
            />
          </div>
        </div>
      </div>
      {/* ------------------------------------- */}
      <div className='multipleImagesInput__images'>
        {/* ----------------uneditable images from API start---------------- */}
        {uneditableImageArray?.length > 0 &&
          uneditableImageArray?.map((url: string, i: number) => {
            let newURL = url;
            if (url.includes('blob:http:')) {
              const blobURL = url.split('blob:http:');
              newURL = `blob:http:${blobURL[1]}`;
            }
            return (
              <div key={i + 100} className='multipleImagesInput__images--image'>
                {/* <img src={newURL} alt='uneditable media' /> */}
                {renderImage(newURL)}
              </div>
            );
          })}
        {/* ----------------uneditable images from API end------------------ */}
        {renderPhotos(selectedFilesPreviews, selectedFiles)}
      </div>
    </div>
  );
};

export default MultipleImagesInput;
