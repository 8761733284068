//api call imports
import { RequestInfo } from '../../../interfaces/RequestInfo';
// import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import store from '../../../store/store';

// other imports
import { useEffect, useState } from 'react';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../ui/inputs/NormalInput';
import NormalSelectInput from '../../../ui/inputs/NormalSelectInput';
import convertAttributesArrayToKeyValues from '../../../lib/helper-functions/convertAttributesArrayToKeyValues';
import MultipleImagesInput from '../../../ui/inputs/MultipleImagesInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import MaskedCurrencyInput from '../../../ui/inputs/CurrencyInput';
import { useTranslation } from 'react-i18next';
import generateSKU from '../../../lib/helper-functions/generateSKU';
import { asyncHandler } from '../../../lib/api/async-handler';
import { trimFromStartToLimitedNumber } from '../../../lib/helper-functions/trimSKU';
import TextAreaAI from '../../../ui/inputs/TextAreaAI';
import { PRODUCT_API } from '../../../lib/constants/central-api';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../lib/utils/body-builder';
import { submitLogToBugsnag } from '../../../lib/api/log';

interface Props {
  productVariantsArr: any;
  setProductVariantsArr: (input: any) => void;
  closeWindow: () => void;
  productId: any;
  variantId: any;
  variantSku: any;
}

const EditVariant = ({
  productVariantsArr,
  setProductVariantsArr,
  closeWindow,
  productId,
  variantId,
  variantSku,
}: Props) => {
  const { t } = useTranslation();
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const shop_slug = store.getState().user.company.shop_slug;
  /* productVariant state */
  const [productVariants, setProductVariants] = useState({
    productVariantName:
      productVariantsArr[variantId].productVariants.productVariantName,
    productVariantDescription:
      productVariantsArr[variantId].productVariants.productVariantDescription,
    productVariantSKU:
      productVariantsArr[variantId].productVariants.productVariantSKU,
    productVariantPrice:
      productVariantsArr[variantId].productVariants.productVariantPrice,
    productVariantStock:
      productVariantsArr[variantId].productVariants.productVariantStock,
    productVariantId:
      productVariantsArr[variantId].productVariants.productVariantId,
  });

  const [productVariantStatus, setProductVariantStatus] = useState(
    productVariantsArr[variantId].productVariantStatus
  );

  const [generatedSKU, setGeneratedSKU] = useState<string>('');

  useEffect(() => {
    setGeneratedSKU(
      trimFromStartToLimitedNumber(
        generateSKU(productVariants.productVariantName),
        20
      )
    );
  }, [productVariants.productVariantName]);

  /* ProductVariant images state */
  const [selectedProductVariantImages, setSelectedProductVariantImages] =
    useState(
      productVariantsArr[variantId].productVariantImages
        .selectedProductVariantImages
    );
  const [previewProductVariantImages, setPreviewProductVariantImages] =
    useState(
      productVariantsArr[variantId].productVariantImages
        .previewProductVariantImages
    );

  const uneditablePreviewImagesArray =
    productVariantsArr[variantId].productVariantImages
      .previewProductVariantImages || [];

  const productVariantStatusArr = [
    { OptionValue: 'active', OptionName: 'Active' },
    { OptionValue: 'inactive', OptionName: 'Inactive' },
  ];

  //Variant Attributes state
  /* const [variantAttributes, setVariantAttributes] = useState<
    VariantAttributesInterface[]
  >([]); */

  const editedVariant = {
    productVariants,
    productVariantStatus,
    productVariantImages: {
      selectedProductVariantImages,
      previewProductVariantImages,
    },
    variantAttributes: productVariantsArr[variantId].variantAttributes,
  };

  let isDisabled =
    editedVariant?.productVariants?.productVariantName === '' ||
    editedVariant?.productVariants?.productVariantDescription === '' ||
    editedVariant?.productVariants?.productVariantStock === '' ||
    editedVariant?.productVariants?.productVariantPrice === '' ||
    editedVariant?.productVariants?.productVariantSKU === ''
      ? true
      : false;

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    try {
      const accessToken = store.getState().auth.authorization.accessToken;
      const isWhiteLabel = store.getState().UI?.isWhiteLabel;
      let sellerToken: string;
      if (isWhiteLabel) {
        sellerToken = accessToken?.currentWhiteLabelSellerToken;
      } else {
        sellerToken = accessToken?.sellerToken;
      }
      let sendingSKU: string;
      if (
        productVariants.productVariantSKU === '' ||
        editedVariant?.productVariants?.productVariantSKU === ''
      ) {
        sendingSKU = generatedSKU;
      } else {
        sendingSKU = editedVariant?.productVariants?.productVariantSKU;
      }

      const formData = new FormData();

      formData.append(`_method`, 'put');
      formData.append(`parent_id`, productId);
      formData.append(`sku`, sendingSKU);
      formData.append(
        `name`,
        editedVariant?.productVariants?.productVariantName
      );
      formData.append(
        `description`,
        editedVariant?.productVariants?.productVariantDescription
      );
      formData.append(`type`, 'variant');
      formData.append(
        `attributes`,
        convertAttributesArrayToKeyValues(editedVariant?.variantAttributes) ||
          'null'
      );
      formData.append(`is_manual`, '1');
      formData.append(`is_published`, '1');
      formData.append(
        `stock`,
        editedVariant?.productVariants?.productVariantStock !== ''
          ? editedVariant?.productVariants?.productVariantStock
          : 0
      );
      formData.append(
        `price`,
        editedVariant?.productVariants?.productVariantPrice !== ''
          ? editedVariant?.productVariants?.productVariantPrice
          : 0
      );
      if (
        editedVariant?.productVariantImages?.selectedProductVariantImages
          ?.length > 0
      ) {
        editedVariant?.productVariantImages?.selectedProductVariantImages?.forEach(
          (item: any) => {
            formData.append('media[]', item);
          }
        );
      }

      /* const updatedProduct = {
        name: editedVariant?.productVariants?.productVariantName,
        sku: sendingSKU,
        description: editedVariant?.productVariants?.productVariantDescription,
        type: 'variant',
        attributes:
          convertAttributesArrayToKeyValues(editedVariant?.variantAttributes) ||
          'null',
        parent_id: productId,
        stock:
          editedVariant?.productVariants?.productVariantStock !== ''
            ? editedVariant?.productVariants?.productVariantStock
            : 0,
        price:
          editedVariant?.productVariants?.productVariantPrice !== ''
            ? editedVariant?.productVariants?.productVariantPrice
            : 0,
        is_manual: 1,
        is_published: 1,
        images:
          editedVariant?.productVariantImages?.selectedProductVariantImages, //TODO: API not ready
      }; */

      const requestInfo: RequestInfo = {
        url: `${PRODUCT_API(shop_slug)}/${variantSku}`,
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
          // 'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'UPDATE_PRODUCT_VARIANT';
      const customMsg = t('ProductVariantUpdatedSuccessfully-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      const response = res.data;

      if (res.actionType === actionType && response.success) {
        const allVariantsExceptCurrent = productVariantsArr.filter(
          (el: any, i: number) => i !== variantId
        );
        setProductVariantsArr([...allVariantsExceptCurrent, editedVariant]);
        let closeWindowTimeout = setTimeout(() => {
          closeWindow();
          clearTimeout(closeWindowTimeout);
        }, 500);
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  };

  return (
    <form
      id='edit-variant-form'
      onSubmit={onSubmitHandler}
      className='normalForm'
    >
      <div className='normalForm__top'>
        <div className='normalForm__titleContainer--With-marginBottom'>
          <h1>{t('EditVariant')}</h1>
        </div>
        <MultipleImagesInput
          setSendingFiles={setSelectedProductVariantImages}
          setSendingFilesPreviews={setPreviewProductVariantImages}
          labelName={t('ProductMedia')}
          acceptedFileTypes={'.png, .jpeg, .webp, .mp4, .gif'}
          id={'edit-productVariant-images'}
          manyImages={false}
          uneditableImageArray={uneditablePreviewImagesArray?.map(
            (url: string) => {
              return url;
            }
          )}
        />
        {/* ------------------------------------------------------- */}
        <div className='normalForm__container1'>
          <div className='normalForm__container1--left'>
            <NormalInput
              changeListeners={[
                (e) =>
                  setProductVariants((prev) => {
                    return { ...prev, productVariantName: e.target.value };
                  }),
              ]}
              id='edit-productVariant-name'
              value={productVariants.productVariantName}
              placeholder={t('ProductName')}
              required={true}
              title={t('ProductName-validation')}
              minlength={1}
              maxlength={100}
            />
          </div>
          <div className='normalForm__container1--right'>
            <TextAreaAI
              id='edit-productVariant-description'
              productDescription={productVariants.productVariantDescription}
              setProductDescription={(description) =>
                setProductVariants((prevProduct) => ({
                  ...prevProduct,
                  productVariantDescription: description,
                }))
              }
              placeholder={t('ProductDescription')}
              required={true}
              title={t('ProductDescription-validation')}
              textForAI={productVariants.productVariantName}
            />
          </div>
        </div>
        {/* <ImageInput
          mainText='Variant Media'
          subText='Accepted file types (.png, .jpeg)'
          acceptedFileTypes={'.png, .jpeg'}
          id={'edit-productVariant-images'}
          selectedFile={selectedProductVariantImages}
          setSelectedFile={setSelectedProductVariantImages}
          preview={previewProductVariantImages}
          setPreview={setPreviewProductVariantImages}
          fileError={fileErrorProductVariantImages}
          setFileError={setFileErrorProductVariantImages}
          manyImages={true}
        /> */}

        {/* ------------------------------------------------------- */}

        {/* ------------------------------------------------------- */}

        <div className='normalForm__container1'>
          <div className='normalForm__container1--left'>
            {' '}
            <NormalSelectInput
              defaultValue={productVariantStatus}
              setSelect={setProductVariantStatus}
              selectOptionArr={productVariantStatusArr}
              placeholder={t('Status')}
              id={'edit-productVariant-status'}
            />
          </div>
          <div className='normalForm__container1--right'>
            {' '}
            <NormalInput
              changeListeners={[
                (e) =>
                  setProductVariants((prev) => {
                    return { ...prev, productVariantSKU: e.target.value };
                  }),
              ]}
              id='edit-productVariant-sku'
              value={productVariants.productVariantSKU}
              placeholder='SKU'
              pattern='[^ ]{1,20}$'
              /* required={true} */
              innerPlaceholder={generatedSKU}
              maxlength={20}
              title={t('ProductSKU-validation')}
            />
          </div>
        </div>
        {/* ------------------------------------------------------- */}
        <div className='normalForm__container1'>
          <div className='normalForm__container1--left'>
            {' '}
            <NormalInput
              changeListeners={[
                (e) =>
                  setProductVariants((prev) => {
                    return { ...prev, productVariantStock: e.target.value };
                  }),
              ]}
              id='edit-productVariant-stock'
              value={productVariants.productVariantStock}
              placeholder={t('Stock')}
              required={true}
              pattern='^[0-9]{1,10}$'
              title={t('ProductStock-validation')}
              onKeyDown={(e) => {
                // Allow only numeric keys and specific control keys
                if (
                  !/^[0-9]$/.test(e.key) &&
                  ![
                    'Backspace',
                    'Delete',
                    'ArrowLeft',
                    'ArrowRight',
                    'Home',
                    'End',
                  ].includes(e.key)
                ) {
                  e.preventDefault();
                }
              }}
              minlength={1}
              maxlength={10}
            />
          </div>
          <div className='normalForm__container1--right'>
            {' '}
            <MaskedCurrencyInput
              changeListeners={[
                (value) =>
                  setProductVariants((prev) => {
                    return { ...prev, productVariantPrice: value };
                  }),
              ]}
              id='edit-productVariant-price'
              value={
                productVariants.productVariantPrice &&
                !Number.isNaN(productVariants.productVariantPrice)
                  ? productVariants.productVariantPrice
                  : '0'
              }
              placeholder={t('Price')}
              required={true}
              title={t('OnlyNumbers-validation')}
              type='text'
              pattern='^[\d|\.|\,]+/'
              defaultValue={
                productVariants.productVariantPrice &&
                !Number.isNaN(productVariants.productVariantPrice)
                  ? productVariants.productVariantPrice
                  : '0'
              }
            />
          </div>
        </div>
        {/* ------------------------------------------------------- */}

        {/* ----------------------Attributes start--------------------- */}
        <div className='normalForm__titleContainer--With-marginBottom'>
          <h1>{t('Attributes')}</h1>
        </div>
        {productVariantsArr[variantId].variantAttributes?.map(
          (el: any, index: number) => {
            return (
              <div
                key={index}
                className='normalForm__top--container1 normalForm-margin-bottom-2rem'
              >
                <div className='normalForm__top--container1-left normalForm-margin-bottom-0'>
                  <NormalInput
                    id={`edit-productVariant-name-${el.id}`}
                    value={el.name}
                    placeholder={t('Name')}
                    readonly={true}
                  />
                </div>
                <div className='normalForm__top--container1-right'>
                  <NormalInput
                    id={`edit-productVariant-value-${el.id}`}
                    value={el.value}
                    placeholder={t('Value')}
                    readonly={true}
                  />
                </div>
              </div>
            );
          }
        )}
        {/* ----------------------Attributes end--------------------- */}
      </div>

      <div className='normalForm__bottom flex-start'>
        <MainSquareButton
          value={t('EditVariant')}
          /* icon={<CustomSVGs svg={plusSignSVG} />} */
          disabled={isDisabled}
          form='edit-variant-form'
        />
      </div>
    </form>
  );
};

export default EditVariant;
