import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OverallCTRBarChart from './OverallCTRBarChart';
import '../../../../../styles/components/charts/pieChartComponent.scss';
import IndividualCTRBarChart from './IndividualCTRBarChart';
import ChartWithConfig from '../../../../../components/charts/ChartWithConfig';
import {
  addToCartButtonElement_CTR_DummyData,
  clickThroughRateDummyData,
  goToCheckoutButtonElement_CTR_DummyData,
  payButtonElement_CTR_DummyData,
} from '../../../../../lib/constants/graphDummyData/clickThroughRateDummyData';

const MainCTRChart = () => {
  const { t } = useTranslation();
  const [selectedElement, setSelectedElement] = useState<string>('');
  const [isBackButtonVisible, setIsBackButtonVisible] =
    useState<boolean>(false);

  const [comment, setComment] = useState<string>(t('OverallClickThroughRate'));

  useEffect(() => {
    if (selectedElement === '') {
      setComment(t('OverallClickThroughRate'));
    } else if (selectedElement !== '') {
      setComment(`${t('ClickThroughRateOf')} ${selectedElement}`);
    }
  }, [selectedElement]);

  const onBackButtonClick = () => {
    if (selectedElement !== '') {
      setSelectedElement('');
    }
  };

  useEffect(() => {
    if (selectedElement !== '') {
      setIsBackButtonVisible(true);
    } else {
      setIsBackButtonVisible(false);
    }
  }, [selectedElement]);

  const getIndividualCTRData = (element: string) => {
    if (element === 'addToCartButtonElement') {
      return addToCartButtonElement_CTR_DummyData;
    } else if (element === 'goToCheckoutButtonElement') {
      return goToCheckoutButtonElement_CTR_DummyData;
    } else if (element === 'payButtonElement') {
      return payButtonElement_CTR_DummyData;
    } else {
      return [];
    }
  };

  function renderChart() {
    if (selectedElement === '') {
      return (
        <OverallCTRBarChart
          apiResponse={{
            type: 'overall',
            data: clickThroughRateDummyData,
          }}
          setSelectedElement={setSelectedElement}
        />
      );
    } else {
      return (
        <IndividualCTRBarChart
          apiResponse={{
            type: 'individual',
            data: getIndividualCTRData(selectedElement),
          }}
        />
      );
    }
  }

  return (
    <ChartWithConfig
      title={t('ClickThroughRate')}
      comment={comment}
      onBackButtonClick={onBackButtonClick}
      isBackButtonVisible={isBackButtonVisible}
      isConfigHidden={true}
    >
      {renderChart()}
    </ChartWithConfig>
  );
};

export default MainCTRChart;
