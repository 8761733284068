import '../../../../styles/components/subPageList/subPageListView.scss';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../store/store';
import CopyToClipboard from '../../../../ui/inputs/CopyToClipboard';
import NormalInput from '../../../../ui/inputs/NormalInput';
import { useTranslation } from 'react-i18next';

const ConnectionsView = () => {
  const { integrationId } = useParams();
  const { t } = useTranslation();
  const autofillInregrations: any = useSelector(
    (state: RootState) => state.user.autofillInregrations
  );

  const selectedItem = autofillInregrations.find((item: any) => {
    return item.id.toString() === integrationId;
  });

  return (
    <div className='subPageListView'>
      <h1>{t('IntegrationInfo')}</h1>
      <NormalInput
        /* changeListeners={[(e) => setWebsiteDomain(e.target.value)]} */
        id='website-domain'
        value={selectedItem.domain}
        placeholder={t('WebsiteDomain')}
        innerPlaceholder='https://www.example.com'
        readonly={true}
      />
      <NormalInput
        /* changeListeners={[(e) => setWebsiteName(e.target.value)]} */
        id='website-name'
        value={selectedItem.website_name}
        placeholder={t('WebsiteName')}
        innerPlaceholder={t('MyWebsiteName')}
        readonly={true}
      />
      <CopyToClipboard
        inputTitle={t('CopyKey')}
        inputValue={selectedItem.token}
      />
    </div>
  );
};

export default ConnectionsView;
