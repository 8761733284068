import React from 'react';
import isNotEmptyString from '../../lib/helper-functions/isNotEmptyString';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';

type Props = {
  isRegisterPage?: boolean;
};

const FooterLinks = ({ isRegisterPage }: Props) => {
  const { t } = useTranslation();
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );

  const onPrivacyLinkClick = () => {
    window.open(
      `${
        isNotEmptyString(merchantTheme?.privacy_url)
          ? merchantTheme?.privacy_url
          : 'https://adup.io/privacy'
      }`,
      '_blank'
    );
  };

  const onTermsLinkClick = () => {
    window.open(
      `${
        isNotEmptyString(merchantTheme?.terms_url)
          ? merchantTheme?.terms_url
          : 'https://adup.io/terms'
      }`,
      '_blank'
    );
  };

  const renderFooterLinks = () => {
    if (isRegisterPage) {
      return (
        <div className='signup__right--bottom-links-privacy'>
          {t('Agree')} <span onClick={onTermsLinkClick}>{t('Terms')}</span>{' '}
          {t('And')} <span onClick={onPrivacyLinkClick}>{t('Privacy')}</span>
        </div>
      );
    } else {
      return (
        <>
          <span onClick={onTermsLinkClick}>{t('Terms')}</span>
          {` & `}
          <span onClick={onPrivacyLinkClick}>{t('Privacy')}</span>
        </>
      );
    }
  };

  return renderFooterLinks();
};

export default FooterLinks;
