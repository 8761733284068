import '../../styles/components/messageBanner/messageBanner.scss';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { AnimatePresence, motion } from 'framer-motion';
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';

interface Props {
  icon: any;
  title: string;
  subTitle?: string;
  btnTitle: string;
  otherBtnTitle?: string;
  success: boolean;
  isLoading?: boolean;
  onSelect: (event: React.MouseEvent) => void;
  onSelectOtherBtn?: (event: React.MouseEvent) => void;
}

const MessageBanner = ({
  icon,
  title,
  subTitle,
  btnTitle,
  otherBtnTitle,
  success,
  isLoading,
  onSelect,
  onSelectOtherBtn,
}: Props) => {
  return (
    <div className='messageBanner'>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className='messageBanner__message'
        >
          {isLoading && (
            <>
              <LoadingSpinner />
              <br />
            </>
          )}
          {!isLoading && (
            <>
              <div className={`messageBanner__message--icon--${success}`}>
                <CustomSVGs svg={icon} />
              </div>
              <div className='messageBanner__message--title'>
                <h1>{title}</h1>
              </div>
              <div className='messageBanner__message--body'>
                <p>{subTitle}</p>
              </div>
              <div className='messageBanner__message--button'>
                <MainSquareButton
                  value={btnTitle}
                  onClick={onSelect}
                  buttonType={success ? 'success' : 'light'}
                />
                {otherBtnTitle && (
                  <MainSquareButton
                    value={otherBtnTitle}
                    onClick={onSelectOtherBtn}
                    buttonType={'light'}
                  />
                )}
              </div>
            </>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default MessageBanner;
