import { useState } from 'react';
import '../../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../../ui/inputs/NormalInput';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../../lib/utils/body-builder';
import { INTEGRATION_ENDPOINT } from '../../../../lib/constants/urls';
import { useDispatch } from 'react-redux';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../../lib/api/async-handler';
import store from '../../../../store/store';
import CopyToClipboard from '../../../../ui/inputs/CopyToClipboard';
import { useNavigate } from 'react-router-dom';
import { setAutofillInregrations } from '../../../../store/reducers/userSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useTranslation } from 'react-i18next';
import { submitLogToBugsnag } from '../../../../lib/api/log';

const ConnectionsAdd = () => {
  const { t } = useTranslation();
  const [websiteDomain, setWebsiteDomain] = useState<string>('');
  const [websiteName, setWebsiteName] = useState<string>('');
  const [responseKey, setResponseKey] = useState<string>('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const autofillInregrations: any = useSelector(
    (state: RootState) => state.user.autofillInregrations
  );

  const updatedIntegrations = Object.assign([], autofillInregrations);

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    try {
      /* const response = await addIntegration(websiteDomain, websiteName); */
      /*  */
      const accessToken = store.getState().auth.authorization.accessToken;
      const isWhiteLabel = store.getState().UI?.isWhiteLabel;
      let sellerToken: string;
      if (isWhiteLabel) {
        sellerToken = accessToken?.currentWhiteLabelSellerToken;
      } else {
        sellerToken = accessToken?.sellerToken;
      }

      const requestInfo: RequestInfo = {
        url: INTEGRATION_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({
          domain: websiteDomain,
          website_name: websiteName,
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'AUTOFILL_ADD_INTEGRATION';
      const customMsg = t('IntegrationSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        setResponseKey(response.result.token);
        updatedIntegrations.push(response.result);
        dispatch(setAutofillInregrations(updatedIntegrations));
        navigate('/integrations/autofill/connections');
      } else {
        /* onFormSubmitedFailed(generateErrorMessage(response)); */
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  const isDisabled = websiteDomain === '' || websiteName === '' ? true : false;

  return (
    <form
      id='autofillAdd-form'
      onSubmit={onSubmitHandler}
      className='normalForm'
    >
      <div className='normalForm__top'>
        {/* <h1>Add the Following Information.</h1> */}

        <NormalInput
          changeListeners={[(e) => setWebsiteDomain(e.target.value)]}
          id='website-domain'
          value={websiteDomain}
          placeholder={t('WebsiteDomain')}
          innerPlaceholder='https://www.example.com'
          required={true}
          pattern='^(https?:\/\/)?[0-9a-zA-Z]+\.[-_0-9a-zA-Z]+\.[0-9a-zA-Z]+$'
          title={t('Expected-URL-Format-validation')}
        />
        <NormalInput
          changeListeners={[(e) => setWebsiteName(e.target.value)]}
          id='website-name'
          value={websiteName}
          placeholder={t('WebsiteName')}
          innerPlaceholder={t('MyWebsiteName')}
          required={true}
          pattern='^[A-Za-z0-9 ]{1,100}$'
          title={t('WebsiteName-validation')}
        />

        {responseKey && (
          <CopyToClipboard inputTitle={t('CopyKey')} inputValue={responseKey} />
        )}
      </div>

      <div className='normalForm__bottom'>
        <MainSquareButton
          value={t('Add')}
          /* icon={<CustomSVGs svg={plusSignSVG} />} */
          disabled={isDisabled}
          type='submit'
          form='autofillAdd-form'
        />
      </div>
    </form>
  );
};

export default ConnectionsAdd;
