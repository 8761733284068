import arrayToObject from './arrayToObject';
import array_of_objects_to_default_value_object from './array_of_objects_to_default_value_object';

/**
 * Retrieves custom input fields based on the selected value.
 * @param selectedValue - The value selected by the user (e.g., slug or identifier).
 * @param allValues - An array containing all available objects.
 * @returns A tuple with two elements:
 *   - An array of input field names related to the selected object.
 *   - An object where each input field name maps to a empty value.
 *   Or return an empty array and an empty object
 */
export function getCustomInputFields(
  selectedValue: string,
  allValues: any[]
): [string[], Record<string, any>] {
  // Get the current object from the available objects array
  const currentValue: any = allValues?.filter((el: any) => {
    return el?.slug === selectedValue ? el : null;
  });

  if (currentValue && currentValue.length > 0) {
    // Get the connection fields from the current object
    const current_inputs_array = currentValue[0]?.connection_fields?.map(
      (el: any) => {
        return el?.name; // Extracting the 'name' property from each element
      }
    );

    if (current_inputs_array?.length > 0) {
      // Convert the array of strings to an object with strings as keys
      const current_inputs_object = arrayToObject(current_inputs_array);
      return [current_inputs_array, current_inputs_object];
    } else {
      // If no connection fields are found, return an empty array and an empty object
      return [[], {}];
    }
  }

  // If no connection fields are found, return an empty array and an empty object
  return [[], {}];
}

/**
 * Retrieves custom input fields based on the selected value.
 * @param selectedValue - The value selected by the user (e.g., slug or identifier).
 * @param allValues - An array containing all available objects.
 * @returns A tuple with two elements:
 *   - An array of input field names related to the selected object.
 *   - An object where each input field name maps to a empty value.
 *   Or return an empty array and an empty object
 */
export function getExtraCustomInputFields(
  selectedValue: string,
  allValues: any[]
): [string[], Record<string, any>] {
  // Get the current object from the available objects array
  const currentValue: any = allValues?.filter((el: any) => {
    return el?.slug === selectedValue ? el : null;
  });

  if (currentValue && currentValue.length > 0) {
    // Get the extra connection fields details from the current object
    const current_inputs_array = currentValue[0]?.additional_fields?.map(
      (el: any) => {
        return el; // Extracting the properties from each element
      }
    );

    if (current_inputs_array?.length > 0) {
      // using the input details object array to create an object with default values
      const current_inputs_object =
        array_of_objects_to_default_value_object(current_inputs_array);
      return [current_inputs_array, current_inputs_object];
    } else {
      // If no extra connection fields are found, return an empty array and an empty object
      return [[], {}];
    }
  }

  // If no connection fields are found, return an empty array and an empty object
  return [[], {}];
}
