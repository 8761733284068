import SubPageList from '../../../../components/subPageList/SubPageList';
import { editSVG, trashSVG } from '../../../../lib/allCustomSVGs';
import { useState, useEffect } from 'react';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ListPSP = () => {
  const origin = window.location.origin;
  const [subPageListItems, setSubPageListItems] = useState<
    { id: any; title: any; img: any }[]
  >([]);
  const { t } = useTranslation();
  const alreadyAddedPSP = useSelector(
    (state: RootState) => state.user.company.alreadyAddedPSP
  ) as any;
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const all_psp_services = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object?.available_psp
  );

  const subPageDropdownItems = [
    {
      title: t('Edit'),
      subTitle: t('EditPSP-Connection'),
      id: '1',
      icon: editSVG,
      url: '/settings/payments/connection/edit',
    },
    {
      title: t('Delete'),
      subTitle: t('DeletePSP-Connection'),
      id: '2',
      icon: trashSVG,
      url: '/settings/payments/connection/delete',
    },
  ];

  //creating initial state for each list item
  const initialEditButtonState: any = {};

  subPageListItems?.map((item: any) => {
    initialEditButtonState[item?.id] = false;
  });

  const onListItemClick = () => {
    //on list dropdown item click, make dropdown open states to initial state
    setIsListMenuOpen(initialEditButtonState);
  };

  const [isListMenuOpen, setIsListMenuOpen] = useState(initialEditButtonState);

  const onListMenuClick = (itemId: any) => {
    setIsListMenuOpen((isListMenuOpen: any) => ({
      ...isListMenuOpen,
      [itemId]: !isListMenuOpen[itemId],
    }));
  };

  useEffect(() => {
    if (alreadyAddedPSP?.length > 0) {
      if (alreadyAddedPSP[0]?.payment_secret) {
        const alreadyAddedPSPNames = Object.keys(
          alreadyAddedPSP[0]?.payment_secret
        );

        const alreadyAddedPSPObjects = all_psp_services
          ?.filter((service: any) =>
            alreadyAddedPSPNames?.includes(service.slug)
          )
          ?.map((foundService: any) => ({
            id: foundService.id,
            title: foundService.label,
            img: `${staticURL}/small/${foundService.icon}`, //! image should have the exact gateway_name from API foundService.icon
          }));
        //https://adupwhitelabelbucketmain.s3.eu-central-1.a…/static-data/medium/payment-icons/mollie_logo.png
        //https://adupwhitelabelbucketmain.s3.eu-central-1.amazonaws.com/static-data/medium/payment-icons/payment-icons/mollie_logo.png
        setSubPageListItems(alreadyAddedPSPObjects);
      }
    }
  }, [alreadyAddedPSP]);

  return (
    <SubPageList
      onListMenuClick={onListMenuClick}
      onListItemClick={onListItemClick}
      isListMenuOpen={isListMenuOpen}
      items={subPageListItems}
      dropdownItems={subPageDropdownItems}
    />
  );
};

export default ListPSP;
