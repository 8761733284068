export const availableTrackableButtons = {
  payButtonElement: "pay_btn",
  addToCartButtonElement: "add_to_cart_btn",
  removeFromCartButtonElement: "remove_from_cart_btn",
  viewCartButtonElement: "view_cart_btn",
  goToCheckoutButtonElement: "go_to_checkout_btn",
  addToFavoritesButtonElement: "add_to_favorites_btn",
  removeFromFavoritesButtonElement: "remove_from_favorites_btn",
  addToCompareButtonElement: "add_to_compare_btn",
  customerReviewsButtonElement: "customer_reviews_btn",
  productViewButtonElement: "product_view_btn",
};
