import React, { useState, useEffect } from 'react';

interface CountdownProps {
  futureDate: Date;
  className?: string;
  prefix?: string;
  suffix?: string;
  setIsTimesUp?: (dateTime: boolean) => void;
  display?: boolean;
}

const CountdownTimer: React.FC<CountdownProps> = ({
  futureDate,
  className,
  prefix,
  suffix,
  setIsTimesUp,
  display = true,
}) => {
  const [countDown, setCountDown] = useState<string | null>(null);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const targetTime = futureDate.getTime();
      const difference = targetTime - now;

      if (difference <= 0) {
        setCountDown('timesUp');
        if (setIsTimesUp) {
          setIsTimesUp(true);
        }
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        if (days > 0) {
          setCountDown(
            `${days} day${days > 1 ? 's' : ''} : ${hours
              .toString()
              .padStart(2, '0')} : ${minutes
              .toString()
              .padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`
          );
        } else {
          setCountDown(
            `${hours.toString().padStart(2, '0')} : ${minutes
              .toString()
              .padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`
          );
        }
      }
    };

    calculateTimeLeft();
    const interval = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(interval);
  }, [futureDate]);

  return (
    <p
      style={{ display: display ? 'block' : 'none' }}
      className={className ? className : ''}
    >{`${prefix ? prefix : ''} ${countDown} ${suffix ? suffix : ''}`}</p>
  );
};

export default CountdownTimer;
