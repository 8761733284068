import store from '../../store/store';

const getCountryCodeByCountryName = (name: string) => {
  const countries = store.getState().app?.countries?.available;
  for (let key in countries) {
    if (countries[key].name === name) {
      return countries[key]?.countryCode;
    }
  }
  return undefined;
};

export default getCountryCodeByCountryName;
