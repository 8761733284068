import React, { useState, useEffect, useRef } from 'react';
import '../../styles/inputs/blurAndCopyInput.scss';
import CustomSVGs from '../customSVGs/CustomSVGs';
import {
  copySVG,
  eyeCrossedSVG,
  eyeSVG,
  filledCheckboxSVG,
} from '../../lib/allCustomSVGs';
import { useTranslation } from 'react-i18next';

type Props = {
  icon: any;
  required?: boolean;
  value: string;
  placeholder?: string;
  changeListeners?: ((e: React.ChangeEvent<HTMLInputElement>) => void)[];
  focusListeners?: ((e: React.FocusEvent) => void)[];
  id: string;
  classes?: string;
  pattern?: string;
  title?: string;
  readonly?: boolean;
  isCopyToClipboardEnabled?: boolean;
};

const BlurAndCopyInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      icon,
      value,
      changeListeners,
      focusListeners,
      placeholder = '',
      required,
      id,
      classes = '',
      pattern,
      title,
      readonly,
      isCopyToClipboardEnabled,
    }: Props,
    ref
  ) => {
    const { t } = useTranslation();
    const [innerValue, setValue] = useState<string>(value);
    const [focused, setFocused] = useState<boolean>(false);
    const [onlyOnFocus, setOnlyOnFocus] = useState<boolean>(false);
    const [passwordShown, setPasswordShown] = useState<boolean>(false);
    const [isSVGClicked, setIsSVGClicked] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      setValue(value);
    }, [value]);

    useEffect(() => {
      const inputElement = inputRef.current;
      if (inputElement) {
        const handleCopy = (e: ClipboardEvent) => e.preventDefault();
        const handleCut = (e: ClipboardEvent) => e.preventDefault();
        const handlePaste = (e: ClipboardEvent) => e.preventDefault();
        const handleContextMenu = (e: MouseEvent) => e.preventDefault();
        const handleDragStart = (e: DragEvent) => e.preventDefault();

        if (!passwordShown) {
          inputElement.addEventListener('copy', handleCopy);
          inputElement.addEventListener('cut', handleCut);
          inputElement.addEventListener('paste', handlePaste);
          inputElement.addEventListener('contextmenu', handleContextMenu);
          inputElement.addEventListener('dragstart', handleDragStart);
        } else {
          inputElement.removeEventListener('copy', handleCopy);
          inputElement.removeEventListener('cut', handleCut);
          inputElement.removeEventListener('paste', handlePaste);
          inputElement.removeEventListener('contextmenu', handleContextMenu);
          inputElement.removeEventListener('dragstart', handleDragStart);
        }

        return () => {
          inputElement.removeEventListener('copy', handleCopy);
          inputElement.removeEventListener('cut', handleCut);
          inputElement.removeEventListener('paste', handlePaste);
          inputElement.removeEventListener('contextmenu', handleContextMenu);
          inputElement.removeEventListener('dragstart', handleDragStart);
        };
      }
    }, [passwordShown]);

    const togglePasswordVisibility = () => {
      setPasswordShown(!passwordShown);
    };

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      changeListeners?.forEach((listener) => listener(event));
    };

    const handleFocusElement = (event: React.FocusEvent<HTMLInputElement>) => {
      focusListeners?.forEach((listener) => listener(event));
      setOnlyOnFocus(true);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      setOnlyOnFocus(false);
    };

    const copyToClipboard = (text: string) => {
      navigator.clipboard.writeText(text);
    };

    const svgClickHandler = () => {
      setIsSVGClicked(true);

      const timer = setTimeout(() => {
        setIsSVGClicked(false);
        clearTimeout(timer);
      }, 800);
    };

    return (
      <>
        <div className='blurAndCopyInput'>
          <div className='blurAndCopyInput__left blurAndCopyInput__left--icon'>
            {icon}
          </div>
          <div
            data-is-value-blurred={passwordShown ? 'false' : 'true'}
            className='blurAndCopyInput__right'
          >
            <input
              className={`blurAndCopyInput-input ${classes}`}
              style={{ fontFamily: 'var(--main-font-secondary)' }}
              ref={inputRef}
              id={id}
              type={'text'}
              value={innerValue}
              onChange={handleChangeValue}
              onFocus={handleFocusElement}
              onBlur={handleBlur}
              data-focused={focused.toString()}
              required={required}
              pattern={pattern}
              title={title}
              readOnly={readonly}
            />
            <label htmlFor={id}>{placeholder}</label>
          </div>
          <div className='blurAndCopyInput__actions'>
            <div
              title={passwordShown ? t('Hide') : t('Show')}
              onClick={togglePasswordVisibility}
              className={`blurAndCopyInput__visibility ${
                passwordShown
                  ? 'blurAndCopyInput__visibility--true'
                  : 'blurAndCopyInput__visibility--false'
              }`}
            >
              {passwordShown ? (
                <CustomSVGs svg={eyeCrossedSVG} />
              ) : (
                <CustomSVGs svg={eyeSVG} />
              )}
            </div>
            {isCopyToClipboardEnabled && (
              <div
                onClick={() => {
                  svgClickHandler();
                  copyToClipboard(innerValue);
                }}
                title={t('CopyToClipboard')}
                className='blurAndCopyInput__copyToClipboard'
              >
                {!isSVGClicked && <CustomSVGs svg={copySVG} />}
                {isSVGClicked && <CustomSVGs svg={filledCheckboxSVG} />}
              </div>
            )}
          </div>
        </div>

        <div className='blurAndCopyInput-validation-text'>
          {onlyOnFocus && title}
        </div>
      </>
    );
  }
);

export default BlurAndCopyInput;
