import '../../styles/pages/plugin/pluginAuthPage.scss';
import pluginProcessImg from '../../imgs/plugin-process.png';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { cmsSVG, shopSVG } from '../../lib/allCustomSVGs';
import store from '../../store/store';
import { useTranslation } from 'react-i18next';
import { asyncHandler } from '../../lib/api/async-handler';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { PLUGIN_PREAUTH_ENDPOINT } from '../../lib/constants/urls';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const PluginAuthPage = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  // const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shopURL = queryParams.get('shop_url');
  const sitename = queryParams.get('sitename');

  /* console.log(shopURL);
  console.log(sitename); */
  /* ----------------------------API CALL START---------------------------- */
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    //PLUGIN_PREAUTH_ENDPOINT query parameters hardcoded for testing
    url: `${PLUGIN_PREAUTH_ENDPOINT}?redirect_url=${shopURL}&site_name=${sitename} `,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_PLUGIN_DATA';
  const customMsg = 'Plugin Data Loaded Successfully.';

  const onClick = async () => {
    setIsLoading(true);
    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res?.data;

    if (res?.actionType === actionType && response?.success === true) {
      //do something with the response
      console.log(response);
      /* setIsLoading(false); */
      const redirect_url = response?.result?.redirect_url;
      window.location.href = redirect_url;
    } else {
      setIsLoading(false);
      console.log('error getting url, please try again!');
    }
  };

  /* ----------------------------API CALL END---------------------------- */

  return (
    <div className='pluginAuthPage'>
      <div className='pluginAuthPage__content'>
        <div className='pluginAuthPage__content--top'>
          <img src={pluginProcessImg} alt='plugin Process' />
          <h1>Authorize Example</h1>
        </div>
        <div className='pluginAuthPage__content--center'>
          {/* item 1 */}
          <div className='pluginAuthPage__content--center-item'>
            <div className='pluginAuthPage__content--center-item-icon'>
              <CustomSVGs svg={cmsSVG} />
            </div>
            <div className='pluginAuthPage__content--center-item-text'>
              <h2>Lorem, ipsum dolor sit.</h2>
              <h3>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</h3>
            </div>
          </div>
          {/* item 2 */}
          <div className='pluginAuthPage__content--center-item'>
            <div className='pluginAuthPage__content--center-item-icon'>
              <CustomSVGs svg={shopSVG} />
            </div>
            <div className='pluginAuthPage__content--center-item-text'>
              <h2>Lorem, ipsum dolor sit.</h2>
              <h3>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</h3>
            </div>
          </div>
          <div className='pluginAuthPage__content--center-divider'></div>
          <div
            onClick={onClick}
            className='pluginAuthPage__content--center-btn'
          >
            <h2>{isLoading ? 'Please wait...' : 'Authorize'}</h2>
          </div>
          <div className='pluginAuthPage__content--center-info'>
            <h2>Authorizing will redirect to</h2>
            <a href='#'>http://example.com</a>
          </div>
        </div>
        <div className='pluginAuthPage__content--bottom'>
          Learn more about Auth
        </div>
      </div>
    </div>
  );
};

export default PluginAuthPage;
