import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import store from '../../../store/store';
import '../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../ui/inputs/NormalInput';
import NormalSelectInput from '../../../ui/inputs/NormalSelectInput';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { WEBHOOK_ENDPOINT } from '../../../lib/constants/urls';
import { asyncHandler } from '../../../lib/api/async-handler';
import ToggleSwitch from '../../../ui/buttons/ToggleSwitch';
import NoData from '../../../ui/noData/NoData';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import { applicationJSONBodyBuilder } from '../../../lib/utils/body-builder';
import { useTranslation } from 'react-i18next';
import { submitLogToBugsnag } from '../../../lib/api/log';

const EditWebhook = () => {
  const navigate = useNavigate();
  const { webhookId } = useParams();
  const [loading, setLoading] = useState('pending');
  const [name, setName] = useState<string>(``);
  const [method, setMethod] = useState<string>(``);
  const [endpoint, setEndpoint] = useState<string>(``);
  const [status, setStatus] = useState<boolean>();
  const { t } = useTranslation();

  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const statusArr = [
    { OptionValue: 'active', OptionName: 'Active' },
    { OptionValue: 'inactive', OptionName: 'Inactive' },
  ];

  const methodOptions: any = [
    { OptionValue: 'GET', OptionName: 'GET' },
    { OptionValue: 'POST', OptionName: 'POST' },
    { OptionValue: 'PATCH', OptionName: 'PATCH' },
    { OptionValue: 'DELETE', OptionName: 'DELETE' },
  ];

  const isDisabled =
    endpoint === '' || name === '' || method === '' ? true : false;

  useEffect(() => {
    const data = getWebhookData();
    data
      .then((result) => {
        setName(result.name ?? '');
        setMethod(result.method ?? 'GET');
        setEndpoint(result.endpoint ?? '');
        setStatus(result.status ? Boolean(result.status) : false);
        setLoading('success');
      })
      .catch((err) => {
        console.error(err);
        setLoading('error');
        submitLogToBugsnag('error', `${err}`);
      });
  }, []);

  /* ----------------------------API CALL START---------------------------- */
  async function getWebhookData() {
    try {
      const requestInfo: RequestInfo = {
        url: `${WEBHOOK_ENDPOINT}/${webhookId}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_SINGLE_WEBHOOK';
      const customMsg = 'Webhook data loaded Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        return response.result;
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    try {
      const sendingData = {
        name,
        method,
        endpoint,
        status: status === true ? 1 : 0,
      };

      const requestInfo: RequestInfo = {
        url: `${WEBHOOK_ENDPOINT}/${webhookId}`,
        method: 'PATCH',
        body: applicationJSONBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'EDIT_WEBHOOK';
      const customMsg = t('WebhookUpdatedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        navigate('/settings/webhooks');
      }
    } catch (exception) {
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}
      {loading === 'error' && (
        <NoData
          title={t('ErrorFetchingData')}
          subTitle={t('SomethingWentWrong-msg')}
        />
      )}
      {loading === 'success' && (
        <form
          id='webhookEdit-form'
          onSubmit={onSubmitHandler}
          className='normalForm'
        >
          <div className='normalForm__top'>
            {/* <h1>Add the Following Information.</h1> */}

            <NormalInput
              changeListeners={[(e) => setName(e.target.value)]}
              id='edit-webhook-name'
              value={name}
              placeholder={t('Name')}
              required={true}
              pattern='^[A-Za-z0-9 ]{1,100}$'
              title={t('WebhookName-validation')}
            />

            <NormalSelectInput
              defaultValue={method}
              setSelect={setMethod}
              selectOptionArr={methodOptions}
              placeholder={t('Method')}
              id='add-webhook-method'
            />

            <NormalInput
              changeListeners={[(e) => setEndpoint(e.target.value)]}
              id='edit-webhook-endpoint'
              value={endpoint}
              placeholder={t('Endpoint')}
              innerPlaceholder='https://www.example.com'
              required={true}
              // pattern='^(https?:\/\/)?[0-9a-zA-Z]+\.[-_0-9a-zA-Z]+\.[0-9a-zA-Z]+$'
              // title='Expected URL format: https://www.example.com'
            />
            <br />
            <ToggleSwitch
              isOn={status}
              title={t('Status')}
              onClick={setStatus}
            />
          </div>

          <div className='normalForm__bottom'>
            <MainSquareButton
              value={t('Edit')}
              /* icon={<CustomSVGs svg={plusSignSVG} />} */
              disabled={isDisabled}
              type='submit'
              form='webhookEdit-form'
            />
          </div>
        </form>
      )}
    </>
  );
};

export default EditWebhook;
