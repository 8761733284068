import '../../styles/pages/orders/orderDetailPage.scss';
import { useParams } from 'react-router-dom';
import CustomTable from '../../ui/customTables/CustomTable';
import { useState, useEffect } from 'react';

//api call imports
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import store from '../../store/store';
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../ui/noData/NoData';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import SubPageInnerContentHolder from '../../components/subPageContentHolder/SubPageInnerContentHolder';
import { useTranslation } from 'react-i18next';
import formatDate from '../../lib/helper-functions/formatDate';
import makeFirstLetterCapital from '../../lib/helper-functions/makeFirstLetterCapital';
import timeSince from '../../lib/helper-functions/timeSince';
import { ORDERS_API } from '../../lib/constants/central-api';

const billData = {
  subTotal: '',
  shipping: '',
  discount: '',
  grandTotal: '',
};

const OrderDetailPage = () => {
  const { orderId } = useParams();
  const [loading, setLoading] = useState('pending');
  const [mainOrderObj, setMainOrderObj] = useState<any>();
  const [cartItems, setCartItems] = useState<any>();
  const { t } = useTranslation();
  const columns = [
    {
      field: 'images',
      headerName: t('Image'),
      width: '10rem',
      isImageField: true,
    },
    {
      field: 'name',
      headerName: t('Name'),
      width: '20rem',
    },
    {
      field: 'sku',
      headerName: 'SKU',
      width: '13rem',
    },
    {
      field: 'price',
      headerName: t('Price'),
      width: '10rem',
    },
    {
      field: 'quantity',
      headerName: t('Quantity'),
      width: '10rem',
    },
    {
      field: 'amount',
      headerName: t('Amount'),
      width: '10rem',
    },
  ];

  /* ----------------------------API CALL START---------------------------- */
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url: `${ORDERS_API}/${orderId}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_SINGLE_ORDER';
  const customMsg = 'Order Details Loaded Successfully.';

  useEffect(() => {
    const getOrder = async () => {
      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;

      if (res.actionType === actionType && response.success === true) {
        const mainOrder = response?.data;
        if (mainOrder) {
          setLoading('success');
          setMainOrderObj(mainOrder);
          setCartItems(mainOrder?.order_items);
        } else {
          setLoading('null');
        }
      } else {
        setLoading('error');
      }
    };

    getOrder();
  }, []);
  /* ----------------------------API CALL END---------------------------- */

  const getAddress = (addressObj: any) => {
    const address = `${addressObj?.house_number}, ${addressObj?.street}, ${
      addressObj?.street2 || ''
    }, ${addressObj?.city}, ${addressObj?.province}, ${addressObj?.postcode}, ${
      addressObj?.country
    }`;
    return addressObj?.country ? address : '--- --- --- ---';
  };

  const getCountry = (addressObj: any) => {
    const country = `${addressObj?.country}`;
    return addressObj?.country ? country : '---';
  };
  const orderDetails = {
    dateTime: mainOrderObj?.created_at
      ? formatDate(mainOrderObj?.created_at)
      : '---',
    gateway: mainOrderObj?.payment_service_provider
      ? makeFirstLetterCapital(mainOrderObj?.payment_service_provider)
      : '---',
    method: mainOrderObj?.payment_method
      ? makeFirstLetterCapital(mainOrderObj?.payment_method)
      : '---',
    bank: '---',
    status: mainOrderObj?.status
      ? makeFirstLetterCapital(mainOrderObj?.status)
      : '---',
    cmsPost: '---',
  };

  const shopperDetails = {
    name: `${mainOrderObj?.shopper?.first_name || '---'} ${
      mainOrderObj?.shopper?.last_name || '---'
    }`,
    phone: `+${mainOrderObj?.shopper?.country_code || '---'} ${
      +mainOrderObj?.shopper?.contact || '---'
    }`,
    email: mainOrderObj?.shopper?.user?.email || '---',
    joinedAdup: mainOrderObj?.shopper?.created_at
      ? timeSince(mainOrderObj?.shopper?.created_at)
      : '---',
    verified: mainOrderObj?.shopper?.user?.email_verified_at
      ? t('Yes')
      : t('No'),
    orderPlaced: getCountry(mainOrderObj?.shipping_address),
    shippingAddress: getAddress(mainOrderObj?.shipping_address),
    billingAddress: getAddress(mainOrderObj?.billing_address),
  };

  const createProductTableData = () => {
    const sendingData = cartItems?.map((el: any) => {
      const tableItem = {
        images: '',
        name: '',
        sku: '',
        price: '',
        quantity: '',
        amount: '',
      };
      tableItem.quantity = el?.quantity;
      const currentProduct = el?.product;

      if (currentProduct) {
        tableItem.name = currentProduct?.name;
        tableItem.sku = currentProduct?.sku;
        tableItem.price = `${el?.price?.formatted}`;
        tableItem.amount = `${mainOrderObj?.subtotal?.formatted}`;
        tableItem.images =
          currentProduct?.images?.length > 0 ? currentProduct?.images[0] : '';
      }
      return tableItem;
    });

    return sendingData;
  };

  if (mainOrderObj) {
    billData.subTotal =
      typeof mainOrderObj?.subtotal?.major === 'number'
        ? mainOrderObj?.subtotal?.formatted
        : '---';
    billData.discount =
      typeof mainOrderObj?.discount_amount?.major === 'number'
        ? mainOrderObj?.discount_amount?.formatted
        : '---';
    billData.shipping =
      typeof mainOrderObj?.shipping_cost?.major === 'number'
        ? mainOrderObj?.shipping_cost?.formatted
        : '---';
    billData.grandTotal =
      typeof mainOrderObj?.grand_total?.major === 'number'
        ? mainOrderObj?.grand_total?.formatted
        : '---';
  }

  return (
    <SubPageContentHolder>
      <SubPageInnerContentHolder
        title={`Order #${orderId}`}
        /* subTitle={'Manage Products'} */
        backBtnLink='/orders'
      >
        <div className='orderDetailPage'>
          <div className='orderDetailPage-backButton'></div>
          {loading === 'error' && (
            <NoData
              title={t('ErrorFetchingData')}
              subTitle={t('SomethingWentWrong-msg')}
            />
          )}
          {loading === 'pending' && <LoadingSpinner />}
          {loading === 'success' && (
            <>
              <div className='orderDetailPage__top'>
                <div className='orderDetailPage__top--left'>
                  <h1>{t('OrderDetails')}</h1>
                  <h2>
                    {t('Date & Time')}: <span>{orderDetails.dateTime}</span>
                  </h2>
                  <h2>
                    {t('Gateway')}: <span>{orderDetails.gateway}</span>
                  </h2>
                  <h2>
                    {t('Method')}: <span>{orderDetails.method}</span>
                  </h2>
                  {/* <h2>
                      Bank: <span>{orderDetails.bank}</span>
                    </h2> */}
                  <h2 className='orderDetailPage__top--center-status'>
                    {t('Status')}: <span>{orderDetails.status}</span>
                    {/* <div /> */}
                  </h2>
                  {/* <h2>
                      CMS post: <span>{orderDetails.cmsPost}</span>
                    </h2> */}
                </div>
                {/* center start*/}
                <div className='orderDetailPage__top--center'>
                  <h1>{t('Shopper details')}</h1>
                  <h2>{shopperDetails.name}</h2>
                  <h2>
                    {t('Phone')}: <span>{shopperDetails.phone}</span>
                  </h2>
                  <h2>
                    {t('Email')}: <span>{shopperDetails.email}</span>
                  </h2>
                  {/*  <h2>
                    {t('Joined adup')}:{' '}
                    <span>
                      {shopperDetails.joinedAdup} {t('ago')}
                    </span>
                  </h2> */}
                  <h2>
                    {t('Verified')}: <span>{shopperDetails.verified}</span>
                  </h2>
                  <h2>
                    {t('Order placed')}:{' '}
                    <span>
                      {t('from')} {shopperDetails.orderPlaced}
                    </span>
                  </h2>
                </div>
                {/* center end*/}
                <div className='orderDetailPage__top--right'>
                  <div className='orderDetailPage__top--right-shipping'>
                    <h1>{t('ShippingAddress')}</h1>
                    <span>{shopperDetails.shippingAddress}</span>
                  </div>

                  <div className='orderDetailPage__top--right-billing'>
                    <h1>{t('BillingAddress')}</h1>
                    <span>{shopperDetails.billingAddress}</span>
                  </div>
                </div>
              </div>
              <div className='orderDetailPage--variants-text'>{t('Order')}</div>
              <div className='orderDetailPage__bottom'>
                <CustomTable
                  headerData={columns}
                  rowData={createProductTableData() || []}
                  billData={billData}
                />
              </div>
            </>
          )}
        </div>
      </SubPageInnerContentHolder>
    </SubPageContentHolder>
  );
};

export default OrderDetailPage;
