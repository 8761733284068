import { useState, useEffect } from 'react';
import { plusSignSVG, saveSVG, trashSVG } from '../../../lib/allCustomSVGs';
import '../../../styles/pages/settings/settingsShippingAdd.scss';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import SquareGrayButton from '../../../ui/buttons/SquareGrayButton';
import NormalInput from '../../../ui/inputs/NormalInput';
import { AnimatePresence, motion } from 'framer-motion';
import store from '../../../store/store';
import { useTranslation } from 'react-i18next';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../lib/utils/body-builder';
import { asyncHandler } from '../../../lib/api/async-handler';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import { setMessage } from '../../../store/reducers/appSlice';
import MaskedCurrencyInput from '../../../ui/inputs/CurrencyInput';
import { CONVENIENCE_FEE_API } from '../../../lib/constants/central-api';
import { submitLogToBugsnag } from '../../../lib/api/log';

interface MethodConvenienceFeeInterface {
  id: number;
  method: string;
  rateType: 'percentage' | 'flat' | string;
  cost: string;
  savedOnServerFlag?: boolean;
}

interface Props {
  methodsConvenienceFee: MethodConvenienceFeeInterface[] | any[];
  setMethodsConvenienceFee: (
    input: MethodConvenienceFeeInterface[] | any[]
  ) => void;
}

const SettingsConvenienceFeeAdd = ({
  methodsConvenienceFee,
  setMethodsConvenienceFee,
}: Props) => {
  const { t } = useTranslation();

  const slug = store.getState().user?.company?.shop_slug;
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [selectOptionValues, setSelectOptionValues] = useState([
    {
      OptionValue: 'null',
      OptionName: `${t('SelectPaymentMethod')}`,
    },
  ]);

  const shippingTypes = [
    { OptionValue: 'null', OptionName: `${t('SelectRateType')}` },
    { OptionValue: 'percentage', OptionName: `${t('Percentage')}` },
    { OptionValue: 'flat', OptionName: `${t('Flat')}` },
  ];

  useEffect(() => {
    let convenienceFees: any = [];
    setIsLoading(true);
    const data = getConvenienceFee();
    data
      .then((result) => {
        const availableMethods = result?.available_payment_method as any[];
        const prices = result?.convenience_fee as any[];

        let methodOptions = selectOptionValues as any;

        if (availableMethods && availableMethods.length > 0) {
          availableMethods.forEach(({ method, label }) => {
            methodOptions.push({
              OptionValue: method,
              OptionName: label,
            });
          });
        }

        setSelectOptionValues(methodOptions);

        if (prices && prices.length > 0) {
          for (let i of prices) {
            let rate = {
              id: prices.indexOf(i),
              method: i?.method_name,
              rateType: i?.type,
              cost: i?.value,
              savedOnServerFlag: true,
            };
            convenienceFees.push(rate);
          }
          setMethodsConvenienceFee(convenienceFees);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
        submitLogToBugsnag('error', `${err}`);
      });
  }, []);

  const addBtnHandler = () => {
    if (methodsConvenienceFee.length === 0) {
      setMethodsConvenienceFee([{ id: 0, method: '', cost: '', rateType: '' }]);
    } else {
      setMethodsConvenienceFee([
        ...methodsConvenienceFee,
        {
          id: +methodsConvenienceFee?.length,
          method: '',
          cost: '',
          rateType: '',
        },
      ]);
    }
  };

  const deleteBtnHandler = (item: any) => {
    if (methodsConvenienceFee.length === 0) {
      return;
    } else if (item.savedOnServerFlag) {
      const updatedItemArr = methodsConvenienceFee;
      updatedItemArr[item?.id] = null;
      setMethodsConvenienceFee(updatedItemArr);
    } else {
      const updatedItemArr = methodsConvenienceFee.slice(0, -1);
      setMethodsConvenienceFee(updatedItemArr);
    }
  };

  /* ----------------------------API CALL START---------------------------- */
  async function addConvenienceFee(e: any) {
    try {
      if (!e?.rateType || e?.rateType === '' || e?.rateType === 'null') {
        store.dispatch(
          setMessage({
            message: `${t('EmptyRateType')}`,
            messageType: 'error',
          })
        );
      } else if (
        e?.rateType === 'percentage' &&
        e?.cost &&
        (Number.isNaN(+e?.cost) ||
          Number(e?.cost) < 0 ||
          Number(e?.cost) > 100 ||
          Number(e?.cost) === -0)
      ) {
        store.dispatch(
          setMessage({
            message: `${t('Percentage-validation')}`,
            messageType: 'error',
          })
        );
      } else {
        const requestInfo: RequestInfo = {
          url: CONVENIENCE_FEE_API(slug),
          method: 'POST',
          body: applicationWWWXRLEncodedBodyBuilder({
            method: e?.method,
            type: e?.rateType,
            value: e?.cost,
          }),
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/x-www-form-urlencoded',
            Authorization: accessToken.type + ' ' + sellerToken,
          },
        };

        const actionType = 'ADD_PSP_METHOD_CONVENIENCE_FEES';
        const customMsg = t('ConvenienceFeeAddedSuccess-msg');

        const res: any = await asyncHandler(requestInfo, actionType, customMsg);

        const response = res.data;

        const updatedItemArr = methodsConvenienceFee;
        updatedItemArr[e?.id] = {
          id: e.id,
          method: e?.method,
          cost: e?.cost,
          rateType: e?.rateType,
          savedOnServerFlag: true,
        };
        setMethodsConvenienceFee(updatedItemArr);

        if (res.actionType === actionType && response.success) {
          // setAddProductResponse(response?.result);
        }
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  async function deleteConvenienceFee(e: any) {
    try {
      const params = `/${e?.method}`;
      const requestInfo: RequestInfo = {
        url: `${CONVENIENCE_FEE_API(slug)}${params}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'DELETE_PSP_METHOD_CONVENIENCE_FEES';
      const customMsg = t('ConvenienceFeeDeletedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;
      deleteBtnHandler(e);

      if (res.actionType === actionType && response.success) {
        // setAddProductResponse(response?.result);
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  async function updateConvenienceFee(e: any) {
    try {
      if (!e?.rateType || e?.rateType === '' || e?.rateType === 'null') {
        store.dispatch(
          setMessage({
            message: `${t('EmptyRateType')}`,
            messageType: 'error',
          })
        );
      } else if (
        e?.rateType === 'percentage' &&
        e?.cost &&
        (Number.isNaN(+e?.cost) ||
          Number(e?.cost) < 0 ||
          Number(e?.cost) > 100 ||
          Number(e?.cost) === -0)
      ) {
        store.dispatch(
          setMessage({
            message: `${t('Percentage-validation')}`,
            messageType: 'error',
          })
        );
      } else {
        const params = `/${e?.method}`;
        const requestInfo: RequestInfo = {
          url: `${CONVENIENCE_FEE_API(slug)}${params}`,
          method: 'PUT',
          body: applicationWWWXRLEncodedBodyBuilder({
            type: e?.rateType,
            value: e?.cost,
          }),
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/x-www-form-urlencoded',
            Authorization: accessToken.type + ' ' + sellerToken,
          },
        };

        const actionType = 'UPDATE_PSP_METHOD_CONVENIENCE_FEES';
        const customMsg = t('ConvenienceFeeUpdatedSuccess-msg');

        const res: any = await asyncHandler(requestInfo, actionType, customMsg);

        const response = res.data;

        const updatedItemArr = methodsConvenienceFee;
        updatedItemArr[e?.id] = {
          id: e?.id,
          method: e?.method,
          cost: e?.cost,
          rateType: e?.rateType,
          savedOnServerFlag: true,
        };
        setMethodsConvenienceFee(updatedItemArr);

        if (res.actionType === actionType && response.success) {
          // setAddProductResponse(response?.result);
        }
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  async function getConvenienceFee() {
    try {
      const requestInfo: RequestInfo = {
        url: CONVENIENCE_FEE_API(slug),
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_PSP_METHOD_CONVENIENCE_FEES';
      const customMsg = 'Convenience Fees fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        return response.data;
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  const onChangeHandler = (
    value: any,
    id: any,
    inputType: 'method' | 'rateType' | 'cost'
  ) => {
    const changedInputIndex = methodsConvenienceFee.findIndex(
      (item) => item?.id === +id
    );
    const methodsConvenienceFeeCopy: any = methodsConvenienceFee;
    if (typeof changedInputIndex === 'number') {
      if (inputType === 'method') {
        methodsConvenienceFeeCopy[changedInputIndex].method = value;
        setMethodsConvenienceFee([...methodsConvenienceFeeCopy]);
      } else if (inputType === 'rateType') {
        methodsConvenienceFeeCopy[changedInputIndex].rateType = value;
        setMethodsConvenienceFee([...methodsConvenienceFeeCopy]);
      } else {
        methodsConvenienceFeeCopy[changedInputIndex].cost = value;
        setMethodsConvenienceFee([...methodsConvenienceFeeCopy]);
      }
    }
  };

  return (
    <div className='settingsShippingAdd'>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          <div className='settingsShippingAdd__bottom'>
            <AnimatePresence>
              {methodsConvenienceFee.map(
                (item: MethodConvenienceFeeInterface, i: number) => {
                  if (item == null) {
                    return;
                  } else
                    return (
                      <motion.div
                        initial={{ scale: 0.95, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.95, opacity: 0 }}
                        transition={{ type: 'spring' }}
                        key={i}
                        className='settingsShippingAdd__bottom--item'
                      >
                        <div className='settingsShippingAdd__bottom--item-inputs'>
                          <div className='settingsShippingAdd__bottom--item-inputs-country'>
                            {/* ---------SELECT INPUT START---------- */}
                            <div className='normalSelectInput-outer'>
                              <div className='normalSelectInput'>
                                <label htmlFor={item?.id.toString()}>
                                  {t('Method')}
                                </label>
                                <select
                                  className='w-full peer border border-solid border-[#dbdbdb] rounded-lg  p-2 pb-1 outline-none focus:outline-none focus:border-[#cecece] transition-all duration-500 hover:border-[#cecece]'
                                  onChange={(e) => {
                                    onChangeHandler(
                                      e?.target?.value,
                                      e?.target?.id,
                                      'method'
                                    );
                                  }}
                                  defaultValue={
                                    item.method && item.method !== ''
                                      ? item.method
                                      : 'null'
                                  }
                                  id={item?.id.toString()}
                                  disabled={item.savedOnServerFlag}
                                >
                                  {selectOptionValues.map((selectOption, i) => {
                                    return (
                                      <option
                                        key={i}
                                        value={selectOption.OptionValue}
                                        disabled={methodsConvenienceFee.find(
                                          (item) =>
                                            item?.method ===
                                            selectOption?.OptionValue
                                        )}
                                      >
                                        {selectOption.OptionName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className='normalSelectInput-validation-text'></div>
                            </div>
                            {/* ---------SELECT INPUT END------------ */}
                          </div>

                          <div className='settingsShippingAdd__bottom--item-inputs-country'>
                            {/* ---------SELECT INPUT START---------- */}
                            <div className='normalSelectInput-outer'>
                              <div className='normalSelectInput'>
                                <label htmlFor={item?.id.toString()}>
                                  {t('RateType')}
                                </label>
                                <select
                                  className='w-full peer border border-solid border-[#dbdbdb] rounded-lg  p-2 pb-1 outline-none focus:outline-none focus:border-[#cecece] transition-all duration-500 hover:border-[#cecece]'
                                  onChange={(e) => {
                                    onChangeHandler(
                                      e?.target?.value,
                                      e?.target?.id,
                                      'rateType'
                                    );
                                  }}
                                  defaultValue={
                                    item.rateType && item.rateType !== ''
                                      ? item.rateType
                                      : 'null'
                                  }
                                  id={item?.id.toString()}
                                >
                                  {shippingTypes.map((selectOption, i) => {
                                    return (
                                      <option
                                        key={i}
                                        value={selectOption.OptionValue}
                                      >
                                        {selectOption.OptionName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className='normalSelectInput-validation-text'></div>
                            </div>
                            {/* ---------SELECT INPUT END------------ */}
                          </div>

                          <div className='settingsShippingAdd__bottom--item-inputs-cost'>
                            {
                              item.rateType && item.rateType === 'percentage' && (
                                // ---------NORMAL INPUT START----------
                                <NormalInput
                                  changeListeners={[
                                    (e) =>
                                      onChangeHandler(
                                        e?.target?.value,
                                        e?.target?.id,
                                        'cost'
                                      ),
                                  ]}
                                  id={item?.id.toString()}
                                  value={item?.cost ?? 0.0}
                                  placeholder={t('Value')}
                                  required={true}
                                  pattern='^100(\.0{0,2})? *%?$|^[0-9]{1,2}(\.[0-9]{1,2})? *%?$'
                                  title={t('Percentage-validation')}
                                />
                              )
                              // ---------NORMAL INPUT END------------
                            }{' '}
                            {
                              item.rateType && item.rateType === 'flat' && (
                                // ---------CURRENCY INPUT START----------
                                <MaskedCurrencyInput
                                  changeListeners={[
                                    (value: any) =>
                                      onChangeHandler(value, item?.id, 'cost'),
                                  ]}
                                  id={item?.id.toString()}
                                  value={
                                    item?.cost && !Number.isNaN(item?.cost)
                                      ? item?.cost
                                      : '0'
                                  }
                                  placeholder={t('Value')}
                                  required={true}
                                  title={t('OnlyNumbers-validation')}
                                  type='text'
                                  pattern='^[\d|\.|\,]+/'
                                  defaultValue={
                                    item?.cost && !Number.isNaN(item?.cost)
                                      ? item?.cost
                                      : '0'
                                  }
                                />
                              )
                              // ---------CURRENCY INPUT END------------
                            }
                          </div>
                        </div>
                        <div className='settingsShippingAdd__bottom--item-btn-container'>
                          <div className='settingsShippingAdd__bottom--item-btn'>
                            <SquareGrayButton
                              onClick={(e) => {
                                e.preventDefault();
                                if (
                                  !item?.method ||
                                  item?.method === '' ||
                                  !item?.rateType ||
                                  item?.rateType === '' ||
                                  !item?.cost ||
                                  item?.cost === ''
                                ) {
                                  return;
                                } else {
                                  if (item.savedOnServerFlag) {
                                    updateConvenienceFee(item);
                                  } else {
                                    addConvenienceFee(item);
                                  }
                                }
                              }}
                              type={'button'}
                              icon={saveSVG}
                              normal={false}
                              disabled={
                                !item?.method ||
                                item?.method === '' ||
                                !item?.rateType ||
                                item?.rateType === '' ||
                                !item?.cost ||
                                item?.cost === ''
                              }
                              classes='squareGrayButton-add'
                            />
                          </div>
                          <div className='settingsShippingAdd__bottom--item-btn'>
                            <SquareGrayButton
                              onClick={(e) => {
                                e.preventDefault();
                                if (item.savedOnServerFlag) {
                                  deleteConvenienceFee(item);
                                } else {
                                  deleteBtnHandler(item);
                                }
                              }}
                              type={'button'}
                              icon={trashSVG}
                              normal={false}
                              /* value={(item + 1).toString()} */
                              classes='squareGrayButton-delete'
                            />
                          </div>
                        </div>
                      </motion.div>
                    );
                }
              )}
            </AnimatePresence>
          </div>
          <div className='settingsShippingAdd__top'>
            <h1> </h1>
            <MainSquareButton
              onClick={(e) => {
                e.preventDefault();
                addBtnHandler();
              }}
              type={'button'}
              value={t('AddPaymentMethod')}
              icon={plusSignSVG}
              normal={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SettingsConvenienceFeeAdd;
