import React, { useEffect, useState } from 'react';
import '../../../../styles/components/charts/chart.scss';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../store/store';
import get_graph_data_api_call from '../../../../lib/api/stats/get_graph_data_api_call';
import DeviceConversionRateBarChart from './DeviceConversionRateBarChart';
import SingleDeviceConversionRateBarChart from './SingleDeviceConversionRateBarChart';
import { useSelector } from 'react-redux';
import ChartWithConfig from '../../ChartWithConfig';

const MainDeviceBasedCRChart = () => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    '?graph_type=conversion_rate&time_range=all&group_by=device'
  );

  const [response, setResponse] = useState<any>({
    type: 'overallDevices',
    data: {},
  });

  //Time filter
  const [timeFilter, setTimeFilter] = useState<string>('all');
  const timeFilterOptionsArr = [
    { OptionValue: 'all', OptionName: t('All') },
    { OptionValue: 'last_24_hours', OptionName: t('Last24Hrs') },
    { OptionValue: 'last_7_days', OptionName: t('Last7Days') },
    { OptionValue: 'last_30_days', OptionName: t('Last30Days') },
    { OptionValue: '3_months', OptionName: t('Last3Months') },
  ];

  //Selected country
  const [selectedDevice, setSelectedDevice] = useState<string>('');

  //Comments and titles
  const [title, setTitle] = useState<string>(t('OverallConversionRate'));
  const [comment, setComment] = useState<string>('');

  const [isBackButtonVisible, setIsBackButtonVisible] =
    useState<boolean>(false);

  const renderCharts = () => {
    if (selectedDevice === '') {
      return (
        <DeviceConversionRateBarChart
          apiResponse={response}
          setSelectedDevice={setSelectedDevice}
        />
      );
    } else if (selectedDevice !== '') {
      return <SingleDeviceConversionRateBarChart apiResponse={response} />;
    }
  };

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        if (selectedDevice && selectedDevice !== '') {
          setResponse({ type: 'singleDevice', data: res });
        } else {
          setResponse({
            type: 'overallDevices',
            data: res,
          });
        }
      } else {
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    if (selectedDevice === '') {
      setTitle(t('DeviceBasedConversionRate'));
      setComment(t('TheConversionRateOfCartsToCheckoutsBasedOnDevice'));
    } else if (selectedDevice !== '') {
      setTitle(`${t('TheConversionRateOf')} ${selectedDevice}`);
      setComment(`${t('ConversionRateCartsToCheckoutsIn')} ${selectedDevice}`);
    }
  }, [selectedDevice]);

  useEffect(() => {
    setResponse({ type: 'load', data: [] });
    if (selectedDevice && selectedDevice !== '') {
      setParams(
        `?graph_type=conversion_rate&time_range=${timeFilter}&group_by=device&group_by_value=${selectedDevice}`
      );
    } else {
      setParams(
        `?graph_type=conversion_rate&time_range=${timeFilter}&group_by=device`
      );
    }
  }, [timeFilter, selectedDevice]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  const onBackButtonClick = () => {
    if (selectedDevice !== '') {
      setSelectedDevice('');
    }
  };

  useEffect(() => {
    if (selectedDevice !== '') {
      setIsBackButtonVisible(true);
    } else {
      setIsBackButtonVisible(false);
    }
  }, [selectedDevice]);

  return (
    <ChartWithConfig
      timeFilter={timeFilter}
      setTimeFilter={setTimeFilter}
      timeFilterOptionsArr={timeFilterOptionsArr}
      isBackButtonVisible={isBackButtonVisible}
      onBackButtonClick={onBackButtonClick}
      title={title}
      comment={comment}
    >
      {renderCharts()}
    </ChartWithConfig>
  );
};

export default MainDeviceBasedCRChart;
