import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import HeaderTheme2 from './HeaderTheme2';
import NewHeader from './NewHeader';

interface Props {
  toggleSidebarDrawer: any;
  isSidebarDrawerOpen: boolean;
}

const HeaderMain = ({ toggleSidebarDrawer, isSidebarDrawerOpen }: Props) => {
  const themeType = useSelector((state: RootState) => state.UI.theme.type);

  const renderHeader = () => {
    if (themeType === '2') {
      return (
        <HeaderTheme2
          isSidebarDrawerOpen={isSidebarDrawerOpen}
          toggleSidebarDrawer={toggleSidebarDrawer}
        />
      );
    }
    return (
      <NewHeader
        isSidebarDrawerOpen={isSidebarDrawerOpen}
        toggleSidebarDrawer={toggleSidebarDrawer}
      />
    );
  };

  return renderHeader();
};

export default HeaderMain;
