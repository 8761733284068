import { searchSVG } from '../../lib/allCustomSVGs';
import '../../styles/components/filter/filterTheme2.scss';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';

type Props = {
  categoryFilter?: { name: string; value: number | string }[];
  onCategoryFilterChange?: (filterName: string) => void;
  selectedFilterCategory?: string;
  sortingValues?: { name: string; value: number | string }[];
  onSortingValueChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedSortingValue?: string;
  onSearchFilterChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchFilterInputPlaceholder?: string;
};

const FilterTheme2 = ({
  categoryFilter = [],
  onCategoryFilterChange = () => {},
  selectedFilterCategory,
  onSearchFilterChange = () => {},
  searchFilterInputPlaceholder = '',
  sortingValues = [],
  onSortingValueChange = () => {},
  selectedSortingValue,
}: Props) => {
  return (
    <div className='filterTheme2'>
      {categoryFilter?.length > 0 && (
        <div className='filterTheme2__top'>
          {categoryFilter.map((filter, index) => (
            <span
              key={index}
              className={`filterTheme2__top--category ${
                selectedFilterCategory === filter?.name
                  ? 'filterTheme2__top--selected'
                  : ''
              }`}
              onClick={() => onCategoryFilterChange(filter?.name)}
            >
              {`${filter?.name} ${
                filter?.value === 'none' ? '' : `(${filter?.value})`
              }`}
            </span>
          ))}
          <span className='filterTheme2__top--hidden'></span>
        </div>
      )}
      <div className='filterTheme2__bottom'>
        <div className='filterTheme2__bottom--search'>
          <input
            onChange={onSearchFilterChange}
            placeholder={searchFilterInputPlaceholder}
            type='search'
          />
          <CustomSVGs svg={searchSVG} />
        </div>

        <select
          onChange={onSortingValueChange}
          value={selectedSortingValue}
          name='filterByTheme2'
          id='filterByTheme2'
        >
          {sortingValues?.map((filter, index) => (
            <option key={index} value={filter?.value}>
              {filter?.name}
            </option>
          ))}
          {/* <option value='date'>Filter by date range</option>
          <option value='name'>Filter by name</option>
          <option value='price'>Filter by price</option> */}
        </select>
      </div>
    </div>
  );
};

export default FilterTheme2;
