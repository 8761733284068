// import { useState } from 'react';
import '../../styles/inputs/colorInput.scss';
// import { SketchPicker } from 'react-color';
import Sketch from '@uiw/react-color-sketch';
import useComponentVisible from '../../customHooks/useComponentVisible';

interface Props {
  mainText: string;
  value: string;
  setValue: (input: any) => void;
}

const ColorInput = ({ mainText, value, setValue }: Props) => {
  /* const [innerValue, setValue] = useState<any>(value); */
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  /*  function handleChangeValue(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    changeListeners?.forEach((listener) => listener(event));
  } */

  const pickerStyles: any = {
    position: 'absolute',
    bottom: '1rem',
    left: '1rem',
  };
  /* const pickerStyles: any = {
    default: {
      picker: {
        position: 'absolute',
        bottom: '1rem',
        left: '1rem',
      },
    },
  }; */
  return (
    <div className='colorInput-outer'>
      <div className='colorInput-outer--label'>{mainText}</div>
      {/*  <input
        className='colorInput-outer--input'
        style={{ fontFamily: 'var(--main-font-secondary)' }}
        type='color'
        id='color'
        value={innerValue}
        onChange={handleChangeValue}
      ></input> */}
      <div
        style={{ backgroundColor: value }}
        className='colorInput-outer--colorView'
        onClick={() => setIsComponentVisible(true)}
      ></div>
      <div ref={ref}>
        {isComponentVisible && (
          <Sketch
            style={pickerStyles}
            color={value}
            onChange={(updatedColor) => {
              setValue(updatedColor.hex);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ColorInput;
