import '../../styles/inputs/imageInput.scss';
import React, { useEffect, useRef } from 'react';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { uploadSVG } from '../../lib/allCustomSVGs';
import csvFileIcon from '../../assets/csvFileIcon.png';
import { t } from 'i18next';

interface Props {
  mainText: string;
  subText: string;
  acceptedFileTypes: string;
  selectedFile: any;
  setSelectedFile: (para: any) => void;
  preview: any;
  setPreview: (para: any) => void;
  fileError: any;
  setFileError: (para: any) => void;
  id: string;
  manyImages: boolean;
  removeImage?: () => void;
  isFileUpload?: boolean;
  isVideoAllowed?: boolean;
  onClose?: () => void;
}

const ImageInput = ({
  mainText,
  subText,
  acceptedFileTypes,
  selectedFile,
  setSelectedFile,
  preview,
  setPreview,
  fileError,
  setFileError,
  id,
  manyImages,
  removeImage,
  isFileUpload,
  isVideoAllowed,
  onClose,
}: Props) => {
  /* const [preview, setPreview] = useState<string>();
  const [fileError, setFileError] = useState(false); */

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    if (isFileUpload) {
      setPreview(csvFileIcon);
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(csvFileIcon);
    } else {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // Using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
    setFileError(false);
  };

  //drag and drop code
  const mainContainerRef = useRef<HTMLDivElement>(null);

  const onFileDropHandler = (e: any) => {
    e.preventDefault();
    if (mainContainerRef.current) {
      mainContainerRef.current.classList.remove('imageInput-outer-dragOver');
    }
    const files = e.dataTransfer.files;
    if (!files || files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // Using the first image instead of multiple
    setSelectedFile(files[0]);
    setFileError(false);
  };

  const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    /* e.stopPropagation(); */
    if (mainContainerRef.current) {
      mainContainerRef.current.classList.add('imageInput-outer-dragOver');
    }
  };

  const onDragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (mainContainerRef.current) {
      mainContainerRef.current.classList.remove('imageInput-outer-dragOver');
    }
  };

  return (
    <div
      onDrop={onFileDropHandler}
      onDragOver={onDragOverHandler}
      onDragLeave={onDragLeaveHandler}
      ref={mainContainerRef}
      className='imageInput-outer'
    >
      <div className='imageInput-outer--label'>{mainText}</div>
      {onClose && (
        <div className='imageInput-outer--close' onClick={onClose}>
          {'╳'}
        </div>
      )}
      <div className='imageInput'>
        <div
          title={
            isFileUpload
              ? t('ClickToUploadFile')
              : isVideoAllowed
              ? t('ClickToUploadImageVideo')
              : t('ClickToUploadImage')
          }
          className='imageInput__top'
        >
          <div>
            <label htmlFor={id}>
              {preview &&
              isVideoAllowed &&
              ((selectedFile && selectedFile?.type?.includes('video')) ||
                (!selectedFile && preview?.includes('.mp4'))) ? (
                <video autoPlay muted loop src={preview} />
              ) : preview ? (
                <img src={preview} alt={id} />
              ) : (
                <div>
                  <CustomSVGs svg={uploadSVG} />
                </div>
              )}
            </label>
            <input
              id={id}
              type='file'
              name='logo'
              style={{ fontFamily: 'var(--main-font-secondary)' }}
              multiple={manyImages}
              onChange={onSelectFile}
              accept={acceptedFileTypes}
            />
          </div>
        </div>
        <div className='imageInput__bottom'>
          {selectedFile?.name && isFileUpload && (
            <span>{selectedFile?.name}</span>
          )}
          {!(selectedFile?.name && isFileUpload) && (
            <>
              <p data-image-error={fileError?.toString()}>
                {isFileUpload
                  ? t('SelectFile')
                  : manyImages
                  ? t('SelectImages')
                  : isVideoAllowed
                  ? t('SelectImageVideo')
                  : t('SelectImage')}
              </p>
              <span>{subText}</span>
            </>
          )}
          {preview && (
            <a onClick={removeImage}>
              {isFileUpload
                ? t('RemoveFile')
                : isVideoAllowed
                ? t('RemoveImageVideo')
                : t('RemoveImage')}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageInput;
