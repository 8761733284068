import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AverageCartValueLineChart from './AverageCartValueLineChart';
import ChartWithConfig from '../../../../../components/charts/ChartWithConfig';
import { averageCartValueDummyData } from '../../../../../lib/constants/graphDummyData/averageCartValueDummyData';

const MainAverageCartValueChart = () => {
  const { t } = useTranslation();

  //Group by options
  const [groupBy, setGroupBy] = useState<string>('last_3_months');
  const groupByOptionsArr = [
    { OptionValue: 'last_7_days', OptionName: t('Last7Days') },
    { OptionValue: 'last_4_weeks', OptionName: t('Last4Weeks') },
    { OptionValue: 'last_3_months', OptionName: t('Last3Months') },
  ];

  const [title, setTitle] = useState<string>(t('AverageCartValue'));
  const [comment, setComment] = useState<string>(
    t('AverageCartValueLast3MonthsComment')
  );

  useEffect(() => {
    if (groupBy === 'last_3_months') {
      setComment(t('AverageCartValueLast3MonthsComment'));
    } else if (groupBy === 'last_4_weeks') {
      setComment(t('AverageCartValueLast4WeeksComment'));
    } else if (groupBy === 'last_7_days') {
      setComment(t('AverageCartValueLast7DaysComment'));
    }
  }, [groupBy]);

  return (
    <ChartWithConfig
      groupBy={groupBy}
      setGroupBy={setGroupBy}
      groupByOptionsArr={groupByOptionsArr}
      title={title}
      comment={comment}
    >
      <AverageCartValueLineChart
        apiResponse={averageCartValueDummyData}
        groupBy={groupBy}
      />
    </ChartWithConfig>
  );
};

export default MainAverageCartValueChart;
