import { useLocation } from 'react-router-dom';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';

const PSPOptions = () => {
  const location = useLocation();
  const activeLink = location.pathname;
  return (
    <SubPageInnerContentHolder
      title='PSP Options'
      subTitle='Manage PSP Options'
      backBtnLink='/shop'
      /* mainBtnIcon={plusSignSVG}
              mainBtnTitle='Create'
              mainBtnLink='/shop/integrations/autofill/add' */
    >
      {activeLink === '/settings/payments/options' && <div>PSP Options</div>}

      {/* <Routes>
                <Route path='/add' element={<AutofillAdd />} />
              </Routes> */}
    </SubPageInnerContentHolder>
  );
};

export default PSPOptions;
