import store from '../../store/store';
import '../../styles/components/subPageContentHolder/subPageContentHolder.scss';

/* interface Props {
  title: string;
  subTitle: string;
} */

const SubPageContentHolder: React.FC = ({ children }) => {
  const themeType = store.getState().UI.theme.type;
  return (
    <div
      data-sub-page-content-theme={themeType}
      className='subPageContentHolder'
    >
      {children}
    </div>
  );
};

export default SubPageContentHolder;
