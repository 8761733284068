import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { useState, useEffect } from 'react';
import '../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import ImageInput from '../../../ui/inputs/ImageInput';
import ColorInput from '../../../ui/inputs/ColorInput';
import store, { RootState } from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../lib/api/async-handler';
import NoData from '../../../ui/noData/NoData';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NormalSelectInput from '../../../ui/inputs/NormalSelectInput';
import colorNameToHex from '../../../lib/helper-functions/colorNameToHex';
import { setUserCompanySettings } from '../../../store/reducers/userSlice';
import { SHOP_API } from '../../../lib/constants/central-api';
// import { applicationWWWXRLEncodedBodyBuilder } from '../../../lib/utils/body-builder';
import { submitLogToBugsnag } from '../../../lib/api/log';
import { HOME_ROUTE } from '../../../lib/constants/route';

const ShopSettingsDesign = () => {
  const { t } = useTranslation();
  const staticURL = useSelector((state: RootState) => state.app.staticURL);

  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const slug = store.getState().user?.company?.shop_slug;

  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const [shopThemeColor, setShopThemeColor] = useState<string>(
    colorNameToHex('#f6f6f6')
  );
  const [backgroundColor, setBackgroundColor] = useState<string>(
    colorNameToHex('#f6f6f6')
  );
  const [fontColor, setFontColor] = useState<string>(colorNameToHex('#000000'));

  const [checkoutBackgroundColor, setCheckoutBackgroundColor] =
    useState<string>(colorNameToHex('#ffffff'));
  //checkout_background_color
  //Image upload state

  //Company Logo
  const [selectedLogo, setSelectedLogo] = useState();
  const [isSelectedLogoChanged, setIsSelectedLogoChanged] = useState(false);
  const [previewLogo, setPreviewLogo] = useState<string>();
  const [fileErrorLogo, setFileErrorLogo] = useState(false);
  const [serverLogo, setServerLogo] = useState();

  //Company Favi Icon
  const [selectedIcon, setSelectedIcon] = useState();
  const [isSelectedIconChanged, setIsSelectedIconChanged] = useState(false);
  const [previewIcon, setPreviewIcon] = useState<string>();
  const [fileErrorIcon, setFileErrorIcon] = useState(false);
  const [serverIcon, setServerIcon] = useState();

  //Background Image
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState();
  const [
    isSelectedBackgroundImageChanged,
    setIsSelectedBackgroundImageChanged,
  ] = useState(false);
  const [previewBackgroundImage, setPreviewBackgroundImage] =
    useState<string>();
  const [fileErrorBackgroundImage, setFileErrorBackgroundImage] =
    useState(false);
  const [serverBackgroundImage, setServerBackgroundImage] = useState();

  const cartOptionsArr = [
    { OptionValue: 'null', OptionName: t('FullCart') },
    { OptionValue: 'compact', OptionName: t('CompactCart') },
  ];
  const headerOptionsArr = [
    { OptionValue: 'default', OptionName: t('Default') },
    { OptionValue: 'fixed', OptionName: t('Fixed') },
  ];
  const imageRatioOptionsArr = [
    { OptionValue: '1:1 (square)', OptionName: `1:1 (${t('Square')})` },
    { OptionValue: '16:9 (wide)', OptionName: `16:9 (${t('Wide')})` },
    { OptionValue: '9:16 (tall)', OptionName: `9:16 (${t('Tall')})` },
  ];

  const [cartOption, setCartOption] = useState<string>('null');
  const [header, setHeader] = useState<string>('default');
  const [imageRatio, setImageRatio] = useState<string>('1:1 (square)');

  const isDisabled = false;
  const [loading, setLoading] = useState('pending');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (slug && slug !== '') {
      const response = getDesignSettings();
      response
        .then((result) => {
          result?.background_color &&
            setBackgroundColor(colorNameToHex(result?.background_color));

          result?.main_brand_color &&
            setShopThemeColor(colorNameToHex(result?.main_brand_color));

          result?.font_color &&
            setFontColor(colorNameToHex(result?.font_color));

          result?.checkout_background_color &&
            setCheckoutBackgroundColor(
              colorNameToHex(result?.checkout_background_color)
            );

          result?.merchant_logo_light !== '' &&
            result?.merchant_logo_light !== null &&
            setServerLogo(result?.merchant_logo_light);

          result?.merchant_icon !== '' &&
            result?.merchant_icon !== null &&
            setServerIcon(result?.merchant_icon);

          result?.background_image !== '' &&
            result?.background_image !== null &&
            setServerBackgroundImage(result?.background_image);

          result?.merchant_logo_light !== '' &&
            result?.merchant_logo_light !== null &&
            setPreviewLogo(result?.merchant_logo_light);

          result?.merchant_icon !== '' &&
            result?.merchant_icon !== null &&
            setPreviewIcon(result?.merchant_icon);

          result?.background_image !== '' &&
            result?.background_image !== null &&
            setPreviewBackgroundImage(result?.background_image);

          if (
            result?.fascheckout_ui === 'null' ||
            result?.fascheckout_ui === '' ||
            result?.fascheckout_ui === null
          ) {
            setCartOption('null');
          } else {
            setCartOption('compact');
          }

          result?.header_position && setHeader(result?.header_position);

          result?.product_image_ratio &&
            setImageRatio(result?.product_image_ratio);

          setLoading('success');
        })
        .catch((err) => {
          console.error(err);
          submitLogToBugsnag('error', `${err}`);
          setLoading('error');
        });
    }
  }, [slug]);

  /* ----------------------------API CALL START---------------------------- */
  async function getDesignSettings() {
    try {
      const requestInfo: RequestInfo = {
        url: SHOP_API + `/${slug}/settings?filter[group]=design`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_DESIGN_SETTINGS';
      const customMsg = 'Design Settings fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        let data: any = {};
        response?.data?.forEach((item: any) => {
          data[item.key] = item.value === null ? '' : item.value;
        });
        return data;
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  async function onSubmitHandler(e: any) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formData: any = new FormData();
      formData.append(`_method`, 'put');
      formData.append('group', 'design');
      formData.append('background_color', backgroundColor);
      formData.append('main_brand_color', shopThemeColor);
      formData.append('font_color', fontColor);
      formData.append('checkout_background_color', checkoutBackgroundColor);
      formData.append('header_position', header);
      formData.append('product_image_ratio', imageRatio);
      formData.append('theme', 'classic');
      formData.append('fascheckout_ui', cartOption);

      isSelectedBackgroundImageChanged &&
        formData.append('background_image', selectedBackgroundImage);

      isSelectedLogoChanged &&
        formData.append('merchant_logo_light', selectedLogo);

      isSelectedIconChanged && formData.append('merchant_icon', selectedIcon);

      /*         const sendingData = {
        group: 'design',
        background_color: backgroundColor,
        main_brand_color: shopThemeColor,
        font_color: fontColor,
        checkout_background_color: checkoutBackgroundColor,
        header_position: header,
        product_image_ratio: imageRatio,
        theme: 'classic',
        fascheckout_ui: cartOption,
        background_image:
          selectedBackgroundImage ?? serverBackgroundImage ?? '',
        merchant_logo_light: selectedLogo ?? serverLogo ?? '',
        merchant_icon: selectedIcon ?? serverIcon ?? '',
      };  */

      const requestInfo: RequestInfo = {
        url: SHOP_API + `/${slug}/settings`,
        method: 'POST',
        // body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        body: formData,
        headers: {
          Accept: 'application/json',
          // 'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'UPDATE_DESIGN_SETTINGS';
      const customMsg = t('SettingsUpdatedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      setIsSubmitting(false);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        setIsSelectedLogoChanged(false);
        setIsSelectedIconChanged(false);
        setIsSelectedBackgroundImageChanged(false);
        store.dispatch(setUserCompanySettings(response?.result));
        return response.result;
      }
    } catch (exception) {
      console.log('Error during authentication request');
      setIsSubmitting(false);
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  return (
    <SubPageInnerContentHolder
      title={t('ShopAppearanceSettings')}
      subTitle={t('ManageAppearanceSettings')}
      backBtnLink={isWhiteLabel ? undefined : HOME_ROUTE}
    >
      <>
        {loading === 'pending' && <LoadingSpinner />}
        {loading === 'error' && (
          <NoData
            title={t('ErrorFetchingData')}
            subTitle={t('SomethingWentWrong-msg')}
          />
        )}
        {loading === 'success' && (
          <form
            id='design-settings-form'
            onSubmit={onSubmitHandler}
            className='normalForm'
          >
            <div className='normalForm__top'>
              {/* ------------------------------------------------------- */}
              <h2>{t('GeneralAppearance')}</h2>
              <hr />
              {/* <div className='normalForm__top--dividerLine' /> */}
              <div className='normalForm__top--container1'>
                <div className='normalForm__top--container1-left'>
                  <ImageInput
                    mainText={t('UploadCompanyLogo')}
                    subText={`${t('AcceptedFileTypes')} (.png, .jpeg)`}
                    acceptedFileTypes={'.png, .jpeg'}
                    id={'design-settings-form-logo'}
                    selectedFile={selectedLogo}
                    setSelectedFile={(file: any) => {
                      setSelectedLogo(file);
                      setIsSelectedLogoChanged(true);
                    }}
                    preview={previewLogo}
                    setPreview={selectedLogo ? setPreviewLogo : () => {}}
                    fileError={fileErrorLogo}
                    setFileError={setFileErrorLogo}
                    manyImages={false}
                    removeImage={() => {
                      setSelectedLogo(undefined);
                      setPreviewLogo(undefined);
                      setServerLogo(undefined);
                      setIsSelectedLogoChanged(true);
                    }}
                  />
                </div>
                <div className='normalForm__top--container1-center'>
                  <ImageInput
                    mainText={t('UploadCompanyIcon')}
                    subText={`${t('AcceptedFileTypes')} (.ico, .png)`}
                    acceptedFileTypes={'.ico, .png'}
                    id={'design-settings-form-icon'}
                    selectedFile={selectedIcon}
                    setSelectedFile={(file: any) => {
                      setSelectedIcon(file);
                      setIsSelectedIconChanged(true);
                    }}
                    preview={previewIcon}
                    setPreview={selectedIcon ? setPreviewIcon : () => {}}
                    fileError={fileErrorIcon}
                    setFileError={setFileErrorIcon}
                    manyImages={false}
                    removeImage={() => {
                      setSelectedIcon(undefined);
                      setPreviewIcon(undefined);
                      setServerIcon(undefined);
                      setIsSelectedIconChanged(true);
                    }}
                  />
                </div>
                <div className='normalForm__top--container1-right'>
                  <ImageInput
                    isVideoAllowed={true}
                    mainText={t('UploadBackgroundImageVideo')}
                    subText={`${t(
                      'AcceptedFileTypes'
                    )} (.png, .jpeg, .mp4, .gif, .webp)`}
                    acceptedFileTypes={'.png, .jpeg, .mp4, .gif, .webp'}
                    id={'design-settings-form-bg'}
                    selectedFile={selectedBackgroundImage}
                    setSelectedFile={(file: any) => {
                      setSelectedBackgroundImage(file);
                      setIsSelectedBackgroundImageChanged(true);
                    }}
                    preview={previewBackgroundImage}
                    setPreview={
                      selectedBackgroundImage
                        ? setPreviewBackgroundImage
                        : () => {}
                    }
                    fileError={fileErrorBackgroundImage}
                    setFileError={setFileErrorBackgroundImage}
                    manyImages={false}
                    removeImage={() => {
                      setSelectedBackgroundImage(undefined);
                      setPreviewBackgroundImage(undefined);
                      setServerBackgroundImage(undefined);
                      setIsSelectedBackgroundImageChanged(true);
                    }}
                  />
                </div>
              </div>
              {/* ------------------------------------------------------- */}
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <ColorInput
                    mainText={t('BackgroundColor')}
                    setValue={setBackgroundColor}
                    value={backgroundColor}
                  />
                </div>
                <div className='normalForm__container1--right'>
                  <ColorInput
                    mainText={t('ShopThemeColor')}
                    setValue={setShopThemeColor}
                    value={shopThemeColor}
                  />
                </div>
              </div>
              {/* ------------------------------------------------------- */}
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <ColorInput
                    mainText={t('FontColor')}
                    setValue={setFontColor}
                    value={fontColor}
                  />
                </div>
                <div className='normalForm__container1--right'>
                  <ColorInput
                    mainText={t('CheckoutBackgroundColor')}
                    setValue={setCheckoutBackgroundColor}
                    value={checkoutBackgroundColor}
                  />
                </div>
              </div>
            </div>

            <div className='normalForm__center'>
              <h2>{t('FastCheckoutAppearance')}</h2>
              <hr />

              {/* ------------------------------------------------------- */}
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <NormalSelectInput
                    defaultValue={cartOption}
                    setSelect={setCartOption}
                    selectOptionArr={cartOptionsArr}
                    placeholder={t('CartOrientation')}
                    id={'design-settings-form-cartOption'}
                  />
                </div>
                <div className='normalForm__container1--right'>
                  <NormalSelectInput
                    defaultValue={header}
                    setSelect={setHeader}
                    selectOptionArr={headerOptionsArr}
                    placeholder={t('HeaderPosition')}
                    id={'design-settings-form-headerPosition'}
                  />
                </div>
              </div>

              {/* ------------------------------------------------------- */}
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <NormalSelectInput
                    defaultValue={imageRatio}
                    setSelect={setImageRatio}
                    selectOptionArr={imageRatioOptionsArr}
                    placeholder={t('ProductImageRatio')}
                    id={'design-settings-form-productImageRatio'}
                  />
                </div>
              </div>
              {/* ------------------------------------------------------- */}
            </div>

            <div className='normalForm__bottom flex-start'>
              <MainSquareButton
                value={
                  isSubmitting ? `${t('Updating')}...` : t('UpdateSettings')
                }
                /* icon={<CustomSVGs svg={plusSignSVG} />} */
                disabled={isDisabled || isSubmitting}
                type='submit'
                form='design-settings-form'
              />
            </div>
          </form>
        )}
      </>
    </SubPageInnerContentHolder>
  );
};
export default ShopSettingsDesign;
