import { t } from 'i18next';

interface InputData {
  name: string;
  type: string;
  label: string;
  default: string;
  is_active: boolean;
  is_required: boolean;
  is_hidden?: boolean;

  //? Add new CMS types here
  woocommerce?: string;
  lightspeed?: string;
}

const paymentStatusInputData: InputData[] = [
  {
    name: 'statusCompletePaymentTrackingMechanism',
    type: 'text',
    label: t('TrackingMechanismStatus', {
      status: 'Complete',
    }),
    default: '',
    is_active: false,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusFailedPaymentTrackingMechanism',
    type: 'text',
    label: t('TrackingMechanismStatus', {
      status: 'Failed',
    }),
    default: '',
    is_active: false,
    is_required: false,
    is_hidden: true,
  },
  {
    name: 'statusExpiredPaymentTrackingMechanism',
    type: 'text',
    label: t('TrackingMechanismStatus', {
      status: 'Expired',
    }),
    default: '',
    is_active: false,
    is_required: false,
    is_hidden: true,
  },
  {
    name: 'statusCancelPaymentTrackingMechanism',
    type: 'text',
    label: t('TrackingMechanismStatus', {
      status: 'Cancel',
    }),
    default: '',
    is_active: false,
    is_required: false,
    is_hidden: true,
  },
  {
    name: 'statusCompletePaymentCSSElement',
    type: 'text',
    label: t('PaymentCSSElement', {
      status: 'Complete',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCompletePaymentPreDefinedText',
    type: 'text',
    label: t('PaymentPreDefinedText', {
      status: 'Complete',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCompletePaymentURLparam',
    type: 'text',
    label: t('PaymentURLparam', {
      status: 'Complete',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCompletePaymentUniqueURL',
    type: 'text',
    label: t('PaymentUniqueURL', {
      status: 'Complete',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusFailedPaymentCSSElement',
    type: 'text',
    label: t('PaymentCSSElement', {
      status: 'Failed',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusFailedPaymentPreDefinedText',
    type: 'text',
    label: t('PaymentPreDefinedText', {
      status: 'Failed',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusFailedPaymentURLparam',
    type: 'text',
    label: t('PaymentURLparam', {
      status: 'Failed',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusFailedPaymentUniqueURL',
    type: 'text',
    label: t('PaymentUniqueURL', {
      status: 'Failed',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusExpiredPaymentCSSElement',
    type: 'text',
    label: t('PaymentCSSElement', {
      status: 'Expired',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusExpiredPaymentPreDefinedText',
    type: 'text',
    label: t('PaymentPreDefinedText', {
      status: 'Expired',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusExpiredPaymentURLparam',
    type: 'text',
    label: t('PaymentURLparam', {
      status: 'Expired',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusExpiredPaymentUniqueURL',
    type: 'text',
    label: t('PaymentUniqueURL', {
      status: 'Expired',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCancelPaymentCSSElement',
    type: 'text',
    label: t('PaymentCSSElement', {
      status: 'Cancel',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCancelPaymentPreDefinedText',
    type: 'text',
    label: t('PaymentPreDefinedText', {
      status: 'Cancel',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCancelPaymentURLparam',
    type: 'text',
    label: t('PaymentURLparam', {
      status: 'Cancel',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
  {
    name: 'statusCancelPaymentUniqueURL',
    type: 'text',
    label: t('PaymentUniqueURL', {
      status: 'Cancel',
    }),
    default: '',
    is_active: true,
    is_required: true,
    is_hidden: true,
  },
];

export const initialGeneralInputData: InputData[] = [
  {
    name: 'bodyElement',
    type: 'text',
    label: 'Page Body',
    default: '',
    woocommerce: 'body',
    lightspeed: 'body',
    is_active: true,
    is_required: false,
  },
  {
    name: 'headerElement',
    type: 'text',
    label: 'Header',
    default: '',
    woocommerce: 'header',
    lightspeed: 'header',
    is_active: true,
    is_required: false,
  },
  {
    name: 'bannerElement',
    type: 'text',
    label: 'Banner',
    default: '',
    woocommerce: '.site-banner',
    lightspeed: '.header-banner',
    is_active: true,
    is_required: false,
  },
  {
    name: 'paymentPageIdentifierElement',
    type: 'text',
    label: 'Payment Page Unique Identifier',
    default: '',
    woocommerce: '.woocommerce-checkout-payment',
    lightspeed: '.checkout-payment',
    is_active: true,
    is_required: false,
  },
  {
    name: 'orderReceivedPageIdentifierElement',
    type: 'text',
    label: 'Order Received Page Unique Identifier',
    default: '',
    woocommerce: '.woocommerce-order-received',
    lightspeed: '.order-confirmation',
    is_active: true,
    is_required: false,
  },
  {
    name: 'profilePageIdentifierElement',
    type: 'text',
    label: 'Profile Page Unique Identifier',
    default: '',
    woocommerce: '.woocommerce-account',
    lightspeed: '.account-profile',
    is_active: true,
    is_required: false,
  },
  {
    name: 'favoritesPageIdentifierElement',
    type: 'text',
    label: 'Favorites Page Unique Identifier',
    default: '',
    woocommerce: '.woocommerce-wishlist',
    lightspeed: '.wishlist',
    is_active: true,
    is_required: false,
  },
  {
    name: 'comparePageIdentifierElement',
    type: 'text',
    label: 'Compare Page Unique Identifier',
    default: '',
    woocommerce: '.woocommerce-compare',
    lightspeed: '.compare-products',
    is_active: true,
    is_required: false,
  },
  {
    name: 'customerReviewsPageIdentifierElement',
    type: 'text',
    label: 'Customer Reviews Page Unique Identifier',
    default: '',
    woocommerce: '.woocommerce-reviews',
    lightspeed: '.customer-reviews',
    is_active: true,
    is_required: false,
  },
];

export const initialProductInputData: InputData[] = [
  {
    name: 'productPageIdentifierElement',
    type: 'text',
    label: 'Product Page Unique Identifier',
    default: '',
    woocommerce: '.single-product',
    lightspeed: '.product-detail',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productNameElement',
    type: 'text',
    label: 'Product Name',
    default: '',
    woocommerce: '.product_title',
    lightspeed: '.product-title',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productDescriptionElement',
    type: 'text',
    label: 'Product Description',
    default: '',
    woocommerce: '.woocommerce-product-details__short-description',
    lightspeed: '.product-description',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productImageCarouselElement',
    type: 'text',
    label: 'Product Image Carousel',
    default: '',
    woocommerce: '.woocommerce-product-gallery__wrapper',
    lightspeed: '.product-gallery',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productMainImageElement',
    type: 'text',
    label: 'Product Main Image',
    default: '',
    woocommerce: '.woocommerce-product-gallery__image img',
    lightspeed: '.product-main-image img',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productSizeElement',
    type: 'text',
    label: 'Product Size',
    default: '',
    woocommerce: '.product-size',
    lightspeed: '.product-size',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productTagsElement',
    type: 'text',
    label: 'Product Tags',
    default: '',
    woocommerce: '.tagged_as',
    lightspeed: '.product-tags',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productPriceElement',
    type: 'text',
    label: 'Product Price',
    default: '',
    woocommerce: '.woocommerce-Price-amount',
    lightspeed: '.product-price',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productQuantityElement',
    type: 'text',
    label: 'Product QTY',
    default: '',
    woocommerce: '.quantity input.qty',
    lightspeed: '.product-quantity input.qty',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productSaleElement',
    type: 'text',
    label: 'Product Sale / Discount',
    default: '',
    woocommerce: '.onsale',
    lightspeed: '.product-sale',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productShippingElement',
    type: 'text',
    label: 'Shipping Cost',
    default: '',
    woocommerce: '.shipping',
    lightspeed: '.product-shipping',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productDeliveryElement',
    type: 'text',
    label: 'Delivery Date & Time',
    default: '',
    woocommerce: '.delivery-date',
    lightspeed: '.product-delivery',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productVariantsElement',
    type: 'text',
    label: 'Product Variants',
    default: '',
    woocommerce: '.variations',
    lightspeed: '.product-variants',
    is_active: true,
    is_required: false,
  },
  {
    name: 'customerReviewsButtonElement',
    type: 'text',
    label: 'Customer Reviews Button',
    default: '',
    woocommerce: '.woocommerce-review-link',
    lightspeed: '.customer-reviews-button',
    is_active: true,
    is_required: false,
  },
];

export const initialCatalogAndCartInputData: InputData[] = [
  {
    name: 'catalogPageIdentifierElement',
    type: 'text',
    label: 'Catalog Page Unique Identifier',
    default: '',
    woocommerce: '.archive',
    lightspeed: '.catalog-page',
    is_active: true,
    is_required: false,
  },
  {
    name: 'cartPageIdentifierElement',
    type: 'text',
    label: 'Cart Page Unique Identifier',
    default: '',
    woocommerce: '.woocommerce-cart',
    lightspeed: '.cart-page',
    is_active: true,
    is_required: false,
  },
  {
    name: 'addToCartButtonElement',
    type: 'text',
    label: 'Add to Cart Button',
    default: '',
    woocommerce: '.single_add_to_cart_button',
    lightspeed: '.add-to-cart-button',
    is_active: true,
    is_required: false,
  },
  {
    name: 'removeFromCartButtonElement',
    type: 'text',
    label: 'Remove from Cart Button',
    default: '',
    woocommerce: '.remove',
    lightspeed: '.remove-from-cart-button',
    is_active: true,
    is_required: false,
  },
  {
    name: 'productViewButtonElement',
    type: 'text',
    label: 'Product View Button',
    default: '',
    woocommerce: '.view-product',
    lightspeed: '.view-product-button',
    is_active: true,
    is_required: false,
  },
  {
    name: 'viewCartButtonElement',
    type: 'text',
    label: 'View Cart Button',
    default: '',
    woocommerce: '.view-cart',
    lightspeed: '.view-cart-button',
    is_active: true,
    is_required: false,
  },
  {
    name: 'goToCheckoutButtonElement',
    type: 'text',
    label: 'Go to Checkout Button',
    default: '',
    woocommerce: '.checkout-button',
    lightspeed: '.go-to-checkout-button',
    is_active: true,
    is_required: false,
  },
  {
    name: 'addToFavoritesButtonElement',
    type: 'text',
    label: 'Add to Favorites Button',
    default: '',
    woocommerce: '.add_to_wishlist',
    lightspeed: '.add-to-favorites-button',
    is_active: true,
    is_required: false,
  },
  {
    name: 'removeFromFavoritesButtonElement',
    type: 'text',
    label: 'Remove from Favorites Button',
    default: '',
    woocommerce: '.remove_from_wishlist',
    lightspeed: '.remove-from-favorites-button',
    is_active: true,
    is_required: false,
  },
  {
    name: 'addToCompareButtonElement',
    type: 'text',
    label: 'Add to Compare Button',
    default: '',
    woocommerce: '.compare',
    lightspeed: '.add-to-compare-button',
    is_active: true,
    is_required: false,
  },
];

export const initialCheckoutInputData: InputData[] = [
  {
    name: 'checkoutPageIdentifierElement',
    type: 'text',
    label: 'Checkout Page Unique Identifier',
    default: '',
    woocommerce: '.woocommerce-checkout',
    lightspeed: '.checkout-page',
    is_active: true,
    is_required: false,
  },
  {
    name: 'cartTotalElement',
    type: 'text',
    label: 'Cart Total',
    default: '',
    woocommerce: '.cart-subtotal .woocommerce-Price-amount',
    lightspeed: '.cart-total',
    is_active: true,
    is_required: false,
  },
  {
    name: 'cartCountElement',
    type: 'text',
    label: 'Cart Items Count',
    default: '',
    woocommerce: '.cart-contents .count',
    lightspeed: '.cart-items-count',
    is_active: true,
    is_required: false,
  },
  {
    name: 'checkoutFormElement',
    type: 'text',
    label: 'Checkout Form',
    default: '',
    woocommerce: '.checkout.woocommerce-checkout',
    lightspeed: '.checkout-form',
    is_active: true,
    is_required: false,
  },
  {
    name: 'payButtonElement',
    type: 'text',
    label: 'Pay Button',
    default: '',
    woocommerce: '#place_order',
    lightspeed: '#pay-button',
    is_active: true,
    is_required: false,
  },
  {
    name: 'selectedPaymentMethodElement',
    type: 'text',
    label: 'Selected Payment Method',
    default: '',
    woocommerce: '.payment_methods input:checked',
    lightspeed: '.selected-payment-method',
    is_active: true,
    is_required: false,
  },
  {
    name: 'currencyElement',
    type: 'text',
    label: 'Currency',
    default: '',
    woocommerce: '.woocommerce-Price-currencySymbol',
    lightspeed: '.currency-symbol',
    is_active: true,
    is_required: false,
  },
  {
    name: 'countryCodeInputElement',
    type: 'text',
    label: 'Country Code Input',
    default: '',
    woocommerce: '#billing_country',
    lightspeed: '#country-code-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'phoneNumberInputElement',
    type: 'text',
    label: 'Phone Number Input',
    default: '',
    woocommerce: '#billing_phone',
    lightspeed: '#phone-number-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'firstNameInputElement',
    type: 'text',
    label: 'First Name Input',
    default: '',
    woocommerce: '#billing_first_name',
    lightspeed: '#first-name-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'lastNameInputElement',
    type: 'text',
    label: 'Last Name Input',
    default: '',
    woocommerce: '#billing_last_name',
    lightspeed: '#last-name-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'emailInputElement',
    type: 'text',
    label: 'Email Input',
    default: '',
    woocommerce: '#billing_email',
    lightspeed: '#email-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingHouseNoInputElement',
    type: 'text',
    label: 'Billing House No Input',
    default: '',
    woocommerce: '#billing_address_1',
    lightspeed: '#billing-house-no-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingStreetInputElement',
    type: 'text',
    label: 'Billing Street Input',
    default: '',
    woocommerce: '#billing_address_2',
    lightspeed: '#billing-street-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingStreet2InputElement',
    type: 'text',
    label: 'Billing Street 2 Input',
    default: '',
    woocommerce: '#billing_address_2',
    lightspeed: '#billing-street2-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingCityInputElement',
    type: 'text',
    label: 'Billing City Input',
    default: '',
    woocommerce: '#billing_city',
    lightspeed: '#billing-city-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingProvinceInputElement',
    type: 'text',
    label: 'Billing Province Input',
    default: '',
    woocommerce: '#billing_state',
    lightspeed: '#billing-province-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingPostalCodeInputElement',
    type: 'text',
    label: 'Billing Postal Code Input',
    default: '',
    woocommerce: '#billing_postcode',
    lightspeed: '#billing-postal-code-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'billingCountryInputElement',
    type: 'text',
    label: 'Billing Country Input',
    default: '',
    woocommerce: '#billing_country',
    lightspeed: '#billing-country-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingHouseNoInputElement',
    type: 'text',
    label: 'Shipping House No Input',
    default: '',
    woocommerce: '#shipping_address_1',
    lightspeed: '#shipping-house-no-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingStreetInputElement',
    type: 'text',
    label: 'Shipping Street Input',
    default: '',
    woocommerce: '#shipping_address_2',
    lightspeed: '#shipping-street-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingStreet2InputElement',
    type: 'text',
    label: 'Shipping Street 2 Input',
    default: '',
    woocommerce: '#shipping_address_2',
    lightspeed: '#shipping-street2-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingCityInputElement',
    type: 'text',
    label: 'Shipping City Input',
    default: '',
    woocommerce: '#shipping_city',
    lightspeed: '#shipping-city-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingProvinceInputElement',
    type: 'text',
    label: 'Shipping Province Input',
    default: '',
    woocommerce: '#shipping_state',
    lightspeed: '#shipping-province-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingPostalCodeInputElement',
    type: 'text',
    label: 'Shipping Postal Code Input',
    default: '',
    woocommerce: '#shipping_postcode',
    lightspeed: '#shipping-postal-code-input',
    is_active: true,
    is_required: false,
  },
  {
    name: 'shippingCountryInputElement',
    type: 'text',
    label: 'Shipping Country Input',
    default: '',
    woocommerce: '#shipping_country',
    lightspeed: '#shipping-country-input',
    is_active: true,
    is_required: false,
  },
];

export const initialInputData: InputData[] = [
  //* Attaching the general input data
  ...initialGeneralInputData,
  //* Attaching the product input data
  ...initialProductInputData,
  //* Attaching the catalog and cart input data
  ...initialCatalogAndCartInputData,
  //* Attaching the checkout input data
  ...initialCheckoutInputData,
  //* Attaching the payment status input data
  ...paymentStatusInputData,
];

export function transformInputData(inputArray: InputData[]): {
  [key: string]: string;
} {
  // Check if the array is empty or undefined
  if (!inputArray?.length) {
    return {};
  }

  // Create the resulting object
  const result: { [key: string]: string } = {};

  // Iterate over each item in the array
  inputArray.forEach((item) => {
    result[item.name] = item.default;
  });

  return result;
}

//--------------------usage------------------------
// const transformedObject = transformInputData(initialInputData);
// console.log(transformedObject);
// Output:
// {
//     bodyElement: '',
//     productNameElement: '',
//     productPriceElement: '',
//     productDescriptionElement: '',
//     productQuantityElement: '',
//     productSaleElement: '',
//     productShippingElement: '',
//     productDeliveryElement: '',
//     productVariantsElement: '',
//     cartTotalElement: '',
//     checkoutFormElement: '',
//     payButtonElement: ''
// }

export function prefillInputDataWithDefaultConfigsForCMS(cmsType: string): {
  [key: string]: string;
} {
  // Check if the array is empty or undefined
  if (!initialInputData?.length) {
    return {};
  }

  // Create the resulting object
  const result: { [key: string]: string } = {};

  // Iterate over each item in the array
  initialInputData.forEach((item: any) => {
    result[item.name] = item?.[cmsType] || item.default;
  });

  return result;
}
