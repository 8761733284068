import { asyncHandler } from '../../async-handler';
import store from '../../../../store/store';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { GET_SUBSCRIPTION_API } from '../../../constants/central-api';

export default async function get_subscriptions_api_call() {
  const shopSlug = store.getState().user?.company?.shop_slug;
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url: `${GET_SUBSCRIPTION_API}/${shopSlug}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_SUBSCRIPTIONS';
  const customMsg = 'Subscription data fetched successfully';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;
  if (!response?.success) {
    return false;
  }
  if (res?.actionType === actionType && response?.success === true) {
    if (response?.data === null) {
      return null;
    } else {
      return response?.data;
    }
  }
}
