import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import '../../styles/inputs/otpCodeInput.scss';
import { submitLogToBugsnag } from '../../lib/api/log';

interface OTPCodeInputProps {
  numberOfFields: number;
  parseActivationCode?: () => void;
  onComplete?: (otpValue: string) => void;
}

export const OTPCodeInput: React.FC<OTPCodeInputProps> = ({
  numberOfFields,
  parseActivationCode,
  onComplete,
}) => {
  const [fields, setFields] = useState<JSX.Element[]>([]);

  const [otpValue, setOtpValue] = useState<string>('');

  const invitationCodeDigits = useRef<HTMLDivElement | null>(null);

  /**
   *
   * @param {KeyboardEvent} event
   */
  function handleBackspaceDigit(event: KeyboardEvent) {
    if (event.code === 'Backspace') {
      if (otpValue.length === 0) {
        setOtpValue(() => '');
      } else {
        setOtpValue((value) => value.substring(0, value.length - 1));
      }
    }
  }

  /**
   *
   * @param {any} event
   */
  function validateCodeDigit(event: any) {
    if (event.nativeEvent?.data) {
      try {
        const intValue = event.nativeEvent.data;
        if (otpValue.length < numberOfFields) {
          setOtpValue((val) => val + intValue);
        }
      } catch (exception) {
        console.log(exception);
        submitLogToBugsnag('error', `${exception}`);
      }
    }
  }

  /**
   * @param {KeyboardEvent} event
   */
  function checkPasteFromClipboard(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
      navigator.clipboard.readText().then((clipText) => {
        if (clipText.length === numberOfFields) {
          try {
            parseInt(clipText);

            setOtpValue((val) => clipText);
          } catch (exception) {
            console.log('invalid data sent from clipboard');
            submitLogToBugsnag(
              'error',
              `invalid data sent from clipboard : ${exception}`
            );
          }
        }
      });
    }
  }

  useEffect(() => {
    // send a request to the server when the OTP is completed
    if (otpValue.length === numberOfFields) {
      if (onComplete) onComplete(otpValue);
    }
  }, [otpValue]);

  useEffect(() => {
    const startBox = document
      .getElementsByClassName('otp-input-field-box')
      .item(0);
    if (startBox)
      (startBox as HTMLInputElement).setAttribute('autofocus', 'true');
  }, []);

  useEffect(() => {
    const inputFields: JSX.Element[] = [];

    for (let idx = 0; idx < numberOfFields; idx++) {
      inputFields.push(
        <input
          className='otp-input-field-box light-gray-border'
          style={{ fontFamily: 'var(--main-font-secondary)' }}
          key={idx}
          autoFocus={idx === 0 ? true : false}
          type='number'
          inputMode='numeric'
          max={9}
          min={0}
          //   disabled={otpValue.length === numberOfFields}
          onKeyDown={(e) => {
            handleBackspaceDigit(e);
            checkPasteFromClipboard(e);
          }}
          onChange={(e: ChangeEvent) => validateCodeDigit(e)}
          value={otpValue.charAt(idx)}
        ></input>
      );
    }

    setFields(() => inputFields);
  }, [otpValue]);

  useEffect(() => {
    // console.log(otpValue);
    if (invitationCodeDigits?.current?.children) {
      for (
        let idx = 0;
        idx < invitationCodeDigits.current.children.length;
        idx++
      ) {
        if (
          otpValue.charAt(idx) &&
          invitationCodeDigits.current.children.item(idx + 1)
        ) {
          (
            invitationCodeDigits.current.children.item(
              idx + 1
            ) as HTMLInputElement
          ).focus();
        } else if (otpValue.charAt(idx)) {
          (
            invitationCodeDigits.current.children.item(idx) as HTMLInputElement
          ).focus();
        }
        if (otpValue === '') {
          (
            invitationCodeDigits.current.children.item(0) as HTMLInputElement
          ).focus();
        }
      }
    }
  }, [otpValue]);

  return (
    <div className='otp-inputs-holder' ref={invitationCodeDigits}>
      {fields.map((field) => field)}
    </div>
  );
};
