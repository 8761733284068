import { asyncHandler } from '../../async-handler';
import store from '../../../../store/store';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { MAKE_SUBSCRIPTION_API } from '../../../constants/central-api';

export default async function make_subscriptions_api_call(
  isTrial: 'true' | 'false',
  amount: number,
  showMsg = true
) {
  const shopSlug = store.getState().user?.company?.shop_slug;
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const formData: any = new FormData();
  formData.append(`subscription_type`, 'ai-tracking-recommanation-service');
  formData.append('package', 'pay-what-you-want');
  formData.append('payment_method', 'creditcard');
  formData.append('amount', amount);
  formData.append('trial_active', isTrial);

  const requestInfo: RequestInfo = {
    url: `${MAKE_SUBSCRIPTION_API}/${shopSlug}`,
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'MAKE_SUBSCRIPTIONS';
  const customMsg = 'Subscription activated successfully';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    showMsg
  );

  const response = res?.data;
  if (!response?.success) {
    return false;
  }
  if (res?.actionType === actionType && response?.success === true) {
    if (response?.data === null) {
      return null;
    } else {
      return response?.data;
    }
  }
}
