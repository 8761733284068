import React, { useState } from 'react';
import '../../styles/inputs/aiURLInput.scss';

type Props = {
  id: string;
  value: string;
  prefix: string;
  placeholder: string;
  label: string;
  changeListeners: ((event: React.ChangeEvent<HTMLInputElement>) => void)[];
  pattern?: string;
  title?: string;
  required?: boolean;
};

const AIURLInput: React.FC<Props> = ({
  id,
  value,
  prefix,
  placeholder,
  label,
  changeListeners,
  required,
  pattern,
  title,
}) => {
  const [focused, setFocused] = useState<boolean>(false);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
  };

  return (
    <div className='aiURLInput-outer'>
      <label htmlFor={id}>{label}</label>
      <div className='aiURLInput'>
        <div className='aiURLInput-prefix'>
          <span>{prefix}</span>
        </div>
        <div className='aiURLInput-input'>
          <input
            id={id}
            type='text'
            value={value}
            placeholder={placeholder}
            required={required}
            onBlur={handleBlur}
            data-focused={focused.toString()}
            pattern={pattern}
            title={title}
            style={{ fontFamily: 'var(--main-font-secondary)' }}
            onChange={(event) => {
              for (const listener of changeListeners) {
                listener(event);
              }
            }}
          />
        </div>
      </div>
      {/* <div className='aiURLInput-validation-text'>{onlyOnFocus && title}</div> */}
    </div>
  );
};

export default AIURLInput;

//aiURLInput
