import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { arrowRightThinSVG } from '../../lib/allCustomSVGs';
import '../../styles/components/sidebar/sidebarTheme1.scss';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface Items {
  icon: any;
  iconType?: string;
  title: string;
  link: string;
  urlName: string;
  specialURL?: string;
  level?: number;
}
interface Props {
  mainSubTitle: string;
  items: Items[];
}

const SidebarTheme1 = ({ mainSubTitle, items }: Props) => {
  const userCompany = useSelector((state: RootState) => state.user.company);
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const [renderingItems, setRenderingItems] = useState<Items[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const selectedPathObj = items.find((item) => item.link === currentPath);

    const selectedPathLevel = selectedPathObj?.level;

    if (selectedPathLevel === 1) {
      const level_1_Items = items?.filter(
        (item) => item.level === selectedPathLevel
      );
      setRenderingItems(level_1_Items);
    } else if (selectedPathLevel && selectedPathLevel > 1) {
      const otherItems = items?.filter(
        (item) => item.level === selectedPathLevel
      );
      const selectedLinkSplitArr = selectedPathObj.link.split('/');
      const selectedLinkCommonPath =
        selectedLinkSplitArr[selectedLinkSplitArr.length - 2];

      const result = otherItems?.filter((item) => {
        const otherLinkSplitArr = item.link.split('/');
        const otherLinkCommonPath =
          otherLinkSplitArr[otherLinkSplitArr.length - 2];
        if (otherLinkCommonPath === selectedLinkCommonPath) {
          return item;
        } else return null;
      });

      setRenderingItems(result);
    } else {
      const otherLinkSplitArr = currentPath.split('/');
      const otherLinkSplitArrLength = otherLinkSplitArr.length;
      if (otherLinkSplitArrLength > 1) {
        if (otherLinkSplitArr[otherLinkSplitArrLength - 1] === 'add') {
          const selectedLink = [...otherLinkSplitArr].slice(0, -1).join('/');
          console.log(selectedLink);
        } else if (
          otherLinkSplitArr[otherLinkSplitArrLength - 2] === 'edit' ||
          otherLinkSplitArr[otherLinkSplitArrLength - 2] === 'view' ||
          otherLinkSplitArr[otherLinkSplitArrLength - 2] === 'delete'
        ) {
          const selectedLink = [...otherLinkSplitArr].slice(0, -3).join('/');
          console.log(selectedLink);
        }
      }
    }
  }, [currentPath, items]);

  return (
    <div className='sidebarTheme1'>
      <div className='sidebarTheme1__top'>
        <div className='sidebarTheme1__top--left'>
          <img
            src={
              userCompany?.settings?.company_logo &&
              userCompany?.settings?.company_logo !== '' &&
              userCompany?.settings?.company_logo !== null
                ? `${staticURL}/medium/${userCompany?.settings?.company_logo}`
                : `${staticURL}/small/default.jpg`
            }
            alt='pic'
          />
        </div>
        <div className='sidebarTheme1__top--right'>
          <h2>{userCompany.name}</h2>
        </div>
      </div>
      <ul className='sidebarTheme1__bottom'>
        <AnimatePresence>
          {renderingItems?.map((item, i) => {
            return (
              <motion.li
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, ease: 'easeOut' }}
                // transition={{ type: 'spring', damping: 20, stiffness: 150 }}
                onClick={() => {
                  navigate(item.link);
                }}
                data-sub_page_link_holder={
                  item.specialURL
                    ? currentPath === item.specialURL
                    : currentPath.includes(item.urlName)
                    ? 'true'
                    : 'false'
                }
                key={i}
                className='sidebarTheme1__bottom--linkItem'
              >
                <div className='sidebarTheme1__bottom--linkItem-left'>
                  {item?.iconType === 'svg' ? (
                    <CustomSVGs svg={item.icon} />
                  ) : (
                    <img
                      src={item.icon}
                      className='subPageLinkHolderItems__img'
                      alt='icon'
                    />
                  )}
                  <span>{t(item.title)}</span>
                </div>
                <div className='sidebarTheme1__bottom--linkItem-right'>
                  <CustomSVGs svg={arrowRightThinSVG} />
                </div>
              </motion.li>
            );
          })}
        </AnimatePresence>
      </ul>
    </div>
  );
};

export default SidebarTheme1;
