import { useEffect, useState } from 'react';
import '../../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../../ui/inputs/NormalInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import NormalSelectInput from '../../../../ui/inputs/NormalSelectInput';
import { useNavigate } from 'react-router-dom';
import { updateIntercom } from '../../../../store/reducers/userSlice';
import { useTranslation } from 'react-i18next';
import makeFirstLetterCapital from '../../../../lib/helper-functions/makeFirstLetterCapital';
import createSelectOptionValueObject from '../../../../lib/helper-functions/createSelectOptionValueObject';
import {
  getCustomInputFields,
  getExtraCustomInputFields,
} from '../../../../lib/helper-functions/getCustomInputFields';
import add_cms_api_call from '../../../../lib/api/cms/add-cms';
import ToggleSwitch from '../../../../ui/buttons/ToggleSwitch';

const AddCMS = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );

  const [cmsInputs, setCMSInputs] = useState<string[]>([]);
  const [cmsInputValues, setCMSInputValues] = useState<any>();
  const [cmsExtraInputs, setCMSExtraInputs] = useState<any[]>([]);
  const [cmsExtraInputValues, setCMSExtraInputValues] = useState<any>();

  const shopId = useSelector((state: RootState) => state.user.company.shop_id);
  const options: any = useSelector(
    (state: RootState) => state.user.onboarding.availableCMS
  );

  const cmsOptions: any = createSelectOptionValueObject(options);

  const [selectedCMS, setSelectedCMS] = useState(options[0]);

  /* ================================================================================= */
  useEffect(() => {
    const [currentCMS_inputs_array, currentCMS_inputs_object] =
      getCustomInputFields(selectedCMS, onboarding_object?.available_cms);

    setCMSInputs(currentCMS_inputs_array);
    setCMSInputValues(currentCMS_inputs_object);

    ////////////////////////////////////////////////////////////////////////////////
    const [currentCMS_extra_inputs_array, currentCMS_extra_inputs_object] =
      getExtraCustomInputFields(selectedCMS, onboarding_object?.available_cms);

    setCMSExtraInputs(currentCMS_extra_inputs_array);
    setCMSExtraInputValues(currentCMS_extra_inputs_object);
  }, [selectedCMS]);
  /* ================================================================================= */

  async function onSubmitHandler(e: any) {
    e.preventDefault();

    const res: boolean = await add_cms_api_call(
      selectedCMS,
      cmsInputValues,
      cmsExtraInputValues
    );
    if (res) {
      dispatch(
        updateIntercom({
          cms: selectedCMS,
          cmsdatafilled: 'true',
        })
      );
      navigate('/integrations/cms/connection');
    }
  }

  let isDisabled;
  if (cmsInputValues) {
    isDisabled = Object.values(cmsInputValues)?.some((el: any) => el === '');
  }

  const onInputChange = (e: any, value: string) => {
    setCMSInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  const onToggleChange = (value: boolean, name: string) => {
    setCMSExtraInputValues((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <form id='CMSAdd-form' onSubmit={onSubmitHandler} className='normalForm'>
      <div className='normalForm__top'>
        <div className='normalForm__container3'>
          <div className='normalForm__container3--item'>
            <div className='normalForm__container3--item-content'>
              <NormalSelectInput
                defaultValue={selectedCMS}
                setSelect={setSelectedCMS}
                selectOptionArr={cmsOptions}
                placeholder={t('APIType')}
                id={'selected-cms-add'}
              />
            </div>
          </div>
          {cmsInputs?.map((el: string, index: number) => {
            return (
              <div className='normalForm__container3--item' key={index}>
                <div className='normalForm__container3--item-content'>
                  <NormalInput
                    changeListeners={[(e) => onInputChange(e, el)]}
                    id={`add-cms-integration-${el}`}
                    value={cmsInputValues ? cmsInputValues[el] : ''}
                    placeholder={makeFirstLetterCapital(
                      el.replaceAll('_', ' ')
                    )}
                    required={true}
                    autoFocus={index === 0 ? true : false}
                    classes={`add-cms-${selectedCMS}-${el}-input`}
                    labelClasses={`add-cms-${selectedCMS}-${el}-label`}
                  />
                </div>
              </div>
            );
          })}
          {cmsExtraInputs?.map((el: any, index: number) => {
            return (
              <div className='normalForm__container3--item' key={index}>
                <div className='normalForm__container3--item-content'>
                  {el?.type === 'toggle' ? (
                    <ToggleSwitch
                      message={t('PaymentMethodAlreadyAdded-msg')}
                      disabled={!el?.is_active}
                      key={el?.name}
                      id={el?.name}
                      isOn={
                        cmsExtraInputValues
                          ? cmsExtraInputValues[el?.name]
                          : el.default
                      }
                      title={el?.label}
                      onClick={(value: boolean) =>
                        onToggleChange(value, el.name)
                      }
                      classes='margin-top-2rem'
                    />
                  ) : (
                    <NormalInput
                      changeListeners={[(e) => onInputChange(e, el?.name)]}
                      id={`add-cms-integration-${el?.name}`}
                      value={cmsInputValues ? cmsInputValues[el?.name] : ''}
                      placeholder={el?.label}
                      required={el?.is_required ? true : false}
                      autoFocus={index === 0 ? true : false}
                      classes={`add-cms-${selectedCMS}-${el}-input`}
                      labelClasses={`add-cms-${selectedCMS}-${el}-label`}
                      disabled={el?.is_active ? false : true}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className='normalForm__bottom'>
        <MainSquareButton
          value={t('Add')}
          /* icon={<CustomSVGs svg={plusSignSVG} />} */
          disabled={isDisabled}
          type='submit'
          form='CMSAdd-form'
        />
      </div>
    </form>
  );
};

export default AddCMS;
