/**
 * Converts an array of strings into an object with custom keys.
 * Each key is initialized with an empty string.
 * @param array The input array of strings.
 * @returns An object with custom keys based on the array values.
 */
export default function arrayToObject(array: string[]): Record<string, string> {
  // Initialize an empty object to store the result
  const result: Record<string, string> = {};

  // Use the reduce method to create the object
  const outputObject = array.reduce((obj, value) => {
    // Spread the existing object and add a new key-value pair
    return { ...obj, [value]: '' };
  }, result);

  // Return the final object
  return outputObject;
}

/* 
Example usage:
const inputArray = ["shop_url", "customer_key", "customer_secret"];
const outputObject = arrayToObject(inputArray);

Log the output object
console.log(outputObject); => { "shop_url": "", "customer_key": "", "customer_secret": "" } 
*/
