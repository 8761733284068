import { VariantAttributesInterface } from '../initialStates/productPagesInitialState';

const generateVariants = (
  variantAttributes: VariantAttributesInterface[]
): string[][] => {
  const variantsArr = variantAttributes?.map(
    (item: VariantAttributesInterface) => {
      return item?.value;
    }
  );

  const variantsArrWithKey = variantAttributes?.map(
    (item: VariantAttributesInterface) => {
      let returningArr: string[] = [];
      if (item?.value?.length > 0) {
        returningArr = item?.value?.map((el: string) => {
          return `${item?.name}:${el}`;
        });
      }
      return returningArr;
    }
  );

  function getAllPossibleCases(arr: string[][]): any {
    if (arr.length === 1) {
      return arr[0];
    } else {
      let result = [];
      let allCasesOfRest = getAllPossibleCases(arr.slice(1)); // recur with the rest of array
      for (let i = 0; i < allCasesOfRest.length; i++) {
        for (let j = 0; j < arr[0].length; j++) {
          result.push(arr[0][j] + `~~${allCasesOfRest[i]}`);
        }
      }
      return result;
    }
  }

  let allPossibleCases;
  let allPossibleCasesWithKey;
  if (variantsArr.length === 0 || !variantsArr) {
    allPossibleCases = [];
    allPossibleCasesWithKey = [];
  } else {
    allPossibleCases = getAllPossibleCases(variantsArr);
    allPossibleCasesWithKey = getAllPossibleCases(variantsArrWithKey);
  }

  console.log(allPossibleCases);
  console.log(allPossibleCasesWithKey);

  return [allPossibleCases, allPossibleCasesWithKey];
};

export default generateVariants;
