import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { useEffect, useState } from 'react';
import '../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../ui/inputs/NormalInput';

/* ---------API Connection Imports------------- */
import { applicationJSONBodyBuilder } from '../../../lib/utils/body-builder';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../lib/api/async-handler';
import store from '../../../store/store';
import NoData from '../../../ui/noData/NoData';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import {
  DISCOUNTS_API,
  DISCOUNTS_TYPES_API,
} from '../../../lib/constants/central-api';
import { submitLogToBugsnag } from '../../../lib/api/log';
import SelectableButton from '../../../ui/buttons/SelectableButton';
import MaskedCurrencyInput from '../../../ui/inputs/CurrencyInput';
import { HOME_ROUTE } from '../../../lib/constants/route';

const SettingsDiscounts = () => {
  const { t } = useTranslation();

  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const slug = store.getState().user?.company?.shop_slug;

  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const [availableDiscountsTypes, setAvailableDiscountsTypes] = useState<any>();
  const [discountPercentage, setDiscountPercentage] = useState<string>('');
  const [minOrderTotal, setMinOrderTotal] = useState<string>('');
  const [loading, setLoading] = useState('pending');
  const [editingMode, setEditingMode] = useState<boolean>(false);
  const [editingId, setEditingId] = useState<number>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (slug && slug !== '') {
      const response = getAllDiscountsTypes();
      response
        .then((responseResult) => {
          //TODO rules & actions hardcoded as below until BE is fully functional
          if (responseResult?.order_total_min && responseResult?.percentage) {
            setAvailableDiscountsTypes(responseResult);
            const discounts = getAvailableDiscounts();
            discounts.then((discountsResult) => {
              if (
                discountsResult &&
                discountsResult?.id &&
                discountsResult?.rules &&
                discountsResult?.rules.length > 0 &&
                discountsResult?.actions &&
                discountsResult?.actions.length > 0
              ) {
                setEditingMode(true);
                setEditingId(discountsResult?.id);

                const orderTotalMinRule = discountsResult?.rules.find(
                  (rule: any) => rule.rule_type_slug === 'order_total_min'
                );
                setMinOrderTotal(orderTotalMinRule?.conditions?.value ?? '');

                const percentageAction = discountsResult?.actions.find(
                  (action: any) => action.action_type_slug === 'percentage'
                );
                setDiscountPercentage(
                  percentageAction?.parameters?.percentage ?? ''
                );
                setLoading('success');
              } else {
                setEditingMode(false);
                setLoading('success');
              }
            });
          } else {
            setLoading('error');
          }
        })
        .catch((err) => {
          console.error(err);
          submitLogToBugsnag('error', `${err}`);
          setLoading('error');
        });
    }
  }, [slug]);

  /* ----------------------------API CALL START---------------------------- */
  async function getAllDiscountsTypes() {
    try {
      const requestInfo: RequestInfo = {
        url: DISCOUNTS_TYPES_API,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_DISCOUNTS_TYPES';
      const customMsg = 'Discounts Types fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        let data: any = {};
        response?.data?.forEach((item: any) => {
          data[item.slug] = item.id ?? undefined;
        });
        return data;
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  async function getAvailableDiscounts() {
    try {
      const requestInfo: RequestInfo = {
        url: DISCOUNTS_API(slug),
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_SHOP_DISCOUNTS';
      const customMsg = 'Shop Discounts fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        //TODO only last discount is considered for now
        return response?.data?.data?.slice(-1)[0];
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const sendingData = {
        name: 'Order Total specific Discount',
        rules: [
          {
            type: availableDiscountsTypes?.order_total_min,
            conditions: {
              operator: '>=',
              value: minOrderTotal,
            },
          },
        ],
        actions: [
          {
            type: availableDiscountsTypes?.percentage,
            parameters: {
              percentage: discountPercentage,
            },
          },
        ],
      };

      const requestInfo: RequestInfo =
        editingMode && editingId
          ? {
              url: `${DISCOUNTS_API(slug)}/${editingId}`,
              method: 'PUT',
              body: applicationJSONBodyBuilder(sendingData),
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: accessToken.type + ' ' + sellerToken,
              },
            }
          : {
              url: DISCOUNTS_API(slug),
              method: 'POST',
              body: applicationJSONBodyBuilder(sendingData),
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: accessToken.type + ' ' + sellerToken,
              },
            };

      const actionType = 'UPDATE_DISCOUNTS_SETTINGS';
      const customMsg = t('SettingsUpdatedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      setIsSubmitting(false);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        return response.result;
      }
    } catch (exception) {
      console.log('Error during authentication request');
      setIsSubmitting(false);
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  const isDisabled =
    discountPercentage === '' || minOrderTotal === '' ? true : false;

  return (
    <SubPageInnerContentHolder
      title={t('DiscountSettings')}
      subTitle={t('ManageDiscountSettings')}
      backBtnLink={isWhiteLabel ? undefined : HOME_ROUTE}
    >
      <>
        {loading === 'pending' && <LoadingSpinner />}
        {loading === 'error' && (
          <NoData
            title={t('NoDiscountsAvailable')}
            subTitle={t('NoDiscountsAvailable-Sub')}
          />
        )}
        {loading === 'success' && (
          <form
            id='discounts-settings-form'
            onSubmit={onSubmitHandler}
            className='normalForm'
          >
            <div className='normalForm__top'>
              {/* ------------------------------- */}
              {/* //* Discount cost model Selection -- Start */}
              <div className='shopPage__title'>
                <h2>{t('DiscountModel')}</h2>
                <hr />
              </div>
              {/* <div className='shopPage__divider' /> */}
              <div className='shopPage__container1-alignLeft'>
                <SelectableButton
                  icon={''}
                  title={''}
                  subTitle={t('OrderTotalDiscount')}
                  subTitle2={t('OrderTotalDiscount-Sub')}
                  link=''
                  onSelect={() => {}}
                  isSelected={true}
                />
              </div>
              {/* //* Discount cost model Selection -- End */}

              {/* ------------------------------------------------------- */}
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <NormalInput
                    changeListeners={[
                      (e) => setDiscountPercentage(e?.target?.value),
                    ]}
                    id={availableDiscountsTypes?.percentage?.toString()}
                    value={discountPercentage ?? 0}
                    placeholder={t('DiscountPercentage')}
                    required={true}
                    pattern='^100(\.0{0,2})? *%?$|^[0-9]{1,2}(\.[0-9]{1,2})? *%?$'
                    title={t('Percentage-validation')}
                  />
                </div>
                <div className='normalForm__container1--right'>
                  <MaskedCurrencyInput
                    changeListeners={[(value) => setMinOrderTotal(value)]}
                    id={availableDiscountsTypes?.order_total_min?.toString()}
                    value={
                      minOrderTotal && !Number.isNaN(minOrderTotal)
                        ? minOrderTotal
                        : '0'
                    }
                    placeholder={t('MinOrderForDiscount')}
                    required={true}
                    title={t('OnlyNumbers-validation')}
                    type='text'
                    pattern='^[\d|\.|\,]+/'
                    defaultValue={
                      minOrderTotal && !Number.isNaN(minOrderTotal)
                        ? minOrderTotal
                        : '0'
                    }
                  />
                </div>
              </div>
            </div>

            <div className='normalForm__bottom flex-start'>
              <MainSquareButton
                value={
                  isSubmitting ? `${t('Updating')}...` : t('UpdateSettings')
                }
                /* icon={<CustomSVGs svg={plusSignSVG} />} */
                disabled={isDisabled || isSubmitting}
                type='submit'
                form='discounts-settings-form'
              />
            </div>
          </form>
        )}
      </>
    </SubPageInnerContentHolder>
  );
};

export default SettingsDiscounts;
