import { AI_CHECKOUT_TRACKING_LINK } from './central-api';

export const regularScriptCode = (apiKey: string) => {
  return `<!-- AI Checkout Tracking script -->
  <script defer>
    function loadAITrackingScript() {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;
      script.src = "${AI_CHECKOUT_TRACKING_LINK(
        apiKey
      )}&t=" + new Date().getTime();
      script.onload = function () {
       initializeAnalytics();
     };
     document.head.appendChild(script);
   }
   document.addEventListener("DOMContentLoaded",function(){const e=new Date,t=60*e.getHours()+e.getMinutes(),n=JSON.parse(localStorage.getItem("styleRecommendationsByAdUp"))||{};n&&"object"==typeof n&&Object.keys(n).forEach((e=>{const o=e.split("&t=")[0],c=e.split("&t=")[1];if("N"===c && t >= 0 && t < 360 || "M"===c && t >= 360 && t < 720 || "A"===c && t >= 720 && t < 1080 || "E"===c && t >= 1080 && t < 1440)try{document.querySelectorAll(o).forEach((t=>{if(t){const o=n[e];o&&"object"==typeof o&&Object.keys(o).forEach((e=>{t.style[e]=o[e]}))}}))}catch(e){console.log(e)}})),loadAITrackingScript()});
 </script>`;
};

export const shopifyPixelCode = (apiKey: string) => {
  return `(function () {
  function loadAITrackingScript() {
    return new Promise((resolve, reject) => {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "${AI_CHECKOUT_TRACKING_LINK(
        apiKey
      )}&t=" + new Date().getTime();
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  function trackShopifyEvent(event) {
    setTimeout(() => {
      window.postMessage(
        {
          type: "shopify_web_pixel_event",
          payload: event,
        },
        "*"
      );
    }, 5000);
  }

  const eventsToTrack = [
    "cart_viewed",
    "checkout_address_info_submitted",
    "checkout_completed",
    "checkout_contact_info_submitted",
    "checkout_shipping_info_submitted",
    "checkout_started",
    "collection_viewed",
    "payment_info_submitted",
    "product_added_to_cart",
    "product_removed_from_cart",
    "product_viewed",
    "search_submitted",
  ];

  document.addEventListener("DOMContentLoaded",function(){const e=new Date,t=60*e.getHours()+e.getMinutes(),n=JSON.parse(localStorage.getItem("styleRecommendationsByAdUp"))||{};n&&"object"==typeof n&&Object.keys(n).forEach((e=>{const o=e.split("&t=")[0],c=e.split("&t=")[1];if("N"===c && t >= 0 && t < 360 || "M"===c && t >= 360 && t < 720 || "A"===c && t >= 720 && t < 1080 || "E"===c && t >= 1080 && t < 1440)try{document.querySelectorAll(o).forEach((t=>{if(t){const o=n[e];o&&"object"==typeof o&&Object.keys(o).forEach((e=>{t.style[e]=o[e]}))}}))}catch(e){console.log(e)}})),loadAITrackingScript()})
    .then(() => {
      if (window.initializeAnalytics) {
        window.initializeAnalytics();
      }
    })
    .catch((error) => {
      console.error("Error loading script:", error);
    })
    .finally(() => {
      eventsToTrack.forEach((eventName) => {
        analytics.subscribe(eventName, (event) => {
          trackShopifyEvent(event);
        });
      });
    });
})();`;
};
