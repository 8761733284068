import { useEffect } from 'react';
import store from '../store/store';

const useTitle = (title: string) => {
  const merchantName = store.getState().user.merchant.config.merchant_name;
  useEffect(() => {
    const prevTitle = document.title;

    if (merchantName) {
      document.title = `${merchantName} Dashboard - ${title}`;
      return () => {
        document.title = prevTitle;
      };
    } else {
      document.title = `Dashboard - ${title}`;
      return () => {
        document.title = prevTitle;
      };
    }
  });
};

export default useTitle;
