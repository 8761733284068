import { useState, useEffect } from 'react';
import '../../styles/pages/whiteLabel.scss';
import { applicationJSONBodyBuilder } from '../../lib/utils/body-builder';
import { asyncHandler } from '../../lib/api/async-handler';
import store from '../../store/store';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { setMessage } from '../../store/reducers/appSlice';
import { setIsWhiteLabel } from '../../store/reducers/uiSlice';
import {
  setAccessToken,
  setWhiteLabelRedirectPath,
} from '../../store/reducers/authSlice';
import { useNavigate } from 'react-router-dom';
import { LOGIN_API } from '../../lib/constants/central-api';
import { fetchAppInit } from '../../lib/api/app-init';
import { updateOnboardingStage } from '../../store/reducers/userSlice';
import { stepsAndStagesMap } from '../../lib/constants/steps_stages_map';
import { submitLogToBugsnag } from '../../lib/api/log';

interface MerchantData {
  seller_id: string;
  hash_key: string;
  page: string;
  origin: string;
}

const WhiteLabel = () => {
  const navigate = useNavigate();
  const [seller, setSeller] = useState<MerchantData>();
  const accessToken = store.getState().auth.authorization.accessToken;
  const sellerIdFromStore = store.getState().user?.profile?.seller_id;
  const shopIdFromStore = store.getState().user.company.shop_id;

  const redirectToOnboarding = (isRedirectToOnboarding: boolean) => {
    if (seller?.page) {
      //store the whiteLabel redirect path in the store
      store.dispatch(setWhiteLabelRedirectPath(seller?.page));

      if (isRedirectToOnboarding) {
        store.dispatch(updateOnboardingStage(stepsAndStagesMap['step_2'].name));
        navigate('/onboarding');
      } else {
        navigate(seller?.page);
      }

      //TODO: remove after testing
      /* store.dispatch(updateOnboardingStage(stepsAndStagesMap['step_3'].name));
      navigate('/onboarding'); */
    }
  };

  useEffect(() => {
    //todo add this back
    // if (window.location === window.parent.location) {
    //   console.log('--INVALID parent window ❌--');
    //   store.dispatch(setIsWhiteLabel(false));
    //   navigate('/');
    // }
    function receiveHash(event: any) {
      //TODO: Need PSP domains : Check the origin of the message to ensure it's from a trusted source
      // if (event.origin !== "https://parent-domain.com") {
      //   return;
      // }

      // Process the message
      setSeller(event.data);
    }
    window.addEventListener('message', receiveHash, false);
    return () => {
      window.removeEventListener('message', receiveHash);
    };
  }, []);

  useEffect(() => {
    try {
      if (
        seller &&
        seller?.seller_id &&
        seller?.hash_key &&
        seller?.page &&
        seller?.origin
      ) {
        //set whiteLabel true when all the above conditions are met.
        store.dispatch(setIsWhiteLabel(true));

        /* console.log('--seller_id :: 💡', seller?.seller_id);
        console.log('--hash_key :: 💡', seller?.hash_key);
        console.log('--PAGE :: 💡', seller?.page);
        console.log('--Seller Origin :: 💡', seller?.origin); */
        if (
          accessToken?.currentWhiteLabelSellerToken &&
          accessToken?.currentWhiteLabelSellerToken !== '' &&
          sellerIdFromStore &&
          sellerIdFromStore === seller?.seller_id // if received seller_id is different from the one in the store then authenticate
        ) {
          //When shop_id (which is in the central state) is not available, redirect to onboarding
          if (shopIdFromStore && shopIdFromStore !== '') {
            redirectToOnboarding(false);
          } else {
            redirectToOnboarding(true);
          }
        } else {
          authenticate();
        }
      }
    } catch (error) {
      console.log(error);
      submitLogToBugsnag('error', `${error}`);
    }
  }, [seller]);

  async function authenticate() {
    try {
      const requestInfo: RequestInfo = {
        url: LOGIN_API,
        method: 'POST',
        body: applicationJSONBodyBuilder({
          // domain: seller?.origin,
          seller_id: seller?.seller_id,
          hash_key: seller?.hash_key,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization:
            accessToken.type + ' ' + accessToken?.currentWhiteLabelSellerToken,
        },
      };

      const actionType = 'SELLER_AUTH_VERIFICATION';
      const customMsg = 'Seller Authentication Successful.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        const { access_token, user } = response.data;

        if (seller?.origin && access_token && access_token !== '') {
          // store.dispatch(setIsWhiteLabel(true));
          store.dispatch(
            setAccessToken({
              ...accessToken,
              whiteLabelSellerTokens: {
                ...accessToken?.whiteLabelSellerTokens,
                [seller?.origin]: access_token,
              },
              currentWhiteLabelSellerToken: access_token,
            })
          );
        }

        const initResponseData: any = await fetchAppInit();
        //When init response shop array is empty, redirect to onboarding
        if (initResponseData) {
          if (initResponseData?.shop && initResponseData?.shop?.length > 0) {
            redirectToOnboarding(false);
          } else {
            redirectToOnboarding(true);
          }
        } else {
          store.dispatch(
            setMessage({
              message: 'Authorization failed',
              messageType: 'error',
            })
          );
          console.log('Error fetching init response');
        }
      } else {
        store.dispatch(
          setMessage({
            message: response.message,
            messageType: 'error',
          })
        );
        console.log(response);
      }
    } catch (exception) {
      store.dispatch(
        setMessage({
          message: 'Authorization failed',
          messageType: 'error',
        })
      );
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  return (
    <div className='container'>
      <div className='loadingSpinner__roller'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default WhiteLabel;
