import React from 'react';
import MainOverallAndLocationBasedCRChart from './ConversionRate/OverallAndLocationBased/MainOverallAndLocationBasedCRChart';
import { useTranslation } from 'react-i18next';
import WeeklyConversionRateLineChart from './ConversionRate/Weekly/WeeklyConversionRateLineChart';
import MainDeviceBasedCRChart from './ConversionRate/DeviceBased/MainDeviceBasedCRChart';
import MainAverageCartValueChart from './AverageCartValue/MainAverageCartValueChart';
import MainCTRChart from './ClickThroughRate/MainCTRChart';
import MainTimeAndDayCRChart from './ConversionRate/TimeAndDay/MainTimeAndDayCRChart';
import MainUserDemographicsChart from './UserDemographics/MainUserDemographicsChart';

const DummyStats = () => {
  const { t } = useTranslation();
  return (
    <div className='chartLayout'>
      <h2 className='chartLayout-dummy-text'>{t('DummyStatsText')}</h2>
      <div className='chartLayout--content'>
        <MainOverallAndLocationBasedCRChart />
      </div>
      <div className='chartLayout--content'>
        <WeeklyConversionRateLineChart />
      </div>
      <div className='chartLayout--content'>
        <MainDeviceBasedCRChart />
      </div>
      <div className='chartLayout--content'>
        <MainAverageCartValueChart />
      </div>
      <div className='chartLayout--content'>
        <MainCTRChart />
      </div>
      <div className='chartLayout--content'>
        <MainTimeAndDayCRChart />
      </div>
      <div className='chartLayout--content'>
        <MainUserDemographicsChart />
      </div>
    </div>
  );
};

export default DummyStats;
