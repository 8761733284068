import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../styles/components/subPageLinkHolder/subPageLinkHolder.scss';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';

interface Props {
  mainSubTitle: string;
  items: { icon: any; title: string; link: string; urlName: string }[];
}

const SubPageLinkHolder = ({ mainSubTitle, items }: Props) => {
  const userCompany = useSelector((state: RootState) => state.user.company);
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { t } = useTranslation();

  /*  console.log(userCompany); */

  return (
    <div className='subPageLinkHolder'>
      {/*  <div className='subPageLinkHolder__top'>
        <div className='subPageLinkHolder__top--left'>
          <img
            src={
              userCompany?.settings?.company_logo &&
              userCompany?.settings?.company_logo !== '' &&
              userCompany?.settings?.company_logo !== null
                ? `${staticURL}/medium/${userCompany?.settings?.company_logo}`
                : dummyImage
            }
            alt='pic'
          />
        </div>
        <div className='subPageLinkHolder__top--right'>
          <h2>{userCompany.name}</h2>
          <p>{mainSubTitle}</p>
        </div>
      </div> */}
      <ul className='subPageLinkHolder__bottom'>
        {items.map((item, i) => {
          return (
            <li
              onClick={() => {
                navigate(item.link);
              }}
              data-sub_page_link_holder={
                currentPath.includes(item.urlName) ? 'true' : 'false'
              }
              key={i}
              className='subPageLinkHolder__bottom--linkItem'
            >
              <div className='subPageLinkHolder__bottom--linkItem-left'>
                <CustomSVGs svg={item.icon} />
                <span>{t(item.title)}</span>
              </div>
              {/*   <div className='subPageLinkHolder__bottom--linkItem-right'>
                <CustomSVGs svg={arrowRightThinSVG} />
              </div> */}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SubPageLinkHolder;
