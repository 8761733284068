import React from 'react';
import ReactPaginate from 'react-paginate';
import '../../styles/components/pagination/paginationTheme2.scss';
import { useTranslation } from 'react-i18next';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { arrowHeadLeftSVG } from '../../lib/allCustomSVGs';

type Props = {
  totalRecords: number;
  itemsPerPage: number;
  onRecordsPerPageChange: (recordsPerPage: number) => void;
  breakLabel: string;
  onPageChange: (selectedItem: { selected: number }) => void;
  pageRangeDisplayed: number;
  initialPage: number;
  pageCount: number;
  renderOnZeroPageCount?: (para: any) => void | null | undefined;
  marginPagesDisplayed: number;
  containerClassName: string;
  pageLinkClassName: string;
  previousLinkClassName: string;
  nextLinkClassName: string;
  disabledLinkClassName: string;
  activeLinkClassName: string;
};

const PaginationTheme2 = ({
  itemsPerPage,
  totalRecords,
  onRecordsPerPageChange,
  breakLabel,
  onPageChange,
  pageRangeDisplayed,
  initialPage,
  pageCount,
  renderOnZeroPageCount,
  marginPagesDisplayed,
  containerClassName,
  pageLinkClassName,
  previousLinkClassName,
  nextLinkClassName,
  disabledLinkClassName,
  activeLinkClassName,
}: Props) => {
  const { t } = useTranslation();

  const nextButtonElement = (
    <div title={t('Next')} className='paginationTheme2--next-label'>
      <CustomSVGs svg={arrowHeadLeftSVG} />
    </div>
  );

  const previousButtonElement = (
    <div title={t('Previous')} className='paginationTheme2--prev-label'>
      <CustomSVGs svg={arrowHeadLeftSVG} />
    </div>
  );

  return (
    <div className='paginationTheme2'>
      <div className='paginationTheme2__left'>
        <span>{t('Showing')}</span>
        <select
          onChange={(e) => {
            onRecordsPerPageChange(parseInt(e.target.value));
          }}
          value={itemsPerPage}
          name='pagination-number-of-records'
        >
          <option value='5'>5</option>
          <option value='6'>6</option>
          <option value='8'>8</option>
          <option value='10'>10</option>
          <option value='12'>12</option>
        </select>
        <span>{t('Of')}</span>
        <span>{totalRecords}</span>
      </div>

      <div className='paginationTheme2__right'>
        <ReactPaginate
          breakLabel={breakLabel}
          nextLabel={nextButtonElement}
          onPageChange={onPageChange}
          pageRangeDisplayed={pageRangeDisplayed}
          initialPage={initialPage}
          pageCount={pageCount}
          previousLabel={previousButtonElement}
          renderOnZeroPageCount={renderOnZeroPageCount}
          marginPagesDisplayed={marginPagesDisplayed}
          containerClassName={containerClassName}
          pageLinkClassName={pageLinkClassName}
          previousLinkClassName={previousLinkClassName}
          nextLinkClassName={nextLinkClassName}
          disabledLinkClassName={disabledLinkClassName}
          activeLinkClassName={activeLinkClassName}
        />
      </div>
    </div>
  );
};

export default PaginationTheme2;
