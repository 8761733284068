const returnArrayOrNull = (item: any) => {
  /* This function is used to find if a certain item is an object or an array or non of that.*/
  /* if the item is an array, return array */
  /* if the item is an object, put object values to an array an return the array*/
  /* else return null*/
  let returnValue;
  if (item?.data?.length > 0) {
    returnValue = item?.data;
  } else if (item?.data) {
    returnValue = Object.values(item?.data);
  } else {
    returnValue = null;
  }

  return returnValue;
};

export default returnArrayOrNull;
