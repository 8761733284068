import { OnboardingStagesMap } from '../../store/reducers/userSlice';

export const stepsAndStagesMap: { [index: string]: any } = {
  step_1: OnboardingStagesMap.ProfileUpdate,
  step_2: OnboardingStagesMap.SubmittingCompany,
  step_3: OnboardingStagesMap.IntegratingCMS1,
  step_4: OnboardingStagesMap.SettingCMSConnection,
  step_5: OnboardingStagesMap.IntegratingPSP1,
  step_6: OnboardingStagesMap.SettingPSPConnection,
  step_7: OnboardingStagesMap.WebshopTransactions,
};

/*    
'ProfileUpdate',
      'SubmittingCompany',
      'IntegratingCMS1',
      'SettingCMSConnection',
      'IntegratingPSP1',
      'SettingPSPConnection', */
