import { useEffect, useState } from 'react';
import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import store from '../../../store/store';
import { asyncHandler } from '../../../lib/api/async-handler';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import { useTranslation } from 'react-i18next';
import '../../../styles/pages/settings/settingsSecurityQR.scss';
import { submitLogToBugsnag } from '../../../lib/api/log';
import {
  GENERATE_AUTH_KEY_API,
  SHOP_API,
} from '../../../lib/constants/central-api';
import KeySVG from '../../../ui/customSVGs/KeySVG';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import BlurAndCopyInput from '../../../ui/inputs/BlurAndCopyInput';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import { warningWithCircleSVG } from '../../../lib/allCustomSVGs';
import { HOME_ROUTE } from '../../../lib/constants/route';

const SettingsSecurity = () => {
  const slug = store.getState().user?.company?.shop_slug;
  const [loading, setLoading] = useState('pending');
  const [authKey, setAuthKey] = useState<string>('');

  const { t } = useTranslation();

  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  useEffect(() => {
    if (slug && slug !== '') {
      getSecuritySettings()
        .then((result) => {
          if (!result?.authorization_key || result?.authorization_key === '') {
            getAuthKeyAPICall();
          } else {
            setAuthKey(result?.authorization_key || '');
            setLoading('success');
          }
        })
        .catch((err) => {
          console.error(err);
          submitLogToBugsnag('error', `${err}`);
          setLoading('error');
        });
    }
  }, [slug]);

  /* ----------------------------API CALL START---------------------------- */

  const getAuthKeyAPICall = async () => {
    try {
      const requestInfo: RequestInfo = {
        url: GENERATE_AUTH_KEY_API(slug),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: `${accessToken.type} ${sellerToken}`,
        },
      };

      const actionType = 'GENERATE_AUTH_KEY';
      const customMsg = t('AuthorizationKeyGeneratedSuccessfully');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        if (response?.data?.value) {
          setAuthKey(response.data.value);
          setLoading('success');
        }
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  };

  const getSecuritySettings = async () => {
    try {
      const requestInfo: RequestInfo = {
        url: `${SHOP_API}/${slug}/settings?filter[group]=security`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: `${accessToken.type} ${sellerToken}`,
        },
      };

      const actionType = 'GET_SECURITY_SETTINGS';
      const customMsg = 'Security Settings fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        const data: any = {};
        response?.data?.forEach((item: any) => {
          data[item.key] = item.value || '';
        });
        return data;
      }
    } catch (exception) {
      console.log('Error during request');
      submitLogToBugsnag('error', `${exception}`);
    }
  };

  /* ----------------------------API CALL END---------------------------- */

  return (
    <SubPageInnerContentHolder
      title={t('SecuritySettings')}
      subTitle={t('ManageSecuritySettings')}
      backBtnLink={isWhiteLabel ? undefined : HOME_ROUTE}
    >
      <>
        {loading === 'pending' && <LoadingSpinner />}
        {loading === 'error' && (
          <NoData
            title={t('ErrorFetchingData')}
            subTitle={t('SomethingWentWrong-msg')}
          />
        )}
        {loading === 'success' && (
          <form id='security-settings-form' className='normalForm'>
            <div className='normalForm__top'>
              <h2>{t('Authorization')}</h2>
              <hr />
              <div className='normalForm__container1'>
                <div className='normalForm__container1--left'>
                  <BlurAndCopyInput
                    icon={<KeySVG fill='#888888' />}
                    id='settingsSecurity-authKey'
                    value={authKey}
                    changeListeners={[(e) => setAuthKey(e.target.value)]}
                    focusListeners={[]}
                    placeholder={t('AuthorizationKey')}
                    required={true}
                    readonly={true}
                    isCopyToClipboardEnabled={true}
                  />
                </div>
              </div>
            </div>

            {/* <div className='normalForm__bottom flex-start'>
              <MainSquareButton
                value={t('GenerateNewAuthorizationKey')}
                form='general-settings-form'
                onClick={getAuthKeyAPICall}
              />
            </div>
            <div className='normalForm__icon--message'>
              <CustomSVGs svg={warningWithCircleSVG} />
              <span>{t('AuthorizationKeyRegeneratingMsg')}</span>
            </div> */}
          </form>
        )}
      </>
    </SubPageInnerContentHolder>
  );
};

export default SettingsSecurity;
