import { resetLastVersionCheckTimestamp } from '../../store/reducers/appSlice';
import store from '../../store/store';
import getCacheBustedUrl from '../helper-functions/cacheBuster';

const isThereNewVersion = (metaVersion: string, currentVersion: string) => {
  //* Check if the meta version is exactly the same as the current version in releaseSpec
  return Boolean(!(metaVersion.toString() === currentVersion.toString()));
};

export const runCacheBuster = async () => {
  try {
    const latestMetaJsonURL = getCacheBustedUrl(`/meta.json`);
    const res = await fetch(latestMetaJsonURL);
    const { version: metaVersion } = await res.json();
    const currentVersion = store.getState().app?.version ?? '';

    const accessToken = store.getState().auth.authorization.accessToken;
    const isWhiteLabel = store.getState().UI?.isWhiteLabel;

    let token: string;
    if (isWhiteLabel) {
      token = accessToken?.currentWhiteLabelSellerToken;
    } else {
      token = accessToken?.sellerToken;
    }

    const shouldForceRefresh = isThereNewVersion(metaVersion, currentVersion);

    if (shouldForceRefresh) {
      console.log(
        `🔄 CacheBuster : An old version has been loaded (v${currentVersion}). There is a new version (v${metaVersion}). Should force refresh.`
      );
      //* Clear the cache and reload the page
      setTimeout(() => {
        window.localStorage.clear();
        console.log('🔄 CacheBuster : Cache Cleared ✅');
        if (token && !isWhiteLabel) {
          let url = window.location.href;
          if (url.indexOf('?') > -1) {
            url += `&cacheBustToken=${token}`;
          } else {
            url += `?cacheBustToken=${token}`;
          }
          window.location.href = url;
        } else {
          window.location.reload();
        }
      }, 1000);
      return true;
    } else {
      console.log(
        `🔄 CacheBuster : There is no new version available. No cache force refresh needed. (v${currentVersion}) === (v${metaVersion})`
      );
      store.dispatch(resetLastVersionCheckTimestamp(null));
      return false;
    }
  } catch (error) {
    console.log(
      '🔄 CacheBuster : An error occurred while running cache buster.',
      error
    );
    return false;
  }
};
