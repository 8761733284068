import { useTranslation } from 'react-i18next';
import { useLocation, Route, Routes } from 'react-router-dom';
import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { plusSignSVG } from '../../../lib/allCustomSVGs';
import AddWebhook from './AddWebhook';
import DeleteWebhook from './DeleteWebhook';
import EditWebhook from './EditWebhook';
import ListWebhook from './ListWebhook';
import ViewWebhook from './ViewWebhook';
import store from '../../../store/store';
import { HOME_ROUTE } from '../../../lib/constants/route';

const ShopSettingsWebhooks = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  /* const navigate = useNavigate(); */
  const activeLink = location.pathname;
  /*  const [loading, setLoading] = useState('pending');
    const [pspData, setPSPData] = useState([]);
    const shopId = useSelector((state: RootState) => state.user.company.shop_id); */

  // console.log(window.location.origin);

  const pathname = location.pathname;
  let subTitle = '';

  if (pathname.includes('add')) {
    subTitle = t('CreateWebhook');
  } else if (pathname.includes('delete')) {
    subTitle = t('DeleteWebhook');
  } else if (pathname.includes('edit')) {
    subTitle = t('EditWebhook');
  } else if (pathname.includes('view')) {
    subTitle = t('ViewSingleWebhook');
  } else {
    subTitle = t('ManageWebhookConnections');
  }

  const getBackButtonRoutes = () => {
    let backButtonLink;
    if (
      pathname.includes('add') ||
      pathname.includes('edit') ||
      pathname.includes('delete') ||
      pathname.includes('view')
    ) {
      backButtonLink = '/settings/webhooks';
    } else {
      backButtonLink = isWhiteLabel ? undefined : HOME_ROUTE;
    }

    return backButtonLink;
  };

  return (
    <SubPageInnerContentHolder
      title={t('Webhooks')}
      subTitle={subTitle}
      backBtnLink={getBackButtonRoutes()}
      mainBtnIcon={plusSignSVG}
      mainBtnTitle={
        activeLink === '/settings/webhooks' ? t('AddWebhook') : undefined
      }
      mainBtnLink='/settings/webhooks/add'
    >
      {activeLink === '/settings/webhooks' && <ListWebhook />}
      <Routes>
        <Route path='/add' element={<AddWebhook />} />
        <Route path='/delete/:webhookId' element={<DeleteWebhook />} />
        <Route path='/edit/:webhookId' element={<EditWebhook />} />
        <Route path='/view/:webhookId' element={<ViewWebhook />} />
      </Routes>
    </SubPageInnerContentHolder>
  );
};

export default ShopSettingsWebhooks;
