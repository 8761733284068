import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import {
  arrowHeadRightSVG,
  hourGlassSVG,
  tickSVG,
  tickWithCircleSVG,
  warningWithCircleSVG,
} from '../../lib/allCustomSVGs';
import '../../styles/pages/dashboard/dashboardTab.scss';
import { useTranslation } from 'react-i18next';
import loadingSpinner from '../../assets/gif/loading.gif';
import CountdownTimer from '../../ui/timers/CountdownTimer';
import addDaysToDate from '../../lib/helper-functions/addDaysToDate';
import { DAYS_TO_ADD } from '../../lib/constants/abstracts';

type Props = {
  icon: any;
  title: string;
  subTitle?: string;
  isFillSVG?: boolean;
  onClickHandler: () => void;
  loadingStatus?: 'pending' | 'success' | 'error' | 'null';
  dateTimeISOString?: string;
  isTimesUp?: boolean;
  isCompleted: boolean;
};

const DashboardTabItem = ({
  icon,
  title,
  subTitle,
  isFillSVG,
  onClickHandler,
  loadingStatus = 'null',
  dateTimeISOString,
  isTimesUp,
  isCompleted = false,
}: Props) => {
  const { t } = useTranslation();

  const futureDate = addDaysToDate(
    dateTimeISOString ? dateTimeISOString : '',
    DAYS_TO_ADD
  );

  const renderButton = () => {
    if (isCompleted) {
      return (
        <>
          <CustomSVGs svg={tickSVG} />
        </>
      );
    }

    if (dateTimeISOString && !isTimesUp) {
      return (
        <>
          <CustomSVGs svg={hourGlassSVG} />
        </>
      );
    }

    if (loadingStatus === 'success') {
      return (
        <>
          <CustomSVGs svg={tickWithCircleSVG} />
        </>
      );
    }

    if (loadingStatus === 'pending') {
      return <img src={loadingSpinner} alt='loading' />;
    }

    if (loadingStatus === 'error') {
      return (
        <>
          <span>{t('TryAgainLater')}</span>
          <CustomSVGs svg={warningWithCircleSVG} />
        </>
      );
    }

    if (loadingStatus === 'null') {
      return (
        <>
          <span>{t('Start')}</span>
          <CustomSVGs svg={arrowHeadRightSVG} />
        </>
      );
    }
  };

  const onItemClick = () => {
    if (dateTimeISOString && !isTimesUp) {
      return;
    } else if (isCompleted) {
      return;
    } else {
      onClickHandler();
    }
  };

  return (
    <div className='dashboardTab__item'>
      <div
        className='dashboardTab__item--mobile-click-overlay'
        onClick={onItemClick}
      ></div>
      <div className='dashboardTab__item--left'>
        <div
          data-fill-dash-svg={isFillSVG ? 'true' : 'false'}
          className='dashboardTab__item--left-icon'
        >
          <CustomSVGs svg={icon} />
        </div>
        <div className='dashboardTab__item--left-titleContainer'>
          <div className='dashboardTab__item--left-title'>{title}</div>
          {subTitle && !dateTimeISOString && (
            <div className='dashboardTab__item--left-subtitle'>{subTitle}</div>
          )}
          {subTitle && dateTimeISOString && !isTimesUp && (
            <CountdownTimer
              prefix={`${t(subTitle)}`}
              suffix={`${t('remaining')}.`}
              className='dashboardTab__item--left-subtitle'
              futureDate={futureDate}
            />
          )}
        </div>
      </div>
      <div className='dashboardTab__item--right'>
        <button
          onClick={onItemClick}
          className='dashboardTab__item--right-link'
          disabled={loadingStatus === 'pending' || loadingStatus === 'error'}
          data-dash-success-svg={loadingStatus === 'success' ? 'true' : 'false'}
          data-is-dashboard-item-done={isCompleted?.toString()}
        >
          {renderButton()}
        </button>
      </div>
    </div>
  );
};

export default DashboardTabItem;
