import { useEffect, useState } from 'react';
import '../../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../../ui/inputs/NormalInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import NormalSelectInput from '../../../../ui/inputs/NormalSelectInput';
import { updateIntercom } from '../../../../store/reducers/userSlice';
import { useTranslation } from 'react-i18next';
import createSelectOptionValueObject from '../../../../lib/helper-functions/createSelectOptionValueObject';
import makeFirstLetterCapital from '../../../../lib/helper-functions/makeFirstLetterCapital';
import update_cms_api_call from '../../../../lib/api/cms/update-cms';
import ToggleSwitch from '../../../../ui/buttons/ToggleSwitch';

interface Props {
  cmsData: any;
}

const EditCMS = ({ cmsData }: Props) => {
  const [cmsInputs, setCMSInputs] = useState([]);
  const [cmsInputValues, setCMSInputValues] = useState<any>();
  const [cmsExtraInputs, setCMSExtraInputs] = useState<any[]>([]);
  const [cmsExtraInputValues, setCMSExtraInputValues] = useState<any>();
  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  /* ============================================================== */
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const options: any = useSelector(
    (state: RootState) => state.user.onboarding.availableCMS
  );
  const cmsOptions: any = createSelectOptionValueObject(options);

  const [selectedCMS, setSelectedCMS] = useState(cmsData?.cms_service?.slug);
  const selectedCMS_ID = cmsData?.id;
  /* ================================================================================= */
  useEffect(() => {
    //Get the current CMS data from the available CMS data
    const currentCMS: any = onboarding_object?.available_cms?.filter(
      (el: any) => {
        return el?.slug === selectedCMS ? el : null;
      }
    );

    let current_CMS_API_values;
    let current_CMS_extra_API_values;
    if (cmsData?.id) {
      if (selectedCMS === cmsData?.cms_service?.slug) {
        current_CMS_API_values = cmsData?.connection_data;
      } else {
        current_CMS_API_values = null;
      }

      if (selectedCMS === cmsData?.cms_service?.slug) {
        current_CMS_extra_API_values = cmsData?.additional_configs;
      } else {
        current_CMS_extra_API_values = null;
      }
    }

    if (currentCMS?.length > 0) {
      const currentCMS_inputs = currentCMS[0]?.connection_fields;
      if (currentCMS_inputs?.length > 0) {
        let temp_inputs: any = [];
        let temp_input_values: any = {};
        for (let i = 0; i < currentCMS_inputs.length; i++) {
          const inputDetails = currentCMS_inputs[i];
          const name = inputDetails?.name;
          const defaultValue = inputDetails?.default;

          temp_inputs.push(inputDetails);
          temp_input_values[name] = defaultValue;
        }
        setCMSInputs(temp_inputs);
        if (selectedCMS === cmsData?.cms_service?.slug) {
          setCMSInputValues(current_CMS_API_values);
        } else {
          setCMSInputValues(temp_input_values);
        }
      }
      /* ------------------------------------------- */
      const currentCMS_extra_inputs = currentCMS[0]?.additional_fields;
      setCMSExtraInputs([]);
      setCMSExtraInputValues({});
      if (currentCMS_extra_inputs?.length > 0) {
        let temp_inputs: any = [];
        let temp_input_values: any = {};
        for (let i = 0; i < currentCMS_extra_inputs.length; i++) {
          const extraInputDetails = currentCMS_extra_inputs[i];
          const name = extraInputDetails?.name;
          const defaultValue = extraInputDetails?.default;

          temp_inputs.push(extraInputDetails);
          temp_input_values[name] = defaultValue;
        }
        setCMSExtraInputs(temp_inputs);
        if (selectedCMS === cmsData?.cms_service?.slug) {
          setCMSExtraInputValues(current_CMS_extra_API_values);
        } else {
          setCMSExtraInputValues(temp_input_values);
        }
      }
    }
  }, [selectedCMS]);
  /* ================================================================================= */

  async function onSubmitHandler(e: any) {
    e.preventDefault();

    const response: any = await update_cms_api_call(
      selectedCMS,
      cmsInputValues,
      selectedCMS_ID,
      cmsExtraInputValues
    );

    if (response) {
      /* const result = response.result;
        setSelectedCMS(result[0].cms_name);
        setApiEndpoint(result[0].secure_data?.shop_url);
        setApiKey(result[0].secure_data?.customer_key);
        setApiSecret(result[0].secure_data?.customer_secret); */
      dispatch(
        updateIntercom({
          cms: selectedCMS,
          cmsdatafilled: 'true',
        })
      );
    }
  }

  let isDisabled;

  if (cmsInputValues) {
    isDisabled = Object.values(cmsInputValues)?.some((el: any) => el === '');
  }

  const onInputChange = (e: any, value: string) => {
    setCMSInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  const onToggleChange = (value: boolean, name: string) => {
    setCMSExtraInputValues((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <form id='CMS-Edit-form' onSubmit={onSubmitHandler} className='normalForm'>
      <div className='normalForm__top'>
        <div className='normalForm__container3'>
          <div className='normalForm__container3--item'>
            <div className='normalForm__container3--item-content'>
              <NormalSelectInput
                defaultValue={selectedCMS}
                setSelect={setSelectedCMS}
                selectOptionArr={cmsOptions}
                placeholder={t('APIType')}
                id={'selected-cms-edit'}
              />
            </div>
          </div>
          {cmsInputs.map((el: any, index: number) => {
            return (
              <div className='normalForm__container3--item' key={index}>
                <div className='normalForm__container3--item-content'>
                  <NormalInput
                    changeListeners={[(e) => onInputChange(e, el?.name)]}
                    id={`edit-cms-integration-${el?.name}`}
                    value={cmsInputValues ? cmsInputValues[el?.name] : ''}
                    placeholder={el?.label}
                    required={el?.is_required ? true : false}
                    autoFocus={index === 0 ? true : false}
                    classes={`edit-cms-${selectedCMS}-${el?.name}-input`}
                    labelClasses={`edit-cms-${selectedCMS}-${el?.name}-label`}
                    disabled={el?.is_active ? false : true}
                  />
                </div>
              </div>
            );
          })}
          {cmsExtraInputs?.map((el: any, index: number) => {
            return (
              <div className='normalForm__container3--item' key={index}>
                <div className='normalForm__container3--item-content'>
                  {el?.type === 'toggle' ? (
                    <ToggleSwitch
                      message={t('PaymentMethodAlreadyAdded-msg')}
                      disabled={!el?.is_active}
                      key={el?.name}
                      id={el?.name}
                      isOn={
                        cmsExtraInputValues
                          ? cmsExtraInputValues[el?.name]
                          : el.default
                      }
                      title={el?.label}
                      onClick={(value: boolean) =>
                        onToggleChange(value, el.name)
                      }
                      classes='margin-top-2rem'
                    />
                  ) : (
                    <NormalInput
                      changeListeners={[(e) => onInputChange(e, el?.name)]}
                      id={`add-cms-integration-${el?.name}`}
                      value={cmsInputValues ? cmsInputValues[el?.name] : ''}
                      placeholder={el?.label}
                      required={el?.is_required ? true : false}
                      autoFocus={index === 0 ? true : false}
                      classes={`add-cms-${selectedCMS}-${el}-input`}
                      labelClasses={`add-cms-${selectedCMS}-${el}-label`}
                      disabled={el?.is_active ? false : true}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className='normalForm__bottom'>
        <MainSquareButton
          value={t('Edit')}
          /* icon={<CustomSVGs svg={plusSignSVG} />} */
          disabled={isDisabled}
          type='submit'
          form='CMS-Edit-form'
        />
      </div>
    </form>
  );
};

export default EditCMS;
