import { useTranslation } from 'react-i18next';
import '../../../../../../styles/components/charts/chart.scss';
import { useEffect, useState } from 'react';
import OverallConversionRatePieChart from './OverallConversionRatePieChart';
import LocationBasedConversionRatePieChart from './LocationBasedConversionRatePieChart';
import SingleCountryConversionRatePieChart from './SingleCountryConversionRatePieChart';
import ChartWithConfig from '../../../../../../components/charts/ChartWithConfig';
import {
  locationBasedConversionRateDummyData,
  overallConversionRateDummyData,
} from '../../../../../../lib/constants/graphDummyData/conversionRateDummyData';

const MainOverallAndLocationBasedCRChart = () => {
  const { t } = useTranslation();
  const [isBackButtonVisible, setIsBackButtonVisible] =
    useState<boolean>(false);

  //Time filter
  const [timeFilter, setTimeFilter] = useState<string>('all');
  const timeFilterOptionsArr = [
    { OptionValue: 'all', OptionName: t('All') },
    { OptionValue: 'last_24_hours', OptionName: t('Last24Hrs') },
    { OptionValue: 'last_7_days', OptionName: t('Last7Days') },
    { OptionValue: 'last_30_days', OptionName: t('Last30Days') },
    { OptionValue: '3_months', OptionName: t('Last3Months') },
  ];

  //Group by options
  const [groupBy, setGroupBy] = useState<string>('overall');
  const groupByOptionsArr = [
    { OptionValue: 'overall', OptionName: t('Overall') },
    { OptionValue: 'location', OptionName: t('Location') },
  ];

  //Selected country
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [title, setTitle] = useState<string>(t('OverallConversionRate'));
  const [comment, setComment] = useState<string>('');

  const renderCharts = () => {
    if (groupBy === 'overall') {
      return (
        <OverallConversionRatePieChart
          apiResponse={{
            type: 'overall',
            data: overallConversionRateDummyData,
          }}
        />
      );
    } else if (groupBy === 'location' && selectedCountry === '') {
      return (
        <LocationBasedConversionRatePieChart
          apiResponse={{
            type: 'location',
            data: locationBasedConversionRateDummyData,
          }}
          setSelectedCountry={setSelectedCountry}
        />
      );
    } else if (groupBy === 'location' && selectedCountry !== '') {
      return (
        <SingleCountryConversionRatePieChart
          apiResponse={{
            type: 'country',
            data: locationBasedConversionRateDummyData[selectedCountry],
          }}
        />
      );
    }
  };

  useEffect(() => {
    if (groupBy === 'overall') {
      setTitle(t('OverallConversionRate'));
      setComment(t('TheOverallConversionRate'));
    } else if (groupBy === 'location' && selectedCountry === '') {
      setTitle(t('LocationBasedConversionRate'));
      setComment(t('TheConversionRateOfCartsToCheckoutsBasedOnLocation'));
    } else if (groupBy === 'location' && selectedCountry !== '') {
      setTitle(`${t('TheConversionRateOf')} ${selectedCountry}`);
      setComment(`${t('ConversionRateCartsToCheckoutsIn')} ${selectedCountry}`);
    }
  }, [groupBy, selectedCountry]);

  useEffect(() => {
    setSelectedCountry('');
  }, [groupBy]);

  const onBackButtonClick = () => {
    if (groupBy === 'location' && selectedCountry !== '') {
      setSelectedCountry('');
    }
  };

  useEffect(() => {
    if (groupBy === 'location' && selectedCountry !== '') {
      setIsBackButtonVisible(true);
    } else {
      setIsBackButtonVisible(false);
    }
  }, [groupBy, selectedCountry]);

  return (
    <ChartWithConfig
      groupBy={groupBy}
      setGroupBy={setGroupBy}
      groupByOptionsArr={groupByOptionsArr}
      timeFilter={timeFilter}
      setTimeFilter={setTimeFilter}
      timeFilterOptionsArr={timeFilterOptionsArr}
      isBackButtonVisible={isBackButtonVisible}
      onBackButtonClick={onBackButtonClick}
      title={title}
      comment={comment}
    >
      {renderCharts()}
    </ChartWithConfig>
  );
};

export default MainOverallAndLocationBasedCRChart;
