import { motion } from 'framer-motion';
import '../../styles/cards/simpleCard.scss';

type Props = {
  grow?: boolean;
  children: React.ReactNode;
};

const SimpleCard = ({ children, grow = true }: Props) => {
  return (
    <motion.div
      style={{ flexGrow: grow ? 1 : 0 }}
      initial={{ opacity: 0, scale: 0.99 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.99 }}
      transition={{ duration: 0.5 }}
      className='simpleCard'
    >
      {children}
    </motion.div>
  );
};

export default SimpleCard;
