import '../../styles/components/charts/chart.scss';
import NormalSelectInput from '../../ui/inputs/NormalSelectInput';
import { useTranslation } from 'react-i18next';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { arrowHeadLeftSVG, filterSVG } from '../../lib/allCustomSVGs';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

type Props = {
  loading?: string;
  children: React.ReactNode;
  groupBy?: string;
  setGroupBy?: React.Dispatch<React.SetStateAction<string>>;
  timeFilter?: string;
  setTimeFilter?: React.Dispatch<React.SetStateAction<string>>;
  groupByOptionsArr?: { OptionValue: string; OptionName: string }[];
  timeFilterOptionsArr?: { OptionValue: string; OptionName: string }[];
  isBackButtonVisible?: boolean;
  onBackButtonClick?: () => void;
  title: string;
  comment?: string;
  isConfigHidden?: boolean;
};

const ChartWithConfig = ({
  loading,
  children,
  groupBy,
  setGroupBy,
  groupByOptionsArr,
  timeFilter,
  setTimeFilter,
  timeFilterOptionsArr,
  isBackButtonVisible,
  onBackButtonClick,
  title,
  comment,
  isConfigHidden,
}: Props) => {
  const { t } = useTranslation();
  const [isConfigOpen, setIsConfigOpen] = useState<boolean>(false);

  return (
    <div data-is-chart-config-open={isConfigOpen.toString()} className='chart'>
      {!isConfigHidden && (
        <div
          onClick={() => {
            setIsConfigOpen(!isConfigOpen);
          }}
          className='chart-config'
          title={isConfigOpen ? t('CloseConfig') : t('OpenConfig')}
        >
          <CustomSVGs svg={filterSVG} />
        </div>
      )}
      {isBackButtonVisible && (
        <div
          title={t('GoBackToPreviousChart')}
          onClick={onBackButtonClick}
          className='chart-goBack'
        >
          <CustomSVGs svg={arrowHeadLeftSVG} />
        </div>
      )}
      <div className='chart__heading'>
        <div className='chart__heading--title'>{title}</div>
        {comment && <div className='chart__heading--comment'>{comment}</div>}
      </div>
      <AnimatePresence>
        {isConfigOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: '100%' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className='chart__top'
          >
            <div className='chart__top--controls'>
              {groupByOptionsArr && groupBy && setGroupBy && (
                <div className='chart__top--controls-selections'>
                  <div className='chart__top--controls-selections-title'>
                    {t('GroupBy')}
                  </div>
                  <div className='chart__top--controls-selections-inputs'>
                    <NormalSelectInput
                      defaultValue={groupBy}
                      setSelect={setGroupBy}
                      selectOptionArr={groupByOptionsArr}
                      //   placeholder={t('Time')}
                      id={'ConversionRateChart-groupBy'}
                    />
                  </div>
                </div>
              )}
              {timeFilter && setTimeFilter && timeFilterOptionsArr && (
                <div className='chart__top--controls-selections'>
                  <div className='chart__top--controls-selections-title'>
                    {t('Filters')}
                  </div>
                  <div className='chart__top--controls-selections-inputs'>
                    <NormalSelectInput
                      defaultValue={timeFilter}
                      setSelect={setTimeFilter}
                      selectOptionArr={timeFilterOptionsArr}
                      placeholder={t('Time')}
                      id={'ConversionRateChart-timeFilter'}
                    />
                  </div>
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className='chart__bottom'>
        <div className='chart__bottom--content'>
          {/* Render Chart */}
          {children}
        </div>
      </div>
    </div>
  );
};

export default ChartWithConfig;
