import { useLocation, Route, Routes, useNavigate } from 'react-router-dom';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';
import getCurrentRoute from '../../../../lib/helper-functions/getCurrentRoute';
import AddCMS from './AddCMS';
import EditCMS from './EditCMS';
import store from '../../../../store/store';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';
import { useTranslation } from 'react-i18next';
import { setAvailableCMS } from '../../../../store/reducers/userSlice';
import get_all_cms_api_call from '../../../../lib/api/cms/get-all-available-cms';
import get_all_cms_by_shop_api_call from '../../../../lib/api/cms/get-all-cms-by-shop';

const CMSConnection = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const activeLink = location.pathname;
  const [loading, setLoading] = useState('pending');
  const [cmsData, setCMSData] = useState<any>();
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  // const shopId = useSelector((state: RootState) => state.user.company.shop_id);

  const currentRoute = getCurrentRoute(location);
  let subTitle = '';

  if (currentRoute === 'add') {
    subTitle = t('AddCMS-Connection');
  } else if (currentRoute === 'connection') {
    subTitle = t('EditCMS-Connection');
  } else {
    subTitle = t('ManageCMS');
  }

  /* ----------------------------API CALL START---------------------------- */
  const getAvailableCMS = async () => {
    setLoading('pending');
    const response: any = await get_all_cms_api_call();

    if (!response) {
      setLoading('error');
      console.log('No Available CMS');
    }
    if (response) {
      if (response?.length === 0) {
        setLoading('null');
        console.log('No Available CMS');
      } else {
        console.log(response);
        store.dispatch(setAvailableCMS(response));
        getShopCMS();
      }
    }
  };

  const getShopCMS = async () => {
    const response: any = await get_all_cms_by_shop_api_call();

    if (!response) {
      setLoading('error');
      navigate('/integrations/cms/connection/add');
    }

    if (response?.length === 0) {
      navigate('/integrations/cms/connection/add');
      setLoading('null');
    } else {
      setLoading('success');
      setCMSData(response);
    }
  };

  useEffect(() => {
    if (currentRoute === 'connection') getAvailableCMS();
  }, [currentRoute]);
  /* ----------------------------API CALL END---------------------------- */
  const getBackButtonRoutes = () => {
    let backButtonLink;
    if (currentRoute === 'add') {
      backButtonLink = '/integrations';
    } else if (currentRoute === 'connection') {
      backButtonLink = '/integrations';
    } else {
      backButtonLink = '/integrations';
    }

    return backButtonLink;
  };

  return (
    <SubPageInnerContentHolder
      title={t('CMS-Connection')}
      subTitle={subTitle}
      backBtnLink={isWhiteLabel ? undefined : getBackButtonRoutes()}
      // mainBtnIcon={plusSignSVG}
      // mainBtnTitle='Add CMS'
      // mainBtnLink='/integrations/cms/connection/add'
    >
      {activeLink === '/integrations/cms/connection' && (
        <>
          {loading === 'pending' && <LoadingSpinner />}
          {loading === 'error' && (
            <NoData
              title={t('ErrorFetchingData')}
              subTitle={t('SomethingWentWrong-msg')}
            />
          )}
          {cmsData?.id && <EditCMS cmsData={cmsData} />}
        </>
      )}

      <Routes>
        <Route path='/add' element={<AddCMS />} />
      </Routes>
    </SubPageInnerContentHolder>
  );
};

export default CMSConnection;
