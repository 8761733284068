import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useEffect, useState } from 'react';
import { weeklyConversionRateDummyData } from '../../../../../../lib/constants/graphDummyData/conversionRateDummyData';

const WeeklyConversionRateLineChart = () => {
  const [response, setResponse] = useState<any>([]);

  const getGraphData = () => {
    const sortedRes = weeklyConversionRateDummyData?.sort(
      (a, b) => new Date(a.week).getTime() - new Date(b.week).getTime()
    );
    setResponse(sortedRes);
  };

  useEffect(() => {
    getGraphData();
  }, []);

  const valueFormatter = (value: number | null) => `${value}%`;
  return (
    <div className='pieChartComponent'>
      <h2 className='pieChartComponent__title'>{'Weekly Conversion Rate'}</h2>
      <div className='pieChartComponent__chart'>
        <LineChart
          dataset={response}
          xAxis={[{ scaleType: 'band', dataKey: 'week', label: 'Date' }]}
          yAxis={[
            {
              label: 'Conversion Rate (%)',
              min: 0,
              max: 100,
            },
          ]}
          series={[{ dataKey: 'conversion_rate', valueFormatter }]}
          height={300}
          colors={['#8884d8']}
          margin={{ left: 50, right: 30, top: 30, bottom: 40 }}
          grid={{ vertical: true, horizontal: true }}
          sx={(theme) => ({
            [`.${axisClasses.root}`]: {
              [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                stroke: '#8b909a',
                strokeWidth: 3,
              },
              [`.${axisClasses.tickLabel}`]: {
                fill: '#8b909a',
              },
            },
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              fill: '#8b909a',
              // transform: 'translate(-10px, -10px)',
            },
            [`.${axisClasses.bottom} .${axisClasses.label}`]: {
              fill: '#8b909a', // Change color of x-axis label
            },
            [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
              fill: '#8b909a', // Change color of x-axis tick labels
            },
          })}
        />
      </div>
    </div>
  );
};

export default WeeklyConversionRateLineChart;
