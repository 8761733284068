import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import EnglishTranslations from '../assets/json/translations/en.json';
import DutchTranslations from '../assets/json/translations/nl.json';
import SpanishTranslations from '../assets/json/translations/es.json';
import GermanTranslations from '../assets/json/translations/de.json';
import FrenchTranslations from '../assets/json/translations/fr.json';
import ItalianTranslations from '../assets/json/translations/it.json';
import PortugeseTranslations from '../assets/json/translations/pt.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: EnglishTranslations,
  nl: DutchTranslations,
  es: SpanishTranslations,
  de: GermanTranslations,
  fr: FrenchTranslations,
  it: ItalianTranslations,
  pt: PortugeseTranslations,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
