import store from '../store/store';

export function openWindowOnClick(foundHref) {
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const url = foundHref;
  const height = 700;
  const width = 400;

  if (!isWhiteLabel) {
    //* If not white label then open the popup window
    //injecting CSS to global style
    const style = document.createElement('style');
    style.innerHTML = `
    .popUpWindow-overlay {
      background-color: rgba(0, 0, 0, 0.7);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100000;
      cursor: pointer;
    }
    
    .hide {
      display: none;
    }
    
    .show {
      display: block;
    }
    
 
        `;
    document.head.appendChild(style);

    //injecting HTML to body
    const buttonHTML = `
    <div class="popUpWindow-Container hide">
        <div class="popUpWindow-overlay"></div>
    </div>
    `;

    document.querySelector('body').insertAdjacentHTML('afterbegin', buttonHTML);

    //selcetors
    const popUpWindowContainer = document.querySelector(
      '.popUpWindow-Container'
    );
    const overlay = document.querySelector('.popUpWindow-overlay');

    //event handlers
    /* const openPopUpWindow = () => {
     window.open(
       foundHref,
       "_blank",
       "toolbar=no,location=no,top=50,status=no,menubar=no,scrollbars=yes,resizable=yes,width=400,height=650, top="
     );
   }; */

    /*--------------- Event Handlers -------------------*/
    let newWindow;
    function openPopUpWindow() {
      popUpWindowContainer.classList.remove('hide');
      const parentWindow = window;
      const popupTitle = 'adup';
      const y =
        parentWindow.top.outerHeight / 2 +
        parentWindow.top.screenY -
        height / 2;
      const x =
        parentWindow.top.outerWidth / 2 + parentWindow.top.screenX - width / 2;

      newWindow = parentWindow.open(
        url,
        popupTitle,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
      );

      //timer to check if the newWindow is closed. if closed hide overlay
      if (newWindow) {
        var timer = setInterval(function () {
          if (newWindow.closed) {
            clearInterval(timer);
            popUpWindowContainer.classList.add('hide');
          }
        }, 1000);
      }

      return newWindow;
    }

    openPopUpWindow();

    const closePopUpWindow = () => {
      newWindow.close();
      popUpWindowContainer.classList.add('hide');
    };

    /*--------------- Event Listeners -------------------*/
    //close popup and overlay when you click on the overlay
    overlay.addEventListener('click', closePopUpWindow);
  } else {
    //* If white label then open as a new window
    window.open(
      foundHref,
      '_blank',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}`
    );
  }
}
