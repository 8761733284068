import store from '../../../store/store';
import { asyncHandler } from '../async-handler';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { CMS_API } from '../../constants/central-api';
import { submitLogToBugsnag } from '../log';

export default async function update_cms_api_call(
  selectedCMS: string,
  cmsInputValues: any,
  id: string | number,
  cmsExtraInputValues: any
) {
  try {
    const accessToken = store.getState().auth.authorization.accessToken;
    const isWhiteLabel = store.getState().UI?.isWhiteLabel;
    const shop_slug = store.getState().user.company.shop_slug;
    let sellerToken: string;
    if (isWhiteLabel) {
      sellerToken = accessToken?.currentWhiteLabelSellerToken;
    } else {
      sellerToken = accessToken?.sellerToken;
    }

    const requestInfo: RequestInfo = {
      url: `${CMS_API(shop_slug)}/${id}`,
      method: 'PUT',
      body: JSON.stringify({
        cms_service: selectedCMS,
        connection_data: cmsInputValues,
        additional_configs: cmsExtraInputValues,
      }),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: accessToken.type + ' ' + sellerToken,
      },
    };

    const actionType = 'UPDATE_CMS_CONNECTION';
    const customMsg = 'CMS Connection Updated Successful.';

    const res: any = await asyncHandler(requestInfo, actionType, customMsg);

    const response = res.data;

    if (res.actionType === actionType && response.success === true) {
      return true;
    } else {
      return false;
    }
  } catch (exception) {
    console.log('Error during authentication request');
    submitLogToBugsnag('error', `${exception}`);
    return false;
  }
}
