// put framer motion animation variants in here
// for animating the page, not individual components

import { IFramerAnimation } from './IFramerAnimation';

// page slide in from right
export const pageSlideInBackward: IFramerAnimation = {
  initial: {
    x: '200vw',
  },
  animate: {
    x: 0,
  },
};

// page slide in from left
export const pageSlideInForward: IFramerAnimation = {
  initial: {
    x: '-200vw',
  },
  animate: {
    x: 0,
  },
};

export const pageSlideOutBackward: IFramerAnimation = {
  exit: {
    x: '200vw',
  },
};

export const pageSlideOutForward: IFramerAnimation = {
  exit: {
    x: '-20vw',
    opacity: 0,
  },
  transition: {
    duration: 0.4,
  },
};

export const pageSlideInFromRightWithFade: IFramerAnimation = {
  animate: {
    x: 0,
    opacity: 1,
  },
  initial: {
    x: '200vw',
    opacity: 0,
  },
  transition: {
    duration: 0.7,
    ease: 'anticipate',
  },
};
