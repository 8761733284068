import { useTranslation } from 'react-i18next';
import { trashSVG, shareSVG, editSVG } from '../../lib/allCustomSVGs';
import makeFirstLetterCapital from '../../lib/helper-functions/makeFirstLetterCapital';
import timeSince from '../../lib/helper-functions/timeSince';
import '../../styles/customDataGrid/customDataGrid.scss';
import CustomSVGs from '../customSVGs/CustomSVGs';
import formatDate from '../../lib/helper-functions/formatDate';

interface Props {
  allRows: any[];
  titleField: string;
  imageField?: string;
  dateField?: string;
  excludingField?: string[];
  borderType?: 'none' | 'round';
  isManual?: string;
  onDeleteHandler?: (params: any) => void;
  onEditHandler?: (params: any) => void;
  onShareHandler?: (params: any) => void;
  onViewHandler?: (params: any) => void;
}

const CustomDataGrid = ({
  allRows,
  titleField,
  imageField,
  dateField,
  excludingField,
  borderType,
  isManual,
  onDeleteHandler,
  onEditHandler,
  onShareHandler,
  onViewHandler,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className='customDataGrid'>
      <ul
        data-datagrid-boarder-type={borderType}
        className='customDataGrid__items'
      >
        {allRows?.map((el: any, index: number) => {
          let others = Object.assign({}, el);
          if (dateField) {
            delete others[dateField];
          }
          if (imageField) {
            delete others[imageField];
          }
          if (isManual) {
            delete others[isManual];
          }
          if (excludingField) {
            excludingField?.forEach((el) => {
              delete others[el];
            });
          }
          delete others[titleField];

          const getObjectKeyValueString = (obj: any) => {
            const keysArr = Object.keys(obj);
            const valuesArr: any = Object.values(obj);
            const stringArr = [];

            for (let i = 0; i < keysArr.length; i++) {
              let value: any = valuesArr[i];
              if (!isNaN(parseInt(value))) {
                value = valuesArr[i];
              } else if (typeof value === 'string') {
                value = makeFirstLetterCapital(valuesArr[i]);
              }

              let key = keysArr[i]?.split('_');
              let formatedKey = '';
              key?.map((text: string, index: number) => {
                if (index === key?.length - 1) {
                  formatedKey += ' ' + makeFirstLetterCapital(text) + ' :';
                } else if (index === 0) {
                  formatedKey += makeFirstLetterCapital(text) + ' ';
                } else {
                  formatedKey += ' ' + makeFirstLetterCapital(text);
                }
                return null;
              });

              // stringArr.push(` ${formatedKey}: ${value}`);
              /*     const currentElement = (
                <div key={i}>
                  <h2>{formatedKey}</h2>
                  <h3>{value}</h3>
                </div>
              ); */

              //rendering various element according to our need
              const renderCurrentElement = () => {
                if (formatedKey === ' Type :') {
                  return (
                    <div
                      className={`customDataGrid__item--left-content-items-pill customDataGrid__item--left-content-items-sku-${value}`}
                      key={i}
                    >
                      {value}
                    </div>
                  );
                } else if (formatedKey === ' Status :') {
                  return (
                    <div
                      className={`customDataGrid__item--left-content-items-pill customDataGrid__item--left-content-items-status-${value}`}
                      key={i}
                    >
                      {value}
                    </div>
                  );
                } else if (formatedKey === ' Sku :') {
                  return (
                    <div key={i}>
                      <h2>{formatedKey?.toUpperCase()}</h2>
                      <h3>{value}</h3>
                    </div>
                  );
                } else if (formatedKey === 'Cart  Total :') {
                  return (
                    <div key={i}>
                      <h2>{t('Total')}</h2>
                      <h3>{`${value}`}</h3>
                    </div>
                  );
                } else if (formatedKey === 'Updated  At :') {
                  return (
                    <div key={i}>
                      <h2>{t('LastSyncedOn')}</h2>
                      <h3>
                        {value
                          ? formatDate(value)
                          : obj?.created_at
                          ? formatDate(obj?.created_at)
                          : '-'}
                      </h3>
                    </div>
                  );
                } else if (formatedKey === 'Created  At :') {
                  return null;
                } else {
                  return (
                    <div key={i}>
                      <h2>{formatedKey}</h2>
                      <h3>{value}</h3>
                    </div>
                  );
                }
              };

              stringArr.push(renderCurrentElement());
            }

            // return stringArr.toString();
            return stringArr;
          };

          getObjectKeyValueString(others);

          const renderImage = (imageURL: string) => {
            if (imageURL?.includes('.mp4')) {
              const newURL = imageURL.replace('small', 'medium');
              return <video src={newURL} />;
            } else if (imageURL?.includes('.gif')) {
              const newURL = imageURL.replace('small', 'medium');
              return <img src={newURL} alt='pic' />;
            } else {
              return <img src={imageURL} alt='pic' />;
            }
          };

          return (
            <li key={index} className='customDataGrid__item'>
              <div
                title={onViewHandler ? t('ClickToView') : ''}
                onClick={() => {
                  if (onViewHandler) {
                    onViewHandler(el);
                  }
                }}
                className='customDataGrid__item--left'
              >
                {imageField && (
                  <div className='customDataGrid__item--left-img'>
                    {renderImage(el[imageField])}
                    {/* <img src={el[imageField]} alt='pic' /> */}
                  </div>
                )}
                <div className='customDataGrid__item--left-content'>
                  <h2>
                    {titleField === 'product_id'
                      ? `Product  Id : ${el[titleField] || '-/-'}`
                      : el[titleField]}
                  </h2>
                  <div className='customDataGrid__item--left-content-items'>
                    {getObjectKeyValueString(others)}
                  </div>
                </div>
              </div>
              <div
                data-has-datagrid-date={dateField ? 'true' : 'false'}
                className='customDataGrid__item--right'
              >
                {dateField && (
                  <h2 className='customDataGrid__item--right-date'>
                    {`${timeSince(el[dateField])} ago`}
                  </h2>
                )}
                <div className='customDataGrid__item--right-action'>
                  {onEditHandler && (
                    <div
                      title={t('Edit')}
                      onClick={() => {
                        if (isManual && el[isManual] === true)
                          onEditHandler(el);
                      }}
                      className={`customDataGrid__item--right-action-btns ${
                        isManual && el[isManual] === true
                          ? 'customDataGrid__item--right-action-edit'
                          : 'customDataGrid__item--right-action-disabled'
                      }`}
                    >
                      <CustomSVGs svg={editSVG} />
                    </div>
                  )}

                  {onDeleteHandler && (
                    <div
                      title={t('Delete')}
                      onClick={() => {
                        if (isManual && el[isManual] === true)
                          onDeleteHandler(el);
                      }}
                      className={`customDataGrid__item--right-action-btns ${
                        isManual && el[isManual] === true
                          ? 'customDataGrid__item--right-action-delete'
                          : 'customDataGrid__item--right-action-disabled'
                      }`}
                    >
                      <CustomSVGs svg={trashSVG} />
                    </div>
                  )}

                  {onShareHandler && (
                    <div
                      title={t('Share')}
                      onClick={() => {
                        onShareHandler(el);
                      }}
                      className='customDataGrid__item--right-action-share customDataGrid__item--right-action-btns'
                    >
                      <CustomSVGs svg={shareSVG} />
                    </div>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CustomDataGrid;
