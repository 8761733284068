import { useState } from 'react';
import '../../../../styles/pages/checkoutTracking/recommendationItem.scss';
import { useTranslation } from 'react-i18next';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { arrowHeadLeftSVG } from '../../../../lib/allCustomSVGs';
import { AnimatePresence, motion } from 'framer-motion';
import spinner from '../../../../assets/gif/loading.gif';

interface Item {
  id: string;
  property_type: string;
  value: string;
  element_id: string;
  title: string;
  description: string;
  type: string;
  is_paused: 0 | 1;
  loadingStatus: 'pending' | 'initial';
}

type Props = {
  data: Item;
  isActive: boolean;
  isDisabled: boolean;
  setRecommendationsHandler: (
    id: string,
    action: 'select' | 'unselect' | 'pause' | 'resume'
  ) => Promise<void>;
};

const RecommendationItem = ({
  data,
  isActive,
  setRecommendationsHandler,
  isDisabled,
}: Props) => {
  const { t } = useTranslation();
  const [isItemExpanded, setIsItemExpanded] = useState<boolean>(false);

  const renderPreview = () => {
    if (
      data?.property_type === 'color' ||
      data?.property_type === 'backgroundColor' ||
      data?.property_type === 'background'
    ) {
      return (
        <div className='recommendationItem__top--content-left-preview-color'>
          <span style={{ backgroundColor: data?.value }}></span>
          <span>{data?.value}</span>
        </div>
      );
    } else if (data?.property_type === 'borderRadius') {
      return (
        <div
          style={{ borderRadius: data?.value }}
          className='recommendationItem__top--content-left-preview-border-radius'
        >
          {data?.value}
        </div>
      );
    } else if (data?.property_type === 'fontFamily') {
      return (
        <div
          style={{ fontFamily: data?.value }}
          className='recommendationItem__top--content-left-preview-font-family'
        >
          {data?.value}
        </div>
      );
    } else {
      return (
        <div className='recommendationItem__top--content-left-preview-other'>
          {data?.value}
        </div>
      );
    }
  };

  const renderButton = (
    action: 'resume' | 'pause' | 'select' | 'unselect',
    text: string,
    className: string
  ) => {
    if (data?.loadingStatus !== 'pending') {
      return (
        <button
          onClick={() => {
            if (isDisabled) return;
            setRecommendationsHandler(data?.id, action);
          }}
          className={`recommendationItem__bottom--actions-btn ${className}`}
        >
          {text}
        </button>
      );
    }
  };

  return (
    <div
      data-is-recommendation-item-expanded={isItemExpanded.toString()}
      className='recommendationItem'
    >
      <div className='recommendationItem__top'>
        <div className='recommendationItem__top--title'>
          <h2>{data?.title}</h2>
          <h3>
            {data?.type === 'near_by'
              ? `(${t('NearBy')})`
              : `(${t('NightTime')})`}
          </h3>
        </div>
        <div className='recommendationItem__top--content'>
          <div className='recommendationItem__top--content-left'>
            {renderPreview()}
          </div>

          <div className='recommendationItem__top--content-right'>
            <span>{isItemExpanded ? 'Collapse' : 'Expand'}</span>
            <div
              onClick={() => setIsItemExpanded(!isItemExpanded)}
              className='recommendationItem__top--content-right-icon'
            >
              <CustomSVGs svg={arrowHeadLeftSVG} />
            </div>
          </div>
        </div>
      </div>
      <div className='recommendationItem__bottom'>
        <AnimatePresence>
          {isItemExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
              style={{ overflow: 'hidden' }}
            >
              {data?.description}
            </motion.div>
          )}
        </AnimatePresence>
        <div className='recommendationItem__bottom--actions'>
          {data?.loadingStatus === 'pending' && (
            <img src={spinner} alt='spinner' />
          )}
          {isActive &&
            data?.is_paused === 1 &&
            renderButton(
              'resume',
              t('Resume'),
              'recommendationItem__bottom--actions-resume-btn'
            )}
          {isActive &&
            data?.is_paused === 0 &&
            renderButton(
              'pause',
              t('Pause'),
              'recommendationItem__bottom--actions-pause-btn'
            )}
          {!isActive &&
            renderButton(
              'select',
              t('Accept'),
              'recommendationItem__bottom--actions-accept-btn'
            )}
          {isActive &&
            renderButton(
              'unselect',
              t('Decline'),
              'recommendationItem__bottom--actions-decline-btn'
            )}
        </div>
      </div>
    </div>
  );
};

export default RecommendationItem;
