import { t } from 'i18next';

export const recommendation_packages = [
  {
    id: 1,
    name: t('Starter'),
    annual_slug: 'starter-annually',
    monthly_slug: 'starter-monthly',
    monthly_price: '€227',
    annual_price: '€200',
    description: t('GetStartedWithBasicRecommendations'),
    features: [
      // t('2ActiveRecommendations'),
      t('2WeekUpdate'),
      t('DataInsights'),
    ],
    is_most_popular: false,
  },
  {
    id: 2,
    name: t('Professional'),
    annual_slug: 'professional-annually',
    monthly_slug: 'professional-monthly',
    monthly_price: '€350',
    annual_price: '€308',
    description: t('AdvancedToolsForMoreComprehensiveInsights'),
    features: [
      t('AllFromStarter'),
      // t('6ActiveRecommendations'),
      t('PredictiveModel'),
      t('WeeklyUpdate'),
    ],
    is_most_popular: true,
  },
  {
    id: 3,
    name: t('Enterprise'),
    annual_slug: 'company-annually',
    monthly_slug: 'company-monthly',
    monthly_price: '€500',
    annual_price: '€440',
    description: t('CustomSolutionsForLarge-scaleOperations'),
    features: [
      t('AllFromStarter&professional'),
      t('PredictiveAdjustments'),
      t('UnlimitedRecommendations'),
      t('CustomSupport'),
    ],
    is_most_popular: false,
  },
];
