import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { settingsPlusSVG, ToggleSideBarSVG } from '../../lib/allCustomSVGs';
import '../../styles/components/sidebar/sidebarTheme2.scss';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { linksTrackingCssClasses } from '../../lib/constants/sidebarLinks';
import { allRoutes } from '../../lib/constants/route';

interface Items {
  icon: any;
  iconType?: string;
  title: string;
  link: string;
  urlName: string;
  specialURL?: string;
  level?: number;
}
interface Props {
  changeSidebarSize: () => void;
  isSidebarMinimized: boolean;
  mainSubTitle: string;
  items: Items[];
}

const SidebarTheme2 = ({
  mainSubTitle,
  items,
  isSidebarMinimized,
  changeSidebarSize,
}: Props) => {
  const userCompany = useSelector((state: RootState) => state.user.company);
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const [renderingItems, setRenderingItems] = useState<Items[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const selectedPathObj = items.find((item) => item.link === currentPath);

    const selectedPathLevel = selectedPathObj?.level;

    if (selectedPathLevel === 1) {
      const level_1_Items = items?.filter(
        (item) => item.level === selectedPathLevel
      );
      setRenderingItems(level_1_Items);
    } else if (selectedPathLevel && selectedPathLevel > 1) {
      if (currentPath.includes('tracking')) {
        const level_1_Items = items?.filter((item) => item.level === 1);
        setRenderingItems(level_1_Items);
      } else {
        const otherItems = items?.filter(
          (item) => item.level === selectedPathLevel
        );
        const selectedLinkSplitArr = selectedPathObj.link.split('/');
        const selectedLinkCommonPath =
          selectedLinkSplitArr[selectedLinkSplitArr.length - 2];

        const result = otherItems?.filter((item) => {
          const otherLinkSplitArr = item.link.split('/');
          const otherLinkCommonPath =
            otherLinkSplitArr[otherLinkSplitArr.length - 2];
          if (otherLinkCommonPath === selectedLinkCommonPath) {
            return item;
          } else return null;
        });

        setRenderingItems(result);
      }
    } else {
      const otherLinkSplitArr = currentPath.split('/');
      const otherLinkSplitArrLength = otherLinkSplitArr.length;
      if (otherLinkSplitArrLength > 1) {
        if (otherLinkSplitArr[otherLinkSplitArrLength - 1] === 'add') {
          const selectedLink = [...otherLinkSplitArr].slice(0, -1).join('/');
          console.log(selectedLink);
        } else if (
          otherLinkSplitArr[otherLinkSplitArrLength - 2] === 'edit' ||
          otherLinkSplitArr[otherLinkSplitArrLength - 2] === 'view' ||
          otherLinkSplitArr[otherLinkSplitArrLength - 2] === 'delete'
        ) {
          const selectedLink = [...otherLinkSplitArr].slice(0, -3).join('/');
          console.log(selectedLink);
        }
      }
    }
  }, [currentPath, items]);

  const mainSectionView = () => {
    return (
      <AnimatePresence>
        {renderingItems?.map((item, i) => {
          /* Here we add checks to skip links which are related to main sections eg:(setting and tracking) */
          if (item.title === 'Settings' && item.link === '/settings') {
            return null;
          } else if (
            item.title === 'AICheckoutTracking' &&
            item.link === '/tracking'
          ) {
            return null;
          } else {
            return (
              <motion.li
                title={t(item.title)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, ease: 'easeOut' }}
                // transition={{ type: 'spring', damping: 20, stiffness: 150 }}
                onClick={() => {
                  navigate(item.link);
                }}
                data-sub_page_link_holder={
                  item.specialURL
                    ? currentPath === item.specialURL
                    : currentPath.includes(item.urlName)
                    ? 'true'
                    : 'false'
                }
                key={i}
                className='sidebarTheme2__bottom--linkItem'
              >
                <div className='sidebarTheme2__bottom--linkItem-left'>
                  {item?.iconType === 'svg' ? (
                    <CustomSVGs svg={item.icon} />
                  ) : (
                    <img
                      src={item.icon}
                      className='subPageLinkHolderItems__img'
                      alt='icon'
                    />
                  )}
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isSidebarMinimized ? 0 : 1 }}
                    transition={{ duration: 1 }}
                  >
                    {t(item.title)}
                  </motion.span>
                </div>
              </motion.li>
            );
          }
        })}
      </AnimatePresence>
    );
  };

  const trackingSectionView = () => {
    if (!allRoutes['/tracking']) return null;

    if (!currentPath.includes('settings')) {
      // const pathNameRoot = currentPath.split('/')[1];

      return (
        <>
          <div className='sidebarTheme2__bottom--title2'>
            <span>{t('AICheckoutTracking')}</span>
          </div>
          <AnimatePresence>
            {linksTrackingCssClasses.map((item, i) => {
              return (
                <motion.li
                  key={i}
                  title={t(item.title)}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2, ease: 'easeOut' }}
                  // transition={{ type: 'spring', damping: 20, stiffness: 150 }}
                  onClick={() => {
                    navigate(item.link);
                  }}
                  data-sub_page_link_holder={
                    currentPath.includes(item.urlName) &&
                    currentPath.includes('tracking')
                      ? 'true'
                      : 'false'
                  }
                  className='sidebarTheme2__bottom--linkItem'
                >
                  <div className='sidebarTheme2__bottom--linkItem-left'>
                    <CustomSVGs svg={item.icon} />

                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isSidebarMinimized ? 0 : 1 }}
                      transition={{ duration: 1 }}
                    >
                      {t(item.title)}
                    </motion.span>
                  </div>
                </motion.li>
              );
            })}
          </AnimatePresence>
        </>
      );
    } else {
      return null;
    }
  };

  const settingsSectionView = () => {
    if (!allRoutes['/settings']) return null;

    if (!currentPath.includes('settings')) {
      return (
        <>
          <div className='sidebarTheme2__bottom--title2'>
            <span>{t('Settings')}</span>
          </div>
          <AnimatePresence>
            <motion.li
              title={t('Settings')}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeOut' }}
              // transition={{ type: 'spring', damping: 20, stiffness: 150 }}
              onClick={() => {
                navigate('/settings');
              }}
              data-sub_page_link_holder={
                currentPath.includes('settings') ? 'true' : 'false'
              }
              className='sidebarTheme2__bottom--linkItem'
            >
              <div className='sidebarTheme2__bottom--linkItem-left'>
                <CustomSVGs svg={settingsPlusSVG} />

                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isSidebarMinimized ? 0 : 1 }}
                  transition={{ duration: 1 }}
                >
                  {t('Settings')}
                </motion.span>
              </div>
            </motion.li>
          </AnimatePresence>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      data-is-sidebar-minimized={isSidebarMinimized?.toString()}
      className='sidebarTheme2'
    >
      <div className='sidebarTheme2__top'>
        <div className='sidebarTheme2__top--left'>
          <img
            src={
              userCompany?.settings?.company_logo &&
              userCompany?.settings?.company_logo !== '' &&
              userCompany?.settings?.company_logo !== null
                ? `${staticURL}/medium/${userCompany?.settings?.company_logo}`
                : `${staticURL}/small/default.jpg`
            }
            alt='pic'
          />
          <h2>{userCompany.name}</h2>
        </div>
        <div onClick={changeSidebarSize} className='sidebarTheme2__top--right'>
          <CustomSVGs svg={ToggleSideBarSVG} />
        </div>
      </div>
      <ul className='sidebarTheme2__bottom'>
        <div className='sidebarTheme2__bottom--title1'>
          <span>
            {currentPath.includes('settings') && t('Settings')}
            {!currentPath.includes('settings') && t('MainMenu')}
          </span>
        </div>

        {/* Main Menu Section*/}
        {mainSectionView()}

        {/* AI Checkout Tracking Section*/}
        {trackingSectionView()}

        {/* Settings Section*/}
        {settingsSectionView()}
      </ul>
    </div>
  );
};

export default SidebarTheme2;
