import { useTranslation } from 'react-i18next';
import { trashSVG, shareSVG, editSVG } from '../../lib/allCustomSVGs';
import CustomSVGs from '../customSVGs/CustomSVGs';
import '../../styles/customDataGrid/customDataGridTheme2.scss';
import truncate from '../../lib/helper-functions/truncate';
import formatDate from '../../lib/helper-functions/formatDate';
import timeSince from '../../lib/helper-functions/timeSince';

type ColumnType = {
  field: string;
  headerName: string;
  imageField?: boolean;
  width?: number;
  highlightingValue?: { value: string; color: string }[];
  highlightStyle?: 'background' | 'text';
  truncate?: number;
  dateField?: boolean;
  dataFormat?: 'timeSince' | 'formatDate';
};

interface Props {
  allRows: any[];
  columns: ColumnType[];
  actions?: boolean;
  onDeleteHandler?: (params: any) => void;
  onEditHandler?: (params: any) => void;
  onShareHandler?: (params: any) => void;
  onViewHandler?: (params: any) => void;
}

const CustomDataGridTheme2 = ({
  allRows,
  columns,
  onDeleteHandler,
  onEditHandler,
  onShareHandler,
  onViewHandler,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className='customDataGridTheme2'>
      <div className='customDataGridTheme2__wrapper'>
        <div className='customDataGridTheme2__columns'>
          <div className='customDataGridTheme2__columns--title customDataGridTheme2__columns--title-select'>
            <input type='checkbox' />
          </div>

          {columns.map((column, index) => (
            <div
              className='customDataGridTheme2__columns--title'
              style={{ width: column?.width ? `${column?.width}rem` : '15rem' }}
              key={index}
            >
              <span>{column.headerName}</span>
            </div>
          ))}

          {(onDeleteHandler || onEditHandler || onShareHandler) && (
            <div className='customDataGridTheme2__columns--title customDataGridTheme2__columns--title-action'>
              <span>Actions</span>
            </div>
          )}
        </div>
        <ul className='customDataGridTheme2__rows'>
          {allRows.map((el: any, index: number) => (
            <li
              key={index}
              className='customDataGridTheme2__row'
              onClick={() => {
                if (onViewHandler) {
                  onViewHandler(el);
                }
              }}
            >
              <div
                className='customDataGridTheme2__row--select'
                onClick={(e) => e.stopPropagation()}
              >
                <input type='checkbox' />
              </div>

              {columns.map((column, colIndex) => {
                if (column?.imageField) {
                  return (
                    <div
                      key={colIndex}
                      className='customDataGridTheme2__row--image'
                    >
                      <img
                        style={{
                          width: column?.width
                            ? `${column?.width}rem`
                            : '15rem',
                          height: column?.width
                            ? `${column?.width}rem`
                            : '15rem',
                        }}
                        src={el[column?.field]}
                        alt={el[column?.field]}
                      />
                    </div>
                  );
                } else if (
                  column?.highlightingValue &&
                  column?.highlightingValue?.length > 0
                ) {
                  const highlight = column?.highlightingValue.find(
                    (hv) => el[column?.field] === hv?.value
                  );
                  return (
                    <div
                      key={colIndex}
                      style={{
                        width: column?.width ? `${column?.width}rem` : '15rem',
                      }}
                      className='customDataGridTheme2__row--text'
                    >
                      {column?.highlightStyle === 'background' ? (
                        <span
                          style={{
                            padding: '0.4rem 1.3rem',
                            borderRadius: '2rem',
                            backgroundColor: highlight?.color,
                            fontWeight: '500',
                            textTransform: 'capitalize',
                            color: '#fff',
                          }}
                        >
                          {el[column?.field]}
                        </span>
                      ) : (
                        <span
                          style={{
                            color: highlight?.color,
                            fontWeight: '500',
                            textTransform: 'capitalize',
                          }}
                        >
                          {el[column?.field]}
                        </span>
                      )}
                      {/*   <span
                        style={{
                          color: highlight?.color,
                          fontWeight: '500',
                          textTransform: 'capitalize',
                        }}
                      >
                        {el[column?.field]}
                      </span>
                      <span
                        style={{
                          color: '#fff',
                          padding: '0.3rem 1.2rem',
                          borderRadius: '1rem',
                          backgroundColor: highlight?.color,
                          fontWeight: '500',
                          textTransform: 'capitalize',
                        }}
                      >
                        {el[column?.field]}
                      </span> */}
                    </div>
                  );
                } else if (column?.dateField) {
                  return (
                    <div
                      key={colIndex}
                      style={{
                        width: column?.width ? `${column?.width}rem` : '15rem',
                      }}
                      className='customDataGridTheme2__row--text'
                    >
                      <span
                        style={{
                          fontWeight: '500',
                        }}
                      >
                        {column?.dataFormat === 'timeSince'
                          ? `${timeSince(el[column?.field])} ${t('Ago')}`
                          : formatDate(el[column?.field])}
                      </span>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={colIndex}
                      style={{
                        width: column?.width ? `${column?.width}rem` : '15rem',
                      }}
                      className='customDataGridTheme2__row--text'
                    >
                      <span
                        style={{
                          fontWeight: '500',
                          textTransform: 'capitalize',
                        }}
                      >
                        {column?.truncate
                          ? truncate(el[column?.field], column?.truncate)
                          : el[column?.field]}
                      </span>
                    </div>
                  );
                }
              })}

              {(onDeleteHandler || onEditHandler || onShareHandler) && (
                <div
                  className='customDataGridTheme2__row--action'
                  onClick={(e) => e.stopPropagation()}
                >
                  {onEditHandler && (
                    <div
                      className='customDataGridTheme2__row--action-edit'
                      onClick={() => onEditHandler(el)}
                    >
                      <CustomSVGs svg={editSVG} />
                    </div>
                  )}
                  {onShareHandler && (
                    <div
                      className='customDataGridTheme2__row--action-share'
                      onClick={() => onShareHandler(el)}
                    >
                      <CustomSVGs svg={shareSVG} />
                    </div>
                  )}
                  {onDeleteHandler && (
                    <div
                      className='customDataGridTheme2__row--action-delete'
                      onClick={() => onDeleteHandler(el)}
                    >
                      <CustomSVGs svg={trashSVG} />
                    </div>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CustomDataGridTheme2;
