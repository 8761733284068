import { useState } from 'react';
import '../../../../styles/pages/checkoutTracking/cssClassesHelp.scss';
import NormalInput from '../../../../ui/inputs/NormalInput';
import { useTranslation } from 'react-i18next';
import store from '../../../../store/store';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { submitLogToBugsnag } from '../../../../lib/api/log';
import { asyncHandler } from '../../../../lib/api/async-handler';
import { HELP_LINE_API } from '../../../../lib/constants/central-api';
import RibbonCard from '../../../../ui/cards/RibbonCard';
import { support2SVG, tickWithCircleSVG } from '../../../../lib/allCustomSVGs';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import loadingSpinner from '../../../../assets/gif/loading.gif';
import { motion } from 'framer-motion';

const CssClassesHelp = () => {
  const { t } = useTranslation();
  const [shopURL, setShopURL] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('null');
  const shopSlug = store.getState().user?.company?.shop_slug;

  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;

  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  /* const urlPattern = new RegExp(
    '^(https?:\\/\\/)?(www\\.)?[\\w\\d]*\\.([a-zA-Z]*\\.)?([a-zA-Z]*)?$'
  ); */

  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?(www\\.)?[\\w\\d]+\\.([a-zA-Z]{2,})(\\.[a-zA-Z]{2,})?(\\/[^\\s]*)?(\\?[^\\s]*)?$'
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setShopURL(value);
    setIsButtonVisible(value !== '' && urlPattern.test(value));
  };

  const onSubmitHandler = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setLoadingStatus('pending');

    try {
      const sendingData = JSON.stringify({
        type: 'tracking',
        data: {
          cms_url: shopURL,
          shop_slug: shopSlug,
        },
      });

      const requestInfo: RequestInfo = {
        url: HELP_LINE_API,
        method: 'POST',
        body: sendingData,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'HELP_LINE_API_POST';
      const customMsg = t('EmailSentSuccessfully');

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;
      if (res.actionType === actionType && response.success) {
        setIsSubmitted(true);
      } else {
        setIsHelpFormOpen(false);
        setLoadingStatus('null');
      }
    } catch (exception) {
      setLoadingStatus('null');
      setIsHelpFormOpen(false);
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  };

  return (
    <div className='cssClassesHelp'>
      {!isHelpFormOpen && !isSubmitted && (
        <RibbonCard
          centerText={t('SetupForMe')}
          ribbonPosition='top-right'
          ribbonText='FREE'
          baseSize={3.5}
          cardHeight={5.5}
          centerTextFontSize={1.8}
          ribbonColor='#33ce57'
          icon={support2SVG}
          onClick={() => setIsHelpFormOpen(true)}
        />
      )}
      {isHelpFormOpen && !isSubmitted && (
        <motion.div
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.98 }}
          transition={{ duration: 0.5 }}
          className='normalForm__container3'
        >
          <div className='normalForm__container3--item'>
            <div className='normalForm__container3--item-content'>
              <NormalInput
                id='company-details-shopURL'
                value={shopURL}
                changeListeners={[handleInputChange]}
                focusListeners={[]}
                placeholder={t('ShopURL')}
                classes='lg:w-[13rem]'
                innerPlaceholder={'https://example.com'}
                title={t('Expected-URL-Format-validation')}
                type='url'
                // pattern='^(https?:\/\/)?(wwww{1}\.)?[\w\d]*\.([a-zA-Z]*\.)?([a-zA-Z]*)?$'
              />
              <div className='cssClassesHelp--buttons'>
                <div
                  className='cssClassesHelp--buttons-cancel'
                  onClick={() => {
                    setIsHelpFormOpen(false);
                  }}
                >
                  {t('Cancel')}
                </div>
                {isButtonVisible && (
                  <div
                    className='cssClassesHelp--buttons-submit'
                    onClick={(e) => {
                      if (loadingStatus !== 'pending') onSubmitHandler(e);
                    }}
                  >
                    {loadingStatus === 'pending' && (
                      <img src={loadingSpinner} alt='loading' />
                    )}
                    {loadingStatus !== 'pending' && t('Submit')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}

      {isSubmitted && (
        <motion.div
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.98 }}
          transition={{ duration: 0.5 }}
          className='cssClassesHelp--submitted'
        >
          <h3>{t('EmailSentSuccessfully')}</h3>
          <CustomSVGs svg={tickWithCircleSVG} />
        </motion.div>
      )}
    </div>
  );
};

export default CssClassesHelp;
