import '../../styles/customTable/customTable.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';

interface ChangeableField {
  color: string;
  value: string | number;
  text: string;
}

interface Props {
  headerData: {
    field: string;
    headerName: string;
    width: string;
    isImageField?: boolean;
    changeableField?: ChangeableField[];
  }[];
  rowData: any;
  billData?: {
    subTotal: string;
    shipping: string;
    discount: string;
    grandTotal: string;
  };
}
const CustomTable = ({ headerData, rowData, billData }: Props) => {
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const { t } = useTranslation();
  return (
    <div className='customTable'>
      <div className='customTable__top'>
        {headerData?.map((hData: any, i: number) => {
          return (
            <div
              style={{ width: `${hData?.width}` }}
              key={i + Math.random()}
              className='customTable__top--column'
            >
              <div className='customTable__top--column-head'>
                {hData?.headerName}
              </div>
              {rowData?.map((rData: any, x: number) => {
                return (
                  <div
                    style={{ width: `${hData?.width}` }}
                    key={x + Math.random()}
                    className='customTable__top--column-rows'
                  >
                    {!hData?.isImageField && (
                      <div className='customTable__top--column-rows-textholder'>
                        {!hData?.changeableField && (
                          <p>{rData[hData?.field]}</p>
                        )}
                        {hData?.changeableField &&
                          hData?.changeableField?.map(
                            (cf: ChangeableField, c: number) => {
                              let returnHtml;
                              if (cf.value === rData[hData?.field]) {
                                returnHtml = (
                                  <>
                                    <p key={c + Math.random()}>{cf?.text}</p>
                                    <div
                                      style={{ backgroundColor: cf?.color }}
                                      className='customTable__top--column-rows-textholder-changeableField'
                                    />
                                  </>
                                );
                              }
                              return returnHtml;
                            }
                          )}
                      </div>
                    )}
                    {hData?.isImageField &&
                      (rData[hData?.field] ? (
                        <img
                          src={`${staticURL}/medium/${rData[hData?.field]}`}
                          alt='variant'
                        />
                      ) : (
                        <img
                          src={`${staticURL}/small/default.jpg`}
                          alt='variant'
                        />
                      ))}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {/* table bottom additional part start*/}
      {billData && (
        <div className='customTable__bottom'>
          <div className='customTable__bottom'>
            <div className='customTable__bottom--left'>
              <p>{t('SubTotal')}</p>
              <p>{t('Shipping')}</p>
              <p>{t('Discount')}</p>
              <h2>{t('GrandTotal')}</h2>
            </div>
            <div className='customTable__bottom--right'>
              <p>{`${billData?.subTotal}`}</p>
              <p>{`${billData?.shipping}`}</p>
              <p>{` ${billData?.discount}`}</p>
              <h2>{` ${billData?.grandTotal}`}</h2>
            </div>
          </div>
        </div>
      )}
      {/* table bottom additional part start*/}
    </div>
  );
};

export default CustomTable;
