import '../src/styles/root/index.scss';
import React from 'react';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store, { persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { HistoryRouter } from './components/routers/HistoryRouter';
import history from './history';
import './internationalization/i18n';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { APP_ENV, BUGSNAG_API_KEY } from './lib/constants/central-api';

// * disabling console.messages & debuggers in LIVE instance
if (
  !(
    process.env.NODE_ENV === 'development' ||
    window.location.href.split('/')[2].includes('dev') ||
    window.location.href.split('/')[2].includes('staging')
  )
) {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.error = () => {};
}

//? Create a Bugsnag instance
Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: APP_ENV,
  enabledReleaseStages: ['LIVE', 'STAGING'],
});
BugsnagPerformance.start({
  apiKey: BUGSNAG_API_KEY,
  releaseStage: APP_ENV,
  enabledReleaseStages: ['LIVE', 'STAGING'],
});
const ErrorBoundary =
  APP_ENV !== 'DEV'
    ? Bugsnag.getPlugin &&
      (Bugsnag.getPlugin('react')?.createErrorBoundary(React) as any)
    : ({ children }: any) => children;

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HistoryRouter history={history}>
          <App />
        </HistoryRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
