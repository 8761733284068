import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import SubPageList from '../../../../components/subPageList/SubPageList';
import { plusSignSVG, eyeSVG, trashSVG } from '../../../../lib/allCustomSVGs';
import '../../../../styles/pages/shop/autofill/autofill.scss';
import { INTEGRATION_ENDPOINT } from '../../../../lib/constants/urls';
import { useDispatch, useSelector } from 'react-redux';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../../lib/api/async-handler';
import store from '../../../../store/store';
import { RootState } from '../../../../store/store';
import { setAutofillInregrations } from '../../../../store/reducers/userSlice';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';
import ConnectionsAdd from './ConnectionsAdd';
import ConnectionsDelete from './ConnectionsDelete';
import ConnectionsView from './ConnectionsView';
import { useTranslation } from 'react-i18next';

const Connections = () => {
  const location = useLocation();
  const activeLink = location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState('pending');
  const autofillInregrations = useSelector(
    (state: RootState) => state.user.autofillInregrations
  );
  const { t } = useTranslation();
  /* const [integrations, setIntegrations] = useState<any>([]); */
  /*  */
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const pathname = location.pathname;
  let subTitle = '';

  if (pathname.includes('add')) {
    subTitle = t('AddAutofill');
  } else if (pathname.includes('delete')) {
    subTitle = t('DeleteAutofill');
  } else if (pathname.includes('view')) {
    subTitle = t('AutofillDetails');
  } else {
    subTitle = t('ManageAutofill');
  }

  const requestInfo: RequestInfo = {
    url: INTEGRATION_ENDPOINT,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'AUTOFILL_INTEGRATIONS';
  const customMsg = 'Connections Loaded Successfully.';

  useEffect(() => {
    const getIntegrations = async () => {
      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;
      console.log(res);
      if (!response) {
        setLoading('error');
      }
      if (res?.actionType === actionType && response?.success === true) {
        if (response.result.length > 0) {
          setLoading('success');
          dispatch(setAutofillInregrations(response.result));
        } else {
          setLoading('null');
          navigate('/integrations/autofill/connections/add');
        }
      } /* else {
        setLoading('error');
      } */
    };

    getIntegrations();
  }, []);

  //formating the response object according to the list component types
  const subPageListItems: any = [];

  if (autofillInregrations?.length > 0) {
    autofillInregrations?.forEach((item: any, i: number) => {
      subPageListItems.push({
        id: item.id,
        title: item.website_name,
        subTitle: item.domain,
        sKey: item.token,
      });
    });
  }

  const subPageDropdownItems = [
    {
      title: t('View'),
      subTitle: t('ViewKey'),
      id: '1',
      icon: eyeSVG,
      url: '/shop/autofill/connections/view',
    },
    {
      title: t('Delete'),
      subTitle: t('DeleteConnection'),
      id: '2',
      icon: trashSVG,
      url: '/shop/autofill/connections/delete',
    },
  ];

  //creating initial state for each list item
  const initialEditButtonState: any = {};

  autofillInregrations?.map((item: any) => {
    initialEditButtonState[item?.id] = false;
  });

  const onListItemClick = () => {
    //on list dropdown item click, make dropdown open states to initial state
    setIsListMenuOpen(initialEditButtonState);
  };

  const [isListMenuOpen, setIsListMenuOpen] = useState(initialEditButtonState);

  const onListMenuClick = (itemId: any) => {
    setIsListMenuOpen((isListMenuOpen: any) => ({
      ...isListMenuOpen,
      [itemId]: !isListMenuOpen[itemId],
    }));
  };

  const autofillList = () => {
    return (
      <SubPageList
        /* mainTitle='Autofill integrations list'
        subTitle='View or delete your integrations' */
        onListMenuClick={onListMenuClick}
        onListItemClick={onListItemClick}
        isListMenuOpen={isListMenuOpen}
        items={subPageListItems}
        dropdownItems={subPageDropdownItems}
      />
    );
  };

  const getBackButtonRoutes = () => {
    let backButtonLink;
    if (
      pathname.includes('add') ||
      pathname.includes('view') ||
      pathname.includes('delete')
    ) {
      backButtonLink = '/integrations/autofill/connections';
    } else {
      backButtonLink = '/integrations';
    }

    return backButtonLink;
  };

  return (
    <SubPageInnerContentHolder
      title={t('Connections')}
      subTitle={subTitle}
      backBtnLink={isWhiteLabel ? undefined : getBackButtonRoutes()}
      mainBtnIcon={plusSignSVG}
      mainBtnTitle={t('Create')}
      mainBtnLink='/integrations/autofill/connections/add'
    >
      {/* {activeLink === '/shop/autofill/connections' && autofillList()} */}
      {activeLink === '/integrations/autofill/connections' && (
        <>
          {loading === 'pending' && <LoadingSpinner />}
          {loading === 'error' && (
            <NoData
              title={t('ErrorFetchingData')}
              subTitle={t('SomethingWentWrong-msg')}
            />
          )}
          {autofillInregrations?.length > 0 && autofillList()}
        </>
      )}

      <Routes>
        <Route path='/add' element={<ConnectionsAdd />} />
        <Route path='/delete/:integrationId' element={<ConnectionsDelete />} />
        <Route path='/view/:integrationId' element={<ConnectionsView />} />
      </Routes>
    </SubPageInnerContentHolder>
  );
};

export default Connections;
