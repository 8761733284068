import React, { useState } from 'react';
import '../../styles/inputs/textArea.scss';

type props = {
  required?: boolean;
  value?: string;
  placeholder?: string;
  changeListeners?: ((
    e: React.ChangeEvent<HTMLTextAreaElement>,
    option?: string
  ) => void)[];
  focusListeners?: ((e: React.FocusEvent) => void)[];
  id: string;
  classes?: string;
  innerPlaceholder?: string;
  readonly?: boolean;
  title?: string;
  defaultValue?: string;
  autoFocus?: boolean;
  labelClasses?: string;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, props>(
  (
    {
      value,
      changeListeners,
      focusListeners,
      placeholder = '',
      required,
      id,
      classes = '',
      innerPlaceholder,
      readonly,
      title,
      defaultValue,
      autoFocus,
      labelClasses,
    }: props,
    ref
  ) => {
    const [innerValue, setValue] = useState<any>(value);
    const [focused, setFocused] = useState<boolean>(false);
    const [onlyOnFocus, setOnlyOnFocus] = useState<boolean>(false);

    function handleChangeValue(event: React.ChangeEvent<HTMLTextAreaElement>) {
      setValue(event.target.value);
      changeListeners?.forEach((listener) => listener(event));
    }

    function handleFocusElement(event: React.FocusEvent<HTMLTextAreaElement>) {
      focusListeners?.forEach((listener) => listener(event));
      setOnlyOnFocus(true);
    }

    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocused(true);
      setOnlyOnFocus(false);
    };

    return (
      <div className='textArea-outer'>
        <div className='textArea'>
          <textarea
            ref={ref}
            id={id}
            className={classes}
            value={innerValue}
            onChange={handleChangeValue}
            onFocus={handleFocusElement}
            required={required}
            placeholder={innerPlaceholder}
            readOnly={readonly}
            onBlur={handleBlur}
            data-focused={focused.toString()}
            title={title}
            defaultValue={defaultValue}
            autoFocus={autoFocus}
          />
          <label className={labelClasses} htmlFor={id}>
            {placeholder}
          </label>
        </div>
        <div className='textArea-validation-text'>{onlyOnFocus && title}</div>
      </div>
    );
  }
);

export default TextArea;
