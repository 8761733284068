// Function to increase or decrease the color brightness
export default function adjustColorBrightness(color: string, amount: number): string {
    // Remove the "#" symbol from the color string
    const parsedColor = color.replace("#", "");
    // Convert the parsed color string to a decimal number
    const num = parseInt(parsedColor, 16);
  
    // Adjust the red component of the color
    let r = (num >> 16) + amount;
    // Ensure the red value stays within the valid range of 0-255
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
  
    // Adjust the green component of the color
    let g = ((num >> 8) & 0x00FF) + amount;
    // Ensure the green value stays within the valid range of 0-255
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
  
    // Adjust the blue component of the color
    let b = (num & 0x0000FF) + amount;
    // Ensure the blue value stays within the valid range of 0-255
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
  
    // Convert the adjusted RGB values back to a hexadecimal string
    return `#${(b | (g << 8) | (r << 16)).toString(16).padStart(6, "0")}`;
  }
  
  
/*   
// Usage example
  const mainThemeColor = "#111111";
  const increasedColor = adjustColorBrightness(mainThemeColor, 10); // Increase brightness by 10
  const decreasedColor = adjustColorBrightness(mainThemeColor, -10); // Decrease brightness by 10
  
  console.log(increasedColor); // "#1a1a1a"
  console.log(decreasedColor); // "#000000" 
  */