// convert (2022-10-19T05:38:59.000000Z) to => (2022-10-19 05:38 AM)
export default function formatDate(isoString: string) {
  const date = new Date(isoString);
  const DateComponenents = (() => {
    const fields = ['YYYY', 'MM', 'DD', 'HH', 'mm', 'ss', 'SSS'];
    const pattern =
      /^(\d{4})\-(\d{2})\-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/;
    return (date: any) => {
      return date
        .toISOString()
        .match(pattern)
        .slice(1)
        .reduce((r: any, v: any, i: any) => {
          return Object.assign(r, { [fields[i]]: v });
        }, {});
    };
  })();
  let c = DateComponenents(date); // Extract the componenents
  let hours: any = parseInt(c['HH'], 10); // Grab the 24-hour piece
  let meridiem = hours < 12 ? 'AM' : 'PM'; // Determine AM or PM
  hours %= 12; // Modular 0 - 12
  if (hours === 0) hours = 12; // Shift 0am to 12am
  hours = ('00' + hours).substr(-2); // Pad the hours
  return `${c['YYYY']}-${c['MM']}-${c['DD']} ${hours}:${c['mm']} ${meridiem}`;
}
