import React, { useState } from 'react';
import '../../../../styles/pages/checkoutTracking/recommendationsTrial.scss';
import { useTranslation } from 'react-i18next';
import make_subscriptions_api_call from '../../../../lib/api/ai-tracker/subscriptions/make_subscriptions_api_call';

type Props = {
  setIsTrialPopupOpen: (value: boolean) => void;
  getSubscriptions: () => void;
};

const RecommendationsTrial = ({
  setIsTrialPopupOpen,
  getSubscriptions,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<'pending' | 'initial'>('initial');

  const onMakeSubscription = async () => {
    setLoading('pending');
    try {
      const res = await make_subscriptions_api_call('true', 0);

      if (res) {
        getSubscriptions();
        setLoading('initial');
        setIsTrialPopupOpen(false);
      } else {
        setLoading('initial');
        setIsTrialPopupOpen(false);
      }
    } catch (error) {
      console.error('Error making subscriptions:', error);
      setLoading('initial');
      setIsTrialPopupOpen(false);
    }
  };

  return (
    <div className='recommendationsTrial'>
      <div className='recommendationsTrial__title'>
        <h2>{t('FreeTrial')}</h2>
        <span>{t('GetFreeRecommendationsFor14Days')}</span>
      </div>
      <div
        className='recommendationsTrial__button'
        onClick={onMakeSubscription}
      >
        {loading === 'pending' ? t('Loading...') : t('StartTrial')}
      </div>
    </div>
  );
};

export default RecommendationsTrial;
