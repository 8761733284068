import generateRandomString from './generateRandomString';

export default function generateSKU(productName: string) {
  //get a random string with a length of 7 characters
  const randomString = generateRandomString(7);

  if (productName === '') {
    const generatedSKU = `${generateRandomString(7)}-${randomString}`;
    return generatedSKU;
  } else {
    const formattedProductName = productName.replaceAll(' ', '-');
    const generatedSKU = `${formattedProductName}-${randomString}`;

    return generatedSKU;
  }
}
