import store from '../../store/store';
import { LOGOUT_API } from '../constants/central-api';
import { submitLogToBugsnag } from './log';
import { asyncHandler } from '../../lib/api/async-handler';
import { RequestInfo } from '../../interfaces/RequestInfo';

export async function tokenRevokeAtLogOutUser() {
  try {
    const key = store.getState().auth.authorization.accessToken?.sellerToken;
    if (key && key !== '') {
      const requestInfo: RequestInfo = {
        url: `${LOGOUT_API}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${key}`,
        },
      };

      const actionType = 'SELLER_LOGOUT';
      const customMsg = 'Seller Logged Out Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;

      if (
        res.actionType === actionType &&
        response.success === true &&
        response?.message === 'You are logged out.'
      ) {
        return response.success;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (exception) {
    console.log('Error while revoking token', exception);
    submitLogToBugsnag('error', `Error while revoking toke : ${exception}`);
  }
}
