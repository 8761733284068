const version = 'v1';

let basePath = ''; // base URL of the APIs
let env: 'DEV' | 'TEST' | 'STAGING' | 'LIVE';

let stripeSubscriptionPriceIDs = {
  subscription14: '',
  subscription49: '',
};

if (
  window.location.href.split('/')[2].includes('localhost') ||
  process.env.NODE_ENV === 'development' ||
  window.location.href.split('/')[2].includes('dev')
) {
  basePath = 'https://wl-seller-dev.adup.io/api/';
  env = 'DEV';
  stripeSubscriptionPriceIDs = {
    //* AdUp Stripe Account price IDs *//
    // subscription14: 'price_1MAVk3LZxwOJ6sIgRIpnspxr',
    // subscription49: 'price_1MAVlOLZxwOJ6sIgDGagqVV7',

    //* Kodeia Stripe Account price IDs *//
    subscription14: 'price_1MHPttLYLeeIPJY6ttHdICBG',
    subscription49: 'price_1MHPttLYLeeIPJY6C49wCPOd',
  };
} else if (window.location.href.split('/')[2].includes('test')) {
  basePath = 'https://wl-seller-test.adup.io/api/';
  env = 'TEST';
  stripeSubscriptionPriceIDs = {
    //* using DEV price IDs at TEST env *//
    subscription14: 'price_1MHPttLYLeeIPJY6ttHdICBG',
    subscription49: 'price_1MHPttLYLeeIPJY6C49wCPOd',
  };
} else if (window.location.href.split('/')[2].includes('staging')) {
  basePath = 'https://wl-seller-staging.adup.io/api/';
  env = 'STAGING';
  stripeSubscriptionPriceIDs = {
    //* using LIVE price IDs at Staging env *//
    subscription14: 'price_1M9QXvLZxwOJ6sIgPGS6mPNk',
    subscription49: 'price_1M9QYMLZxwOJ6sIgX6oLDjGe',
  };
} else {
  basePath = 'https://wl-seller-main.adup.io/api/';
  env = 'LIVE';
  stripeSubscriptionPriceIDs = {
    // subscription14: 'price_1MPjwuLZxwOJ6sIgqcy8NWgU', //? Testing Id with low price
    subscription14: 'price_1M9QXvLZxwOJ6sIgPGS6mPNk',
    subscription49: 'price_1M9QYMLZxwOJ6sIgX6oLDjGe',
  };
}

export const NEW_BASE_URL = basePath;
export const STRIPE_SUBSCRIPTION_PRICE_ID = stripeSubscriptionPriceIDs;

export const SELLER_API_URL = `${NEW_BASE_URL}${version}/`;

/* ----------EVENTS ENDPOINT----------- */
export const EVENTS_URL = `${NEW_BASE_URL}events/`;

/* ----------INIT ENDPOINT----------- */
export const INIT_URL = SELLER_API_URL + 'init';

/* ----------AUTHENTICATION ENDPOINTS----------- */
export const LOGIN_VERIFICATION_ENDPOINT = SELLER_API_URL + 'login';
export const GOOGLE_LOGIN_ENDPOINT = SELLER_API_URL + 'googlesignin';
export const REGISTRATION_ENDPOINT = SELLER_API_URL + 'register';
export const ONBOARDING_ENDPOINT = SELLER_API_URL + 'onboarding';
export const LOGOUT_ENDPOINT = SELLER_API_URL + 'logout';
export const SELLER_AUTH_ENDPOINT = `${NEW_BASE_URL}authorize`;

/* ----------FORGOT PASSWORD ENDPOINTS----------- */
export const FORGOT_PASSWORD_ENDPOINT = SELLER_API_URL + 'forgot';

/* ----------PSP ENDPOINTS----------- */
export const PSP_ENDPOINT = SELLER_API_URL + 'psp';
export const MODULES_PSP_ENDPOINT = SELLER_API_URL + 'modules/psp';

/* ----------CMS ENDPOINTS----------- */
export const CMS_ENDPOINT = SELLER_API_URL + 'cms';
export const MODULES_CMS_ENDPOINT = SELLER_API_URL + 'modules/cms';
export const CMS_EDIT_ENDPOINT = SELLER_API_URL + 'cms/edit';
export const SYNC_HISTORY_ENDPOINT = SELLER_API_URL + 'sync_history';
export const IMPORT_CSV_ENDPOINT = SELLER_API_URL + 'importcsv';

/* ----------AUTOFILL ENDPOINTS----------- */
export const INTEGRATION_ENDPOINT = SELLER_API_URL + 'modules/integration';
export const INTEGRATION_LIST_ENDPOINT =
  SELLER_API_URL + 'modules/integration/list';

/* ----------PRODUCT ENDPOINTS----------- */
export const PRODUCTS_ENDPOINT = SELLER_API_URL + 'products';

/* ----------ORDER ENDPOINTS----------- */
export const ORDERS_ENDPOINT = SELLER_API_URL + 'orders';

/* ----------SHIPPING ENDPOINTS----------- */
export const SHIPPING_ENDPOINT = SELLER_API_URL + 'shipping_rates';

/* ----------ONBOARDING EVENTS ENDPOINTS----------- */
export const ONBOARDING_EVENTS_ENDPOINTS = EVENTS_URL + 'onboarding';

/* ----------SUBSCRIPTION ENDPOINTS----------- */
export const STRIPE_SUBSCRIPTION_ENDPOINT =
  SELLER_API_URL + 'billing/subscription';

/* ----------WEBHOOK ENDPOINTS----------- */
export const WEBHOOK_ENDPOINT = SELLER_API_URL + 'webhooks';

/* ----------SETTINGS ENDPOINTS----------- */
export const SETTINGS_ENDPOINT = SELLER_API_URL + 'settings';

/* ----------ADMIN SHOPS ENDPOINTS----------- */
export const ADMIN_SHOPS_ENDPOINT = SELLER_API_URL + 'shops';

/* ----------ADUP PAY ENDPOINTS----------- */
export const ADUP_PAY_ENDPOINT = SELLER_API_URL + 'aduppay';

/* ----------LOG ENDPOINTS----------- */
export const LOG_ENDPOINT = SELLER_API_URL + 'log';

/* ----------PLUGIN PREAUTH ENDPOINTS----------- */
export const PLUGIN_PREAUTH_ENDPOINT = SELLER_API_URL + 'plugin-preauth';

/* ----------UNSUBSCRIBE ENDPOINTS----------- */
export const UNSUBSCRIBE_ENDPOINT = SELLER_API_URL + 'updatenewsletter';

/* ----------TWO FACTOR AUTHENTICATION ENDPOINTS----------- */
export const TWO_FACTOR_AUTHENTICATION_SETTINGS_ENDPOINT =
  SELLER_API_URL + '2fa';

export const TWO_FACTOR_AUTHENTICATION_LOGIN_ENDPOINT =
  SELLER_API_URL + '2faauthorize';

/* ----------CHAT GTP ENDPOINTS----------- */
export const CHAT_GPT_ENDPOINT = SELLER_API_URL + 'ai';
