import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

type Props = {
  apiResponse: any;
  setSelectedElement: (selectedDevice: string) => void;
};
const OverallCTRBarChart = ({ apiResponse, setSelectedElement }: Props) => {
  const [chartData, setChartData] = useState<any[]>([]);

  const getConversionRateData = async (res: any) => {
    if (res?.type === 'overall' && res?.data) {
      if (res.data?.length === 0) {
        return;
      }

      setChartData(res?.data);
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  const valueFormatter = (value: number | null) => `${value}%`;

  const data = {
    data: chartData,
    valueFormatter,
  };

  const onItemClickHandler = (e: any, d: any) => {
    if (d?.dataIndex !== undefined) {
      setSelectedElement(data?.data[d.dataIndex]?.button_type);
    }
  };

  return (
    <>
      <div className='chart__bottom--content-chart'>
        <div
          style={{ width: '100%' }}
          className='chart__bottom--content-chart-pie'
        >
          <BarChart
            dataset={chartData}
            xAxis={[
              {
                scaleType: 'band',
                dataKey: 'button_label',
                label: 'Element',
              },
            ]}
            yAxis={[
              {
                label: 'CTR (%)',
                min: 0,
                max: 100,
              },
            ]}
            series={[{ dataKey: 'ctr', valueFormatter }]}
            colors={['#8884d8']}
            onItemClick={onItemClickHandler}
            sx={(theme) => ({
              [`.${axisClasses.root}`]: {
                [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                  stroke: '#8b909a',
                  strokeWidth: 3,
                },
                [`.${axisClasses.tickLabel}`]: {
                  fill: '#8b909a',
                },
              },
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                fill: '#8b909a',
                // transform: 'translate(-10px, -10px)',
              },
              [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                fill: '#8b909a', // Change color of x-axis label
              },
              [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
                fill: '#8b909a', // Change color of x-axis tick labels
              },
              border: '1px solid #8b909a25',
              backgroundImage:
                'linear-gradient(#8b909a25 1px, transparent 1px), linear-gradient(90deg, #8b909a25 1px, transparent 1px)',
              backgroundSize: '50px 50px',
              backgroundPosition: '0px 0px, 0px 0px',
              ...theme.applyStyles('dark', {
                borderColor: '#8b909a25',
                backgroundImage:
                  'linear-gradient(#8b909a25 1px, transparent 1px), linear-gradient(90deg, #8b909a25 1px, transparent 1px)',
              }),
            })}
          />
        </div>
      </div>
    </>
  );
};

export default OverallCTRBarChart;
