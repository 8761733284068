import React, { useEffect, useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

type Props = {
  apiResponse: any;
};

const DayOfWeekCRLineChart = ({ apiResponse }: Props) => {
  const [chartData, setChartData] = useState<any[]>([]);

  const getConversionRateData = async (res: any) => {
    if (res?.type === 'DayOfWeek' && res?.data) {
      if (res.data?.length === 0) {
        return;
      }

      setChartData(res?.data);
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  const valueFormatter = (value: number | null) => `${value}%`;

  return (
    <>
      <div className='chart__bottom--content-chart'>
        <div
          style={{ width: '100%' }}
          className='chart__bottom--content-chart-pie'
        >
          <LineChart
            dataset={chartData}
            xAxis={[
              { scaleType: 'band', dataKey: 'day_of_week', label: 'Day' },
            ]}
            yAxis={[
              {
                label: 'Conversion Rate (%)',
                min: 0,
                max: 100,
              },
            ]}
            series={[{ dataKey: 'conversion_rate', valueFormatter }]}
            height={300}
            colors={['#8884d8']}
            margin={{ left: 50, right: 30, top: 30, bottom: 45 }}
            grid={{ vertical: true, horizontal: true }}
            sx={(theme) => ({
              [`.${axisClasses.root}`]: {
                [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                  stroke: '#8b909a',
                  strokeWidth: 3,
                },
                [`.${axisClasses.tickLabel}`]: {
                  fill: '#8b909a',
                },
              },
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                fill: '#8b909a',
                // transform: 'translate(-10px, -10px)',
              },
              [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                fill: '#8b909a', // Change color of x-axis label
              },
              [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
                fill: '#8b909a', // Change color of x-axis tick labels
              },
            })}
          />
        </div>
      </div>
    </>
  );
};

export default DayOfWeekCRLineChart;
