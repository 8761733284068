const getAllCountryNamesArray = (countriesDataObj: any) => {
  const countriesDataArr = Object.values(countriesDataObj);
  const countryNamesArr = countriesDataArr.map((item: any) => {
    return item.name;
  });

  return countryNamesArr;
};

export default getAllCountryNamesArray;
