import React, { useState } from 'react';
import { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../styles/components/pageWrappers/pageWrapperTheme2.scss';
import SidebarTheme2 from '../sidebar/SidebarTheme2';
import { sellerAllSidebarLinks } from '../../lib/constants/sidebarLinks';
import Footer from '../footer/Footer';
import {
  toggleSidebarDrawer,
  toggleSidebarSize,
} from '../../store/reducers/uiSlice';

const PageWrapperTheme2: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSidebarDrawerOpen = useSelector(
    (state: RootState) => state.UI.isSidebarDrawerOpen
  );

  const isSidebarMinimized = useSelector(
    (state: RootState) => state.UI.isSidebarMinimized
  );

  const changeSidebarSize = () => {
    dispatch(toggleSidebarSize());
  };

  return (
    <div
      data-is-sidebar-minimized-wrapper={isSidebarMinimized?.toString()}
      data-is-sidebar-open={isSidebarDrawerOpen?.toString()}
      className='pageWrapperTheme2--outer'
    >
      <div className='pageWrapperTheme2'>
        <div className='pageWrapperTheme2__content'>
          <div className='pageWrapperTheme2__content--sidebar'>
            <SidebarTheme2
              changeSidebarSize={changeSidebarSize}
              isSidebarMinimized={isSidebarMinimized}
              mainSubTitle={t('Autofill')}
              items={sellerAllSidebarLinks}
            />
          </div>
          <div
            onClick={() => {
              if (isSidebarDrawerOpen) {
                dispatch(toggleSidebarDrawer());
              }
            }}
            className='pageWrapperTheme2__content--page'
          >
            {children}
          </div>
        </div>
      </div>
      <div className='pageWrapperTheme2--footer'>
        <Footer />
      </div>
    </div>
  );
};

export default PageWrapperTheme2;
