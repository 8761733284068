import makeFirstLetterCapital from './makeFirstLetterCapital';

const createSelectOptionValueObject = (arr: string[]) => {
  const finalArr = [];
  for (let i = 0; i < arr?.length; i++) {
    finalArr?.push({
      OptionValue: arr[i],
      OptionName: makeFirstLetterCapital(arr[i]?.replace('_', ' ')),
    });
  }
  return finalArr;
};

export default createSelectOptionValueObject;
