import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/pages/pageNotFound.scss';

const PageNotFound = () => {
  return (
    <div className='container'>
      <h1>404</h1>
      <div className='cloak__wrapper'>
        <div className='cloak__container'>
          <div className='cloak'></div>
        </div>
      </div>
      <div className='info'>
        <h2>We can't find that page</h2>
        <Link to={'/'}>
          <button /* href="/" */>Home</button>
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
