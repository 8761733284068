import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router-dom';
import AllOrders from './AllOrders';
import OrderDetailPage from './OrderDetailPage';
import PageWrapperMain from '../../components/page-wrappers/PageWrapperMain';

const OrdersPage = () => {
  const location = useLocation();
  const activeLink = location.pathname.substring(1);
  const [t] = useTranslation();
  /* ----- page number states of all orders page start----- */
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  /* ----- page number states of all orders page  end  ----- */

  /*------------------------- FILTER STATE START ------------------------- */

  return (
    <PageWrapperMain>
      <Routes>
        <Route
          path='/*'
          element={
            <AllOrders
              currentPageNumber={currentPageNumber}
              setCurrentPageNumber={setCurrentPageNumber}
            />
          }
        />
        <Route path='/:orderId' element={<OrderDetailPage />} />
      </Routes>
    </PageWrapperMain>
  );
};

export default OrdersPage;
