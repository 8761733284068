import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeOfDayCRLineChart from './TimeOfDayCRLineChart';
import DayOfWeekCRLineChart from './DayOfWeekCRLineChart';
import ChartWithConfig from '../../../../../../components/charts/ChartWithConfig';
import {
  conversionRateByDayOfWeekDummyData,
  conversionRateByTimeOfDayDummyData,
} from '../../../../../../lib/constants/graphDummyData/conversionRateDummyData';

const MainTimeAndDayCRChart = () => {
  const { t } = useTranslation();

  //Group by options
  const [groupBy, setGroupBy] = useState<string>('time_of_day');
  const groupByOptionsArr = [
    { OptionValue: 'time_of_day', OptionName: t('TimeOfDay') },
    { OptionValue: 'day_of_week', OptionName: t('DayOfWeek') },
  ];

  const [title, setTitle] = useState<string>(t('ConversionRateByTimeOfDay'));

  useEffect(() => {
    if (groupBy === 'time_of_day') {
      setTitle(t('ConversionRateByTimeOfDay'));
    } else if (groupBy === 'day_of_week') {
      setTitle(t('ConversionRateByDayOfWeek'));
    }
  }, [groupBy]);

  function renderChart() {
    if (groupBy === 'time_of_day') {
      return (
        <TimeOfDayCRLineChart
          apiResponse={{
            type: 'TimeOfDay',
            data: conversionRateByTimeOfDayDummyData,
          }}
        />
      );
    } else {
      return (
        <DayOfWeekCRLineChart
          apiResponse={{
            type: 'DayOfWeek',
            data: conversionRateByDayOfWeekDummyData,
          }}
        />
      );
    }
  }

  return (
    <ChartWithConfig
      groupBy={groupBy}
      setGroupBy={setGroupBy}
      groupByOptionsArr={groupByOptionsArr}
      title={title}
    >
      {renderChart()}
    </ChartWithConfig>
  );
};

export default MainTimeAndDayCRChart;
