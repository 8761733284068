//* Check if a string is empty or not
export default function isNotEmptyString(string: string | null | undefined) {
  if (
    string === null ||
    string === undefined ||
    string === '' ||
    string === 'null' ||
    string?.trim()?.length === 0
  ) {
    return false;
  } else {
    return true;
  }
}
