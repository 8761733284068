// import { useState } from 'react';
import '../../styles/components/headers/headerTheme2.scss';
import Topbar from '../topbar/Topbar';
import { useLocation, useNavigate } from 'react-router-dom';
// import { makeFirstLetterCapital } from '../../lib/helperFunctions';
import userPNG from '../../imgs/user.jpg';
import ProfileDropdown from '../dropdowns/ProfileDropdown';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';
// import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
// import { plusSignSVG } from '../../lib/allCustomSVGs';
import { useComponentVisibility } from '../../customHooks/useComponentVisibility';
// import CommonDropdown from '../dropdowns/CommonDropdown';
import { HOME_ROUTE } from '../../lib/constants/route';

interface Props {
  toggleSidebarDrawer: any;
  isSidebarDrawerOpen: boolean;
}

const HeaderTheme2 = ({ toggleSidebarDrawer, isSidebarDrawerOpen }: Props) => {
  /*  const shopSlug = useSelector(
      (state: RootState) => state.user.company?.shop_slug
    );
    const appTheme = useSelector((state: RootState) => state.UI.theme.dark);
    const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] =
      useState(false);
  
      const dispatch = useDispatch(); */
  const location = useLocation();
  const { t } = useTranslation();
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);
  const navigate = useNavigate();
  /* console.log(object) */
  const activeLink = location.pathname.split('/')[1];
  let title = activeLink;

  if (activeLink === 'shop') title = t('Shop');
  if (activeLink === 'products') title = t('Products');
  if (activeLink === 'orders') title = t('Orders');
  /* 
    console.log(linksWithSubLinks[activeLink]); */

  /* useComponentVisibility codes start */

  /* ========================Profile Toggle Logic Start============================ */
  const {
    ref: profileRef,
    isComponentVisible: isProfileVisible,
    setIsComponentVisible: setIsProfileVisible,
  } = useComponentVisibility<HTMLLIElement>(false);
  const handleProfileClick = () => {
    setIsProfileVisible((prev) => !prev);
  };

  const handleProfileItemClick = () => {
    setIsProfileVisible(false);
    // Handle specific actions when a dropdown item is clicked
  };
  /* ========================Profile Toggle Logic End============================= */

  /* ========================Dropdown Toggle Logic Start============================ */
  /*  const {
    ref: dropdownRef,
    isComponentVisible: isDropdownVisible,
    setIsComponentVisible: setIsDropdownVisible,
  } = useComponentVisibility<HTMLLIElement>(false); */

  /* const handleDropdownClick = () => {
    setIsDropdownVisible((prev) => !prev);
  }; */

  /*  const handleDropdownItemClick = () => {
    setIsDropdownVisible(false);
    // Handle specific actions when a dropdown item is clicked
  }; */
  /* ========================Dropdown Toggle Logic End============================= */

  /* useComponentVisibility codes end */

  /* const RenderLogo = ()=>{
        if(merchantTheme.merchant_logo_light && isDarkMode){
          return (
            <img
          
            src={`${staticURL}/medium/${merchantTheme.merchant_logo_light}`}
            alt='logo'
          />
          )
        }else if(merchantTheme.merchant_logo_dark && !isDarkMode){
          return (
            <img
          
            src={`${staticURL}/medium/${merchantTheme.merchant_logo_dark}`}
            alt='logo'
          />
          )
        }else if(isDarkMode){
          return (<img src={logoLight} alt='logo' />)
        }else{
          return (<img src={logoDark} alt='logo' />)
        }
      } */

  const RenderLogo = () => {
    if (
      merchantTheme.merchant_logo_dark &&
      merchantTheme.merchant_name === 'Buckaroo'
    ) {
      return (
        <img
          src={`${staticURL}/medium/${merchantTheme.merchant_logo_dark}`}
          alt='logo'
        />
      );
    } else if (merchantTheme.merchant_logo_light) {
      return (
        <img
          src={`${staticURL}/medium/${merchantTheme.merchant_logo_light}`}
          alt='logo'
        />
      );
    } else {
      return (
        <img
          style={{ borderRadius: '1rem' }}
          src={`${staticURL}/medium/default.jpg`}
          alt='logo'
        />
      );
    }
  };

  //   const [inputValue, setInputValue] = useState('');

  return (
    <>
      <Topbar />
      {/* top bar only for mobile view */}
      <div
        data-sidebar-open={isSidebarDrawerOpen.toString()}
        className='headerTheme2'
        data-header-is-dark-mode={isDarkMode.toString()}
      >
        <div className='headerTheme2__left'>
          <div
            className='headerTheme2__left--logo'
            onClick={() => {
              navigate(HOME_ROUTE);
            }}
          >
            {RenderLogo()}
          </div>
          {/* <div className='headerTheme2__left--title'>{title}</div> */}
          <ul className='headerTheme2__left--list'>
            {/*  <ExpandingInput
                value={inputValue}
                onChange={setInputValue}
                icon={searchSVG}
                id='header-search'
                type='search'
                placeholder='Search...'
              /> */}

            {/*  <li ref={dropdownRef} onClick={handleDropdownClick}>
              <CustomSVGs svg={plusSignSVG} />
              {isDropdownVisible && (
                <div onClick={handleDropdownItemClick}>
                  <CommonDropdown />
                </div>
              )}
            </li> */}
          </ul>
        </div>
        <div className='headerTheme2__right'>
          <ul className='headerTheme2__right--list'>
            {/* <li
                title={appTheme ? t('SwitchToLightMode') : t('SwitchToDarkMode')}
                onClick={() => {
                  dispatch(setAppThemeMode());
                }}
              >
                {appTheme ? (
                  <CustomSVGs svg={sunSVG} />
                ) : (
                  <CustomSVGs svg={moonSVG} />
                )}
              </li> */}
            {/*  <li
                onClick={() => {
                  setIsCountryDropdownOpen(!isCountryDropdownOpen);
                  setIsNotificationDropdownOpen(false);
                  setIsProfileDropdownOpen(false);
                }}
              >
                <img src={enFlag} alt='flag' />
              </li>
              <li
                onClick={() => {
                  setIsNotificationDropdownOpen(!isNotificationDropdownOpen);
                  setIsCountryDropdownOpen(false);
                  setIsProfileDropdownOpen(false);
                }}
              >
                <CustomSVGs svg={bellSVG} />
              </li> */}
            {/*  <div>
                <HeaderStoreButton
                  onClick={(e) => {
                    window.open(
                      `${SHOPPER_APP_URL}${shopSlug?.replaceAll(' ', '')}`,
                      '_blank'
                    );
                  }}
                  type={'button'}
                  value={t('VisitStore')}
                  classes='rotate-180-Deg'
                />
              </div> */}
            <li ref={profileRef} onClick={handleProfileClick}>
              <img src={userPNG} alt='profile-pic' />
              {isProfileVisible && (
                <div onClick={handleProfileItemClick}>
                  <ProfileDropdown />
                </div>
              )}
            </li>
          </ul>
          {/*  {isNotificationDropdownOpen && <NotificationDropdown />}
            {isCountryDropdownOpen && <CountryDropdown />} */}
        </div>
      </div>
    </>
  );
};

export default HeaderTheme2;
