import {
  setAppLanguage,
  setAvailableCountries,
  setBrowser,
  setIPCountry,
  setStaticURL,
} from '../../store/reducers/appSlice';
import {
  updateProfile,
  setUserCompanyDetails,
  setHasInitPSP,
  setUserCompanySettings,
  updateMerchant,
} from '../../store/reducers/userSlice';
import store from '../../store/store';

import { Country } from '../../store/reducers/appSlice';
import { setAppThemeType, setIsWhiteLabel } from '../../store/reducers/uiSlice';

/**
 * Update the state using the data received from the /init response
 * @param { any } initResponseData  The data sent by a successful init response
 */
export function initResponseStateUpdate(initResponseData: any) {
  const { user_data, app_data, merchant, shop, seller } = initResponseData;

  const { countries, static_base_url } = app_data;

  if (shop && shop?.length > 0) {
    const {
      id,
      currency,
      name,
      slug,
      products_count, //! missing in API
      cms, //! missing in API
      psp, //! missing in API
      settings,
      location,
      is_active,
      initial,
    } = shop[0];
    store.dispatch(
      setUserCompanyDetails({
        name,
        currency,
        shop_id: id?.toString(),
        shop_slug: slug,
        location: location ? location : '',
        is_active: is_active ? is_active : false,
        initial: initial ? initial : '',
      })
    );

    store.dispatch(setUserCompanySettings(settings));

    //set login init product count and cms status
    if (!products_count && cms?.length < 1) {
      // store.dispatch(setShouldShowProductWizard(true));
      // store.dispatch(setIsProductWizardOpen(true));
    }
    if (psp?.length > 0) {
      store.dispatch(setHasInitPSP(true));
    }
  }

  if (static_base_url) {
    store.dispatch(setStaticURL(static_base_url));

    //Remove this when the API is ready
    store.dispatch(setAppThemeType('2'));
  }

  if (countries) {
    const countriesArray: Country[] = [];

    countries.map((countryObject: any) => {
      const countryCode = Object.keys(countryObject)[0];
      const countryInfo = countryObject[countryCode];
      countriesArray.push({
        countryCode,
        name: countryInfo.name,
        dialCode: countryInfo.dial_code,
        flag: countryInfo.flag,
      });
    });

    store.dispatch(setAvailableCountries({ countries: countriesArray }));
    store.dispatch(setIPCountry(user_data?.device_country?.countryCode));
  }

  //getting browser language
  store.dispatch(setAppLanguage(user_data?.browser_language));

  //getting browser name
  store.dispatch(setBrowser(user_data?.browser_name));

  if (seller?.seller_id) {
    store.dispatch(
      updateProfile({
        seller_id: seller?.seller_id?.toString() ?? '',
        first_name: seller?.first_name ?? '',
        last_name: seller?.last_name ?? '',
        country_code:
          seller?.country_code ?? user_data?.device_country?.countryCode ?? '',
        phone: seller?.contact ?? '',
        email: seller?.email ?? '',
        language: seller?.language ?? user_data?.browser_language ?? '',
      })
    );
  }

  if (merchant?.id) {
    store.dispatch(updateMerchant(merchant));

    if (merchant?.is_white_label) {
      store.dispatch(setIsWhiteLabel(true));
    }
  }
}
