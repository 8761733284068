import React, { useEffect, useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

type Props = {
  apiResponse: any;
  groupBy: string;
};
const AverageCartValueLineChart = ({ apiResponse, groupBy }: Props) => {
  const [chartData, setChartData] = useState<any[]>([]);
  const [xAxisKey, setXAxisKey] = useState<string>('month');

  const getConversionRateData = async (res: any) => {
    if (res !== 'initial' && Object.keys(res)?.length === 0) {
      return;
    }
    if (res !== 'initial' && Object.keys(res)?.length > 0) {
      setChartData(res);
    }
  };

  useEffect(() => {
    if (groupBy === 'last_3_months') {
      setXAxisKey('month');
    } else if (groupBy === 'last_4_weeks') {
      setXAxisKey('week');
    } else if (groupBy === 'last_7_days') {
      setXAxisKey('day');
    }
  }, [groupBy]);

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  const valueFormatter = (value: number | null) => `${value}€`;

  return (
    <>
      <div className='chart__bottom--content-chart'>
        <div
          style={{ width: '100%', height: '100%' }}
          className='chart__bottom--content-chart-pie'
        >
          <LineChart
            dataset={chartData}
            xAxis={[
              { scaleType: 'band', dataKey: `${xAxisKey}`, label: 'Date' },
            ]}
            yAxis={[
              {
                label: 'Cart Value (€)',
                // min: 0,
              },
            ]}
            series={[{ dataKey: 'average_cart_value', valueFormatter }]}
            height={300}
            colors={['#8884d8']}
            margin={{ left: 50, right: 30, top: 30, bottom: 40 }}
            grid={{ vertical: true, horizontal: true }}
            sx={(theme) => ({
              [`.${axisClasses.root}`]: {
                [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                  stroke: '#8b909a',
                  strokeWidth: 3,
                },
                [`.${axisClasses.tickLabel}`]: {
                  fill: '#8b909a',
                },
              },
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                fill: '#8b909a',
                // transform: 'translate(-10px, -10px)',
              },
              [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                fill: '#8b909a', // Change color of x-axis label
              },
              [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
                fill: '#8b909a', // Change color of x-axis tick labels
              },
            })}
          />
        </div>
      </div>
    </>
  );
};

export default AverageCartValueLineChart;
