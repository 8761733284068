import { menuDotsVerticalSVG } from '../../lib/allCustomSVGs';
import '../../styles/components/subPageList/subPageList.scss';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import SubPageListDropdown from '../dropdowns/SubPageListDropdown';
/* import {SubPageListItemInterface} from '../../interfaces/SubPage/SubPage'; */

interface Props {
  mainTitle?: string;
  subTitle?: string;
  onListMenuClick: (e: any) => void;
  onListItemClick: () => void;
  isListMenuOpen: any;
  items: any;
  dropdownItems: {
    id: string;
    icon: any;
    title: string;
    subTitle: string;
    url: string;
  }[];
}

const SubPageList = ({
  mainTitle,
  subTitle,
  items,
  dropdownItems,
  onListMenuClick,
  isListMenuOpen,
  onListItemClick,
}: Props) => {
  return (
    <div className='subPageList'>
      <div className='subPageList__top'>
        <h1>{mainTitle}</h1>
        <span>{subTitle}</span>
      </div>
      <ul className='subPageList__bottom'>
        {items?.map((item: any, i: number) => {
          return (
            <li key={i} className='subPageList__bottom--item'>
              <div className='subPageList__bottom--item-left'>
                {item?.img && (
                  <div className='subPageList__bottom--item-left-img'>
                    <img src={item?.img} alt={item?.title} />
                  </div>
                )}
                <div className='subPageList__bottom--item-left-titles'>
                  <h3>{item?.title}</h3>
                  <p>{item?.subTitle}</p>
                  {item?.sKey && (
                    <span>{`${item?.sKey?.slice(0, 6)}**********`}</span>
                  )}
                </div>
              </div>
              <div className='subPageList__bottom--item-right'>
                {isListMenuOpen[item?.id] && (
                  <SubPageListDropdown
                    id={item?.id}
                    dropdownItems={dropdownItems}
                    onListItemClick={onListItemClick}
                  />
                )}
                <div
                  className='subPageList__bottom--item-right-svg'
                  onClick={() => {
                    onListMenuClick(item?.id);
                  }}
                >
                  <CustomSVGs svg={menuDotsVerticalSVG} />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SubPageList;
