import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import CMSConnection from './cmsConnection/CMSConnection';
import CMSImport from './cmsImport/CMSImport';
import CMSSync from './cmsSync/CMSSync';
import { allRoutes } from '../../../lib/constants/route';

const CMS = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/integrations/cms/connection');
  }, []);

  return (
    <Routes>
      {allRoutes['/integrations/cms/connection'] && (
        <Route path='connection/*' element={<CMSConnection />} />
      )}
      {allRoutes['/integrations/cms/import'] && (
        <Route path='import/*' element={<CMSImport />} />
      )}
      {allRoutes['/integrations/cms/sync'] && (
        <Route path='sync/*' element={<CMSSync />} />
      )}
      {/* <Route path='options/*' element={<CMSOptions />} /> */}
    </Routes>
  );
};

export default CMS;
