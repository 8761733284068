import { CodeSection } from 'react-code-section-lib';
import '../../../../styles/pages/shop/autofill/autofill.scss';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../../../store/store';
import { useTranslation } from 'react-i18next';

const value = `
//autofill script
<script id="autofillscript" data-autofill_api_key="<YOUR_AUTOFILL_API_KEY>" src="./autofill.js"></script>
`;

const Instructions = () => {
  const { t } = useTranslation();
  const isAppThemeDark = useSelector((state: RootState) => state.UI.theme.dark);
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;

  return (
    <SubPageInnerContentHolder
      title={t('Instructions')}
      subTitle={t('InstructionsSubTitle')}
      backBtnLink={isWhiteLabel ? undefined : '/integrations'}
      mainBtnIcon={undefined}
      mainBtnTitle={undefined}
      mainBtnLink={undefined}
    >
      <div className='autofillInfo'>
        <h1>{t('InstructionsHeading')}</h1>

        <div className='autofillInfo__steps'>
          <div className='autofillInfo__steps--item'>
            <h2>{t('AutofillInstructionStep1')}</h2>
            <div className='autofillInfo__steps--item-codeblock'>
              <CodeSection
                showLineNumbers
                theme={isAppThemeDark ? 'dark' : 'light'}
                lang={'html'}
              >
                {value}
              </CodeSection>
            </div>
          </div>
        </div>
      </div>
    </SubPageInnerContentHolder>
  );
};

export default Instructions;
