export const availableTrackableInputs = {
  countryCodeInputElement: 'country_code',
  phoneNumberInputElement: 'phone_number',
  firstNameInputElement: 'first_name',
  lastNameInputElement: 'last_name',
  emailInputElement: 'email',
  billingHouseNoInputElement: 'billing_house_no',
  billingStreetInputElement: 'billing_street',
  billingStreet2InputElement: 'billing_street_2',
  billingCityInputElement: 'billing_city',
  billingProvinceInputElement: 'billing_province',
  billingPostalCodeInputElement: 'billing_postal_code',
  billingCountryInputElement: 'billing_country',
  shippingHouseNoInputElement: 'shipping_house_no',
  shippingStreetInputElement: 'shipping_street',
  shippingStreet2InputElement: 'shipping_street_2',
  shippingCityInputElement: 'shipping_city',
  shippingProvinceInputElement: 'shipping_province',
  shippingPostalCodeInputElement: 'shipping_postal_code',
  shippingCountryInputElement: 'shipping_country',
};
