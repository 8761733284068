import { successSVG } from '../../lib/allCustomSVGs';
import MessageBanner from '../../components/messageBanner/MessageBanner';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { setSubscriptionLock } from '../../store/reducers/authSlice';
import store from '../../store/store';
import { useNavigate } from 'react-router-dom';

const StripeSubscriptionSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    store.dispatch(setSubscriptionLock('false'));
  }, []);

  return (
    <>
      <MessageBanner
        title={t('StripeSubscriptionSuccessMainText')}
        subTitle={t('StripeSubscriptionSuccessSubText')}
        icon={successSVG}
        btnTitle='Ok'
        onSelect={() => {
          if (window?.opener) {
            window.opener?.location?.reload();
            window.close();
          } else {
            navigate('/');
          }
        }}
        success={true}
      />
    </>
  );
};

export default StripeSubscriptionSuccess;
