import React from 'react';
import '../../styles/pages/ai-onboarding/aiOnboardingReady.scss';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import {
  setAiOnboardingInprogress,
  setIsAiOnboardingCompleted,
  setAiOnboardingStepCompleted,
} from '../../store/reducers/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { tickWithCircleSVG } from '../../lib/allCustomSVGs';
import { setRunInitOnFirstLoad } from '../../store/reducers/appSlice';
import { setIsOnboardingCompleteBannerOpen } from '../../store/reducers/uiSlice';
import { HOME_ROUTE } from '../../lib/constants/route';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/store';

const AIOnboardingReady = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isWhiteLabel = useSelector((state: RootState) => state.UI.isWhiteLabel);
  const whiteLabelRedirectPath = useSelector(
    (state: RootState) => state.auth.authorization.whiteLabelRedirectPath
  );
  return (
    <div className='aiOnboardingReady'>
      <div className='aiOnboardingReady__title'>{t('WelcomeToAdUp')}</div>
      <div className='aiOnboardingReady__icon'>
        <CustomSVGs svg={tickWithCircleSVG} />
      </div>
      <div className='aiOnboardingReady__info'>{t('WelcomeToAdUp-text')}</div>
      <div className='aiOnboardingReady__button'>
        <MainSquareButton
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              setAiOnboardingStepCompleted({ stepNumber: 3, isCompleted: true })
            );

            //hit init
            dispatch(setRunInitOnFirstLoad(true));

            //Open banner
            dispatch(setIsOnboardingCompleteBannerOpen(true));

            //go to home page
            if (isWhiteLabel) {
              if (whiteLabelRedirectPath && whiteLabelRedirectPath !== '') {
                navigate(whiteLabelRedirectPath);
              } else {
                navigate(HOME_ROUTE);
              }
            } else {
              navigate(HOME_ROUTE);
            }
            dispatch(setIsAiOnboardingCompleted(true));
            dispatch(setAiOnboardingInprogress(false));
          }}
          type={'button'}
          value={t('ContinueToDashboard')}
          normal={true}
        />
      </div>
    </div>
  );
};

export default AIOnboardingReady;
