import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import '../../styles/banners/onboardingCompleteBanner.scss';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface Props {
  styles?: any;
}

const OnboardingCompleteBanner = ({ styles }: Props) => {
  const { t } = useTranslation();
  const merchantName = useSelector(
    (state: RootState) => state.user?.merchant?.config?.merchant_name
  );

  const [loadingText, setLoadingText] = useState('Loading');

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingText((prev) => {
        if (prev === 'Loading...') return 'Loading';
        return prev + '.';
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);
  return (
    <motion.div
      exit={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      style={styles}
      className='onboardingCompleteBanner'
    >
      <div className='onboardingCompleteBanner__content '>
        <div className='onboardingCompleteBanner__content--text'>
          <h1>
            {t('WelcomeTo')}{' '}
            {merchantName && merchantName !== '' ? merchantName : 'AdUp'}
          </h1>
          <p>{t('AutomateYour-CRO-With-AI')}</p>
        </div>
        <div className='onboardingCompleteBanner__content--animation'>
          <svg viewBox='0 0 100 100'>
            <circle
              cx='50'
              cy='50'
              r='45'
              fill='none'
              stroke='#E5E7EB'
              strokeWidth='8'
            />
            <circle
              cx='50'
              cy='50'
              r='45'
              fill='none'
              stroke='#3B82F6'
              strokeWidth='8'
              strokeLinecap='round'
              strokeDasharray='280'
              strokeDashoffset='280'
            >
              <animate
                attributeName='stroke-dashoffset'
                values='280;0'
                dur='2s'
                repeatCount='indefinite'
              />
            </circle>
          </svg>
          <p>{loadingText}</p>
        </div>
      </div>
    </motion.div>
  );
};

export default OnboardingCompleteBanner;
