export const getOnlyFirstLettersOfAttributes = (item: string) => {
  /* 
  input string = 'black-small-2022'
  output string = 'b-s-2'
  */
  const trimedItem = item?.split('-');
  let letters = '';
  if (trimedItem?.length > 0) {
    trimedItem?.forEach((el, i) => {
      if (trimedItem?.length - 1 === i) {
        letters += el[0];
      } else {
        letters += el[0] + '-';
      }
    });
  }
  return letters;
};

export const trimFromStartToLimitedNumber = (string: string, limit: number) => {
  /* 
  input string has  26 chars = 'Super-Fast-Phone-tPOiMAP-b'
  if limit = 20
  output will have 20 chars = 'Fast-Phone-tPOiMAP-b'
  */
  if (string?.length > limit) {
    const numberOfCharsToTrim = string?.length - limit;
    return string.substring(numberOfCharsToTrim);
  }
  return string;
};
