import { useTranslation } from 'react-i18next';
import '../../../../styles/components/charts/chart.scss';
import { useEffect, useState } from 'react';
import OverallConversionRatePieChart from './OverallConversionRatePieChart';
import LocationBasedConversionRatePieChart from './LocationBasedConversionRatePieChart';
import { RootState } from '../../../../store/store';
import get_graph_data_api_call from '../../../../lib/api/stats/get_graph_data_api_call';
import SingleCountryConversionRatePieChart from './SingleCountryConversionRatePieChart';
import { useSelector } from 'react-redux';
import ChartWithConfig from '../../ChartWithConfig';

const MainOverallAndLocationBasedCRChart = () => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    '?graph_type=conversion_rate&time_range=all'
  );
  const [response, setResponse] = useState<any>({ type: 'overall', data: {} });
  const [isBackButtonVisible, setIsBackButtonVisible] =
    useState<boolean>(false);

  //Time filter
  const [timeFilter, setTimeFilter] = useState<string>('all');
  const timeFilterOptionsArr = [
    { OptionValue: 'all', OptionName: t('All') },
    { OptionValue: 'last_24_hours', OptionName: t('Last24Hrs') },
    { OptionValue: 'last_7_days', OptionName: t('Last7Days') },
    { OptionValue: 'last_30_days', OptionName: t('Last30Days') },
    { OptionValue: '3_months', OptionName: t('Last3Months') },
  ];

  //Group by options
  const [groupBy, setGroupBy] = useState<string>('overall');
  const groupByOptionsArr = [
    { OptionValue: 'overall', OptionName: t('Overall') },
    { OptionValue: 'location', OptionName: t('Location') },
  ];

  //Selected country
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [title, setTitle] = useState<string>(t('OverallConversionRate'));
  const [comment, setComment] = useState<string>('');

  const renderCharts = () => {
    if (groupBy === 'overall') {
      return <OverallConversionRatePieChart apiResponse={response} />;
    } else if (groupBy === 'location' && selectedCountry === '') {
      return (
        <LocationBasedConversionRatePieChart
          apiResponse={response}
          setSelectedCountry={setSelectedCountry}
        />
      );
    } else if (groupBy === 'location' && selectedCountry !== '') {
      return <SingleCountryConversionRatePieChart apiResponse={response} />;
    }
  };

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        if (selectedCountry && selectedCountry !== '') {
          setResponse({ type: 'country', data: res });
        } else {
          setResponse({ type: groupBy, data: res });
        }
      } else {
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    if (groupBy === 'overall') {
      setTitle(t('OverallConversionRate'));
      setComment(t('TheOverallConversionRate'));
    } else if (groupBy === 'location' && selectedCountry === '') {
      setTitle(t('LocationBasedConversionRate'));
      setComment(t('TheConversionRateOfCartsToCheckoutsBasedOnLocation'));
    } else if (groupBy === 'location' && selectedCountry !== '') {
      setTitle(`${t('TheConversionRateOf')} ${selectedCountry}`);
      setComment(`${t('ConversionRateCartsToCheckoutsIn')} ${selectedCountry}`);
    }
  }, [groupBy, selectedCountry]);

  useEffect(() => {
    setSelectedCountry('');
  }, [groupBy]);

  useEffect(() => {
    setResponse({ type: 'load', data: [] });
    if (groupBy === 'overall') {
      setParams(`?graph_type=conversion_rate&time_range=${timeFilter}`);
    } else if (groupBy === 'location') {
      if (selectedCountry && selectedCountry !== '') {
        setParams(
          `?graph_type=conversion_rate&time_range=${timeFilter}&group_by=location&group_by_value=${selectedCountry}`
        );
      } else {
        setParams(
          `?graph_type=conversion_rate&time_range=${timeFilter}&group_by=location`
        );
      }
    }
  }, [groupBy, timeFilter, selectedCountry]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  const onBackButtonClick = () => {
    if (groupBy === 'location' && selectedCountry !== '') {
      setSelectedCountry('');
    }
  };

  useEffect(() => {
    if (groupBy === 'location' && selectedCountry !== '') {
      setIsBackButtonVisible(true);
    } else {
      setIsBackButtonVisible(false);
    }
  }, [groupBy, selectedCountry]);

  return (
    <ChartWithConfig
      groupBy={groupBy}
      setGroupBy={setGroupBy}
      groupByOptionsArr={groupByOptionsArr}
      timeFilter={timeFilter}
      setTimeFilter={setTimeFilter}
      timeFilterOptionsArr={timeFilterOptionsArr}
      isBackButtonVisible={isBackButtonVisible}
      onBackButtonClick={onBackButtonClick}
      title={title}
      comment={comment}
    >
      {renderCharts()}
    </ChartWithConfig>
  );
};

export default MainOverallAndLocationBasedCRChart;
